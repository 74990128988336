import {
    REQUEST_INSURANCE_REQUEST,
    REQUEST_INSURANCE_SUCCESS,
    REQUEST_INSURANCE_FAILURE,
    REQUEST_INSURANCE_CLEAR
} from "../../actions/skt/RequestInsuranceAction"

const saveActionState = {
    isFetching: false,
    saveResponse: "",
    isError: false
}

 // SAVE UNIV ACTION REDUCER
 export const insuranceAction = (state = saveActionState, action) => {
    switch(action.type) {
        case REQUEST_INSURANCE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveResponse: "REQUEST",
                isError: false
            });
        case REQUEST_INSURANCE_SUCCESS:
            let _saveResponse = "";
            let _isError = false;
            if (action.data === 400) {
                _saveResponse = "400 パラメータが不正です";
                _isError = true;
            } else if (action.data === 500) {
                _saveResponse = "500 予期しないエラーが発生しました";
                _isError = true;
            } else {
                _saveResponse = action.data;
            }
            return Object.assign({}, state, {
                isFetching: false,
                saveResponse: _saveResponse,
                isError: _isError
            });
        case REQUEST_INSURANCE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                saveResponse: action.err,
                isError: true
            });
        case REQUEST_INSURANCE_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                saveResponse: "",
                isError: false
            });
        default:
            return state;
    }
}