import React from "react";
import "../../../css/Common.css";
import "../../../css/University.css";
import TextInput from "../../atoms/TextInput";
import Button from "../../atoms/Button";
import Load from "../../atoms/Load";
import {connect} from "react-redux";
import {getUniversityDetail, resetUniversityDetail} from "../../../actions/skt/GetUnivAction";
import {saveUniversityData, resetSaveUniversity} from "../../../actions/skt/ControllUniversityAction";
import {FACE_IMAGE, SKTValidationCheck, SKTErrorMap, PeriodMap, CountryMap, DivisionMap, SectionMap, JpTeacherMap, EnglishMap, JapaneseMap} from "../../../constants/CommonConstatns";
import Error from "../../atoms/Error"

class UnivDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            universityId: "",
            index_num: 0,
            univData: {},
            errorMessage: [],
            saveInfo: "",
            countryStates: 0,
            apiName: "",
        }
        this.errorDisable = this.errorDisable.bind(this);
    }

    componentDidMount = () => {
        if(this.props.universityId !== ""){
            this.props.getDetailInfo(this.props.universityId);
        }
        this.setState({
            universityId: this.props.universityId,
            index_num: this.props.index_num
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if ((prevState.universityId === nextProps.universityId_detail) && (prevState.univData !== nextProps.univData)) {
            console.log(Object.keys(prevState.univData).length)
            if (Object.keys(prevState.univData).length < 1) {
                var data = nextProps.univData;
                if (Object.keys(data).length > 1) {
                    data.university_id = prevState.universityId;
                }
                return {
                    univData: data,
                    apiName: "skt_university_detail"
                }
            }
        }
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo)
    }

    errorDisable = () => {
        this.props.resetSaveUniversity();
        this.props.resetUniversityDetail();
    }

    saveUniversityData = () => {
        let result = SKTValidationCheck(this.state.univData);
        this.setState({
            errorMessage: result
        })
        if (result.length === 0) {
            this.state.univData.university_id = this.props.universityId;
            this.setState({
                univData: this.state.univData,
                apiName: "skt_save_university"
            });
            this.props.saveUniversityData(this.state.univData)
        }
    }

    setCountryStates = (event) => {
        var val = event.target.value === "0" ? null : parseInt(event.target.value);
        this.state.univData.country = val;
        this.setState({univData: this.state.univData});
    }

    setUniversityName = (text) => {
        this.state.univData.university_name = text;
        this.setState({univData: this.state.univData});
    }

    setUniversityLocation = (text) => {
        this.state.univData.university_location = text
        this.setState({univData: this.state.univData});
    }

    setPeople = (text) => {
        this.state.univData.people = text;
        this.setState({univData: this.state.univData});
    }

    setSection = (event) => {
        var val = event.target.value === "" ? null : event.target.value;
        this.state.univData.section = val;
        this.setState({univData: this.state.univData});
    }

    setPeriod = (event) => {
        this.state.univData.period = parseInt(event.target.value);
        this.setState({univData: this.state.univData});
    }

    setDivision = (event) => {
        this.state.univData.division = parseInt(event.target.value);
        this.setState({univData: this.state.univData});
    }

    setJpTeacher = (event) => {
        
        this.state.univData.jpteacher = event.target.value === "true" ? true : false;
        this.setState({univData: this.state.univData});
    }

    setJapanese = (event) => {
        var val = event.target.value === "" ? null : event.target.value;
        this.state.univData.japanese = val;
        this.setState({univData: this.state.univData});
    }

    setEnglish = (event) => {
        var val = event.target.value === "" ? null : event.target.value;
        this.state.univData.english = val;
        this.setState({univData: this.state.univData});
    }

    setTeacherName = (text) => {
        this.state.univData.teacher_name = text;
        this.setState({univData: this.state.univData});
    }

    setTeacherMail = (text) => {
        this.state.univData.teacher_mail = text;
        this.setState({univData: this.state.univData});
    }

    onFileChange(e) {
        const files = e.target.files;
        if(files.length > 0) {
            var file = files[0];
            var reader = new FileReader();
            reader.onload = (e) => {
                this.state.univData.university_logo = e.target.result;
                this.setState({
                    univData: this.state.univData
                });
            };
            reader.readAsDataURL(file)
        } else {
            this.state.univData.university_logo = null;
            this.setState({
                univData: this.state.univData
            });
        }
    }   

    render() {
        var countryStatesptions = Object.entries(CountryMap).map(
            function([key, value]){
                var dispValue = value;
                if(key === 0){
                    dispValue = ""
                }
                return(
                <option key={key} value={key}>
                    {dispValue}
                </option>
                )
            }
        );

        var facultyptions = Object.entries(DivisionMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {value}
                </option>
            )
        );
        var sectionStatesptions = Object.entries(SectionMap).map(
            ([key, value])=>(
                <option key={key} value={value}>
                    {value}
                </option>
            )
        );
        var jpteacheroptions = Object.entries(JpTeacherMap).map(
            function([key, value]){
                var selectkey = key === 0 ? false : true;
                return(
                <option key={key} value={selectkey}>
                    {value}
                </option>
                )
            }
        );

        var periodoptions = Object.entries(PeriodMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {value}
                </option>
            )
        );
        var japaneseoptions = Object.entries(JapaneseMap).map(
            ([key, value])=>(
                <option key={key} value={value}>
                    {value}
                </option>
            )
        );
        var englishptions = Object.entries(EnglishMap).map(
            ([key, value])=>(
                <option key={key} value={value}>
                    {value}
                </option>
            )
        );
        const imageData = this.state.univData.university_logo;
        let preview = '';
        if(imageData) {
            preview = (
                <div className="editUploadImage"><img src={imageData} alt=""/></div>
            );
        }
        else {
            preview = (
                <div className="editUploadImage"><img src={FACE_IMAGE} alt=""/></div>
            );
        }

        let isDisplay = (this.state.errorMessage.length === 0) ? "errorArea notDisplay": "errorArea";

        return (
            <article>
                {this.props.isSaveData || this.props.isGetInfo? <Load setLabel="ただいま処理中です。しばらくお待ちください。"/>: null}
                { this.props.getMessageDetail ? <Error close={this.errorDisable} apiName={this.state.apiName} setError={this.props.getMessageDetail}/>: null }
                { this.props.getMessageSave ? <Error close={this.errorDisable} apiName={this.state.apiName} setError={this.props.getMessageSave}/>: null }
                    <div className="breadcrumbArea layoutInner">
                        <ol className="breadcrumbList">
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("university")}>大学情報検索</a></li>
                            <li className="breadcrumbItem">大学情報詳細</li>
                        </ol>
                    </div>
                    <div className="layoutInner">
                      以下の項目を入力し、「保存」ボタンを押してください。「<span className="editRequired">*</span>」のついた項目は必須入力です。
                    </div>
                     <ul className={isDisplay}>
                        {
                            this.state.errorMessage.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{SKTErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                <section className="editItem">
                    <form action="" method="post">
                        <div className="editRow">
                            <div className="editInputItem">
                                <label htmlFor="universityId">大学ID</label>
                                <div className="editInputInputArea">
                                    <input id="universityId" name="universityId" type="text" required={true} maxLength="50"
                                        value={this.state.universityId === 0 ? null : this.state.universityId} disabled />
                                </div>
                                <label htmlFor="candidatePhoto">ロゴ<span className="editRequired">*</span></label>
                                <div className="editUploadImage">
                                    {preview}
                                </div>
                                <div className="editInputInputArea">
                                    <div className="editUploadButton">
                                        <label className="fileLabel">
                                            <input id="candidatePhoto" name="candidatePhoto" type="file"
                                                    accept="image/*" ref={this.fileInput} onChange={(e) => {
                                                this.onFileChange(e)
                                            }} />
                                            <div className="fileButton">ファイル選択</div>
                                        </label>
                                    </div>
                                </div>
                                <label htmlFor="universityName">大学名<span className="editRequired">*</span></label>
                                <div className="editInputInputArea">
                                    <TextInput id="universityName" name="universityName" type="text" required={true} maxLength="300" 
                                        setValue={this.state.univData.university_name} onHandleChange={(e) => this.setUniversityName(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="editRow">
                            <div className="editInputItem">
                                <label htmlFor="term">所在国<span className="editRequired">*</span></label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="term" name="term" required value={this.state.univData.country} onChange={this.setCountryStates}>
                                            {countryStatesptions}
                                    </select>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="placeName">所在地</label>
                                <div className="editInputInputArea">
                                    <TextInput id="placeName" name="placeName" type="text" required={false} maxLength="300"
                                        setValue={this.state.univData.university_location} onHandleChange={(e) => this.setUniversityLocation(e)}/>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="people">学生の人数</label>
                                <div className="editInputInputArea">
                                    <TextInput id="people" name="people" type="text" required={false} maxLength="300"
                                        setValue={this.state.univData.people} onHandleChange={(e) => this.setPeople(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="editRow">
                            <div className="editInputItem">
                                <label htmlFor="section">設置区分</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="section" name="section" value={this.state.univData.section} onChange={(e) => this.setSection(e)}>
                                       {sectionStatesptions}
                                    </select>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="period">就業期間</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="period" name="period" value={this.state.univData.period} onChange={(e) => this.setPeriod(e)}>
                                       { periodoptions }
                                    </select>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="faculty">学部</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="faculty" name="faculty" value={this.state.univData.division} onChange={(e) => this.setDivision(e)}>
                                       { facultyptions }
                                    </select>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="teacher">日本語教育専門<br />の先生の有無</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="teacher" name="teacher" type="text" value={this.state.univData.jpteacher} onChange={(e) => this.setJpTeacher(e)}>
                                       { jpteacheroptions }
                                    </select>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="japaneseLevel">日本語レベル</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="japaneseLevel" name="japaneseLevel" value={this.state.univData.japanese} onChange={(e) => this.setJapanese(e)}>
                                       { japaneseoptions }
                                    </select>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="englishLevel">英語レベル</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="englishLevel" name="englishLevel" value={this.state.univData.english} onChange={(e) => this.setEnglish(e)}>
                                       { englishptions }
                                    </select>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="teacherName">担当先生名</label>
                                <div className="editInputInputArea">
                                    <TextInput id="teacherName" name="teacherName" type="text" maxLength="300" required={false} 
                                        setValue={this.state.univData.teacher_name} onHandleChange={(e) => this.setTeacherName(e)}/>
                                </div>
                            </div>
                            <div className="editInputItem">
                                <label htmlFor="teacherEmail">担当先生のメールアドレス</label>
                                <div className="editInputInputArea">
                                    <TextInput id="teacherEmail" name="teacherEmail" type="text" maxLength="300" required={false}
                                        setValue={this.state.univData.teacher_mail} required={false} onHandleChange={(e) => this.setTeacherMail(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="editButtonArea">
                            <div className="editSaveButton">
                                <Button label="登録" buttonEnable={true}
                                    callback={() => this.saveUniversityData()}/>
                            </div>
                        </div>
                    </form>
                </section>
            </article>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        saveInfo: state.saveUniversityData.saveInfo,
        univData: state.getUniversityDetail.univDetail,
        universityId_detail: state.getSktUniversityDetail.university_id,
        isGetInfo: !state.getSktUniversityDetail.isGetInfo,
        getMessageDetail: state.getSktUniversityDetail.getMessage,
        getMessageSave: state.saveUniversityData.errorMessage,
        isSaveData: state.saveUniversityData.isSave,
    }
}


const mapDispatchToProps = (dispatch) => ({
    saveUniversityData: (univData) => dispatch(saveUniversityData(univData)),
    getDetailInfo: (universityId) => dispatch(getUniversityDetail(universityId)),
    resetSaveUniversity: () => dispatch(resetSaveUniversity()),
    resetUniversityDetail: () => dispatch(resetUniversityDetail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnivDetail);
