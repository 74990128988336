import React from "react";
import {withRouter} from "react-router";
import TextInput from "./atoms/TextInput";
import PasswordInput from "./atoms/PasswordInput";
import Button from "./atoms/Button";
import {LoginError} from "../constants/CommonConstatns"
import "../css/Login.css"
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";

const config = require('./config');
const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId,
  ClientId: config.ClientId,
  Storage: sessionStorage
});

const CognitoUrl = 'https://cognito-idp.ap-northeast-1.amazonaws.com/';
var session;

class LoginTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: "",
            psw: "",
            showing: false,
            buttonEnable: true,
            loginButtonEnable: true,
            otpw: "",
            errorShowing: false,
            errorText: "",
            challengeId: ""
        }
    }

    handleToActivate = () => {
        this.setState({showing: true})
        this.setState({challengeId: this.state.id})
        this.setState({loginButtonEnable: true})
        this.start_call()
    }

    handleToMainPage = (user_type) => {
        switch(user_type) {
            case "H":
                this.props.history.push("/hotel/home");
                break;
            case "U":
                this.props.history.push("/univ/home");
                break;
            case "S":
            case "I":
                this.props.history.push("/skt/home");
                break;
            case "K":
                this.props.history.push("/manage/home");
                break;
            case "O":
                this.props.history.push("/send/home");
                break;
            default:
                break;
        }
    }

    checkIdAction = (text) => {
      this.setState({id: text})
    }

    checkPswAction = (text) => {
      this.setState({psw: text})
    }

    checkOtpwAction = (text) => {
      this.setState({otpw: text})
    }

    signInCognito = () => {
        this.setState({errorShowing: false})
        this.setState({buttonEnable: false})
        const authenticationData = {
            Username : this.state.id,
            Password : this.state.psw,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        const userData = {
            Username : this.state.id,
            Pool : userPool
        };
        let _this = this;
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                console.log("認証済み");
                localStorage.clear()
                _this.handleToActivate();
            },
            mfaSetup: function (challengeName, challengeParameters) {
                // ユーザープールでMFAが有効化されていると発生します
                console.log('mfaSetup');
                console.log('ユーザープールでMFAが有効化されていると発生します');
                cognitoUser.associateSoftwareToken(this);
            },
             associateSecretCode: function (secretCode) {
                // MFA有効化されていてTOTP初回認証時に発生します
                // SecretCodeが発行されるので、Google Authenticatorなどに登録できるよう
                // QRコードを生成します。
                console.log('associateSecretCode');
                console.log('MFA有効化されていてTOTP初回認証時に発生します');
                console.log('シークレットコード: ' + secretCode);
            },
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // ユーザー作成後、初回ログイン時にパスワード変更が必要になる
                // とりあえず、仮パスワードをそのまま設定しています
                console.log('ユーザー作成後、初回ログイン時にパスワード変更が必要');
                cognitoUser.completeNewPasswordChallenge(_this.state.psw, {}, this);
            },
            totpRequired: function (secretCode) {
                // ワンタイムパスワード要求時に発生
                console.log('totpRequired');
                console.log('ワンタイムパスワード要求時に発生');

            },
            onFailure: function (err) {
                console.log('onFailure');
                console.log('認証失敗時に発生します');
                console.log(err);
                _this.setState({buttonEnable: true})
                _this.setState({errorShowing: true})
                _this.setState({errorText: LoginError[0]})
            }
        });
    };

    do_post_aws = (url, body, target) => {
        const headers = new Headers( { "Content-type" : "application/x-amz-json-1.1", 'X-Amz-Target': target } );
        return fetch(url, {
            method : 'POST',
            body : JSON.stringify(body),
            headers: headers
        })
        .then((response) => {
            return response.json();
        });
    }

    start_call = () => {

        var params = {
            AuthFlow: 'CUSTOM_AUTH',
            ClientId: config.ClientId,
            AuthParameters: {
                'USERNAME' : this.state.challengeId
            }
        };

        this.do_post_aws(CognitoUrl, params, 'AWSCognitoIdentityProviderService.InitiateAuth' )
        .then(data =>{
            session = data.Session;
        });
    }

    respond_call = () =>{
        this.setState({buttonEnable: true})
        this.setState({loginButtonEnable: false})
        this.setState({errorShowing: false})
        var params = {
            ChallengeName: 'CUSTOM_CHALLENGE',
            ClientId: config.ClientId,
            ChallengeResponses: {
                'USERNAME' : this.state.challengeId,
                'ANSWER' : this.state.otpw
            },
            Session: session,
        };
        this.do_post_aws(CognitoUrl, params, 'AWSCognitoIdentityProviderService.RespondToAuthChallenge' )
        .then(data =>{
            session = data.Session;
            if(data.AuthenticationResult) {
                const userData = {
                    Username : this.state.challengeId,
                    Pool : userPool
                };
                const cognitoUser = new CognitoUser(userData);
                cognitoUser.getCognitoUserSession(data.AuthenticationResult);
                const usernameKey = 'CognitoIdentityServiceProvider.' + config.ClientId + '.LastAuthUser';
                sessionStorage.setItem(usernameKey, this.state.challengeId);
                const idTokenKey = 'CognitoIdentityServiceProvider.' + config.ClientId + '.' + this.state.challengeId + '.idToken';
                sessionStorage.setItem(idTokenKey, data.AuthenticationResult.IdToken);
                const accessTokenKey = 'CognitoIdentityServiceProvider.' + config.ClientId + '.' + this.state.challengeId + '.accessToken';
                sessionStorage.setItem(accessTokenKey, data.AuthenticationResult.AccessToken);
                const refreshTokenKey = 'CognitoIdentityServiceProvider.' + config.ClientId + '.' + this.state.challengeId + '.refreshToken';
                sessionStorage.setItem(refreshTokenKey, data.AuthenticationResult.RefreshToken);
                const currentUser = userPool.getCurrentUser();

                currentUser.getSession((err, session) => {
                    currentUser.getUserAttributes((err, attributes) =>  {
                        if (attributes) {
                            const userType = attributes.filter(attr => {
                                return attr.getName() === "custom:user_type"
                            })
                            this.handleToMainPage(userType[0].getValue());
                        }
                    });
                });
                
            }else{
                this.setState({
                    buttonEnable: true,
                    errorShowing: true,
                    errorText: LoginError[1],
                    loginButtonEnable: false,
                    otpw: ""
                });
            }
        });
    }

    render() {
        return (
            <article className="contentsArea layoutInner">
                <div className="loginArea">
                    <form className="loginForm">
                        { this.state.errorShowing
                            ? <div className="errorArea">
                                <p className="errorText">{this.state.errorText}</p>
                            </div> : null }
                        <div className="loginInput">
                            <TextInput onHandleChange={(e) => this.checkIdAction(e)} type="text" className="loginInput" ph="　ログインID" maxLength="100"/>
                        </div>
                        <div className="loginInput">
                            <PasswordInput onHandleChange={(e) => this.checkPswAction(e)} type="password" className="loginInput" maxLength="100"/>
                        </div>
                        <div className="loginButton">
                            <Button label="認証キー送信" buttonEnable={this.state.buttonEnable} callback={this.signInCognito}/>
                        </div>

                        { this.state.showing 
                            ? <div className="authObject">
                                  <p id="noticeOtpw">※認証キーの有効期限は3分です。</p>
                                  <div className="loginInput">
                                      <TextInput type="text" setValue={this.state.otpw} onHandleChange={(e) => this.checkOtpwAction(e)} className="loginInput" maxLength="100"/>
                                  </div>
                                  <div className="loginButton">
                                      <Button label="ログイン" buttonEnable={this.state.loginButtonEnable} callback={this.respond_call}/>
                                  </div>
                             </div>
                        : null
                        }
                    </form>
                </div>
            </article>
        )
    }
}


export default withRouter(LoginTemplate);
