import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import "../../css/EditIntern.css";
import {ErrorMap} from "../../constants/ManageConstants";

class EditJob7 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            accident_insurance: true,
            reception_whole: true,
            pre_service_training: false,
            promotion_request: false,
            isSaveDetail: false,
            error_message: [],
            isSaveSuccess: false
        }
        this.getAccidentInsurance = this.getAccidentInsurance.bind(this);
        this.getReceptionWhole = this.getReceptionWhole.bind(this);
        this.getPreServiceTraining = this.getPreServiceTraining.bind(this);
        this.getPromotionRequest = this.getPromotionRequest.bind(this);
    }

    componentDidMount = () => {
        if ("insurance" in this.props.jobData) {
            this.setState({
                error_message: this.props.errorMessage,
                accident_insurance: this.props.jobData.insurance.accept_accident,
                reception_whole: this.props.jobData.insurance.accept_human_resources,
                pre_service_training: this.props.jobData.insurance.request_training,
                promotion_request: this.props.jobData.insurance.request_promotion,
                isSaveSuccess: false
            });
        } else {
            this.setState({
                error_message: this.props.errorMessage,
            });
        }
        
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.saveState === 200 && this.props.saveState !== prevProps.saveState) {
            if (this.state.isSaveDetail) {
               this.setState({
                   isSaveSuccess: true
               });
            }
        }
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }
    
    temporalySave = () => {
        this.setState({
            isSaveDetail: false
        }, () => {
                this.props.saveTemporaly();
            }
        )
    }

    saveDetailJob = () => {
        this.props.saveReset();
        this.setState({
            isSaveDetail: true
        }, () => {
                this.props.saveJobDetail()
            }
        )
    }

    getAccidentInsurance(event) {
        this.setState({
            accident_insurance: event.target.checked
        }, () => {
            this.props.getInsuranceStatus(this.createInsuranceStatus());
        });
    }

    getReceptionWhole(event) {
        this.setState({
            reception_whole: event.target.checked
        }, () => {
            this.props.getInsuranceStatus(this.createInsuranceStatus());
        });
    }

    getPreServiceTraining(event) {
        this.setState({
            pre_service_training: event.target.checked
        }, () => {
            this.props.getInsuranceStatus(this.createInsuranceStatus());
        });
    }

    getPromotionRequest(event) {
        this.setState({
            promotion_request: event.target.checked
        }, () => {
            this.props.getInsuranceStatus(this.createInsuranceStatus());
        });
    }

    addErrorMessage = (errorMessage) => {
        this.setState({
            error_message: errorMessage
        }, () => {
            window.scrollTo(0, 0);
        });
    }

    addInternTitle = (internTitle) =>  {
        // ここでは何もしない
    }

    createInsuranceStatus = () => {
        const sendState =  {
            "accept_accident": this.state.accident_insurance,
            "accept_human_resources": this.state.reception_whole,
            "request_training": this.state.pre_service_training,
            "request_promotion": this.state.promotion_request
        }

        return JSON.stringify(sendState);
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";
        if (!this.state.isSaveSuccess) {
            return (
                <article>
                    {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                    {this.props.isError ? <Error close={this.saveReset} apiName="save_job_posting" setError={this.props.saveMessage} /> : null}
                    <div className="breadcrumbArea layoutInner">
                        <ol className="breadcrumbList">
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_job", true)}>求人案件管理</a></li>
                            <li className="breadcrumbItem">求人案件登録</li>
                        </ol>
                    </div>
                    <div className="editInternStepArea layoutInner">
                        <ul className="editInternStepList">
                            <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job", false)}>募集時期</a></li>
                            <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_2", false)}>企業・施設概要</a></li>
                            <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_3", false)}>条件・業務</a></li>
                            <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_4", false)}>待遇</a></li>
                            <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_5", false)}>施設紹介</a></li>
                            <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_6", false)}>周辺情報</a></li>
                            <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_7", false)}>サポートサービス</a></li>
                            <li className="editInternStepItem">登録完了</li>
                        </ul>
                    </div>
                    <div className="editInternContents editIntern7">
                        <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                        <ul className={isDisplay}>
                            {
                                this.state.error_message.map((value, index) => {
                                    return (
                                        <li key={index} className="errorText">{ErrorMap[value]}</li>
                                    )
                                })
                            }
                        </ul>
    
                        <section className="editInternItem">
                            <form action="" method="post">
                                <div className="editInternSupportExplanation">保険加入と周辺サービス選択</div>
                                <div className="editInternInputItem">
                                    <div className="editInternInputInputArea">
                                        <label className="checkboxLabel">
                                            <input type="checkbox" name="accidentInsurance" value="傷害保険の加入に同意します" checked={this.state.accident_insurance} onChange={this.getAccidentInsurance}/>
                                            <div className="checkboxText">傷害保険の加入に同意します</div>
                                        </label>
                                        <p className="editInternSupportDescription">※トラブル防止のため加入必須とします。</p>
                                    </div>
                                </div>
                                <div className="editInternInputItem">
                                    <div className="editInternInputInputArea">
                                        <label className="checkboxLabel">
                                            <input type="checkbox" name="receptionWhole" value="人材受入丸ごとサービスの加入に同意します" checked={this.state.reception_whole} onChange={this.getReceptionWhole}/>
                                            <div className="checkboxText">人材受入丸ごとサービスの加入に同意します</div>
                                        </label>
                                        <div className="editInternSupportName">入国手続のサポート</div>
                                        <p className="editInternSupportDescription">実習計画の承認やビザの発行で行政書士とのやり取りを支援します。</p>
                                        <div className="editInternSupportName">航空機の手配サービス</div>
                                        <p className="editInternSupportDescription">団体チケットで格安確実に航空機の手配ができます。</p>
                                        <div className="editInternSupportName">入出国時の国内移動のサービス</div>
                                        <p className="editInternSupportDescription">入出国時に空港と職場間で実習生のお迎えお見送りを代行します。</p>
                                        <div className="editInternSupportName">入国時のオリエンテーションサービス</div>
                                        <p className="editInternSupportDescription">国内でのマナーの講習と観光案内サービスを提供します。</p>
                                        <div className="editInternSupportName">入国後の行政手続きサポート</div>
                                        <p className="editInternSupportDescription">住民票や国保など行政手続きを案内するサービスを提供します。</p>
                                        <div className="editInternSupportName">実習中の相談サポートサービス</div>
                                        <p className="editInternSupportDescription">電話サポートやトラブル対応や相談窓口サービスを提供します。</p>
                                    </div>
                                </div>
                                <div className="editInternInputItem">
                                    <div className="editInternInputInputArea">
                                        <label className="checkboxLabel">
                                            <input type="checkbox" name="preServiceTraining" value="受入に際して現場スタッフの事前研修を希望する" checked={this.state.pre_service_training} onChange={this.getPreServiceTraining}/>
                                            <div className="checkboxText">受入に際して現場スタッフの事前研修を希望する</div>
                                        </label>
                                        <p className="editInternSupportDescription">外国人材受入や学生対応に向けて、社員の研修サービスを提供します。<br/>初回受け入れ時は本サービスの利用を推奨します。</p>
                                    </div>
                                </div>
                                <div className="editInternInputItem">
                                    <div className="editInternInputInputArea">
                                        <label className="checkboxLabel">
                                            <input type="checkbox" name="promotionRequest" value="プロモーションを現地の会社に依頼する" checked={this.state.promotion_request} onChange={this.getPromotionRequest}/>
                                            <div className="checkboxText">プロモーションを現地の会社に依頼する</div>
                                        </label>
                                        <p className="editInternSupportDescription">現地の感性で、大学や学生向けのプロモーションサービスを提供します。</p>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </div>
                    <div className="editInternButtonArea layoutInner">
                        <div className="editInternSaveButton">
                            <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                        </div>
                        <div className="editInternNextButton">
                            <Button label="登録" buttonEnable={true} callback={() => this.saveDetailJob()}/>
                        </div>
                    </div>
                </article>
            )
        } else {
            return (
                <article>
                    <div className="breadcrumbArea layoutInner">
                        <ol className="breadcrumbList">
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_job", true)}>求人案件管理</a></li>
                            <li className="breadcrumbItem">求人案件登録</li>
                        </ol>
                    </div>
                    <div className="editInternStepArea layoutInner">
                        <ul className="editInternStepList">
                            <li className="editInternStepItem">募集時期</li>
                            <li className="editInternStepItem">企業・施設概要</li>
                            <li className="editInternStepItem">条件・業務</li>
                            <li className="editInternStepItem">待遇</li>
                            <li className="editInternStepItem">施設紹介</li>
                            <li className="editInternStepItem">周辺情報</li>
                            <li className="editInternStepItem">サポートサービス</li>
                            <li className="editInternStepItem active">登録完了</li>
                        </ul>
                    </div>
                    <div className="editInternContents editIntern8">
                        <h1>保存が完了しました。</h1>
                        <div id="backToTopWrapper">
                            <Button label="求人案件管理へ戻る" buttonEnable={true} callback={() => this.handleToDisplay("show_job", true)} />
                        </div>
                    </div>
                </article>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postJobDetailData.isFetching,
        isError: state.postJobDetailData.isError,
        saveState: state.postJobDetailData.saveState,
        saveMessage: state.postJobDetailData.errorMessage
    }
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(EditJob7);

