import axios from "axios";
import {BASE_URL, checkType, checkOverFlow, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {UNIV_DETAIL_TEST2} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

//UNIV INTERN DETAIL
export const UNIV_INTERN_REQUEST = "UNIV_INTERN_REQUEST";
export const UNIV_INTERN_SUCCESS = "UNIV_INTERN_SUCCESS";
export const UNIV_INTERN_FAILURE = "UNIV_INTERN_FAILURE";
export const UNIV_INTERN_RESET = "UNIV_INTERN_RESET";

export const univInternRequest = () => {
    return {
        type: UNIV_INTERN_REQUEST
    }
}

export const univInternSuccess = (data, university_id, actionLog) => {
    return {
        type: UNIV_INTERN_SUCCESS,
        data,
        university_id,
        actionLog
    }
}

export const univInternFailure = (err, university_id, actionLog) => {
    return {
        type: UNIV_INTERN_FAILURE,
        err,
        university_id,
        actionLog
    }
}


export const univInternReset = () => {
    return {
        type: UNIV_INTERN_RESET
    }
}

const UNIV_INTERN_POST = BASE_URL + "/api/university_internAction";

export const univInternAction = (universityId, actionFlg, actionLog) => {
    const query = {
        university_id: universityId,
        action_flg:actionFlg
    }
    return async(dispatch) => {
        dispatch(univInternRequest());
        const idToken = getIdToken();
        let verify = true;
        
        if (!_check_intern_action(query)) {
            return dispatch(univInternSuccess(400));
        }
 
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(univInternFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(univInternFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(univInternFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(univInternFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(univInternFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            console.log(query);
                            axios.post(UNIV_INTERN_POST, query, {headers: headerParam}).then(
                                res => dispatch(univInternSuccess(res.data, universityId, actionLog))
                            ).catch(
                                err => dispatch(univInternFailure(err, universityId, actionLog))
                                //res => dispatch(univInternSuccess(UNIV_DETAIL_TEST2, universityId, actionLog))
                                //res => dispatch(univInternSuccess(400, universityId, actionLog))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(univInternFailure(err, universityId, actionLog))
                //return dispatch(univInternSuccess(UNIV_DETAIL_TEST))
                //return dispatch(univInternSuccess(400, universityId))
            }
        )
    }
}

export const resetUnivIntern = () => {
    return (dispatch) => dispatch(univInternReset())
}

const _check_intern_action = (query) => {
    console.log(query)
    let check_action = true;
    for (const key in query) {
        if (!intern_action_param[key]) {
            return false;
        }

        const target = query[key];

        if (intern_action_param[key] !== checkType(target)) {
            return false;
        }

        if (key === "university_id") {
            check_action = checkOverFlow(target);
        } else if (key === "action_flg") {
            check_action = checkNumberOverflow(target);
        }

        if (!check_action) {
            return false;
        }
    }
    return check_action;
}

const intern_action_param = {
    "university_id": "string",
    "action_flg": "number"
}
