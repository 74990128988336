import {
    GET_JOB_ENTRY_LIST_REQUEST,
    GET_JOB_ENTRY_LIST_SUCCESS,
    GET_JOB_ENTRY_LIST_FAILURE,
    GET_JOB_ENTRY_LIST_RESET

} from "../../actions/posting/GetEntryInfoListAction";

const jobEntryListState = {
    isFetching: false,
    entryListData: [],
    isError: false,
    errorMessage: ""
}

export const getJobEntryList = (state = jobEntryListState, action) => {
    switch(action.type) {
        case GET_JOB_ENTRY_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                entryListData: [],
                isError: false,
                errorMessage: ""
            });
        case GET_JOB_ENTRY_LIST_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            }else{
                responseData = action.data;
                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                entryListData: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case GET_JOB_ENTRY_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                entryListData: [],
                isError: true,
                errorMessage: action.err
            });
        case GET_JOB_ENTRY_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                entryListData: [],
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}
