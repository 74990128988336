import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {ENTRIED_APPLICANT} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

//GET POSTING APPLICANT
export const ENTRY_APPLICANT_REQUEST = "ENTRY_APPLICANT_REQUEST";
export const ENTRY_APPLICANT_SUCCESS = "ENTRY_APPLICANT_SUCCESS";
export const ENTRY_APPLICANT_FAILURE = "ENTRY_APPLICANT_FAILURE";
export const ENTRY_APPLICANT_RESET = "ENTRY_APPLICANT_RESET";

export const entryApplicantRequest = () => {
    return {
        type: ENTRY_APPLICANT_REQUEST
    }
}

export const entryApplicantSuccess = (data) => {
    return {
        type: ENTRY_APPLICANT_SUCCESS,
        data
    }
}

export const entryApplicantFailure = (err) => {
    return {
        type: ENTRY_APPLICANT_FAILURE,
        err
    }
}

export const entryApplicantReset = () => {
    return {
        type: ENTRY_APPLICANT_RESET
    }
}

const GET_ENTRY_APPLICANT = BASE_URL + "/api/get_entried_applicant"

export const getEntryApplicant = (jobId) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        job_id: jobId
    }
    return async(dispatch) => {
        dispatch(entryApplicantRequest());
        if (!_check_param(query)){
            return dispatch(entryApplicantSuccess(400));
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                   var pems = {};
                   var keys = response.data['keys'];
                   for(var i = 0; i < keys.length; i++) {
                       var key_id = keys[i].kid;
                       var modulus = keys[i].n;
                       var exponent = keys[i].e;
                       var key_type = keys[i].kty;
                       var jwk = { kty: key_type, n: modulus, e: exponent};
                       var jtp = jwkToPem(jwk);
                       pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(entryApplicantFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        verify = false;
                        return dispatch(entryApplicantFailure("audクレームがアプリクライアント ID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt);
                        verify = false;
                        return dispatch(entryApplicantFailure("issクレームがユーザープール ID と一致しません"));
                    }

                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(entryApplicantFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                verify = false;
                                return dispatch(entryApplicantFailure(err));
                            } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                axios.post(GET_ENTRY_APPLICANT, query, {headers: headerParam}).then(
                                    res => dispatch(entryApplicantSuccess(res.data))
                                ).catch(
                                    err => dispatch(entryApplicantFailure(err))
                                    //res => dispatch(entryApplicantSuccess(ENTRIED_APPLICANT))
                                    //res => dispatch(entryApplicantSuccess([]))
                                    //res => dispatch(entryApplicantSuccess(400))
                                    //res => dispatch(entryApplicantSuccess(500))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(entryApplicantFailure(err))
                }
            )
        }
    }
}

export const getEntryApplicantReset = () => {
    return (dispatch) => dispatch(entryApplicantReset());
}

const _check_param = (query) => {
    console.log(query)
    let check_ok = true;
    for (const key in query) {
        const target = query[key];

        if (!_data_map[key]) {
            return false;
        }

        if (_data_map[key] !== checkType(target)) {
            if (key === "message" && target != null) {
                return false;
            }
        }

        if (key === "job_id") {
            check_ok = checkNumberOverflow(target);
        }

        if (!check_ok) {
            return false;
        }
    }
    return check_ok;
}

const _data_map = {
    "job_id": "number",
}
