import {
    UNIV_INTERN_REQUEST,
    UNIV_INTERN_SUCCESS,
    UNIV_INTERN_FAILURE,
    UNIV_INTERN_RESET
} from "../../actions/hotel/UnivInternAction";

const actionState = {
    isFetching: false,
    univDetail: [],
    getInfo: false,
    university_id: null,
    isError: false,
    errorMessage: ""
}

export const univInternAction = (state = actionState, action) => {
    let oldLog = {};
    switch(action.type) {
        case UNIV_INTERN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                univDetail: [],
                getInfo: false,
                university_id: null,
                isError: false,
                errorMessage: ""
            });
        
        case UNIV_INTERN_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            oldLog.action_log = action.actionLog.slice();
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = oldLog;
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = oldLog;
                isError = true;
            } else {
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: responseData,
                getInfo: true,
                university_id: action.university_id,
                isError: isError,
                errorMessage: errorMessage
            });
        
        case UNIV_INTERN_FAILURE:
            console.log(action.actionLog);
            oldLog.action_log = action.actionLog.slice()
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: oldLog,
                getInfo: true,
                university_id: action.university_id,
                isError: true,
                errorMessage: action.err
            });
        case UNIV_INTERN_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: [],
                getInfo: true,
                university_id: null,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}