import React from "react";
import "../../../css/Common.css";
import "../../../css/Insurance.css"
import Load from "../../atoms/Load";
import Error from "../../atoms/Error";
import Button from "../../atoms/Button";
import InsuranceBody from "./InsuranceBody";
import {connect} from "react-redux";
import {toDoubleDigits} from "../../../constants/CommonConstatns";
import {getInsuranceTarget, clearInsuranceTarget} from "../../../actions/skt/GetInsuranceTargetList";
import {requestInsuranceAction, requestInsuranceActionClear} from "../../../actions/skt/RequestInsuranceAction";
import {clearInsuranceData} from "../../../actions/skt/SetInsuranceDetail";

class InsuranceView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            internData: [],
            exec_flg: 0,
            targetMonth: ["", "", "", ""],
            searchStatus: 1,
            apiName: "",
            requestEnable: true,
            responseData: "",
            showError: false,
            btnlabel: "申込"
        }
    }

    componentDidMount = () => {
        const date = new Date();
        let monthNum = -1
        const copy_target = this.state.targetMonth.slice();
        for (let i = 0; i < copy_target.length; i++) {
            copy_target[i] = date.getFullYear() + "/" + toDoubleDigits((date.getMonth() + monthNum));
            monthNum++
        }

        this.setState({
            targetMonth: copy_target,
            showError: false
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isTargetError || nextProps.isRequestError) {
            return {
                responseData: nextProps.isTargetError ? nextProps.responseData: nextProps.saveResponse,
                showError: true
            }
        }

        if (nextProps.saveResponse === 200) {
            return {
                exec_flg: 1,
                btnlabel: "実行済"
            }
        }
        
        if (prevState.internData !== nextProps.internData) {
            let isRequestEnable = true;
            let label = "申込"
            // 未来月・もしくは実施済の場合、登録ボタンは押せない
            if (nextProps.targetData.exec_flg === 1 || 2 < prevState.searchStatus) {
                isRequestEnable = false;
                label = nextProps.targetData.exec_flg === 1 ? "実行済": "申込"
            }

            return {
                internData: nextProps.internData,
                exec_flg: nextProps.targetData.exec_flg,
                requestEnable: isRequestEnable,
                btnlabel: label
            }
        }
    }

    handleToDisplay = (changeTo) => {
        this.props.clearInsuranceTarget();
        this.props.requestInsuranceActionClear();
        this.props.clearInsuranceData();
        return this.props.changeDisp(changeTo)
    }

    getSearchStates = (event) => {
        this.setState({
            searchStatus: parseInt(event.target.value)
        });
    }
    
    searchInsurance = () => {
        this.props.requestInsuranceActionClear();
        this.props.clearInsuranceData();
        const displayPeriod = this.state.targetMonth[this.state.searchStatus];

        this.setState({
            apiName: "api/skt_insurance_target",
        });

        this.props.getInsuranceTargetInfo(displayPeriod);
    }
    
    requestInsurance = () => {
        this.setState({
            apiName: "api/skt_insurance_request",
            requestEnable: false
        });
        const displayPeriod = this.state.targetMonth[this.state.searchStatus]
        this.props.requestInsuranceAction(displayPeriod, this.props.sendInsuranceData.insuranceData);
    }

    closeAlertDisplay = () => {
        this.state.apiName === "api/skt_insurance_target" ?
            this.props.clearInsuranceTarget(): 
                this.props.requestInsuranceActionClear();

        this.setState({
            showError: false,
            responseData: "",
            requestEnable: true
        })
    }

    render() {
        const searchTargetStates = [
            { code: 0, name: this.state.targetMonth[0]},
            { code: 1, name: this.state.targetMonth[1]},
            { code: 2, name: this.state.targetMonth[2]},
            { code: 3, name: this.state.targetMonth[3]},
        ];

        const searchTargetOptions = searchTargetStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );

        return (
            <section>
                {this.props.targetFetch ? <Load setLabel="読み込み中..."/>: null}
                {this.props.requestFetch ? <Load setLabel="処理中..."/>: null}
                {this.state.showError ? <Error 
                    apiName={this.state.apiName} 
                    setError={this.state.responseData} 
                    close={this.closeAlertDisplay}/>: null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem active">保険申込</li>
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("history")}>履歴管理</a></li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                        <li className="breadcrumbItem">保険申込</li>
                    </ol>
                </div>
                <div className="layoutInner">
                    <section className="searchRefineArea">
                        <h2 className="searchRefineHeading">検索項目</h2>
                        <div className="searchRefineContents">
                            <div className="searchInsuranceItem">
                                <label htmlFor="term">対象月</label>
                                <div className="selectWrapper searchInsuranceItemSelect">
                                    <select id="term" name="term" value={this.state.searchStatus} onChange={this.getSearchStates}>
                                        {searchTargetOptions}
                                    </select>
                                </div>
                                <div id="targetSearchBtnWrapper">
                                    <Button label="検索" buttonEnable={true} callback={() => this.searchInsurance()}/>
                                </div>
                            </div>
                            
                        </div>
                    </section>
                    <div id="showInternList">
                        <h1>
                            保険申込対象者一覧
                        </h1>
                        <table border="1" bordercolor="#ffffff">
                            <thead>
                                <tr>
                                    <th rowSpan="2" className="narrow_cell">申込<br/>種別</th>
                                    <th rowSpan="2" className="narrow_cell">ステー<br/>タス</th>
                                    <th rowSpan="2">申込プラン</th>
                                    <th rowSpan="2">保険期間</th>
                                    <th rowSpan="2">滞在期間</th>
                                    <th>名前</th>
                                    <th>法人名</th>
                                </tr>
                                <tr>
                                    <th>インターン期間</th>
                                    <th>就業施設</th>
                                </tr>
                            </thead>
                            {
                                this.state.internData.map((item) => {
                                    return(
                                        <InsuranceBody 
                                            key={item.intern_id}
                                            internData={item}
                                            exec_flg={this.state.exec_flg}
                                            />
                                    )
                                })
                            }
                        </table>
                        {(() => {
                            if (this.state.internData.length > 0) {
                                return(
                                    <div id="requestInsulanceBtnWrapper">
                                        <Button label={this.state.btnlabel} 
                                            buttonEnable={this.state.requestEnable}
                                            callback={() => this.requestInsurance()}
                                        />
                                    </div>
                                )
                            }
                        })()         
                        }
                    </div>
                </div>
            </section>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        targetData: state.getInsuranceTargetList.insuranceListData,
        internData: state.getInsuranceTargetList.internData,
        responseData: state.getInsuranceTargetList.responseData,
        targetFetch: state.getInsuranceTargetList.isFetching,
        isTargetError: state.getInsuranceTargetList.isError,
        requestFetch: state.insuranceAction.isFetching,
        saveResponse: state.insuranceAction.saveResponse,
        isRequestError: state.insuranceAction.isError,
        sendInsuranceData: state.getUpdatedInsuranceData
    }
}

const mapDispatchToProps = (dispatch) => ({
    getInsuranceTargetInfo: (displayPeriod) => dispatch(getInsuranceTarget(displayPeriod)),
    clearInsuranceTarget: () => dispatch(clearInsuranceTarget()),
    requestInsuranceAction: (displayPeriod, request) => dispatch(requestInsuranceAction(displayPeriod, request)),
    requestInsuranceActionClear: () => dispatch(requestInsuranceActionClear()),
    clearInsuranceData: () => dispatch(clearInsuranceData())
});


export default connect(mapStateToProps, mapDispatchToProps)(InsuranceView);