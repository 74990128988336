import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {INTERN_TITLE_DATA, INTERN_DETAIL_DATA} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

//GET INTERN OVERVIEWS
export const INTERN_OVERVIEWS_REQUEST = "INTERN_OVERVIEWS_REQUEST";
export const INTERN_OVERVIEWS_SUCCESS = "INTERN_OVERVIEWS_SUCCESS";
export const INTERN_OVERVIEWS_FAILURE = "INTERN_OVERVIEWS_FAILURE";
export const INTERN_OVERVIEWS_RESET = "INTERN_OVERVIEWS_RESET";

export const internOverviewsRequest = () => {
    return {
        type: INTERN_OVERVIEWS_REQUEST
    }
}

export const internOverviewsSuccess = (data) => {
    return {
        type: INTERN_OVERVIEWS_SUCCESS,
        data
    }
}

export const internOverviewsFailure = (err) => {
    return {
        type: INTERN_OVERVIEWS_FAILURE,
        err
    }
}

export const internOverviewsReset = () => {
    return {
        type: INTERN_OVERVIEWS_RESET
    }
}

const GET_INTERN_OVERVIEW = BASE_URL + "/api/intern_overview"

export const getInternOverviews = () => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(internOverviewsRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(internOverviewsFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(internOverviewsFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(internOverviewsFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(internOverviewsFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(internOverviewsFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_INTERN_OVERVIEW, null, {headers: headerParam}).then(
                                res => dispatch(internOverviewsSuccess(res.data))
                            ).catch(
                                err => dispatch(internOverviewsFailure(err))
                                //res => dispatch(internOverviewsSuccess(INTERN_TITLE_DATA))
                                //res => dispatch(internOverviewsSuccess([]))
                                //res => dispatch(internOverviewsSuccess(400))
                                //res => dispatch(internOverviewsSuccess(500))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(internOverviewsFailure(err))
            }
        )
    }
}

export const getInternOverviewsReset = () => {
    return (dispatch) => dispatch(internOverviewsReset());
}

//GET INTERN DETAILS
export const INTERN_DETAIL_REQUEST = "INTERN_DETAIL_REQUEST";
export const INTERN_DETAIL_SUCCESS = "INTERN_DETAIL_SUCCESS";
export const INTERN_DETAIL_FAILURE = "INTERN_DETAIL_FAILURE";
export const INTERN_DETAIL_RESET = "INTERN_DETAIL_RESET";

const GET_INTERN_DETAIL = BASE_URL + "/api/intern_detail";

export const getInternDetailRequest = () => {
    return {
        type: INTERN_DETAIL_REQUEST
    }
}

export const getInternDetailSuccess = (data) => {
    return {
        type: INTERN_DETAIL_SUCCESS,
        data
    }
}

export const getInternDetailFailure = (err) => {
    return {
        type: INTERN_DETAIL_FAILURE,
        err
    }
}

export const getInternDetailReset = () => {
    return {
        type: INTERN_DETAIL_RESET
    }
}


export const getInternDetail = (internId) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        intern_id: internId
    }
    return (dispatch) => {
        dispatch(getInternDetailRequest());
        if (!_check_param(query)){
            return dispatch(getInternDetailSuccess(400));
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                    var pems = {};
                    var keys = response.data['keys'];
                    for(var i = 0; i < keys.length; i++) {
                        var key_id = keys[i].kid;
                        var modulus = keys[i].n;
                        var exponent = keys[i].e;
                        var key_type = keys[i].kty;
                        var jwk = { kty: key_type, n: modulus, e: exponent};
                        var jtp = jwkToPem(jwk);
                        pems[key_id] = jtp;
                     }
                     var decodedJwt = jwt.decode(idToken, {complete: true});
                     if (!decodedJwt) {
                         console.log("Not a valid JWT token");
                         verify = false;
                         return dispatch(getInternDetailFailure("トークンの形式が不正です"));
                     }
                     if (decodedJwt.payload.aud !== config.ClientId) {
                         verify = false;
                         return dispatch(getInternDetailFailure("audクレームがアプリクライアント ID と一致しません"));
                     }
                     if (decodedJwt.payload.iss !== cognitoIss) {
                         console.log(decodedJwt);
                         verify = false;
                         return dispatch(getInternDetailFailure("issクレームがユーザープール ID と一致しません"));
                     }

                     var kid = decodedJwt.header.kid;
                     var pem = pems[kid];
                     if (!pem) {
                         console.log('kid not found');
                         verify = false;
                         return dispatch(getInternDetailFailure("kidが不正です"));
                     }
                     if(verify){
                         jwt.verify(idToken, pem, function(err, payload) {
                             if(err) {
                                 console.log("Invalid Token.");
                                 verify = false;
                                 return dispatch(getInternDetailFailure(err));
                             } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                // API側と通信し、画像を受信
                                axios.post(GET_INTERN_DETAIL, query, {headers: headerParam}).then(
                                    res => dispatch(getInternDetailSuccess(res.data))
                                ).catch(
                                    err => dispatch(getInternDetailFailure(err))
                                    //res => dispatch(getInternDetailSuccess(INTERN_DETAIL_DATA))
                                    //res => dispatch(getInternDetailSuccess(400))
                                    //res => dispatch(getInternDetailSuccess(500))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(getInternDetailFailure(err))
                }
            )
        }
    }
}

export const internDetailReset = () => {
    return (dispatch) => dispatch(getInternDetailReset())
}

const _check_param = (query) => {
    let check_ok = true;

    if (query.hasOwnProperty("intern_id")) {
        const target = query.intern_id;
        if (checkType(target) !== "number") {
            console.log("a")
            return false;
        }
        console.log(checkNumberOverflow(target))
        check_ok = checkNumberOverflow(target)
    } else {
        console.log("aaa")
        return false;
    }

    return check_ok;
}
