import {
    GET_INSURANCE_HISTORY_REQUEST,
    GET_INSURANCE_HISTORY_SUCCESS,
    GET_INSURANCE_HISTORY_FAILURE,
    GET_INSURANCE_HISTORY_CLEAR
} from "../../actions/skt/GetInsuranceHistoryAction"

const resuponseState = {
    isFetching: false,
    responseData: {},
    isError: false,
}

export const insuranceHistoryData = (state = resuponseState, action) => {
    switch(action.type) {
        case GET_INSURANCE_HISTORY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                responseData: "response",
                isError: false,
            });
        case GET_INSURANCE_HISTORY_SUCCESS:
            let responseData = ""
            let isError = false;
            if (action.data === 400) {
                responseData = "400 パラメータが不正です";
                isError = true;
            } else if (action.data === 500) {
                responseData = "500 予期しないエラーが発生しました";
                isError = true;
            }else{
                responseData = action.data
            }
            return Object.assign({}, state, {
                isFetching: false,
                responseData: responseData,
                isError: isError
            });
        case GET_INSURANCE_HISTORY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                responseData: action.err,
                isError: true
            });
        case GET_INSURANCE_HISTORY_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                responseData: {},
                isError: false,
            });
        default:
            return state;
    }
}
