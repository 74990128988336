import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {UNIV_INTERN_OVERVIEW_DATA, UNIV_INTERN_DETAIL_DATA} from "../../constants/TestData";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET UNIV OVERVIEW
export const U_GET_UNIV_OVERVIEW_REQUEST = "U_GET_UNIV_OVERVIEW_REQUEST";
export const U_GET_UNIV_OVERVIEW_SUCCESS = "U_GET_UNIV_OVERVIEW_SUCCESS";
export const U_GET_UNIV_OVERVIEW_FAILURE = "U_GET_UNIV_OVERVIEW_FAILURE";
export const U_GET_UNIV_OVERVIEW_RESET = "U_GET_UNIV_OVERVIEW_RESET";

export const universityOverviewRequest = () => {
    return {
        type: U_GET_UNIV_OVERVIEW_REQUEST
    }
}

export const universityOverviewSuccess = (data) => {
    return {
        type: U_GET_UNIV_OVERVIEW_SUCCESS,
        data
    }
}

export const universityOverviewFailure = (err) => {
    return {
        type: U_GET_UNIV_OVERVIEW_FAILURE,
        err
    }
}

export const universityOverviewReset = () => {
    return {
        type: U_GET_UNIV_OVERVIEW_RESET
    }
}

const U_GET_UNIV_OVERVIEW = BASE_URL + "/api/university_intern_overview";

export const univGetUniversityOverview = () => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(universityOverviewRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(universityOverviewFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(universityOverviewFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(universityOverviewFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(universityOverviewFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(universityOverviewFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(U_GET_UNIV_OVERVIEW, null, {headers: headerParam}).then(
                                res => {
                                    dispatch(universityOverviewSuccess(res.data))
                                }
                            ).catch(
                                err => dispatch(universityOverviewFailure(err))
                                //res => dispatch(universityOverviewSuccess(UNIV_INTERN_OVERVIEW_DATA))
                                //res => dispatch(universityOverviewSuccess(500))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(universityOverviewFailure(err))
            }
        )
    }
}

export const resetUniversityOverview = () => {
    return (dispatch) => dispatch(universityOverviewReset());
}

// GET UNIV DETAIL
export const U_GET_UNIV_DETAIL_REQUEST = "GET_UNIV_DETAIL_REQUEST";
export const U_GET_UNIV_DETAIL_SUCCESS = "GET_UNIV_DETAIL_SUCCESS";
export const U_GET_UNIV_DETAIL_FAILURE = "GET_UNIV_DETAIL_FAILURE";
export const U_GET_UNIV_DETAIL_RESET = "GET_UNIV_DETAIL_RESET";

export const universityDetailRequest = () => {
    return {
        type: U_GET_UNIV_DETAIL_REQUEST
    }
}

export const universityDetailSuccess = (data, intern_id) => {
    return {
        type: U_GET_UNIV_DETAIL_SUCCESS,
        data,
        intern_id
    }
}

export const universityDetailFailure = (err, intern_id) => {
    return {
        type: U_GET_UNIV_DETAIL_FAILURE,
        err,
        intern_id
    }
}

export const universityDetailReset = () => {
    return {
        type: U_GET_UNIV_DETAIL_RESET
    }
}

const U_GET_UNIV_DETAIL = BASE_URL + "/api/university_intern_detail";

export const univGetUniversityDetail = (internId) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        intern_id: internId
    }
    return async(dispatch) => {
        dispatch(universityDetailRequest());
        if (!_check_param(query)){
            return dispatch(universityDetailSuccess(400, internId))
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                    var pems = {};
                    var keys = response.data['keys'];
                    for(var i = 0; i < keys.length; i++) {
                        var key_id = keys[i].kid;
                        var modulus = keys[i].n;
                        var exponent = keys[i].e;
                        var key_type = keys[i].kty;
                        var jwk = { kty: key_type, n: modulus, e: exponent};
                        var jtp = jwkToPem(jwk);
                        pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(universityDetailFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        verify = false;
                        return dispatch(universityDetailFailure("audクレームがアプリクライアント ID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt);
                        verify = false;
                        return dispatch(universityDetailFailure("issクレームがユーザープール ID と一致しません"));
                    }

                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(universityDetailFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                verify = false;
                                return dispatch(universityDetailFailure(err));
                            } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                axios.post(U_GET_UNIV_DETAIL, query, {headers: headerParam}).then(
                                    res => dispatch(universityDetailSuccess(res.data, internId))
                                ).catch(
                                    err => dispatch(universityDetailFailure(err, internId))
                                    //res => dispatch(universityDetailSuccess(UNIV_INTERN_DETAIL_DATA, internId))
                                    //res => dispatch(universityDetailSuccess(500, internId))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                     return dispatch(universityDetailFailure(err, internId))
                }
            )
        }
    }
}

export const resetUniversityDetail = () => {
    return (dispatch) => dispatch(universityDetailReset());
}

const _check_param = (query) => {
    let check_ok = true;
    if (query.intern_id) {
        const target = query.intern_id;
        if (checkType(target) !== "number") {
            return false;
        }
        check_ok = checkNumberOverflow(target)
    } else {
        return false;
    }

    return check_ok;
}
