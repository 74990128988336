import axios from "axios";
import {BASE_URL, checkType, checkOverFlow, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// SAVE CANDIDATE STATUS
export const SAVE_CANDIDATE_STATUS_REQUEST = "SAVE_CANDIDATE_STATUS_REQUEST";
export const SAVE_CANDIDATE_STATUS_SUCCESS = "SAVE_CANDIDATE_STATUS_SUCCESS";
export const SAVE_CANDIDATE_STATUS_FAILURE = "SAVE_CANDIDATE_STATUS_FAILURE";
export const SAVE_CANDIDATE_STATUS_RESET = "SAVE_CANDIDATE_STATUS_RESET";

export const saveCandidateRequest = () => {
    return {
        type: SAVE_CANDIDATE_STATUS_REQUEST
    }
}

export const saveCandidateSuccess = (data) => {
    return {
        type: SAVE_CANDIDATE_STATUS_SUCCESS,
        data
    }
}

export const saveCandidateFailure = (err) => {
    return {
        type: SAVE_CANDIDATE_STATUS_FAILURE,
        err
    }
}

export const saveCandidateReset = () => {
    return {
        type: SAVE_CANDIDATE_STATUS_RESET
    }
}

const SAVE_CANDIDATE_STATUS= BASE_URL + "/api/intern_save_candidate_status";

export const saveCandidateStatus = (internId, candidateId, universityId, candidateStatus) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        intern_id: internId,
        candidate_id: candidateId,
        university_id: universityId,
        intern_candidate_status: candidateStatus
    }
    return async(dispatch) => {
        dispatch(saveCandidateRequest());

        if (!_check_save_param(query)) {
            console.log(query)
            return dispatch(saveCandidateSuccess(400));
        }

        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(saveCandidateFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(saveCandidateFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(saveCandidateFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(saveCandidateFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(saveCandidateFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(SAVE_CANDIDATE_STATUS, query, {headers: headerParam}).then(
                                res => dispatch(saveCandidateSuccess(res.data))
                            ).catch(
                                err => dispatch(saveCandidateFailure(err))
                                //res => dispatch(saveCandidateSuccess(500))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(saveCandidateFailure(err))
                //return dispatch(saveCandidateSuccess(500))
            }
        )
    }
}

export const resetSaveCandidate = () => {
    return (dispatch) => dispatch(saveCandidateReset());
}

const _check_save_param = (query) => {
    let save_check = true;
    for (const key in query) {
        if (!save_param[key]) {
            return false;
        }

        const target = query[key];

        if (save_param[key] !== checkType(target)) {
            return false;
        }

        if (key === "intern_id") {
            save_check = checkOverFlow(target);
        } else if (key === "candidate_id") {
            save_check = checkOverFlow(target);
        } else if (key === "university_id") {
            save_check = checkOverFlow(target);
        } else if (key === "intern_candidate_status") {
            save_check = checkNumberOverflow(target);
        }

        if (!save_check) {
            return false;
        }
    }
    return save_check;
}

const save_param = {
    "intern_id": "string",
    "candidate_id": "string",
    "university_id": "string",
    "intern_candidate_status": "number"
}
