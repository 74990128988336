import {addressCheck, isCountrySelected} from "./CommonConstatns"

export const jobValidationCheck = (sendData) => {
    let jobCheckResult = []

    // タイトルの入力チェック
    if (!sendData.title) {
        jobCheckResult.push(0);
    }

    // 雇用開始期間入力チェック
    if (!sendData.period.employment_start) {
        jobCheckResult.push(1);
    }

    // 雇用終了期間入力チェック
    if (!sendData.period.employment_end) {
        jobCheckResult.push(2);
    }

    // 募集人数チェック
    if (sendData.accepted === null || sendData.accepted === 0 || Number.isNaN(sendData.accepted)) {
        jobCheckResult.push(3);
    } else if (999 < sendData.accepted) {
        jobCheckResult.push(4);
    }

    // 契約形態入力チェック
    if (!sendData.employment_type) {
        jobCheckResult.push(6);
    }

    // 更新有無チェック
    if (!sendData.renew_contract) {
        jobCheckResult.push(5);
    }
    

    // 掲載開始期間入力チェック
    if (!sendData.period.posting_start) {
        jobCheckResult.push(7);
    }

    // 掲載終了期間入力チェック
    if (!sendData.period.posting_end) {
        jobCheckResult.push(8);
    }

    // 会社名チェック
    if (!sendData.company_info.name) {
        jobCheckResult.push(9);
    }

    // 住所チェック
    if (!sendData.company_info.address) {
        jobCheckResult.push(10);
    }

    // 代表者チェック
    if (!sendData.company_info.president) {
        jobCheckResult.push(11);
    }

    // 電話番号チェック
    if (!sendData.company_info.tel) {
        jobCheckResult.push(12);
    } else if (!sendData.company_info.tel.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/)) {
        jobCheckResult.push(13);
    }

    // FAX番号チェック（FAX番号が入っていたら）
    if (sendData.company_info.fax) {
        if (!sendData.company_info.fax.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/)) {
            jobCheckResult.push(14);
        }
    }

    // Web URLチェック
    if (!sendData.company_info.web) {
        jobCheckResult.push(18);
    } else if (!sendData.company_info.web.startsWith("http://") && !sendData.company_info.web.startsWith("https://")) {
        jobCheckResult.push(15);
    }

    // 従業員数チェック
    if (sendData.company_info.employee.regular === null || Number.isNaN(sendData.company_info.employee.regular)) {
        jobCheckResult.push(16);
    }

    // アルバイト人数チェック
    if (sendData.company_info.employee.part_time === null || Number.isNaN(sendData.company_info.employee.part_time)) {
        jobCheckResult.push(17);
    }

    // 就業施設名チェック
    if (!sendData.company_info.facility_name) {
        jobCheckResult.push(19);
    }

    // 就業施設住所チェック
    if (!sendData.company_info.facility_address) {
        jobCheckResult.push(20)
    }

    // 担当者名チェック
    if (!sendData.company_info.pic) {
        jobCheckResult.push(21);
    }

    if (!sendData.company_info.position) {
        jobCheckResult.push(22)
    }

    // 担当者電話番号チェック
    if (!sendData.company_info.pic_tel) {
        jobCheckResult.push(23);
    } else if (!sendData.company_info.pic_tel.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/)) {
        jobCheckResult.push(24);
    }

    // メールアドレスチェック
    if (!sendData.company_info.mail) {
        jobCheckResult.push(25);
    } else if (!addressCheck(sendData.company_info.mail)) {
        jobCheckResult.push(26);
    }

    // 国籍が選ばれているかチェック
    if (!isCountrySelected(sendData.country)) {
        jobCheckResult.push(27);
    }

    // 募集職種チェック
    if (!sendData.occupation) {
        jobCheckResult.push(28);
    }

    // 職務内容チェック
    if (!sendData.content) {
        jobCheckResult.push(29);
    }

    const check_data = sendData.working_time.works;
    let isInputWorkingTime = false;
    for (const data of check_data) {
        if (data.start && data.end && data.break_time && data.actual_time) {
            isInputWorkingTime = true;
        }
    }

    if (!isInputWorkingTime) {
        jobCheckResult.push(30);
    }

    if (!sendData.holiday) {
        jobCheckResult.push(31)
    }

    if (!sendData.insurance.accept_accident) {
        jobCheckResult.push(32);
    }

    if (!sendData.insurance.accept_human_resources) {
        jobCheckResult.push(33);
    }

    return jobCheckResult;
}

export const ErrorMap = {
    0: "求人案件名を入力してください。",
    1: "雇用開始期間を入力してください。",
    2: "雇用終了期間を入力してください。",
    3: "募集人数を入力してください。",
    4: "募集人数が多すぎます。",
    5: "更新有無を入力してください。",
    6: "雇用形態を入力してください。",
    7: "掲載開始期間を入力してください。",
    8: "掲載終了期間を入力してください。",
    9: "法人名を入力してください。",
    10: "本社所在地を入力してください。",
    11: "代表者名を入力してください。",
    12: "代表電話番号を入力してください。",
    13: "代表電話番号の値が不正です。",
    14: "FAX番号の値が不正です。",
    15: "ホームページのURLが不正です。",
    16: "正社員数を入力してください。",
    17: "パート/アルバイト数を入力してください。",
    18: "ホームページのURLを入力してください",
    19: "就業施設名を入力してください。",
    20: "就業施設所在地を入力してください。",
    21: "担当者氏名を入力してください。",
    22: "担当者役職を入力してください。",
    23: "電話番号を入力してください。",
    24: "電話番号の値が不正です。",
    25: "メールアドレスを入力してください。",
    26: "メールアドレスの値が不正です。",
    27: "国籍は必ず一つ選択してください。",
    28: "募集職種を入力してください。",
    29: "業務内容を入力してください。",
    30: "勤務時間を入力してください。",
    31: "休日を入力してください。",
    32: "インターンシップ傷害保険加入への同意は必須です。",
    33: "人材受入丸ごとサービス加入への同意は必須です。"
}

export const PrefecturesMap = {
    "北海道": "hokkaido",
    "青森県": "tohoku",
    "岩手県": "tohoku",
    "宮城県": "tohoku",
    "秋田県": "tohoku",
    "山形県": "tohoku",
    "福島県": "tohoku",
    "茨城県": "kanto",
    "栃木県": "kanto",
    "群馬県": "kanto",
    "埼玉県": "kanto",
    "千葉県": "kanto",
    "東京都": "kanto",
    "神奈川県": "kanto",
    "新潟県": "chubu",
    "富山県": "chubu",
    "石川県": "chubu",
    "福井県": "chubu",
    "山梨県": "chubu",
    "長野県": "chubu",
    "岐阜県": "chubu",
    "静岡県": "chubu",
    "愛知県": "chubu",
    "三重県": "kinki",
    "滋賀県": "kinki",
    "京都府": "kinki",
    "大阪府": "kinki",
    "兵庫県": "kinki",
    "奈良県": "kinki",
    "和歌山県": "kinki",
    "鳥取県": "chugoku",
    "島根県": "chugoku",
    "岡山県": "chugoku",
    "広島県": "chugoku",
    "山口県": "chugoku",
    "徳島県": "shikoku",
    "香川県": "shikoku",
    "愛媛県": "shikoku",
    "高知県": "shikoku",
    "福岡県": "kyushu",
    "佐賀県": "kyushu",
    "長崎県": "kyushu",
    "熊本県": "kyushu",
    "大分県": "kyushu",
    "宮崎県": "kyushu",
    "鹿児島": "kyushu",
    "沖縄県": "kyushu"
}
