import {
    GET_SELECT_CONFIG_REQUEST,
    GET_SELECT_CONFIG_SUCCESS,
    GET_SELECT_CONFIG_FAILURE,
} from "../../actions/skt/GetSelectConfigAction";

const configState = {
    isFetching: false,
    configData: [],
    isError: false
}

export const getSelectConfig = (state = configState, action) => {
    switch(action.type) {
        case GET_SELECT_CONFIG_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                configData: [],
                isError: false
            });
        case GET_SELECT_CONFIG_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                configData: action.data,
                isError: false
            });
        case GET_SELECT_CONFIG_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                configData: [],
                isError: true
            });
        default:
            return state;
    }
}