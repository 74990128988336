import axios from "axios";
import {BASE_URL} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {BASIC_INSURANCE_DATA} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET SELECT CONFIG
export const GET_SELECT_CONFIG_REQUEST = "GET_SELECT_CONFIG_REQUEST";
export const GET_SELECT_CONFIG_SUCCESS = "GET_SELECT_CONFIG_SUCCESS";
export const GET_SELECT_CONFIG_FAILURE = "GET_SELECT_CONFIG_FAILURE";

export const selectConfigRequest = () => {
    return {
        type: GET_SELECT_CONFIG_REQUEST
    }
}

export const selectConfigSuccess = (data) => {
    return {
        type: GET_SELECT_CONFIG_SUCCESS,
        data
    }
}

export const selectConfigFailure = (err) => {
    return {
        type: GET_SELECT_CONFIG_FAILURE,
        err
    }
}

const GET_UNIV_OVERVIEW = BASE_URL + "/api/get_select_config";

export const getSelectConfig = () => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(selectConfigRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(selectConfigFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(selectConfigFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(selectConfigFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(selectConfigFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(selectConfigFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const query = {
                                select_name: "insurance_plan"
                            }
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_UNIV_OVERVIEW, query, {headers: headerParam}).then(
                                res => dispatch(selectConfigSuccess(res.data))
                            ).catch(
                                err => dispatch(selectConfigFailure(err))
                                //res => dispatch(selectConfigSuccess(BASIC_INSURANCE_DATA))
                            )
                        }
                    });
                }
            }
        ).catch(
            err => dispatch(selectConfigFailure(err))
        )
    }
}
