import React from "react";
import "../../../css/Common.css";
import Button from "../../atoms/Button";
import {connect} from "react-redux";
import {saveCandidateStatus} from "../../../actions/hotel/SaveCandidateAction"

class CandidateList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            internId: 0,
            candidateData: {},
            saveState: false
        }
    }

    componentDidMount = () => {
        this.setState({
            internId: this.props.internId,
            candidateData: this.props.candidateData
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.saveState !== nextProps.saveState) {
            if (nextProps.saveState.toString().indexOf("SUCCESS") === 0) {
                nextProps.setCandidateSaveState(false);
                return {
                    saveState: false
                }
            }
            const sts = nextProps.saveState.toString() === "REQUEST" ? true: false
            nextProps.setCandidateSaveState(sts);
            return {
                saveState: sts
            }
        }
    }

    handleChange = (e) => {
        this.state.candidateData.intern_candidate_status = e.target.value;
        this.setState({
            candidateData: this.state.candidateData
        });
    }

    sendMail = () => {
        const cs = this.state.candidateData.intern_candidate_status - 0;
        this.props.saveCandidateStatus(
            this.state.internId.toString(),
            this.state.candidateData.candidate_id,
            this.state.candidateData.university_id,
            cs
        );
    }

    render() {
        if(0 < Object.keys(this.state.candidateData).length) {
            return (
                <tr>
                    <td>{this.state.candidateData.university_name}</td>
                    <td>{this.state.candidateData.candidate_name}</td>
                    <td className="candidate_select">
                        <div className="selectWrapper">
                            <select 
                                value={this.state.candidateData.intern_candidate_status}
                                onChange={this.handleChange}
                                >
                                <option value={0}>応募中</option>
                                <option value={1}>受諾</option>
                                <option value={9}>却下</option>
                            </select>
                        </div>
                        
                    </td>
                    <td>
                        <div className="sendMailButton">
                            <Button label="実行" buttonEnable={true} callback={() => this.sendMail()}/>
                        </div>
                    </td>
                </tr>
            )
        } else {
            return(null);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        saveState: state.saveCandidateAction.saveState
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveCandidateStatus: (internId, candidateId, universityId, candidateStatus) => dispatch(saveCandidateStatus(internId, candidateId, universityId, candidateStatus))
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateList);