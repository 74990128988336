import {
    U_GET_UNIV_OVERVIEW_REQUEST,
    U_GET_UNIV_OVERVIEW_SUCCESS,
    U_GET_UNIV_OVERVIEW_FAILURE,
    U_GET_UNIV_OVERVIEW_RESET,
    U_GET_UNIV_DETAIL_REQUEST,
    U_GET_UNIV_DETAIL_SUCCESS,
    U_GET_UNIV_DETAIL_FAILURE,
    U_GET_UNIV_DETAIL_RESET
} from "../../actions/univ/GetUnivInternAction"

const overViewState = {
    isFetching: false,
    overViewData: [],
    isGetInfo: false,
    isError: false,
    errorMessage: ""
}

const detailState = {
    isFetching: false,
    detailData: [],
    intern_id: "",
    isGetInfo: false,
    isError: false,
    errorMessage: ""
}

export const getUnivInternOverviews = (state = overViewState, action) => {
    switch(action.type) {
        case U_GET_UNIV_OVERVIEW_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isGetInfo: false,
                isError: false,
                errorMessage: ""
            });
        case U_GET_UNIV_OVERVIEW_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            }else{
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: responseData,
                isGetInfo: true,
                isError: isError,
                errorMessage: errorMessage
            });
        case U_GET_UNIV_OVERVIEW_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isGetInfo: true,
                isError: true,
                errorMessage: action.err
            });
        case U_GET_UNIV_OVERVIEW_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isGetInfo: true,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}

export const getUnivInternDetails = (state = detailState, action) => {
    switch(action.type) {
        case U_GET_UNIV_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                detailData: [],
                isGetInfo: false,
                intern_id: null,
                isError: false,
                errorMessage: ""
            });
        case U_GET_UNIV_DETAIL_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            }else{
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                detailData: responseData,
                isGetInfo: true,
                intern_id: action.intern_id,
                isError: isError,
                errorMessage: errorMessage
            });
        case U_GET_UNIV_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                detailData: [],
                isGetInfo: true,
                intern_id: action.intern_id,
                isError: true,
                errorMessage: action.err
            });
        case U_GET_UNIV_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                detailData: [],
                isGetInfo: false,
                intern_id: null,
                isError: false,
                errorMessage: ""
            });

        default:
            return state;
    }
}
