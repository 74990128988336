import axios from "axios";
import {BASE_URL} from "../../constants/CommonConstatns";
import {INTERN_TITLE_DATA, INTERN_DETAIL_DATA} from "../../constants/TestData";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {checkType, checkOverFlow, checkImageSize, checkNumberOverflow} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// SAVE STUDENT DATA
export const SAVE_UNIVERSITY_DATA_REQUEST = "SAVE_UNIVERSITY_DATA_REQUEST";
export const SAVE_UNIVERSITY_DATA_SUCCESS = "SAVE_UNIVERSITY_DATA_SUCCESS";
export const SAVE_UNIVERSITY_DATA_FAILURE = "SAVE_UNIVERSITY_DATA_FAILURE";
export const U_UNIV_SAVE_RESET = "U_UNIV_SAVE_RESET";

export const saveUniversityDataRequest = () => {
    return {
        type: SAVE_UNIVERSITY_DATA_REQUEST
    }
}

export const saveStudentDataSuccess = (data) => {
    return {
        type: SAVE_UNIVERSITY_DATA_SUCCESS,
        data
    }
}

export const saveStudentDataFailure = (err) => {
    return {
        type: SAVE_UNIVERSITY_DATA_FAILURE,
        err
    }
}

export const universitySaveReset = () => {
    return {
        type: U_UNIV_SAVE_RESET
    }
}

const SAVE_UNIVERSITY_LIST = BASE_URL + "/api/university_save_profile";

export const saveProfileData = (univData) => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(saveUniversityDataRequest());

        if (!_check_save_param(univData)) {
            return dispatch(saveStudentDataSuccess(400));
        }

        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(saveStudentDataFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(saveStudentDataFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(saveStudentDataFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(saveStudentDataFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(saveStudentDataFailure(err));
                        } else {
                            console.log("Valid Token.");
                            console.log(univData)
                            const query = univData;
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(SAVE_UNIVERSITY_LIST, query, {headers: headerParam}).then(
                                res => {
                                    dispatch(saveStudentDataSuccess(res.data))
                                }
                            ).catch(
                                err => dispatch(saveStudentDataFailure(err))
                                //res => dispatch(universityOverviewSuccess(INTERN_TITLE_DATA))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(saveStudentDataFailure(err))
            }
        )
    }
}

export const resetSaveUniversity = () => {
    return (dispatch) => dispatch(universitySaveReset());
}

const _check_save_param = (query) => {
    console.log(query)
    let checkResult = false;
    for (const key in query) {
        if (!_save_univ_map[key]) {
            return false;
        }

        const target = query[key];

        if (_save_univ_map[key] !== checkType(target)) {
            return false;
        }

        if (key === "university_logo") {
            checkResult = checkImageSize(target)
        } else if (key === "university_name") {
            checkResult = checkOverFlow(target);
        } else if (key === "country") {
            checkResult = checkNumberOverflow(target);
        } else if (key === "university_location") {
            checkResult = checkOverFlow(target);
        } else if (key === "section") {
            checkResult = checkOverFlow(target);
        } else if (key === "people") {
            checkResult = checkOverFlow(target);
        } else if (key === "period") {
            checkResult = checkNumberOverflow(target);
        } else if (key === "division") {
            checkResult = checkNumberOverflow(target);
        } else if (key === "japanese") {
            checkResult = checkOverFlow(target);
        } else if (key === "english") {
            checkResult = checkOverFlow(target);
        } else if (key === "teacher_name") {
            checkResult = checkOverFlow(target);
        } else if (key === "teacher_mail") {
            checkResult = checkOverFlow(target);
        }

        if (!checkResult) {
            return false;
        }
    }
    return checkResult;
}

const _save_univ_map = {
    "university_logo": "string",
    "university_name": "string",
    "country": "number",
    "university_location": "string",
    "section": "string",
    "people": "string",
    "period": "number",
    "division": "number",
    "jpteacher": "boolean",
    "japanese": "string",
    "english": "string",
    "teacher_name": "string",
    "teacher_mail": "string"
}
