import axios from "axios";
import {BASE_URL} from "../../constants/CommonConstatns";
import {INSURANCE_TEST} from "../../constants/TestData";
import {getIdToken, cognitoIss, cognitoEndpoint, checkType, checkMonthStyle} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET STUDENT LIST
export const GET_INSURANCE_TARGET_REQUEST = "GET_INSURANCE_TARGET_REQUEST";
export const GET_INSURANCE_TARGET_SUCCESS = "GET_INSURANCE_TARGET_SUCCESS";
export const GET_INSURANCE_TARGET_FAILURE = "GET_INSURANCE_TARGET_FAILURE";
export const CLEAR_INSURANCE_TARGET = "CLEAR_INSURANCE_TARGET";

export const insuranceTargetListRequest = () => {
    return {
        type: GET_INSURANCE_TARGET_REQUEST
    }
}

export const insuranceTargetListSuccess = (data) => {
    return {
        type: GET_INSURANCE_TARGET_SUCCESS,
        data
    }
}

export const insuranceTargetListFailure = (err) => {
    return {
        type: GET_INSURANCE_TARGET_FAILURE,
        err
    }
}

export const insulanceTargetClear = () => {
    return {
        type: CLEAR_INSURANCE_TARGET
    }
}

const GET_INSURANCE_TARGET = BASE_URL + "/api/skt_insurance_target";

export const getInsuranceTarget = (displayPeriod) => {
    const idToken = getIdToken();
    let verify = true;

    return async(dispatch) => {
        dispatch(insuranceTargetListRequest());
        const query = {
            display_period: displayPeriod
        }
        
        if (!_check_param(query)) {
            return dispatch(insuranceTargetListSuccess(400));
        }
        
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(insuranceTargetListFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(insuranceTargetListFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(insuranceTargetListFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(insuranceTargetListFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(insuranceTargetListFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_INSURANCE_TARGET, query, {headers: headerParam}).then(
                                res => {
                                    dispatch(insuranceTargetListSuccess(res.data))
                                }
                            ).catch(
                                err => dispatch(insuranceTargetListFailure(err))
                                //res => dispatch(insuranceTargetListSuccess(JSON.parse(JSON.stringify(INSURANCE_TEST))))
                                //res => dispatch(insuranceTargetListSuccess({}))
                                //dispatch(insuranceTargetListSuccess(500))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(insuranceTargetListFailure(err))
            }
        )
    }
}

export const clearInsuranceTarget = () => {
    return (dispatch) => dispatch(insulanceTargetClear());
}

const _check_param = (query) => {
    if (!query["display_period"]) {
        return false;
    }
    if (checkType(query["display_period"]) !== "string") {
        return false;
    }

    return checkMonthStyle(query["display_period"])    
}
