// エントリステータス
export const EntryStatusMap = {
    1: "エントリ依頼中",
    0: "取下げ",
    3: "エントリ受付済",
    9: "エントリ辞退"
}

// エントリステータスに合わせたメッセージ
// 0: エントリ依頼中
// 1: 取下げ
// 3: エントリ受付済
// 9: エントリ辞退
export const EntryStatusMessageMap = {
    1: "エントリ要求しました。",
    0: "エントリ取り下げしました。",
    3: "エントリ受付されました。",
    9: "エントリ辞退されました。"
}

export const PostingStatusMap = {
    0: "作成中",
    1: "作成済",
    2: "募集中",
    3: "募集終了",
}

export const PostingStatusListMap = {
    "S": {
        2: "エントリ依頼中"
    },
    "K": {
        0: "作成中",
        1: "作成済"
    },
    "O": {
        3: "エントリ済"
    },
    "H": {}
}
