import React from "react";
import "../../../css/Common.css";
import "../../../css/SearchUniversity.css"
import UnivDetail from "./UnivDetail";
import {CountryMap, UniversityStatus} from "../../../constants/CommonConstatns";

class UnivOverview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            itemData: {},
            status: 9
        }
        this.toggleChange = this.toggleChange.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            status: this.props.itemData.status,
            itemData: this.props.itemData
        });
    }

    toggleChange() {
        const tgl = this.state.toggle ? false: true;
        this.setState({
            toggle: tgl
        });        
    }

    setStatus = (newStatus) => {
        console.log("newStatus: " + newStatus);
        this.setState({
            status: newStatus
        })
    }

    render() {
        if(0 < Object.keys(this.state.itemData).length) { 
            const name = this.state.itemData.university_name;
            const countryName = CountryMap[this.state.itemData.country];
            const location = this.state.itemData.university_location
            const status = UniversityStatus[this.state.status];
            return (
                <div className="searchUniversityResultItem">
                    <div className="searchUniversityResultItemMainArea">
                        <div className="searchUniversityResultItemLogo">
                            <img src={this.state.itemData.university_logo} alt=""/>
                        </div>
                        <div className="searchUniversityResultItemSummary">
                            <div className="searchUniversityResultItemHeadingArea">
                                <h2 className="searchUniversityResultItemHeading">{name}<small>(所在地　{countryName + "：" + location})</small></h2>
                                {
                                    this.state.status < 9 ? <div className="searchUniversityResultItemStatus statusOrange">{status}</div>: null
                                }
                            </div>
                            <ul className="searchUniversityResultItemTermList">
                                {
                                    this.props.itemData.university_request.map((value, index) => {
                                        if (value.department != null) {
                                            const showData = value.department + "：" + value.people + "人ー" + value.term + "ヶ月間"
                                            return(<li key={index} className="searchUniversityResultItemTermItem">{showData}</li>)
                                        } else {
                                            return(null);
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className={this.state.toggle ? "searchUniversityResultItemDetailArea open" : "searchUniversityResultItemDetailArea"}>
                        <UnivDetail
                            universityId={this.state.itemData.university_id}
                            status={this.props.itemData.status}
                            setNewStatus={this.setStatus}
                            />
                    </div>
                    <div 
                        className={this.state.toggle ? "searchUniversityResultItemToggle open" : "searchUniversityResultItemToggle"} 
                        onClick={() => this.toggleChange()}></div>
                </div>
            )
        } else {
            return(null);
        }
    }
}

export default UnivOverview;