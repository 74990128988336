import {
    GET_UNIV_OVERVIEW_REQUEST,
    GET_UNIV_OVERVIEW_SUCCESS,
    GET_UNIV_OVERVIEW_FAILURE,
    GET_UNIV_OVERVIEW_RESET,
    GET_UNIV_DETAIL_REQUEST,
    GET_UNIV_DETAIL_SUCCESS,
    GET_UNIV_DETAIL_FAILURE,
    GET_UNIV_DETAIL_RESET
} from "../../actions/hotel/GetUnivInforAction";

const overviewState = {
    isFetching: false,
    univOverview: [],
    getInfo: false,
    isError: false,
    errorMessage: ""
}

const detailState = {
    isFetching: false,
    univDetail: [],
    university_id: null,
    isError: false,
    errorMessage: ""
}

export const getUniversityOverview = (state = overviewState, action) => {
    switch(action.type) {
        case GET_UNIV_OVERVIEW_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                univOverview: [],
                getInfo: false,
                isError: false,
                errorMessage: ""
            });
        case GET_UNIV_OVERVIEW_SUCCESS:
            console.log(action.data)
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = "";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = "";
                isError = true;
            } else {
                responseData = action.data;
                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                univOverview: responseData,
                getInfo: true,
                isError: isError,
                errorMessage: errorMessage
            });
        
        case GET_UNIV_OVERVIEW_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                univOverview: [],
                getInfo: true,
                isError: true,
                errorMessage: action.err
            });
        case GET_UNIV_OVERVIEW_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                univOverview: [],
                getInfo: true,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}

export const getUniversityDetail = (state = detailState, action) => {
    switch(action.type) {
        case GET_UNIV_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                univDetail: [],
                university_id: null,
                isError: false,
                errorMessage: ""
            });
        
        case GET_UNIV_DETAIL_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = "";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = "";
                isError = true;
            } else {
                responseData = action.data;
                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                univDetail: responseData,
                university_id: action.university_id,
                isError: isError,
                errorMessage: errorMessage
            });
        
        case GET_UNIV_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: [],
                university_id: action.university_id,
                isError: true,
                errorMessage: action.err
            });
        
        case GET_UNIV_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: [],
                university_id: null,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}