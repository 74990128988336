export const StatusMap = {
    0: "応募中",
    1: "不採用",
    2: "内定",
    3: "本人辞退",
    4: "採用",
    5: "未応募",
}

export const SexMap = {
    0: "男性",
    1: "女性",
}

export const SexFilterMap = {
    0: "未選択",
    1: "男性",
    2: "女性",
}

export const AssessmentStarMap = {
    0: "★★★★★",
    1: "☆★★★★",
    2: "☆☆★★★",
    3: "☆☆☆★★",
    4: "☆☆☆☆★",
    5: "☆☆☆☆☆",
}
