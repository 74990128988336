import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Header from "../components/common_parts/Header";
import Footer from "../components/common_parts/Footer";
import Button from "./atoms/Button";
import Top from "./skt/Top"
import ShowJob from "./posting/ShowJob";
import UnivInfo from "./skt/UnivInfo/UnivInfo";
import UnivDetail from "./skt/UnivInfo/UnivDetail"
import InsuranceView from "./skt/Insurance/InsuranceView";
import HistoryView from "./skt/History/HistoryView";
import JobDetail from "./posting/DetailJob";
import {getSelectConfig} from "../actions/skt/GetSelectConfigAction";
import {setInsuranceData} from "../constants/CommonConstatns";

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
const CognitoRefreshToken = require('amazon-cognito-identity-js-node').CognitoRefreshToken;
const config = require('./config');

const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId,
  ClientId: config.ClientId,
  Storage: sessionStorage
});


class HomeTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            display: "top",
            universityId: "",
            expired: 0,
            userType: "",
            jobId: null,
            organizationId: null,
            loginId: ""
        }
        this.switchDisplay = this.switchDisplay.bind(this);
        this.refreshToken = this.refreshToken.bind(this);
        this.setUniversityId = this.setUniversityId.bind(this);
    }

    refreshToken = () => {
        
        var CurrentUser = userPool.getCurrentUser();
        if(this.state.expired > 0){
            var date = new Date();
            var nowDate = date.getTime();
            var unixtime = Math.floor(nowDate / 1000);
            if(this.state.expired < unixtime){
                 if (CurrentUser != null) {
                     CurrentUser.signOut();
                     CurrentUser.getSession(function(err, session) {
                         console.log(session);
                     });
                 }
                sessionStorage.clear();
                this.props.history.push('/login')
                return;
            }
        }
        let _this = this;
        if (CurrentUser != null) {
            CurrentUser.getSession(function(err, session) {

                CurrentUser.getUserAttributes(function(err, attrresult) {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    for (var i = 0; i < attrresult.length; i++) {
                        if (attrresult[i].getName()==="email"){
                            let email = attrresult[i].getValue();
                            _this.setState({
                                loginId: email
                            })
                        }
                        else if (attrresult[i].getName()==="custom:user_type"){
                            let user_type = attrresult[i].getValue();
                            console.log(user_type)
                            _this.setState({
                                userType: user_type
                            })
                        }
                    }
                });
                var refreshTokenstr = session.getRefreshToken().getToken();
                var refreshToken = new CognitoRefreshToken({RefreshToken: refreshTokenstr});

                CurrentUser.refreshSession(refreshToken, (err, result)=>{
                    if(result) {
                        var idToken = result.getIdToken().getJwtToken();
                    
                        _this.setState({
                            expired: result.idToken.payload.exp
                        })

                        // API連携のためにIDトークンを常時更新
                        //const idTokenKey = 'CognitoIdentityServiceProvider.' + config.ClientId + '.' + this.state.loginId + '.idToken';
                        //sessionStorage.setItem(idTokenKey, idToken);
                    }
                });
            });
        }
        
    }

    componentDidMount = () => {
        this.browserBackBtnAction();
        this.refreshToken();
        this.props.getConfig();
    }

    static getDerivedStateFromProps(nextProps) {
        if(nextProps.isError) {
            nextProps.history.push('/login')
        }
    }

    switchDisplay = (dispId, isReset) => {
        this.refreshToken();
        this.setState({
            display: dispId
        });
    }

    switchDisplayWithJobId = (dispId, jobId, orgId) => {
        this.refreshToken();
        this.setState(
            {
                jobId: jobId,
                organizationId: orgId
            }, () => {
                this.setState({
                    display: dispId,
                })
            }
        )
    }

    setUniversityId = (universityId) => {
        console.log(universityId)
        this.setState({
            universityId: universityId
        });
    }

    logoutAction = () => {
        const CurrentUser = userPool.getCurrentUser();
        if (CurrentUser != null) {
            CurrentUser.signOut();
            sessionStorage.clear();
            CurrentUser.getSession(function(err, session) {
                 console.log(session);
            });
            this.props.history.replace("/login");
        }
    }

    browserBackBtnAction = () => {
        window.addEventListener("popstate", (e) => {
            const CurrentUser = userPool.getCurrentUser();
            if (CurrentUser != null) {
                CurrentUser.signOut();
                sessionStorage.clear();
                this.props.history.push("/login");
            }
            return;
        });
    }

    render() {
        let displayParts;
        if (this.state.display === "top") {
            displayParts = <Top 
                changeDisp={this.switchDisplay} 
                userType={this.state.userType} 
                logout_action={this.logoutAction}
                loginId={this.state.loginId}
                />;
        } else if (this.state.display === "university") {
            displayParts = <UnivInfo setUniversityId={this.setUniversityId} changeDisp={this.switchDisplay} userType={this.state.userType} universityId={this.state.universityId}/>;
        } else if (this.state.display === "insurance") {
            displayParts = <InsuranceView
                changeDisp={this.switchDisplay}
            />;
        } else if (this.state.display === "history") {
            displayParts = <HistoryView
                changeDisp={this.switchDisplay}
            />;
        } else if (this.state.display === "univ_detail") {
            displayParts = <UnivDetail changeDisp={this.switchDisplay} userType={this.state.userType} universityId={this.state.universityId}/>
       } else if (this.state.display === "show_job") {
            displayParts = <ShowJob
                changeDisp={this.switchDisplay}
                changeDispWithId={this.switchDisplayWithJobId}
                setIsNewJob={() => {}}
                isCompanyError={this.props.isCompanyDataError}
                errorDisable={this.companyInfoErrorDisable}
                companyInfoMessage={this.props.companyDataError}
                userType={this.state.userType}
                setPreFacility={() => {}}
                setPrePlace={() => {}}
            />;
        } else if (this.state.display === "job_detail") {
            displayParts = <JobDetail 
                jobId={this.state.jobId}
                organizationId={this.state.organizationId}
                user_type={this.state.userType}
                changeDisp={this.switchDisplay}
                changeDispWithData={null}
                applicantIdList={[]}
                setIsEdit={() => {}}
            />
        }

        if (this.state.display === "top") {
            return(
                displayParts
            )
        } else {
            return (
                <div>
                    <Header />
                    <article className="contentsArea">
                        <div id="logoutBtnWrapper">
                            <Button label="ログアウト" buttonEnable={true} callback={() => this.logoutAction()}/>
                        </div>
                        {displayParts}
                    </article>
                    <Footer />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    if (state.getSelectConfig.isError) {
        return {
            isError: state.getSelectConfig.isError
        }
    }
    if (state.getSelectConfig.configData.length) {
        setInsuranceData(state.getSelectConfig.configData);
    }
}

const mapDispatchToProps = (dispatch) => ({
    getConfig: () => dispatch(getSelectConfig())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeTemplate));
