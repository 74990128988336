import React from "react";
import "../../../css/Common.css";
import Button from "../../atoms/Button";
import Load from "../../atoms/Load";
import "../../../css/University.css";
import {connect} from "react-redux";
import {getUniversityOverview} from "../../../actions/skt/GetUnivAction";
import {CountryMap} from "../../../constants/CommonConstatns";
import Error from "../../atoms/Error"

class UnivInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            overviewData: [],
            countryStates: 0,
            displayCountry: 0,
            getMessage: ""
        }
        this.errorDisable = this.errorDisable.bind(this);
    }

    componentDidMount = () => {
        this.props.getOverviewInfo();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.overViewData || (prevState.overviewData !== nextProps.overviewData)) {
            return {
                overviewData: nextProps.overviewData,
                getMessage: nextProps.getMessage
            };
        }
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo)
    }

    moveToDetail = (id) => {
        this.props.setUniversityId(id)
        return this.props.changeDisp("univ_detail")
    }

    getCountryStates = (event) => {
        this.setState({
            countryStates: parseInt(event.target.value)
        });
    }

    setFilter = () => {
        this.setState({
            displayCountry: this.state.countryStates
        });
        this.render();
    }

    errorDisable = () => {
        this.setState({
            getMessage: ""
        });
    }

    handleRefine = () => {
        console.log("refine")
        this.setState({
            countryStates: this.state.countryStates,
        })
    }

    render() {
        var countryStatesptions = Object.entries(CountryMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {value}
                </option>
            )
        );

        return (
            <section>
                {this.props.showLoadIcon ? <Load setLabel="ただいま処理中です。しばらくお待ちください。"/>: null}
                {this.state.getMessage !== "" ? <Error close={this.errorDisable} apiName="skt_university_overview" setError={this.state.getMessage} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem active">大学検索</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                        <li className="breadcrumbItem">大学検索</li>
                    </ol>
                </div>
                <div className="layoutInner">
                    <section className="searchRefineArea">
                        <h2 className="searchRefineHeading">大学情報絞り込み項目</h2>
                        <div className="searchRefineContents">
                            <div className="searchRefineItemSkt">
                                <label htmlFor="term">所在国</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="term" name="term" required value={this.state.countryStates} onChange={this.getCountryStates}>
                                        {countryStatesptions}
                                    </select>
                                </div>
                                <div id="targetSearchBtnWrapper">
                                    <Button label="絞り込み検索" buttonEnable={true} callback={() => this.setFilter()}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div id="showUnivList">
                        <h1>
                            大学一覧
                        </h1>
                        <table border="1" bordercolor="#ffffff">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>大学ロゴ</th>
                                    <th>大学名</th>
                                    <th>所在国</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.overviewData.map((item, index) => {
                                        return(
                                            this.state.displayCountry === item.country || this.state.displayCountry === 0 ?
                                            <tr id="displayList"
						onClick={() => this.moveToDetail(item.university_id)} key={index}>
                                                <td>{item.university_id}</td>
                                                <td><img src={item.university_logo} /></td>
                                                <td>{item.university_name}</td>
                                                <td>{CountryMap[item.country]}</td>
                                            </tr>
                                            : null
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        overviewData: state.getSktUniversityOverview.overViewData,
        showLoadIcon: !state.getSktUniversityOverview.isGetInfo,
        getMessage: state.getSktUniversityOverview.getMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOverviewInfo: () => dispatch(getUniversityOverview())
});


export default connect(mapStateToProps, mapDispatchToProps)(UnivInfo);
