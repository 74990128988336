import React from "react";
import moment from "moment";
import "../../css/MainPage.css";
import "../../css/PostingShowJob.css";
import "../../css/Common.css";
import {OccupationMap, PostingSortMap} from "../../constants/CommonConstatns";
import {PostingStatusListMap, PostingStatusMap} from "../../constants/JobPostingConstants";
import {AssessmentStarMap} from "../../constants/ApplicantPostingConstants";
import TextInput from "../atoms/TextInput";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import {connect} from "react-redux";
import {getPostingOverviews, getPostingOverviewsReset} from "../../actions/posting/GetPostingAction";


class ShowJob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            overViewData: [],
            statusStates: 6,
            displayStatus: 6,
            sortStates: 0,
            qualification: [false, false, false, false],
            filter_list: [false, false, false, false],
            status_list: [false, false, false, false],
            init_flug: true,
            employment_start: "",
            employment_end: "",
            posting_start: "",
            posting_end: "",
            facility_name: "",
            company_name: "",
            occupation: "",
            displayfacility_name: "",
            displaycompany_name: "",
            displayemployment_start: "",
            displayemployment_end: "",
            displayposting_start: "",
            displayposting_end: "",
            isSort: true
        }
        this.clickJobDetail = this.clickJobDetail.bind(this);
    }

    componentDidMount = () => {
        this.props.setPrePlace("");
        this.props.setPreFacility("");
        this.props.getOverviews();
        if(this.props.userType === "K"){
            this.props.getCompanyData()
        }

        if (this.props.userType === "O"){
            this.props.setApplicantList([]);
        }

        this.props.setIsNewJob(false);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.overViewData !== nextProps.overViewData) {
            if(nextProps.userType === "H" && nextProps.overViewData.length > 0){
                return {
                    company_name: nextProps.overViewData[0].company_info.name,
                };
            }
        }
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo);
    }

    handleToDisplayNewjob = (changeTo) => {
        this.props.setIsNewJob(true);
        return this.props.changeDisp(changeTo);
    }

    clickJobDetail = (jobId, orgId) => {
        return this.props.changeDispWithId("job_detail", jobId, orgId);
    }

    companyErrorDisable = () => {
        this.props.errorDisable();
    }

    internErrorDisable = () => {
        this.props.overViewReset();
    }

    getOccupation = (event) => {
        this.setState({
            occupation: event.target.value
        });
    }

    setFilter = () => {
        this.setState({
            displayfacility_name: this.state.facility_name,
            displaycompany_name: this.state.company_name,
            displayemployment_start: this.state.employment_start.replace(/-/g, "/"),
            displayemployment_end: this.state.employment_end.replace(/-/g, "/"),
            displayposting_start: this.state.posting_start.replace(/-/g, "/"),
            displayposting_end: this.state.posting_end.replace(/-/g, "/"),
            status_list: this.state.filter_list.concat(),
            init_flug: this.state.filter_list.indexOf(true) === -1,
            isSort: false
        });
        this.render();
    }

    getQualification = index => event => {
        this.state.qualification[index] = event.target.checked;
        this.setState({
            qualification: this.state.qualification,
            isSort: false
        });
    };

    getStatusFilter = index => event => {
        this.state.filter_list[index] = event.target.checked;
        this.setState({
            filter_list: this.state.filter_list,
            isSort: false
        });
    };

    getEmploymentStart = (employmentStart) => {
        this.setState({
            employment_start: employmentStart,
            isSort: false
        });
    }

    getEmploymentEnd = (employmentEnd) => {
        this.setState({
            employment_end: employmentEnd,
            isSort: false
        });
    }

    getPostingStart = (posStart) => {
        this.setState({
            posting_start: posStart,
            isSort: false
        });
    }

    getPostingEnd = (posEnd) => {
        this.setState({
            posting_end: posEnd,
            isSort: false
        });
    }

    getCompanyName = (event) => {
        this.setState({
            company_name: event.target.value,
            isSort: false
        });
    }

    getFacilityName = (companyInfoFacilityName) => {
        this.setState({
            facility_name: companyInfoFacilityName,
            isSort: false
        });
    }

    getSortStates = (event) => {
        this.setState({
            sortStates: parseInt(event.target.value),
            isSort: true
        });
        this.render();
    }

    showDummyEvaluateTh = () => {
        if (this.props.userType === "S" || this.props.userType === "O") {
            return (
                <th>評価</th>
            )
        }
    }

    showDummyEvaluateTd = (val) => {
        if (this.props.userType === "S" || this.props.userType === "O") {
            return (
                <td>{AssessmentStarMap[val]}</td>
            )
        }
    }

    render() {

        var statusChackBoxes = Object.entries(PostingStatusListMap[this.props.userType]).map(
            ([key, value])=>(
                <label className="checkboxLabel">
                    <input type="checkbox" name="postingCheckBox" checked={this.state.filter_list[key]} onChange={this.getStatusFilter(key)}/>
                    <span className="checkboxText">{value}</span>
                </label>
            )
        );

        var occupationOptions = Object.entries(OccupationMap).map(
            ([key, value])=>(
                <option key={key} value={value}>
                    {value}
                </option>
            )
        );
        var sortOptions = Object.entries(PostingSortMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {value}
                </option>
            )
        );

        var compare = (a, b, desc = true) => {
            if (a !== a && b !== b) return 0;
            if (a !== a) return 1;
            if (b !== b) return -1; 
    
            if (a == null && b == null) return 0;
            if (a == null) return 1;
            if (b == null) return -1;
    
            if (a === '' && b === '') return 0;
            if (a === '') return 1;
            if (b === '') return -1;
    
            var sig = desc ? 1 : -1;
            return a < b ? sig : (a > b ? -sig : 0);
        }

        return (
            <article id="showInternArticle">
                {this.props.showLoadIcon ? <Load setLabel="読み込み中..."/>: null}
                {this.props.isCompanyError ? <Error close={this.companyErrorDisable} apiName="company_info" setError={this.props.companyInfoMessage} /> : null}
                {this.props.isPostingError ? <Error close={this.internErrorDisable} apiName="job_posting_overview" setError={this.props.internError} /> : null}
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                        <li className="breadcrumbItem">募集案件検索</li>
                    </ol>
                </div>
                <section className="searchRefineArea">
                    <h2 className="searchRefineHeading">募集案件絞り込み項目</h2>
                    <div className="searchRefineContents">
                        <div className="searchRefineItemIntern postingSearch">
                            <div className="flexDiv">
                                <div className="editInternInputItem">
                                    <label htmlFor="company">企業名</label>
                                    <div className="searchRefineItemSelect">
                                        <input id="company" name="company" type="text" disabled={this.props.userType === "H"} maxLength="300" value={this.state.company_name} onChange={(e) => this.getCompanyName(e)}/>
                                    </div>
                                </div>
                                <div className="editInternInputItem">
                                    <label htmlFor="term">業種</label>
                                    <div className="selectWrapper searchRefineItemSelect">
                                        <select id="term" name="term" disabled={this.props.userType === "H"} value={this.state.occupation} onChange={this.getOccupation}>
                                            {occupationOptions}
                                       </select>
                                    </div>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="facility">施設名</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="facility" name="facility" type="text" required={true} maxLength="300" setValue={this.state.facility_name} onHandleChange={(e) => this.getFacilityName(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="qualification">募集資格</label>
                                <div className="editInternInputInputArea checkboxGroup">
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.qualification[1]} onChange={this.getQualification(1)}/>
                                        <span className="checkboxText">技能実習</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.qualification[2]} onChange={this.getQualification(2)}/>
                                        <span className="checkboxText">特定技能</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.qualification[3]} onChange={this.getQualification(3)}/>
                                        <span className="checkboxText">インターン</span>
                                    </label>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodPublishStart">募集開始時期</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodPublishStart" name="periodPublishStart" type="date" setValue={this.state.postingstart} required={true} onHandleChange={(e) => this.getPostingStart(e)}/>
                                    〜
                                    <TextInput id="periodPublishEnd" name="periodPublishEnd" type="date" required={true} setValue={this.state.posting_end} onHandleChange={(e) => this.getPostingEnd(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodPublishStart">就業開始時期</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodPublishStart" name="periodPublishStart" type="date" setValue={this.state.employee_start} required={true} onHandleChange={(e) => this.getEmploymentStart(e)}/>
                                    〜
                                    <TextInput id="periodPublishEnd" name="periodPublishEnd" type="date" required={true} setValue={this.state.employee_end} onHandleChange={(e) => this.getEmploymentEnd(e)}/>
                                </div>
                            </div>
                             {(() => {
                                if(Object.keys(PostingStatusListMap[this.props.userType]).length > 0) {
                                    return(
                                        <div className="editInternInputItem">
                                            <label htmlFor="checkboxLabel">ステータス</label>
                                                <div className="editInternInputInputArea checkboxGroup">
                                                    {statusChackBoxes}
                                                </div>
                                        </div>
                                    )
                                }
                            })()}
                        </div>
                        <div id="targetSearchBtnWrapper" className="showPostingSearch">
                            <Button label="検索" buttonEnable={true} callback={() => this.setFilter()}/>
                        </div>
                    </div>
                </section>
                <div className="showPostingContents" id="showInternContents">
                    {this.props.userType === "K" ? <Button label="求人票新規作成" buttonEnable={true} callback={() => this.handleToDisplayNewjob("edit_job")}/> : null}
                    <div id="sortbox">
                        <h1>
                            現在の登録済募集案件一覧
                        </h1>
                        <div className="sortRefineItemIntern">
                            <label htmlFor="term">並べ替え</label>
                            <div className="selectWrapper sortRefineJobItemSelect">
                                <select id="term" name="term" required value={this.state.sortStates} onChange={this.getSortStates}>
                                    {sortOptions}
                                </select>
                            </div>
                        </div>
                    </div>
                    <table border="1" bordercolor="#ffffff">
                        <thead>
                            <tr>
                                <th>ステータス</th>
                                <th>募集資格</th>
                                <th>企業名</th>
                                <th>施設名</th>
                                <th>募集案件名</th>
                                <th>募集期間</th>
                                <th>就業期間</th>
                                <th>募集職種</th>
                                <th>募集人数</th>
                                {this.showDummyEvaluateTh()}
                                <th>詳細</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.overViewData.sort((a, b) => {
                                    if (this.state.isSort) {
                                        if (this.state.sortStates == 1) {
                                            return (compare(a.period.posting_start, b.period.posting_start, false));
                                        } else if(this.state.sortStates == 2) {
                                            return (compare(a.dummy_eval, b.dummy_eval, true));
                                        } else {
                                            return (compare(a.period.posting_start, b.period.posting_start, true));
                                        }
                                    }
                                }).map((item) => {

                                    let postingStatus = 0;
                                    
                                    // すでに案件作成済の場合
                                    if (item.entered_flg == 1) {
                                        const today = moment(new Date());
                                        const start_day = moment(item.period.posting_start);
                                        const end_day = moment(item.period.posting_end);

                                        if (today < start_day) {
                                            postingStatus = 1
                                        } else if (start_day <= today && today <= end_day) {
                                            postingStatus = 2
                                        } else if (end_day < today) {
                                            postingStatus = 3
                                        }
                                    }

                                    return (                                    
                                        (item.company_info.name.includes(this.state.displaycompany_name) || this.state.displaycompany_name === "") &&
                                        (item.company_info.facility_name.includes(this.state.displayfacility_name) || this.state.displayfacility_name === "") &&
                                        (item.period.employment_start >= this.state.displayemployment_start || this.state.displayemployment_start === "") &&
                                        (item.period.employment_end <= this.state.displayemployment_end || this.state.displayemployment_end === "") &&
                                        (item.period.posting_start >= this.state.displayposting_start || this.state.displayposting_start === "") &&
                                        (item.period.posting_end <= this.state.displayposting_end || this.state.displayposting_end === "") &&
                                        ((this.props.userType === "K" && ((item.entered_flg === 0 && this.state.status_list[0] === true) || (this.state.init_flug === true)) ||
                                        ((item.entered_flg === 1 && this.state.status_list[1] === true) || (this.state.init_flug === true))) ||
                                        (this.props.userType === "S" && ((item.entered_flg === 1 && item.status === 1 && this.state.status_list[2] === true) || (this.state.init_flug === true))) ||
                                        (this.props.userType === "O" && ((item.entered_flg === 1 && (item.status === 1 || item.status === 3 || item.status === 9)&& this.state.status_list[3] === true) || (this.state.init_flug === true))))?
                                        <tr key={item.job_id}>
                                            <td>{PostingStatusMap[postingStatus]}</td>
                                            <td>{item.qualification}</td>
                                            <td>{item.company_info.name}</td>
                                            <td>{item.company_info.facility_name}</td>
                                            <td>{item.title}</td>
                                            <td>{item.period.posting_start}<br/>〜<br/>{item.period.posting_end}</td>
                                            <td>{item.period.employment_start}<br/>〜<br/>{item.period.employment_end}</td>
                                            <td>{item.occupation}</td>
                                            <td>{item.accepted + "名"}</td>
                                            {this.showDummyEvaluateTd(item.dummy_eval)}
                                            <td><div className="detailButton"><Button label="詳細" buttonEnable={true} callback={() => this.clickJobDetail(item.job_id, item.organization_id)}/></div></td>
                                        </tr>
                                        : null
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        overViewData: state.getPostingOverviews.overViewData,
        showLoadIcon: !state.getPostingOverviews.isGetInfo,
        isPostingError: state.getPostingOverviews.isError,
        internError: state.getPostingOverviews.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOverviews: () => dispatch(getPostingOverviews()),
    overViewReset: () => dispatch(getPostingOverviewsReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowJob);

