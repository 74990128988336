import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css"
import Button from "../atoms/Button"
import TextInput from "../atoms/TextInput";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import {ErrorMap} from "../../constants/CommonConstatns";
import "../../css/EditIntern.css";


class EditIntern2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error_message: [],
            name: "",
            address: "",
            president: "",
            tel: "",
            fax: "",
            web: "",
            regular: null,
            part_time: null,
            facility_name: "",
            facility_address: "",
            pic: "",
            pic_tel: "",
            mail: ""
        }
        this.getCompanyInfoName = this.getCompanyInfoName.bind(this);
        this.getCompanyInfoAddress = this.getCompanyInfoAddress.bind(this);
        this.getCompanyInfoPresident = this.getCompanyInfoPresident.bind(this);
        this.getCompanyInfoTel = this.getCompanyInfoTel.bind(this);
        this.getCompanyInfoFax = this.getCompanyInfoFax.bind(this);
        this.getCompanyInfoWeb = this.getCompanyInfoWeb.bind(this);
        this.getCompanyInfoEmployeeRegular = this.getCompanyInfoEmployeeRegular.bind(this);
        this.getCompanyInfoEmployeePartTime = this.getCompanyInfoEmployeePartTime.bind(this);
        this.getCompanyInfoFacilityName = this.getCompanyInfoFacilityName.bind(this);
        this.getCompanyInfoFacilityAddress = this.getCompanyInfoFacilityAddress.bind(this);
        this.getCompanyInfoPic = this.getCompanyInfoPic.bind(this);
        this.getCompanyInfoPicTel = this.getCompanyInfoPicTel.bind(this);
        this.getCompanyInfoMail = this.getCompanyInfoMail.bind(this);
    }

    componentDidMount = () => {
        console.log(this.props.internData)
        this.setState({
            name: this.props.internData.company_info.name,
            address: this.props.internData.company_info.address,
            president: this.props.internData.company_info.president,
            tel: this.props.internData.company_info.tel,
            fax: this.props.internData.company_info.fax,
            web: this.props.internData.company_info.web,
            regular: this.props.internData.company_info.employee.regular,
            part_time: this.props.internData.company_info.employee.part_time,
            facility_name: this.props.internData.company_info.facility_name,
            facility_address: this.props.internData.company_info.facility_address,
            pic: this.props.internData.company_info.pic,
            pic_tel: this.props.internData.company_info.pic_tel,
            mail: this.props.internData.company_info.mail,
            error_message: this.props.errorMessage
        });
        window.scrollTo(0, 0);
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    temporalySave = () => {
        this.props.saveTemporaly();
    }

    getCompanyInfoName = (companyInfoName) => {
        this.setState({
            name: companyInfoName
        });
        this.props.getCompanyInfoName(companyInfoName)
    }

    getCompanyInfoAddress = (companyInfoAddress) => {
        this.setState({
            address: companyInfoAddress
        });
        this.props.getCompanyInfoAddress(companyInfoAddress)
    }

    getCompanyInfoPresident = (companyInfoPresident) => {
        this.setState({
            president: companyInfoPresident
        });
        this.props.getCompanyInfoPresident(companyInfoPresident)
    }

    getCompanyInfoTel = (companyInfoTel) => {
        this.setState({
            tel: companyInfoTel
        });
        this.props.getCompanyInfoTel(companyInfoTel)
    }

    getCompanyInfoFax = (companyInfoFax) => {
        this.setState({
            fax: companyInfoFax
        });
        this.props.getCompanyInfoFax(companyInfoFax)
    }

    getCompanyInfoWeb = (companyInfoWeb) => {
        this.setState({
            web: companyInfoWeb
        });
        this.props.getCompanyInfoWeb(companyInfoWeb)
    }

    getCompanyInfoEmployeeRegular = (companyInfoEmployeeRegular) => {
        this.setState({
            regular: parseInt(companyInfoEmployeeRegular)
        });
        this.props.getCompanyInfoEmployeeRegular(parseInt(companyInfoEmployeeRegular))
    }

    getCompanyInfoEmployeePartTime = (companyInfoEmployeePartTime) => {
        this.setState({
            part_time: parseInt(companyInfoEmployeePartTime)
        });
        this.props.getCompanyInfoEmployeePartTime(parseInt(companyInfoEmployeePartTime))
    }

    getCompanyInfoFacilityName = (companyInfoFacilityName) => {
        this.setState({
            facility_name: companyInfoFacilityName
        });
        this.props.getCompanyInfoFacilityName(companyInfoFacilityName)
    }

    getCompanyInfoFacilityAddress = (companyInfoFacilityAddress) => {
        this.setState({
            facility_address: companyInfoFacilityAddress
        });
        this.props.getCompanyInfoFacilityAddress(companyInfoFacilityAddress)
    }

    getCompanyInfoPic = (companyInfoPic) => {
        this.setState({
            pic: companyInfoPic
        });
        this.props.getCompanyInfoPic(companyInfoPic)
    }

    getCompanyInfoPicTel = (companyInfoPicTel) => {
        this.setState({
            pic_tel: companyInfoPicTel
        });
        this.props.getCompanyInfoPicTel(companyInfoPicTel)
    }

    getCompanyInfoMail = (companyInfoMail) => {
        this.setState({
            mail: companyInfoMail
        });
        this.props.getCompanyInfoMail(companyInfoMail)
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";
        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_intern" setError={this.props.saveMessage} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("outline", true)}>インターンの概要</a></li>
                        <li className="navigationItem active">インターン管理</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_intern", true)}>インターン管理</a></li>
                        <li className="breadcrumbItem">インターン新規登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern", false)}>募集時期</a></li>
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_2", false)}>会社概要</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern2">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoName">法人名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoName" name="companyInfoName" type="text" required={true} maxLength="300" setValue={this.state.name} onHandleChange={(e) => this.getCompanyInfoName(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoAddress">本社所在地<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoAddress" name="companyInfoAddress" type="text" required={true} maxLength="300" setValue={this.state.address} onHandleChange={(e) => this.getCompanyInfoAddress(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoPresident">代表者名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoPresident" name="companyInfoPresident" type="text" required={true} maxLength="300" setValue={this.state.president} onHandleChange={(e) => this.getCompanyInfoPresident(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoTel">代表電話番号<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoTel" name="companyInfoTel" type="tel" required={true} maxLength="13" pattern="[0-9]*" setValue={this.state.tel} onHandleChange={(e) => this.getCompanyInfoTel(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoFax">FAX番号</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoFax" name="companyInfoFax" type="tel" required={false} maxLength="13" pattern="[0-9]*" setValue={this.state.fax} onHandleChange={(e) => this.getCompanyInfoFax(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoWeb">ホームページ<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoWeb" name="companyInfoWeb" type="text" required={true} maxLength="300" setValue={this.state.web} onHandleChange={(e) => this.getCompanyInfoWeb(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoEmployeeRegular">従業員数<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <div className="editInternInputInputAreaColumn">
                                        <div className="editInternInputInputAreaRow">
                                            正社員:<TextInput id="companyInfoEmployeeRegular" name="companyInfoEmployeeRegular" type="number" required={true} setValue={this.state.regular} onHandleChange={(e) => this.getCompanyInfoEmployeeRegular(e)}/>名
                                        </div>
                                        <div className="editInternInputInputAreaRow">
                                            パート/アルバイト:<TextInput id="companyInfoEmployeePartTime" name="companyInfoEmployeePartTime" type="number" required={true} setValue={this.state.part_time} onHandleChange={(e) => this.getCompanyInfoEmployeePartTime(e)}/>名
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoFacilityName">施設名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoFacilityName" name="companyInfoFacilityName" type="text" required={true} maxLength="300" setValue={this.state.facility_name} onHandleChange={(e) => this.getCompanyInfoFacilityName(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoFacilityAddress">施設所在地<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoFacilityAddress" name="companyInfoFacilityAddress" type="text" required={true} maxLength="300" setValue={this.state.facility_address} onHandleChange={(e) => this.getCompanyInfoFacilityAddress(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoPic">担当者氏名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoPic" name="companyInfoPic" type="text" required={true} maxLength="300" setValue={this.state.pic} onHandleChange={(e) => this.getCompanyInfoPic(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoPicTel">電話番号<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoPicTel" name="companyInfoPicTel" type="tel" required={true} maxLength="13" pattern="[0-9]*" setValue={this.state.pic_tel} onHandleChange={(e) => this.getCompanyInfoPicTel(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoMail">メールアドレス<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoMail" name="companyInfoMail" type="text" required={true} maxLength="256" setValue={this.state.mail} onHandleChange={(e) => this.getCompanyInfoMail(e)}/>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_intern_3", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postDetailData.isFetching,
        isError: state.postDetailData.isError,
        saveMessage: state.postDetailData.errorMessage
    }
};

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(EditIntern2);

