import React, {useState, useEffect} from "react";

export default function TextInput(props) {
    const [txt, setTxt] = useState(props.setValue);
    const handleChange = (e) => {
        setTxt(e.target.value);
        props.onHandleChange(e.target.value)
    }

    useEffect(() => {
        setTxt(props.setValue)
    })

    return(
        <input id={props.id} name={props.name} type={props.type} value={txt} onChange={handleChange} placeholder={props.ph} required={props.required} minLength={props.minLength} maxLength={props.maxLength} pattern={props.pattern}/>
    )
}