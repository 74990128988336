import {
    GET_CANDIDATE_LIST_REQUEST,
    GET_CANDIDATE_LIST_SUCCESS,
    GET_CANDIDATE_LIST_FAILURE,
    GET_CANDIDATE_LIST_RESET
} from "../../actions/hotel/GetCandidateAction";

const infoState = {
    isFetching: false,
    candidateList: [],
    isError: false,
    errorMessage: ""
}

export const getCandidateList = (state = infoState, action) => {
    switch(action.type) {
        case GET_CANDIDATE_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                candidateList: [],
                isError: false,
                errorMessage: ""
            });
        case GET_CANDIDATE_LIST_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            }else{
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                candidateList: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case GET_CANDIDATE_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                candidateList: [],
                isError: true,
                errorMessage: action.err
            });
        case GET_CANDIDATE_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                candidateList: [],
                isError: false,
                errorMessage: ""
            });

        default:
            return state;
    }
}