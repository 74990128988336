import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css"
import Button from "../atoms/Button"
import Textarea from "../atoms/Textarea";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import {ErrorMap} from "../../constants/ManageConstants";
import "../../css/EditIntern.css"
import {initImage1} from "../../constants/TestData"

class EditJob5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            imageData: initImage1,
            error_message: []
        }
        this.getFacilityIntroductionComment = this.getFacilityIntroductionComment.bind(this);
        this.resetInput = this.resetInput.bind(this);
        this.fileInput = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            comment: this.props.jobData.facility_introduction.comment,
            imageData: this.props.jobData.facility_introduction.image == null ? initImage1: this.props.jobData.facility_introduction.image,
            error_message: this.props.errorMessage,
        });
        window.scrollTo(0, 0);
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    temporalySave = () => {
        this.props.saveTemporaly();
    }

    onFileChange(e) {
        const files = e.target.files;
        if(files.length > 0) {
            var file = files[0];
            var reader = new FileReader();
            reader.onload = (e) => {
                console.log(e.target.result);
                this.setState({ imageData: e.target.result })
                this.props.getFacilityIntroductionImage(e.target.result);
            };
            reader.readAsDataURL(file)
        } else {
            this.setState({ imageData: null });
            this.props.getFacilityIntroductionImage(null);
        }
    }

    resetInput() {
        this.fileInput.current.value = '';
        this.setState({ imageData: null });
        this.props.getFacilityIntroductionImage(null);
    }

    getFacilityIntroductionComment = (facilityIntroductionComment) => {
        this.setState({
            comment: facilityIntroductionComment
        });
        this.props.getFacilityIntroductionComment(facilityIntroductionComment)
    }

    saveReset = () => {
        this.props.saveReset();
    }

    addPicture = () => {
        console.log("add");
    }

    render() {
        const imageData = this.state.imageData;
        let preview = '';
        let resetButton = '';
        let addButton = '';
        if(imageData != null) {
            preview = (
                <img src={imageData} alt=""/>
            );
            resetButton = (
                <Button label="削除" buttonEnable={true} callback={() => this.resetInput()}/>
            )
            addButton = (
                <Button label="追加" buttonEnable={true} callback={() => this.addPicture()}/>
            )
        }
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_job_posting" setError={this.props.saveMessage} /> : null}
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_job", true)}>求人案件管理</a></li>
                        <li className="breadcrumbItem">求人案件登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job", false)}>募集時期</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_2", false)}>企業・施設概要</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_4", false)}>待遇</a></li>
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern5">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <div className="editInternUploadArea">
                                <div className="editInternUploadExplanation">職場の魅力、仕事をイメージしやすく</div>
                                <div className="editInternUploadButton">
                                    <label className="fileLabel">
                                        <input id="facilityIntroductionImageUpload"
                                               name="facilityIntroductionImageUpload" type="file" accept="image/*"
                                               ref={this.fileInput} onChange={(e) => {
                                            this.onFileChange(e)
                                        }} />
                                        <div className="fileButton">ファイルアップロード</div>
                                    </label>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="facilityIntroductionImage">職場写真</label>
                                <div className="editInternInputInputAreaColumn">
                                    <div className="editInternUploadImage">
                                        {preview}
                                    </div>
                                    <div className="deleteButton">
                                        {resetButton}
                                    </div>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="facilityIntroductionComment">コメント</label>
                                <div className="editInternInputInputArea">
                                    <Textarea id="facilityIntroductionComment" name="facilityIntroductionComment" required={false} maxLength="300" setValue={this.state.comment} onHandleChange={(e) => this.getFacilityIntroductionComment(e)}/>
                                </div>
                            </div>
                                <div className="addButton">
                                        {addButton}
                                </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_job_6", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postJobDetailData.isFetching,
        isError: state.postJobDetailData.isError,
        saveMessage: state.postJobDetailData.errorMessage
    }
};

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(EditJob5);

