import {
    GET_UNIV_OVERVIEW_REQUEST,
    GET_UNIV_OVERVIEW_SUCCESS,
    GET_UNIV_OVERVIEW_FAILURE,
    GET_UNIV_DETAIL_REQUEST,
    GET_UNIV_DETAIL_SUCCESS,
    GET_UNIV_DETAIL_FAILURE,
    S_UNIV_DETAIL_RESET

} from "../../actions/skt/GetUnivAction"

const overViewState = {
    isFetching: false,
    overViewData: [],
    isGetInfo: false,
    getMessage: "",
}

const detailState = {
    isFetching: false,
    univDetail: [],
    university_id: null,
    isGetInfo: false,
    getMessage: "",
}


export const getSktUniversityOverview = (state = overViewState, action) => {
    switch(action.type) {
        case GET_UNIV_OVERVIEW_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isGetInfo: false,
                getMessage: ""
            });
        case GET_UNIV_OVERVIEW_SUCCESS:
            let responseMessage = ""
            let responseData = []
            if (action.data === 400) {
                responseData = []
                responseMessage = "400 パラメータが不正です"
            } else if (action.data === 500) {
                responseData = []
                responseMessage = "500 予期しないエラーが発生しました"
            } else {
                responseData = action.data
                responseMessage = ""
            }
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: responseData,
                isGetInfo: true,
                getMessage: responseMessage
            });
        case GET_UNIV_OVERVIEW_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isGetInfo: true,
                getMessage: action.err
            });
        default:
            return state;
    }
}

export const getSktUniversityDetail = (state = detailState, action) => {
    switch(action.type) {
        case GET_UNIV_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                univDetail: [],
                university_id: null,
                isGetInfo: false,
                getMessage: ""
            });
        
        case GET_UNIV_DETAIL_SUCCESS:
            let responseMessage = ""
            if (action.data === 400) {
                responseMessage = "400 パラメータが不正です"
            } else if (action.data === 500) {
                responseMessage = "500 予期しないエラーが発生しました"
            } else {
                responseMessage = ""
            }
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: action.data,
                university_id: action.university_id,
                isGetInfo: true,
                getMessage: responseMessage
            });
        
        case GET_UNIV_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: [],
                university_id: action.university_id,
                isGetInfo: true,
                getMessage: action.err
            });
        case S_UNIV_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                univDetail: [],
                university_id: null,
                isGetInfo: true,
                getMessage: ""
            });
        default:
            return state;
    }
}
