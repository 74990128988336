import {combineReducers} from "redux"
import {getInternOverviews, getInternDetails} from "./hotel/getInternInfoReducer"
import {postDetailData, postPlanData} from "./hotel/saveInternInfoReducer";
import {getCompanyInformation} from "./hotel/getCompanyReducer";
import {getUniversityOverview, getUniversityDetail} from "./hotel/getUnivReducer";
import {univInternAction} from "./hotel/UnivInternActionReducer"
import {getCandidateList} from "./hotel/getCandidateListResucer";
import {saveCandidateAction} from "./hotel/saveCandidateReducer";
import {saveProfileData} from "./univ/controlUniversityReducer";
import {getUnivInternOverviews, getUnivInternDetails} from "./univ/getUnivInternInfoReducer";
import {saveUnivAction} from "./univ/saveUnivInternReducer";
import {getStudentList} from "./univ/getStudentReducer";
import {saveStudentData, deleteStudentData} from "./univ/controlStudentReducer";

import {getSktUniversityOverview, getSktUniversityDetail} from "./skt/getUniversityInfoReducer";
import {getUniversityProfileDetail} from "./univ/getUniversityInfoReducer";
import {saveUniversityData} from "./skt/controlUniversityReducer";
import {insuranceAction} from "./skt/requestInsuranceReducer";
import {getInsuranceTargetList} from "./skt/getInsurnceTargetReducer";
import {insuranceHistoryData} from "./skt/getInsuranceHistoryReducer";
import {getSelectConfig} from "./skt/selectConfigReducer";
import {exportReportFile} from "./skt/exportReportReducer";
import {getUpdatedInsuranceData} from "./skt/getInsuranceDetailReducer"
import {getManageCompanyInformation} from "./manage/getManageCompanyReducer";
import {postJobDetailData} from "./manage/saveJobPostingReducer";
import {getPostingOverviews} from "./posting/getPostingInfoReducer";
import {getPostingApplicant} from "./posting/getPostingApplicantInfoReducer";
import {getEntryApplicant} from "./posting/getEntryApplicantInfoReducer";
import {getJobPostingDetails} from "./posting/getJobPostingReducer";
import {getJobEntryList} from "./posting/getJobEntryListReducer";
import {entryJobResult} from "./posting/jobEntryActionReducer";
import {postingReactionResult} from "./posting/postingReactionReducer";
import {postingStatusResult} from "./posting/postingStatusReducer";
import {postingCancelResult} from "./posting/postingCancelReducer";
import {postingJobResult} from "./posting/postingJobReducer";

const rootReducer = combineReducers({
    getInternOverviews,
    getInternDetails,
    getCompanyInformation,
    getUniversityOverview,
    getUniversityDetail,
    postDetailData,
    postPlanData,
    univInternAction,
    getCandidateList,
    saveCandidateAction,
    getUnivInternOverviews,
    getUnivInternDetails,
    saveUnivAction,
    getStudentList,
    saveStudentData,
    deleteStudentData,
    getSktUniversityOverview,
    getSktUniversityDetail,
    getUniversityProfileDetail,
    saveUniversityData,
    saveProfileData,
    insuranceAction,
    getInsuranceTargetList,
    getSelectConfig,
    insuranceHistoryData,
    exportReportFile,
    getUpdatedInsuranceData,
    postJobDetailData,
    getManageCompanyInformation,
    getPostingOverviews,
    getPostingApplicant,
    getEntryApplicant,
    getJobPostingDetails,
    getJobEntryList,
    entryJobResult,
    postingReactionResult,
    postingStatusResult,
    postingCancelResult,
    postingJobResult
});

export default rootReducer;
