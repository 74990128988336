import axios from "axios";
import {BASE_URL, checkType, checkOverFlow, checkNumberOverflow, checkImageSize} from "../../constants/CommonConstatns";
import {INTERN_TITLE_DATA, INTERN_DETAIL_DATA} from "../../constants/TestData";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// SAVE STUDENT DATA
export const SAVE_UNIVERSITY_DATA_REQUEST = "SAVE_UNIVERSITY_DATA_REQUEST";
export const SAVE_UNIVERSITY_DATA_SUCCESS = "SAVE_UNIVERSITY_DATA_SUCCESS";
export const SAVE_UNIVERSITY_DATA_FAILURE = "SAVE_UNIVERSITY_DATA_FAILURE";
export const S_UNIV_SAVE_RESET = "S_UNIV_SAVE_RESET";

export const saveUniversityDataRequest = () => {
    return {
        type: SAVE_UNIVERSITY_DATA_REQUEST
    }
}

export const saveStudentDataSuccess = (data) => {
    return {
        type: SAVE_UNIVERSITY_DATA_SUCCESS,
        data
    }
}

export const saveStudentDataFailure = (err) => {
    return {
        type: SAVE_UNIVERSITY_DATA_FAILURE,
        err
    }
}

export const universitySaveReset = () => {
    return {
        type: S_UNIV_SAVE_RESET
    }
}

const SAVE_UNIVERSITY_LIST = BASE_URL + "/api/skt_save_university";

export const saveUniversityData = (univData) => {
    const idToken = getIdToken();
    let verify = true;

    return async(dispatch) => {
        dispatch(saveUniversityDataRequest());
        console.log(univData)
         // オーバーフローチェック
        if (!_check_param(univData)) {
            console.log("oveflow!")
            return dispatch(saveStudentDataSuccess(400))
        } else {
            axios.get(cognitoEndpoint).then(
                response => {
                   var pems = {};
                   var keys = response.data['keys'];
                   for(var i = 0; i < keys.length; i++) {
                       var key_id = keys[i].kid;
                       var modulus = keys[i].n;
                       var exponent = keys[i].e;
                       var key_type = keys[i].kty;
                       var jwk = { kty: key_type, n: modulus, e: exponent};
                       var jtp = jwkToPem(jwk);
                       pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(saveStudentDataFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        console.log(decodedJwt.payload.aud);
                        verify = false;
                        return dispatch(saveStudentDataFailure("audクレームがアプリクライアントID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt.payload.iss);
                        verify = false;
                        return dispatch(saveStudentDataFailure("issクレームがユーザープールID と一致しません"));
                    }
                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(saveStudentDataFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                return dispatch(saveStudentDataFailure(err));
                            } else {
                                console.log(univData);
                                const query = univData;
                                const headerParam = {
                                    Authorization: idToken
                                };
                                axios.post(SAVE_UNIVERSITY_LIST, query, {headers: headerParam}).then(
                                    res => {
                                        dispatch(saveStudentDataSuccess(res.data))
                                    }
                                ).catch(
                                    err => dispatch(saveStudentDataFailure(err))
                                    //res => dispatch(universityOverviewSuccess(INTERN_TITLE_DATA))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(saveStudentDataFailure(err))
                }
            )
        }
    }
}

export const resetSaveUniversity = () => {
    return (dispatch) => dispatch(universitySaveReset());
}

const _check_param = (query) => {
    let check_ok = true;
    for (const key in query) {

        const target = query[key];

        if (query.hasOwnProperty(key)) {
            if (!_param[key]) {
                return false;
            }

            if (_param[key] !== checkType(target)) {
                return false;
            }
            console.log(key)
            if (key === "university_id") {
                check_ok = checkOverFlow(target);
            } else if (key === "university_logo") {
                check_ok = checkImageSize(target)
                console.log("img:" + check_ok);
            } else if (key === "university_name") {
                check_ok = checkOverFlow(target)
                console.log("name:" + check_ok);
            } else if (key === "country") {
                check_ok = checkNumberOverflow(target)
                console.log("country:" + check_ok);
            } else if (key === "university_location") {
                check_ok = checkOverFlow(target)
            } else if (key === "section") {
                check_ok = checkOverFlow(target)
            } else if (key === "people") {
                check_ok = checkOverFlow(target)
            } else if (key === "period") {
                check_ok = checkNumberOverflow(target)
            } else if (key === "division") {
                check_ok = checkNumberOverflow(target)
            } else if (key === "japanese") {
                check_ok = checkOverFlow(target)
            } else if (key === "english") {
                check_ok = checkOverFlow(target)
            } else if (key === "teacher_name") {
                check_ok = checkOverFlow(target)
            } else if (key === "teacher_mail") {
                check_ok = checkOverFlow(target)
            }

            if (!check_ok) {
                return false;
            }
            
        } else {
            return false;
        }
    }

    return check_ok;
}

const _param = {
    "university_id": "string",
    "university_logo": "string",
    "university_name": "string",
    "country": "number",
    "university_location": "string",
    "section": "string",
    "people": "string",
    "period": "number",
    "division": "number",
    "jpteacher": "boolean",
    "japanese": "string",
    "english": "string",
    "teacher_name": "string",
    "teacher_mail": "string"
}
