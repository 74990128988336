import React from "react";
import "../../css/Common.css";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import StatusPostCheck from "../atoms/StatusPostCheck";
import CompanyInfoTable from "./detail_job_parts/CompanyInfoTable";
import JobPostingTable from "./detail_job_parts/JobPostingTable";
import EntryListTable_O from "./detail_job_parts/EntryListTable_O";
import EntryListTable_H_K from "./detail_job_parts/EntryListTable_H_K";
import EntryListTable_S from "./detail_job_parts/EntryListTable_S";
import ApplicantTable from "./detail_job_parts/ApplicantTable";
import ApplicantTable_O_H from "./detail_job_parts/ApplicantTable_O_H";
import "../../css/JobDetail.css"
import {connect} from "react-redux";
import {getJobPostingDetail, resetPostingDetail} from "../../actions/posting/GetPostingDetailAction";
import {getJobEntryInfoList, jobEntryListReset} from "../../actions/posting/GetEntryInfoListAction";
import {getEntryApplicant, getEntryApplicantReset} from "../../actions/posting/GetEntryApplicantListAction";
import {entryJobPosting, jobEntryActionReset} from "../../actions/posting/EntryJobPostingAction";
import {reactionJobPosting, jobPostingReactinReset} from "../../actions/posting/ReactionForRequestAction";
import {postApplicantStatus, applicantStatusReset} from "../../actions/posting/PostApplicantStatusAction";
import {postApplicantCancel, applicantCancelReset} from "../../actions/posting/PostApplicantCancelAction";
import {postApplicantJob, applicantJobReset} from "../../actions/posting/PostJobAction";
import {PrefecturesMap, calculatePayment, toDoubleDigits} from "../../constants/CommonConstatns";
import {EntryStatusMessageMap} from "../../constants/JobPostingConstants"
import {initImage2} from "../../constants/TestData"

class DetailJob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            overViewData: [],
            detailData: {},
            entryLogList: [],
            status: -1,
            entryBtnLabel: "エントリー",
            entryBtnEnable: true,
            jobId: -1,
            user_type: "",
            organizationId: "",
            sendingMessage: [],
            saveInfo: false,
            sktStatus: null,
            sktMessage: "",
            sktIndex: null,
            isCheck: false,
            checkLabel1: "",
            checkLabel2: "",
            checkLabel3: "",
            applicantId: "",
            applicantStatus: 0,
            message: "",
            applicantIdList: [],
            cancelId: ""
        }
    }

    componentDidMount = () => {
        this.setState({
            jobId: this.props.jobId,
            organizationId: this.props.organizationId,
            user_type: this.props.user_type,
            applicantIdList: this.props.applicantIdList
        }, () => {
            this.props.getDetailInfo(this.state.jobId, this.state.organizationId);
            this.props.jobEntryInfoList(this.state.jobId)
            this.props.getEntryApplicant(this.state.jobId)
        })

    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.jobDetailData !== prevState.detailData) {
            console.log(nextProps.jobDetailData)
            return {
                detailData: nextProps.jobDetailData
            }
        }

        if (nextProps.entryList !== prevState.entryLogList) {
            if (0 < nextProps.entryList.length) {
                const sts = nextProps.entryList[0].status;
                let label = sts === 0 ? "エントリー": "エントリ取下げ"
                let enabled = true;

                if (sts == 3 || sts == 9) {
                    enabled = false;
                }            
                return {
                    entryLogList: nextProps.entryList,
                    status: sts,
                    entryBtnLabel: label,
                    entryBtnEnable: enabled
                }
            } else {
                const getCurrentList = nextProps.applicantIdList.slice();
                const od = nextProps.overViewData.slice()
                for(let i in getCurrentList){
                    let applicant = getCurrentList[i];
                    applicant.message = [];
                    od.push(applicant);
                }
                return {
                    entryLogList: nextProps.entryList,
                    status: 0,
                    entryBtnLabel: "エントリー",
                    entryBtnEnable: true,
                    overViewData: od
                }
            }
        }

        if (nextProps.overViewData !== prevState.overViewData || nextProps.applicantIdList !== prevState.applicantIdList) {
            console.log(nextProps.overViewData)
            const getCurrentList = nextProps.applicantIdList.slice();
            let od = nextProps.overViewData.slice()
                if (nextProps.jobResult === 200) {
                    for(let i in getCurrentList){
                        if(getCurrentList[i].status === null){
                            getCurrentList[i].status = 0
                        }
                    }
                    nextProps.applicantJobReset()
                }
                if (nextProps.cancelResult === 200) {
                    for(let i in getCurrentList){
                        if(getCurrentList[i].applicant_id === prevState.cancelId){
                            getCurrentList[i].status = null
                        }
                     }
                     for(let i in od){
                         if(od[i].applicant_id === prevState.cancelId){
                             od[i].status = null
                         }
                     }
                     nextProps.applicantCancelReset()
                }

                if (nextProps.statusResult === 200) {
                    for(let i in od){
                        if(od[i].applicant_id === prevState.applicantId){
                            od[i].status = prevState.applicantStatus
                            od[i].message.unshift(prevState.message)
                        }
                    }
                    nextProps.applicantStatusReset()
                }

                for(let i in getCurrentList){
                    let applicant = getCurrentList[i];
                    applicant.message = [];
                    od.push(applicant);
                }

            return {
                overViewData: od
            }
        }
        
        if (nextProps.entryJobResult === 200) {
            const logList = prevState.entryLogList;
            const sts = prevState.status == 0 ? 1: 0;
            const date = new Date();
            const year = date.getFullYear();
            const month = toDoubleDigits(date.getMonth() + 1);
            const day = toDoubleDigits(date.getDate());
            const hour = toDoubleDigits(date.getHours());
            const minute = toDoubleDigits(date.getMinutes());
            const ymd = year + "/" + month + "/" + day + " " + hour + ":" + minute;
            const log = {
                "send_org_id": "",
                "send_org": "",
                "status": sts,
                "entry_date": ymd,
                "message": EntryStatusMessageMap[sts]
            }
            logList.unshift(log)
            if (prevState.status == 0) {
                return {
                    status: 1,
                    entryBtnLabel: "エントリ取下げ",
                    entryLogList: logList
                }
            } else {
                return {
                    status: 0,
                    entryBtnLabel: "エントリー",
                    entryLogList: logList
                }
            }
        }

        if (nextProps.reactionResult === 200) {
            const getCurrentList = prevState.entryLogList.slice();
            const targetParam = getCurrentList[prevState.sktIndex];
            targetParam.status = prevState.sktStatus == 1 ? 3: 9;
            targetParam.message = EntryStatusMessageMap[targetParam.status] + "\n" + prevState.sktMessage;
            getCurrentList[prevState.sktIndex] = targetParam;
            return {
                entryLogList: getCurrentList
            }
        }

        return null;
    }

    getisCheck = (id, status, message) => {
        let label1 = "";
        let label2 = "";
        let label3 = "";
        if(status === 4){
            label1 = "本人意思確認済み。";
            label2 = "採用・入国手続を行うため、";
            label3 = "企業に連絡します。よろしいですか？";
        }
        else if(status === 3) {
            label1 = "本人の意思で内定を辞退します。";
            label3 = "募集企業に連絡しますが、よろしいですか？";

        }
        else if(status === 1 || status == 2) {
            label1 = "送り出し機関へ合否の連絡をします。";
            label3 = "よろしいですか？";

        }
        this.setState({
            isCheck: true,
            applicantId: id,
            applicantStatus:status,
            message:message,
            checkLabel1: label1,
            checkLabel2: label2,
            checkLabel3: label3,
        });
    }

    getisCheckDisable = () => {
        this.setState({
            isCheck: false,
        });
    }

    postCancel = (id) => {
        const newList = this.props.applicantIdList.filter((v) => v.applicant_id !== id);
        this.props.setApplicantList(newList);
    }
    postJob = (idList) => {
        this.props.postApplicantJob(
            this.state.jobId,
            idList
        )
    }

    postApplicantStatus = () => {
        let ms = (typeof this.state.message === 'undefined' || !this.state.message) ? "コメント無し" : this.state.message;
        const date = new Date();
        const year = date.getFullYear();
        const month = toDoubleDigits(date.getMonth() + 1);
        const day = toDoubleDigits(date.getDate());
        const ymd = "（" + year + "/" + month + "/" + day + "）";
        let org =  this.state.user_type === "O" ? "機関" : "企業"
        ms = org + " " + ymd + " \n" + ms
        this.props.postApplicantStatus(
            this.state.jobId, 
            this.state.applicantId,
            this.state.applicantStatus,
            ms
        )
        this.setState({
            isCheck: false,
            message: ms
        });
    }

    handleToDisplay = (changeTo) => {
        this.props.setIsEdit(false);
        return this.props.changeDisp(changeTo)
    }

    handleToDisplayWithEdit = (cahgeTo) => {
        this.props.setIsEdit(true);
        return this.props.changeDisp(cahgeTo)
    }

    displayButtonByUserK = () => {
        if (this.state.user_type === "K") {
            return(
                <div className="jobDetailButtonArea">
                    <div className="editJobDetailButton">
                        <Button label="募集要項編集" buttonEnable={true} callback={() => this.handleToDisplayWithData()}/>
                    </div>
                </div>
            )
        } 
    }

    displayButtonByUserO = () => {
        if (this.state.user_type === "O") {
            return(
                <div className="jobDetailButtonArea">
                    <div className="entryActionButton">
                        <Button label={this.state.entryBtnLabel} buttonEnable={this.state.entryBtnEnable} callback={() => this.setEntryJobPostingAction()} />
                    </div>
                </div>
            )
        }
    }

    displayEntryListByUserType = () => {
        if (this.state.user_type === "O") {
            return(
                <EntryListTable_O
                    entryList={this.state.entryLogList}
                />
            )
        } else if (this.state.user_type === "K" || this.state.user_type === "H") {
            return(
                <EntryListTable_H_K
                    entryList={this.state.entryLogList}
                />
            )
        } else if (this.state.user_type === "S") {
            return(
                <EntryListTable_S
                    entryList={this.state.entryLogList}
                    setSendingMessage={this.setSendingMessage}
                    sendReaction={this.sendJobPostingReaction}
                />
            )
        }
    }

    displayApplicantTable = () => {
        // エントリ情報の最新ステータスが「3」の場合
        // 応募者一覧を表示する
        if ((this.state.user_type === "O" || this.state.user_type === "H") && (this.state.status === 3)) {
            return(
                <ApplicantTable_O_H 
                    entryApplicantList={this.state.overViewData}
                    user_type={this.state.user_type}
                    getisCheck={this.getisCheck}
                    handleToDisplay={this.handleToDisplayWithEdit}
                    applicantIdList={this.state.applicantIdList}
                    postCancel={this.postCancel}
                    postJob={this.postJob}
                    isEdit={this.props.isEdit}
                />
            )
        }else if((this.state.user_type === "K" || this.state.user_type === "S") && (this.state.status === 3)){
            return(
                <ApplicantTable 
                    entryApplicantList={this.state.overViewData}
                />
            )

        }
    }

    handleToDisplayWithData = () => {
        return this.props.changeDispWithData("edit_job", this.state.detailData);
    }

    setEntryJobPostingAction = () => {
        let sts = 0;
        if (this.state.status == null || this.state.status == 0) {
            sts = 1
        } else {
            sts = 0
        }
        this.props.entryJobPostingAction(this.state.jobId, sts)
    }

    detailErrorDisable = () => {
        this.props.postingDetailActionReset()
    }

    entryListErrorDisable = () => {
        this.props.jobEntryListReset()
    }

    entryJobErrorDisable = () => {
        this.props.jobEntryReset()
    }

    reactionJobErrorDisable = () => {
        this.props.reactionJobReset()
    }

    getPostingApplicantErrorDisable = () => {
        this.props.getEntryApplicantReset()
    }

    applicantStatusDisable = () => {
        this.props.applicantStatusReset()
    }

    applicantCancelDisable = () => {
        this.props.applicantCancelReset()
    }

    applicantJobDisable = () => {
        this.props.applicantJobReset()
    }

    setSendingMessage = (index, message) => {
        const insertList = this.state.sendingMessage.slice();
        insertList[index] = message;
        this.setState({
            sendingMessage: message
        });
    }

    sendJobPostingReaction = (index, action) => {
        const msg = this.state.sendingMessage[index];
        const org_id = this.state.entryLogList[index].send_org_id;
        this.setState({
            sktIndex: index,
            sktStatus: action,
            sktMessage: msg
        }, () => {
            this.props.reactionJobEntry(
                this.state.jobId,
                org_id,
                action,
                this.state.sendingMessage[index]
            )
        });
    }

    render() {
        if(0 < Object.keys(this.state.detailData).length) {
            const working_time = this.state.working_time;
            let payment = 0

            const salary = this.state.detailData.livelihood_support.salary;
            const amountList = this.state.detailData.livelihood_support.deduction.map((value) => {
                return value.amount
            });
            payment = calculatePayment(salary, amountList);
            
            const pref = this.state.detailData.company_info.facility_address ? this.state.detailData.company_info.facility_address.slice(0, 3): "";
            const map = "/img/" + PrefecturesMap[pref] + ".png";
            return (
                <article>
                    {this.props.isDetailFetch ? <Load setLabel="読み込み中..."/>: null}
                    {this.props.showLoadIcon ? <Load setLabel="読み込み中..."/>: null}
                    {this.props.entryJobFetchnig ? <Load setLabel="送信中..."/>: null}
                    {this.props.reactionFetching ? <Load setLabel="送信中..."/>: null}
                    {this.props.statusFetching ? <Load setLabel="送信中..."/>: null}
                    {this.props.cancelFetching ? <Load setLabel="送信中..."/>: null}
                    {this.props.jobFetching ? <Load setLabel="送信中..."/>: null}
                    {this.state.isCheck ? <StatusPostCheck clickYes={this.postApplicantStatus} clickNo={this.getisCheckDisable} setLabel1={this.state.checkLabel1} setLabel2={this.state.checkLabel2} setLabel3={this.state.checkLabel3} />: null}
                    {this.props.isDetailError ? <Error close={this.detailErrorDisable} apiName="job_posting_detail" setError={this.props.errorMessageDetail} /> : null}
                    {this.props.isEntryListError ? <Error close={this.entryListErrorDisable} apiName="get_entry_information" setError={this.props.errorMessageEntryList} /> : null}
                    {this.props.entryJobError ? <Error close={this.entryJobErrorDisable} apiName="entry_job_posting" setError={this.props.entryJobErrorMessage} /> : null}
                    {this.props.reactionIsError ? <Error close={this.reactionJobErrorDisable} apiName="response_entry" setError={this.props.reactionErrorMessage} /> : null}
                    {this.props.isPostingError ? <Error close={this.getPostingApplicantErrorDisable} apiName="get_entried_applicant" setError={this.props.getPostingApplicantError} /> : null}
                    {this.props.statusIsError ? <Error close={this.applicantStatusDisable} apiName="set_applicant_status" setError={this.props.statusErrorMessage} /> : null}
                    {this.props.cancelIsError ? <Error close={this.applicantCancelDisable} apiName="applicant_cancel" setError={this.props.cancelErrorMessage} /> : null}
                    {this.props.jobIsError ? <Error close={this.applicantJobDisable} apiName="apply_job_posting" setError={this.props.jobErrorMessage} /> : null}
                    <div className="breadcrumbArea layoutInner">
                        <ol className="breadcrumbList">
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_job")}>募集管理</a></li>
                            <li className="breadcrumbItem">募集要項詳細</li>
                        </ol>
                    </div>
                    <div className="jobDetailOverviewArea">
                        <div>
                            <img src={initImage2} />
                        </div>
                        <div>
                            <p>{this.state.detailData.company_info.name}</p>
                            <p>技能実習　募集職種：<span>{this.state.detailData.occupation}</span> 手取り賃金：<span>{payment}</span>円 / 月</p>
                        </div>
                        <div>
                            <p>募集人数：{this.state.detailData.accepted}人</p>
                            <p>就業開始：{this.state.detailData.period.employment_start}</p>
                        </div>
                    </div>
                    <CompanyInfoTable
                        name={this.state.detailData.company_info.name}
                        web={this.state.detailData.company_info.web}
                        facility_name={this.state.detailData.company_info.facility_name}
                        facility_address={this.state.detailData.company_info.facility_address}
                        map={map}
                    />
                    {this.displayButtonByUserK()}
                    <JobPostingTable 
                        occupation={this.state.detailData.occupation}
                        content={this.state.detailData.content}
                        working_time={this.state.detailData.working_time}
                        holiday={this.state.detailData.holiday}
                        total_holiday={this.state.detailData.total_holidays}
                        payment={payment}
                        facility_introduction={this.state.detailData.facility_introduction}
                        nearby_info={this.state.detailData.nearby_info}
                    />
                    {this.displayButtonByUserO()}
                    {this.displayEntryListByUserType()}
                    {this.displayApplicantTable()}

                </article>
            )
        } else {
            return(
                <div>
                    {this.props.isDetailFetch ? <Load setLabel="読み込み中..."/>: null}
                    {this.props.isDetailError ? <Error close={this.detailErrorDisable} apiName="job_posting_detail" setError={this.props.errorMessageDetail} /> : null}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        jobDetailData: state.getJobPostingDetails.detailData,
        isDetailError: state.getJobPostingDetails.isError,
        errorMessageDetail: state.getJobPostingDetails.errorMessage,
        isDetailFetch: state.getJobPostingDetails.isFetch,
        entryList: state.getJobEntryList.entryListData,
        isEntryListError: state.getJobEntryList.isError,
        errorMessageEntryList: state.getJobEntryList.errorMessage,
        entryJobFetchnig: state.entryJobResult.isFetching,
        entryJobResult: state.entryJobResult.responseData,
        entryJobError: state.entryJobResult.isError,
        entryJobErrorMessage: state.entryJobResult.errorMessage,
        reactionResult: state.postingReactionResult.responseData,
        reactionFetching: state.postingReactionResult.isFetching,
        reactionIsError: state.postingReactionResult.isError,
        reactionErrorMessage: state.postingReactionResult.errorMessage,
        overViewData: state.getEntryApplicant.overViewData,
        showLoadIcon: !state.getEntryApplicant.isGetInfo,
        isPostingError: state.getEntryApplicant.isError,
        getPostingApplicantError: state.getEntryApplicant.errorMessage,
        statusResult: state.postingStatusResult.responseData,
        statusFetching: state.postingStatusResult.isFetching,
        statusIsError: state.postingStatusResult.isError,
        statusErrorMessage: state.postingStatusResult.errorMessage,
        cancelResult: state.postingCancelResult.responseData,
        cancelFetching: state.postingCancelResult.isFetching,
        cancelIsError: state.postingCancelResult.isError,
        cancelErrorMessage: state.postingCancelResult.errorMessage,
        jobResult: state.postingJobResult.responseData,
        jobFetching: state.postingJobResult.isFetching,
        jobIsError: state.postingJobResult.isError,
        jobErrorMessage: state.postingJobResult.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getDetailInfo: (jobId, organizationId) => dispatch(getJobPostingDetail(jobId, organizationId)),
    postingDetailActionReset: () => dispatch(resetPostingDetail()),
    jobEntryInfoList: (jobId) => dispatch(getJobEntryInfoList(jobId)),
    jobEntryListReset: () => dispatch(jobEntryListReset()),
    entryJobPostingAction: (jobId, status) => dispatch(entryJobPosting(jobId, status)),
    jobEntryReset: () => dispatch(jobEntryActionReset()),
    reactionJobEntry: (jobId, orgId, answer, message) => dispatch(reactionJobPosting(jobId, orgId, answer, message)),
    reactionJobReset: () => dispatch(jobPostingReactinReset()),
    postApplicantStatus: (jobId, applicantId, status, message) => dispatch(postApplicantStatus(jobId, applicantId, status, message)),
    applicantStatusReset: () => dispatch(applicantStatusReset()),
    postApplicantCancel: (jobId, applicantId) => dispatch(postApplicantCancel(jobId, applicantId)),
    applicantCancelReset: () => dispatch(applicantCancelReset()),
    postApplicantJob: (jobId, applicantIdList) => dispatch(postApplicantJob(jobId, applicantIdList)),
    applicantJobReset: () => dispatch(applicantJobReset()),
    getEntryApplicant: (jobId) => dispatch(getEntryApplicant(jobId)),
    getEntryApplicantReset: () => dispatch(getEntryApplicantReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailJob);
