import {
    EXPORT_REPORT_REQUEST,
    EXPORT_REPORT_SUCCESS,
    EXPORT_REPORT_FAILURE,
    EXPORT_REPORT_CLEAR
} from "../../actions/skt/ExportReportAction"

const resuponseState = {
    isFetching: false,
    responseData: 0,
    isError: false,
}

export const exportReportFile = (state = resuponseState, action) => {
    switch(action.type) {
        case EXPORT_REPORT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                responseData: "",
                isError: false,
            });
        case EXPORT_REPORT_SUCCESS:
            let responseData = 0
            let isError = false;
            if (action.data === 400) {
                responseData = "400 パラメータが不正です";
                isError = true;
            } else if (action.data === 500) {
                responseData = "500 予期しないエラーが発生しました";
                isError = true;
            }else{
                responseData = action.data
            }
            return Object.assign({}, state, {
                isFetching: false,
                responseData: responseData,
                isError: isError
            });
        case EXPORT_REPORT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                responseData: action.err,
                isError: true
            });
        case EXPORT_REPORT_CLEAR:
            return Object.assign({}, state, {
                isFetching: false,
                responseData: 0,
                isError: false,
            });
        default:
            return state;
    }
}
