import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css"
import Button from "../atoms/Button"
import TextInput from "../atoms/TextInput";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import {ErrorMap, PrefecturesMap} from "../../constants/ManageConstants";
import "../../css/EditIntern.css";


class EditJob2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error_message: [],
            name: "",
            address: "",
            president: "",
            tel: "",
            fax: "",
            web: "",
            regular: null,
            part_time: null,
            facility_name: "",
            facility_address: "",
            company_id: "",
            company_list_num: 0,
            company: "",
            pic: "",
            position: "",
            pic_tel: "",
            mail: "",
            pre_place: "",
            pre_facility: ""
        }
        this.getCompanyInfoName = this.getCompanyInfoName.bind(this);
        this.getCompanyInfoAddress = this.getCompanyInfoAddress.bind(this);
        this.getCompanyInfoPresident = this.getCompanyInfoPresident.bind(this);
        this.getCompanyInfoTel = this.getCompanyInfoTel.bind(this);
        this.getCompanyInfoFax = this.getCompanyInfoFax.bind(this);
        this.getCompanyInfoWeb = this.getCompanyInfoWeb.bind(this);
        this.getCompanyInfoEmployeeRegular = this.getCompanyInfoEmployeeRegular.bind(this);
        this.getCompanyInfoEmployeePartTime = this.getCompanyInfoEmployeePartTime.bind(this);
        this.getCompanyInfoFacilityName = this.getCompanyInfoFacilityName.bind(this);
        this.getCompanyInfoFacilityAddress = this.getCompanyInfoFacilityAddress.bind(this);
        this.getCompanyInfoPic = this.getCompanyInfoPic.bind(this);
        this.getCompanyInfoPosition = this.getCompanyInfoPosition.bind(this);
        this.getCompanyInfoPicTel = this.getCompanyInfoPicTel.bind(this);
        this.getCompanyInfoMail = this.getCompanyInfoMail.bind(this);
        this.getCompanyInfoId = this.getCompanyInfoId.bind(this);
    }

    componentDidMount = () => {
        console.log(this.props.jobData)
        console.log(this.props.isNewJob)
        let company = this.props.jobData.company_info.company_id === "" ? this.props.companyList[this.props.companylist_num] : this.props.jobData.company_info;
        const pos = company.position ? company.position : "";
        console.log(company);
        var re = /^(.{2}[都道府県]|.{3}県)(.+)/;
        let address1 = "";
        let address2 = "";
        if(this.props.prePlace === ""){
            if(re.test(company.address)){
                var splitaddress = company.address.replace(/^(.{2}[都道府県]|.{3}県)(.+)/, "$1 $2").split(' ');
                address1 = splitaddress[0];
                address2 = splitaddress[1];
            }else{
                address1 = "東京都";
                address2 = company.address;
            }
        }else{
            address1 = this.props.prePlace;
            address2 = company.address.replace(address1, "");
        }

        let facility1 = "";
        let facility2 = "";
        if(this.props.preFacility === ""){
            if(re.test(company.facility_address)){
                var splitadd = company.facility_address.replace(/^(.{2}[都道府県]|.{3}県)(.+)/, "$1 $2").split(' ');
                facility1 = splitadd[0];
                facility2 = splitadd[1];
            }else{
                facility1 = "東京都";
                facility2 = company.facility_address;
            }
        }else{
            facility1 = this.props.preFacility;
            facility2 = company.facility_address.replace(facility1, "");
        }
        this.setState({
            company_id: company.company_id,
            name: company.name,
            pre_place: address1,
            address: address2,
            president: company.president,
            tel: company.tel,
            fax: company.fax,
            web: company.web,
            regular: company.employee.regular,
            part_time: company.employee.part_time,
            facility_name: company.facility_name,
            pre_facility: facility1,
            facility_address: facility2,
            pic: company.pic,
            position: pos,
            pic_tel: company.pic_tel,
            mail: company.mail,
            company_list_num: this.props.companylist_num,
            error_message: this.props.errorMessage
        });

       this.getCompanyInfoId(company.company_id);
       this.getCompanyInfoName(company.name);
       this.props.setPrePlace(address1);
       this.props.setPreFacility(facility1);
       this.getCompanyInfoAddress(address2);
       this.getCompanyInfoPresident(company.president);
       this.getCompanyInfoTel(company.tel);
       this.getCompanyInfoFax(company.fax);
       this.getCompanyInfoWeb(company.web);
       this.getCompanyInfoEmployeeRegular(company.employee.regular);
       this.getCompanyInfoEmployeePartTime(company.employee.part_time);
       this.getCompanyInfoFacilityName(company.facility_name);
       this.getCompanyInfoFacilityAddress(facility2);
       this.getCompanyInfoPic(company.pic);
       this.getCompanyInfoPosition(pos);
       this.getCompanyInfoPicTel(company.pic_tel);
       this.getCompanyInfoMail(company.mail);
        window.scrollTo(0, 0);
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    temporalySave = () => {
        this.props.saveTemporaly();
    }

    getCompanyInfo = (event) => {
       console.log(event.target.value)
       let listIndex = parseInt(event.target.value);
       this.setState({
           company_list_num: listIndex
       });
       let company = this.props.companyList[listIndex];
       this.getCompanyInfoId(company.company_id);
       this.getCompanyInfoName(company.name);
       this.getCompanyInfoAddress(company.address);
       this.getCompanyInfoPresident(company.president);
       this.getCompanyInfoTel(company.tel);
       this.getCompanyInfoFax(company.fax);
       this.getCompanyInfoWeb(company.web);
       this.getCompanyInfoEmployeeRegular(company.employee.regular);
       this.getCompanyInfoEmployeePartTime(company.employee.part_time);
       this.getCompanyInfoFacilityName(company.facility_name);
       this.getCompanyInfoFacilityAddress(company.facility_address);
       this.getCompanyInfoPic(company.pic);
       this.getCompanyInfoPosition(company.position);
       this.getCompanyInfoPicTel(company.pic_tel);
       this.getCompanyInfoMail(company.mail);
       this.props.setCompanyListNum(listIndex);
    }

    getPrePlace = (event) => {
       let place = event.target.value
       this.setState({
           pre_place: place
       }); 
       this.props.setPrePlace(place);
       this.props.getCompanyInfoAddress(place + this.state.address);
    }

    getPreFacility = (event) => {
       let facility = event.target.value
       this.setState({
           pre_facility: facility
       }); 
       this.props.setPreFacility(facility);
       this.props.getCompanyInfoFacilityAddress(facility + this.state.facility_address)
    }

    getCompanyInfoId = (id) => {
        this.setState({
            company_id: id
        });
        this.props.getCompanyInfoId(id)
    }

    getCompanyInfoName = (companyInfoName) => {
        this.setState({
            name: companyInfoName
        });
        this.props.getCompanyInfoName(companyInfoName)
    }

    getCompanyInfoAddress = (companyInfoAddress) => {
        this.setState({
            address: companyInfoAddress
        });
        this.props.getCompanyInfoAddress(this.props.prePlace + companyInfoAddress);
    }

    getCompanyInfoPresident = (companyInfoPresident) => {
        this.setState({
            president: companyInfoPresident
        });
        this.props.getCompanyInfoPresident(companyInfoPresident)
    }

    getCompanyInfoTel = (companyInfoTel) => {
        this.setState({
            tel: companyInfoTel
        });
        this.props.getCompanyInfoTel(companyInfoTel)
    }

    getCompanyInfoFax = (companyInfoFax) => {
         const fax = companyInfoFax ? companyInfoFax: "";
        this.setState({
            fax: fax
        });
        this.props.getCompanyInfoFax(fax)
    }

    getCompanyInfoWeb = (companyInfoWeb) => {
        this.setState({
            web: companyInfoWeb
        });
        this.props.getCompanyInfoWeb(companyInfoWeb)
    }

    getCompanyInfoEmployeeRegular = (companyInfoEmployeeRegular) => {
        this.setState({
            regular: parseInt(companyInfoEmployeeRegular)
        });
        this.props.getCompanyInfoEmployeeRegular(parseInt(companyInfoEmployeeRegular))
    }

    getCompanyInfoEmployeePartTime = (companyInfoEmployeePartTime) => {
        this.setState({
            part_time: parseInt(companyInfoEmployeePartTime)
        });
        this.props.getCompanyInfoEmployeePartTime(parseInt(companyInfoEmployeePartTime))
    }

    getCompanyInfoFacilityName = (companyInfoFacilityName) => {
        this.setState({
            facility_name: companyInfoFacilityName
        });
        this.props.getCompanyInfoFacilityName(companyInfoFacilityName)
    }

    getCompanyInfoFacilityAddress = (companyInfoFacilityAddress) => {
        this.setState({
            facility_address: companyInfoFacilityAddress
        });
        this.props.getCompanyInfoFacilityAddress(this.props.preFacility + companyInfoFacilityAddress)
    }

    getCompanyInfoPic = (companyInfoPic) => {
        this.setState({
            pic: companyInfoPic
        });
        this.props.getCompanyInfoPic(companyInfoPic)
    }

    getCompanyInfoPosition = (companyInfoPosition) => {
        const pos = companyInfoPosition ? companyInfoPosition: "";
        this.setState({
            position: pos
        });
        this.props.getCompanyInfoPosition(pos)
    }
    getCompanyInfoPicTel = (companyInfoPicTel) => {
        this.setState({
            pic_tel: companyInfoPicTel
        });
        this.props.getCompanyInfoPicTel(companyInfoPicTel)
    }

    getCompanyInfoMail = (companyInfoMail) => {
        this.setState({
            mail: companyInfoMail
        });
        this.props.getCompanyInfoMail(companyInfoMail)
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";
        var companyOptions = null;
        if(this.props.isNewJob) {
            if (this.props.companyList != undefined) {
                companyOptions = this.props.companyList.map(
                    (company, i)=>(
                        <option key={i} value={i}>
                            {company.name}
                        </option>
                    )
                );
            }
        }else{

           companyOptions = (
                <option key={0} value={this.state.name}>
                    {this.state.name}
                 </option>
           )
        }

        var prefectureOptions = Object.entries(PrefecturesMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {key}
                </option>
            )
        );

        var prefectureFacilityOptions = Object.entries(PrefecturesMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {key}
                </option>
            )
        );

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_job_posting" setError={this.props.saveMessage} /> : null}
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_job", true)}>求人案件管理</a></li>
                        <li className="breadcrumbItem">求人案件登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job", false)}>募集時期</a></li>
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_2", false)}>企業・施設概要</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern2">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoName">法人名<span className="editInternRequired">*</span></label>
                                <div className="selectWrapper editInternInputInputArea">
                                    <select id="term" name="term" disabled={!this.props.isNewJob} value={this.state.company_list_num} onChange={this.getCompanyInfo}>
                                        {companyOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoAddress">本社所在地<span className="editInternRequired">*</span></label>
                                <div className="selectWrapper editInternInputInputArea" id="addressInputManage">
                                    <select id="term" name="term" disabled={false} value={this.state.pre_place} onChange={this.getPrePlace}>
                                        {prefectureOptions}
                                    </select>
                                </div>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoAddress" name="companyInfoAddress" type="text" required={true} maxLength="300" setValue={this.state.address} onHandleChange={(e) => this.getCompanyInfoAddress(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoPresident">代表者名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoPresident" name="companyInfoPresident" type="text" required={true} maxLength="300" setValue={this.state.president} onHandleChange={(e) => this.getCompanyInfoPresident(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoTel">代表電話番号<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoTel" name="companyInfoTel" type="tel" required={true} maxLength="13" pattern="[0-9]*" setValue={this.state.tel} onHandleChange={(e) => this.getCompanyInfoTel(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoFax">FAX番号</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoFax" name="companyInfoFax" type="tel" required={false} maxLength="13" pattern="[0-9]*" setValue={this.state.fax} onHandleChange={(e) => this.getCompanyInfoFax(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoWeb">ホームページ<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoWeb" name="companyInfoWeb" type="text" required={true} maxLength="300" setValue={this.state.web} onHandleChange={(e) => this.getCompanyInfoWeb(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoEmployeeRegular">従業員数<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <div className="editInternInputInputAreaColumn">
                                        <div className="editInternInputInputAreaRow">
                                            正社員:<TextInput id="companyInfoEmployeeRegular" name="companyInfoEmployeeRegular" type="number" required={true} setValue={this.state.regular} onHandleChange={(e) => this.getCompanyInfoEmployeeRegular(e)}/>名
                                        </div>
                                        <div className="editInternInputInputAreaRow">
                                            パート/アルバイト:<TextInput id="companyInfoEmployeePartTime" name="companyInfoEmployeePartTime" type="number" required={true} setValue={this.state.part_time} onHandleChange={(e) => this.getCompanyInfoEmployeePartTime(e)}/>名
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoFacilityName">就業施設名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoFacilityName" name="companyInfoFacilityName" type="text" required={true} maxLength="300" setValue={this.state.facility_name} onHandleChange={(e) => this.getCompanyInfoFacilityName(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoFacilityAddress">就業施設所在地<span className="editInternRequired">*</span></label>
                                <div className="selectWrapper editInternInputInputArea" id="addressInputManage">
                                    <select id="term" name="term" disabled={false} value={this.state.pre_facility} onChange={this.getPreFacility}>
                                        {prefectureFacilityOptions}
                                    </select>
                                </div>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoFacilityAddress" name="companyInfoFacilityAddress" type="text" required={true} maxLength="300" setValue={this.state.facility_address} onHandleChange={(e) => this.getCompanyInfoFacilityAddress(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoPic">担当者氏名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoPic" name="companyInfoPic" type="text" required={true} maxLength="300" setValue={this.state.pic} onHandleChange={(e) => this.getCompanyInfoPic(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoPic">担当者役職<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoPic" name="companyInfoPosition" type="text" required={true} maxLength="300" setValue={this.state.position} onHandleChange={(e) => this.getCompanyInfoPosition(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoPicTel">電話番号<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoPicTel" name="companyInfoPicTel" type="tel" required={true} maxLength="13" pattern="[0-9]*" setValue={this.state.pic_tel} onHandleChange={(e) => this.getCompanyInfoPicTel(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="companyInfoMail">メールアドレス<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="companyInfoMail" name="companyInfoMail" type="text" required={true} maxLength="256" setValue={this.state.mail} onHandleChange={(e) => this.getCompanyInfoMail(e)}/>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_job_3", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postJobDetailData.isFetching,
        isError: state.postJobDetailData.isError,
        saveMessage: state.postJobDetailData.errorMessage
    }
};

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(EditJob2);

