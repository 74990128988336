export const SET_INSURANCE_DATA = "SET_INSURANCE_DATA";
export const UPDATE_INSURANCE_DATA = "UPDATE_UNSURANCE_DATA";
export const CLEAR_INSURANCE_DATA = "CLEAR_INSURANCE_DATA";

export const setData = (data) => {
    return {
        type: SET_INSURANCE_DATA,
        data
    }
}

export const updateData = (data) => {
    return {
        type: UPDATE_INSURANCE_DATA,
        data
    }
}

export const clearData = () => {
    return {
        type: CLEAR_INSURANCE_DATA
    }
}

export const setInsuranceDetailData = (insuranceData) => {
    return (dispatch) => dispatch(setData(insuranceData));
}

export const updateInsuranceDetailData = (insuranceData) => {
    return (dispatch) => dispatch(updateData(insuranceData));
}

export const clearInsuranceData = () => {
    return (dispatch) => dispatch(clearData());
}
