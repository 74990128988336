import {
    GET_POSTING_DETAIL_REQUEST,
    GET_POSTING_DETAIL_SUCCESS,
    GET_POSTING_DETAIL_FAILURE,
    GET_POSTING_DETAIL_RESET

} from "../../actions/posting/GetPostingDetailAction";

const detailState = {
    isFetching: false,
    detailData: [],
    isError: false,
    errorMessage: ""
}

export const getJobPostingDetails = (state = detailState, action) => {
    switch(action.type) {
        case GET_POSTING_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                detailData: [],
                isError: false,
                errorMessage: ""
            });
        case GET_POSTING_DETAIL_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            }else{
                responseData = action.data;
                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                detailData: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case GET_POSTING_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                detailData: [],
                isError: true,
                errorMessage: action.err
            });
        case GET_POSTING_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                detailData: [],
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}
