import React from "react";
import "../../css/Common.css";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import UnivOverview from "./univParts/UnivOverview";
import "../../css/SearchUniversity.css";
import {connect} from "react-redux";
import {getUniversityOverview, resetUniverSityOverView, resetUniversityDetail} from "../../actions/hotel/GetUnivInforAction";
import {checkType} from "../../constants/CommonConstatns";


class SearchUniversity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            overviewData: [],
            country: 0,
            division: 0,
            period: 0,
            status: 0,
            refineCountry: 0,
            refineDivision: 0,
            refinePeriod: 0,
            refineStatus: 0
        }
    }

    componentDidMount = () => {
        this.props.getOverviewInfo();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.overviewData !== nextProps.overviewData) {
            return {
                overviewData: nextProps.overviewData
            };
        }
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo)
    }

    getCountry = (event) => {
        this.setState({
            country: parseInt(event.target.value)
        });
    }

    getDivision = (event) => {
        this.setState({
            division: parseInt(event.target.value)
        });
    }

    getPeriod = (event) => {
        this.setState({
            period: parseInt(event.target.value)
        });
    }

    getStatus = (event) => {
        this.setState({
            status: parseInt(event.target.value)
        });
    }

    handleRefine = () => {
        this.setState({
            refineCountry: this.state.country,
            refineDivision: this.state.division,
            refinePeriod: this.state.period,
            refineStatus: this.state.status
        })
    }

    overviewReset = () => {
        this.props.resetOverview()
    }

    detailReset = () => {
        this.props.resetDetail()
    }

    render() {
        var countryStates = [
            { code: 0, name: "指定なし" },
            { code: 1, name: "ベトナム" },
            { code: 2, name: "台湾" },
            { code: 3, name: "インドネシア" },
            { code: 4, name: "ミャンマー" },
            { code: 5, name: "シンガポール" },
            { code: 6, name: "マレーシア" },
            { code: 7, name: "中国" },
            { code: 8, name: "韓国" } ];
        var countryOptions = countryStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );
        var divisionStates = [
            { code: 0, name: "指定なし" },
            { code: 1, name: "外国語学部関連" },
            { code: 2, name: "経済学部関連" },
            { code: 3, name: "教育学部関連" } ];
        var divisionOptions = divisionStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );
        var periodStates = [
            { code: 0, name: "指定なし" },
            { code: 1, name: "～3か月" },
            { code: 2, name: "～6か月" },
            { code: 3, name: "～12か月" } ];
        var periodOptions = periodStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );
        var statusStates = [
            { code: 0, name: "指定なし" },
            { code: 1, name: "打診中" },
            { code: 2, name: "打診失敗" },
            { code: 3, name: "打診成功" },
            { code: 9, name: "なし" } ];
        var statusOptions = statusStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );

        return (
            <article>
                {this.props.showLoadIcon ? <Load setLabel="読み込み中..."/>: null}
                {this.props.isError ? <Error close={this.overviewReset} apiName="university_overview" setError={this.props.errorMessage} /> : null}
                {this.props.detailError ? <Error close={this.detailReset} apiName="university_detail" setError={this.props.detailMessage} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem active">大学検索</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                        <li className="breadcrumbItem">大学検索画面</li>
                    </ol>
                </div>
                <div className="layoutInner">
                    <section className="searchUniversityRefineArea">
                        <h2 className="searchUniversityRefineHeading">大学絞り込み項目</h2>
                        <div className="searchUniversityRefineContents">
                            <div className="searchUniversityRefineItem">
                                <label htmlFor="country">国</label>
                                <div className="selectWrapper searchUniversityRefineItemSelect">
                                    <select id="country" name="country" required value={this.state.country} onChange={this.getCountry}>
                                        {countryOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="searchUniversityRefineItem">
                                <label htmlFor="division">学部</label>
                                <div className="selectWrapper searchUniversityRefineItemSelect">
                                    <select id="division" name="division" required value={this.state.division} onChange={this.getDivision}>
                                        {divisionOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="searchUniversityRefineItem">
                                <label htmlFor="period">インターン期間</label>
                                <div className="selectWrapper searchUniversityRefineItemSelect">
                                    <select id="period" name="period" required value={this.state.period} onChange={this.getPeriod}>
                                        {periodOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="searchUniversityRefineItem">
                                <label htmlFor="status">ステータス</label>
                                <div className="selectWrapper searchUniversityRefineItemSelect">
                                    <select id="status" name="status" required value={this.state.status} onChange={this.getStatus}>
                                        {statusOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="searchUniversityRefineButton">
                                <Button label="絞り込み検索" buttonEnable={true} callback={() => this.handleRefine()}/>
                            </div>
                        </div>
                    </section>
                    <section className="searchUniversityResult">
                        {
                            this.state.overviewData.map((item, index) => {
                                let minTerm = 9999;
                                if (checkType(item.university_request) === "array") {
                                    for (let i = 0; i < item.university_request.length; i++) {
                                        if (item.university_request[i].term != null && minTerm > item.university_request[i].term) {
                                            minTerm = item.university_request[i].term;
                                        }
                                    }
                                }
                                if((this.state.refineCountry === 0 || item.country === this.state.refineCountry) && (this.state.refineDivision === 0 || item.division === this.state.refineDivision) && (this.state.refinePeriod === 0 || (this.state.refinePeriod === 1 && minTerm <= 3) || (this.state.refinePeriod === 2 && minTerm <= 6) || (this.state.refinePeriod === 3 && minTerm <= 12)) && (this.state.refineStatus === 0 || item.status === this.state.refineStatus)) {
                                    return(<UnivOverview
                                        key={item.university_id}
                                        itemData={item}/>)
                                }
                            })
                        }
                    </section>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        overviewData: state.getUniversityOverview.univOverview,
        showLoadIcon: !state.getUniversityOverview.getInfo,
        isError: state.getUniversityOverview.isError,
        errorMessage: state.getUniversityOverview.errorMessage,
        detailError: state.getUniversityDetail.isError,
        detailMessage: state.getUniversityDetail.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOverviewInfo: () => dispatch(getUniversityOverview()),
    resetOverview: () => dispatch(resetUniverSityOverView()),
    resetDetail: () => dispatch(resetUniversityDetail())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchUniversity);