import React, { useEffect, useState } from "react";

export default function Textarea(props) {
    const [txt, setTxt] = useState(props.setValue);
    const handleChange = (e) => {
        setTxt(e.target.value);
        props.onHandleChange(e.target.value)
    }

    useEffect(() => {
        setTxt(props.setValue)
    })

    return(
        <textarea id={props.id} name={props.name} type="text" value={txt} onChange={handleChange} placeholder={props.ph} required={props.required} maxlength={props.maxLength}/>
    )
}