import React from "react";
import "../../../css/Common.css";
import "../../../css/Search.css"
import SearchDetail from "./SearchDetail";
import {calculatePayment} from "../../../constants/CommonConstatns";

class SearchOverview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            itemData: {},
            intern_period: 0,
            status: 9
        }
        this.toggleChange = this.toggleChange.bind(this);
    }

    componentDidMount = () => {
        const start = new Date(this.props.itemData.period.intern_start);
        const end = new Date(this.props.itemData.period.intern_end);
        const diff = end.getTime() - start.getTime();
        const daysDiff = (Math.floor(diff / (1000 * 60 * 60 * 24))) + 1;
        this.setState({
            status: this.props.itemData.status,
            itemData: this.props.itemData,
            intern_period: Math.floor(daysDiff / 30)
        });
    }

    toggleChange() {
        const tgl = this.state.toggle ? false: true;
        this.setState({
            toggle: tgl
        });        
    }

    setStatus = (newStatus) => {
        this.setState({
            status: newStatus
        })
    }

    render() {
        if(0 < Object.keys(this.state.itemData).length) {
            let salary = 0;
            let amountList = [];
            let payment = 0

            if ("livelihood_support" in this.state.itemData) {
                salary = this.state.itemData.livelihood_support.salary;
                amountList = this.state.itemData.livelihood_support.deduction.map((value) => {
                    return value.amount
                });
                payment = calculatePayment(salary, amountList);
            }
            return (
                <div className="searchResultItem">
                    <div className="searchResultItemMainArea">
                        <div className="searchResultItemSummary">
                            <div className="searchResultItemHeadingArea">
                                <h2 className="searchResultItemHeading">{this.state.itemData.title}</h2>
                                {
                                    this.state.status === 1 ? <div className="searchResultItemStatus statusOrange">応募済</div>: null
                                }
                            </div>
                            <ul className="searchResultItemSummaryList">
                                <li className="searchResultItemSummaryItem">インターン時期：{this.state.itemData.period.intern_start}〜{this.state.itemData.period.intern_end}</li>
                                <li className="searchResultItemSummaryItem">インターン期間：{this.state.intern_period}か月</li>
                                <li className="searchResultItemSummaryItem">インターン人数：{this.state.itemData.accepted}人</li>
                                <li className="searchResultItemSummaryItem">手取り支援額(月額)：{payment}円</li>
                            </ul>
                        </div>
                    </div>
                    <div className={this.state.toggle ? "searchResultItemDetailArea open" : "searchResultItemDetailArea"}>
                        <SearchDetail 
                            intern_id={this.state.itemData.intern_id}
                            status={this.state.itemData}
                            setNewStatus={this.setStatus}
                            salary={payment}
                            />
                    </div>
                    <div 
                        className={this.state.toggle ? "searchResultItemToggle open" : "searchResultItemToggle"}
                        onClick={() => this.toggleChange()}>
                    </div>
                </div>
            )
        } else {
            return(null);
        }
    }
}

export default SearchOverview;