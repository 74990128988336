import { 
    SAVE_DETAIL_REQUEST,
    SAVE_DETAIL_SUCCESS,
    SAVE_DETAIL_FAILURE,
    SAVE_DETAIL_RESET,
    SAVE_PLAN_REQUEST,
    SAVE_PLAN_SUCCESS,
    SAVE_PLAN_FAILURE,
    SAVE_PLAN_RESET
 } from "../../actions/hotel/SaveInternAction";

 const saveDetailState = {
     isFetching: false,
     saveState: "",
     isError: false,
     errorMessage: "",
     isSaveAction: false
 }

 const savePlanState = {
     isFetching: false,
     saveState: "",
     isError: false,
     errorMessage: ""
 }

 // SAVE PLAN DETAIl
 export const postDetailData = (state = saveDetailState, action) => {
     switch(action.type) {
         case SAVE_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveState: "",
                isError: false,
                errorMessage: "",
                isSaveAction: true
             });
        case SAVE_DETAIL_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = "";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = "";
                isError = true;
            } else {
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                saveState: responseData,
                isError: isError,
                errorMessage: errorMessage,
                isSaveAction: true
            });
        case SAVE_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: true,
                errorMessage: action.err,
                isSaveAction: true
            });
        case SAVE_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: false,
                errorMessage: "",
                isSaveAction: true
             });
        default:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: false,
                errorMessage: "",
                isSaveAction: false
             });
     }
 }

 // SAVE PLAN REDUCER
 export const postPlanData = (state = savePlanState, action) => {
    switch(action.type) {
        case SAVE_PLAN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveState: "",
                isError: false,
                errorMessage: ""
             });
        case SAVE_PLAN_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = "";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = "";
                isError = true;
            } else {
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                saveState: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case SAVE_PLAN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: true,
                errorMessage: action.err
            });
        case SAVE_PLAN_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: false,
                errorMessage: ""
             });
        default:
            return state;
    }
}