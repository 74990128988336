import React from "react";
import "../../../css/Common.css";
import TextInput from "../../atoms/TextInput";
import Button from "../../atoms/Button";
import Load from "../../atoms/Load";
import {connect} from "react-redux";
import {saveStudentData, deleteStudentData} from "../../../actions/univ/ControllStudentAction";
import {FACE_IMAGE} from "../../../constants/CommonConstatns";

class EditDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            candidate_status: 0,
            index_num: 0,
            studentData: {},
            saveInfo: false,
            saveBtnEnable: false
        }
    }

    componentDidMount = () => {
        const setData = JSON.parse(JSON.stringify(this.props.studentData));

        setData.candidate_birthday
            = setData.candidate_birthday ? setData.candidate_birthday.replace(/\//g, "-"): null;

        setData.candidate_passportlimit
            = setData.candidate_passportlimit ? setData.candidate_passportlimit.replace(/\//g, "-"): null;

        this.setState({
            candidate_status: this.props.candidateStatus,
            studentData: setData,
            index_num: this.props.index_num,
            saveBtnEnable: this.props.studentData.candidate_student_no ? true: false
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.saveInfo !== nextProps.saveInfo) {
            if (nextProps.saveInfo.toString().indexOf("SUCCESS") === 0) {
                return {
                    candidate_status: 1,
                    saveInfo: false
                }
            }
            return {
                saveInfo: nextProps.saveInfo.toString() === "REQUEST" ? true: false
            }
        }
    }

    setCandidateStudentNo = (text) => {
        this.state.studentData.candidate_student_no = text;
        if (0 < text.length) {
            this.setState({
                studentData: this.state.studentData,
                saveBtnEnable: true
            });
        } else {
            this.setState({
                studentData: this.state.studentData,
                saveBtnEnable: false
            });
        }
        
    }

    setCandidateName = (text) => {
        this.state.studentData.candidate_name = text;
        this.setState({studentData: this.state.studentData});
    }

    setCandidateNickname = (text) => {
        this.state.studentData.candidate_nickname = text
        this.setState({studentData: this.state.studentData});
    }

    setCandidateCountry = (text) => {
        this.state.studentData.candidate_country = text;
        this.setState({studentData: this.state.studentData});
    }

    setCandidateBirthday = (text) => {
        this.state.studentData.candidate_birthday = text;
        this.setState({studentData: this.state.studentData});
    }

    setCandidateSex = (text) => {
        this.state.studentData.candidate_sex = text;
        this.setState({studentData: this.state.studentData});
    }

    setCandidateJapaneseLevel = (text) => {
        this.state.studentData.candidate_japanese_level = text;
        this.setState({studentData: this.state.studentData});
    }

    setCandidateDepartment = (text) => {
        this.state.studentData.candidate_department = text;
        this.setState({studentData: this.state.studentData});
    }

    setCandidateAcquiredCredits = (num) => {
        this.state.studentData.candidate_acquired_credits = num - 0;
        this.setState({studentData: this.state.studentData});
    }

    setCandidateScheduleCredits = (num) => {
        this.state.studentData.candidate_schedule_credits = num - 0;
        this.setState({studentData: this.state.studentData});
    }

    setCandidatePassportno = (text) => {
        this.state.studentData.candidate_passportno = text;
        this.setState({studentData: this.state.studentData});
    }

    setCandidatePassportLimit = (text) => {
        this.state.studentData.candidate_passportlimit = text;
        this.setState({studentData: this.state.studentData});
    }

    onFileChange(e) {
        const files = e.target.files;
        if(files.length > 0) {
            var file = files[0];
            var reader = new FileReader();
            reader.onload = (e) => {
                this.state.studentData.candidate_photo = e.target.result;
                this.setState({
                    studentData: this.state.studentData
                });
            };
            reader.readAsDataURL(file)
        } else {
            this.state.studentData.candidate_photo = null;
            this.setState({
                studentData: this.state.studentData
            });
        }
    }   

    render() {
        const imageData = this.state.studentData.candidate_photo;
        let preview = '';
        if(imageData) {
            preview = (
                <div className="editUploadImage"><img src={imageData} alt=""/></div>
            );
        }
        else {
            preview = (
                <div className="editUploadImage"><img src={FACE_IMAGE} alt=""/></div>
            );
        }
        return (
            <section className="editItem">
                {this.state.saveInfo ? <Load setLabel="応募者保存中..."/>: null}
                <form action="" method="post">
                    <div className="editItemSummary">
                        <div className="editInputItemNumber">{this.state.index_num + 1}人目</div>
                        {this.state.candidate_status === 1 ? <div className="editInputItemStatus statusOrange">登録済</div>: null}
                    </div>
                    <div className="editRow">
                        <div className="editInputItem">
                            <label htmlFor="candidatePhoto">写真</label>
                            <div className="editUploadImage">
                                {preview}
                            </div>
                            <div className="editInputInputArea">
                                <div className="editUploadButton">
                                    <label className="fileLabel">
                                        <input id="candidatePhoto" name="candidatePhoto" type="file"
                                                accept="image/*" ref={this.fileInput} onChange={(e) => {
                                            this.onFileChange(e)
                                        }} />
                                        <div className="fileButton">ファイル選択</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateId">学籍番号<span className="editRequired">*</span></label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateStudentNo" name="candidateStudentNo" type="text" required={true} maxLength="300" 
                                    setValue={this.state.studentData.candidate_student_no} onHandleChange={(e) => this.setCandidateStudentNo(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="editRow">
                        <div className="editInputItem">
                            <label htmlFor="candidateName">氏名(ローマ字)</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateName" name="candidateName" type="text" required={false} maxLength="300" 
                                    setValue={this.state.studentData.candidate_name} onHandleChange={(e) => this.setCandidateName(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateNickname">ニックネーム(ローマ字)</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateNickname" name="candidateNickname" type="text" required={false} maxLength="300"
                                    setValue={this.state.studentData.candidate_nickname} onHandleChange={(e) => this.setCandidateNickname(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="editRow">
                        <div className="editInputItem">
                            <label htmlFor="candidateCountry">国籍</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateCountry" name="candidateCountry" type="text" required={false} maxLength="300" 
                                    setValue={this.state.studentData.candidate_country} onHandleChange={(e) => this.setCandidateCountry(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateBirthday">生年月日</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateBirthday" name="candidateBirthday" type="date" required={false} maxLength="300"
                                    setValue={this.state.studentData.candidate_birthday} onHandleChange={(e) => this.setCandidateBirthday(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateSex">性別</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateSex" name="candidateSex" type="text" required={false} maxLength="300" 
                                    setValue={this.state.studentData.candidate_sex} onHandleChange={(e) => this.setCandidateSex(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateJapaneseLevel">日本語レベル</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateJapaneseLevel" name="candidateJapaneseLevel" type="text" required={false} maxLength="300" 
                                    setValue={this.state.studentData.candidate_japanese_level} onHandleChange={(e) => this.setCandidateJapaneseLevel(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateDepartment">学部・専攻</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateDepartment" name="candidateDepartment" type="text" required={false} maxLength="300" 
                                    setValue={this.state.studentData.candidate_department} onHandleChange={(e) => this.setCandidateDepartment(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateAcquiredCredits">取得済単位数</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateAcquiredCredits" name="candidateAcquiredCredits" type="number" required={false} 
                                    setValue={this.state.studentData.candidate_acquired_credits} onHandleChange={(e) => this.setCandidateAcquiredCredits(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidateScheduleCredits">取得予定単位数</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidateScheduleCredits" name="candidateScheduleCredits" type="number" value="2" required={false} 
                                    setValue={this.state.studentData.candidate_schedule_credits} onHandleChange={(e) => this.setCandidateScheduleCredits(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidatePassportno">パスポートNO.</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidatePassportno" name="candidatePassportno" type="text" maxLength="300"
                                    setValue={this.state.studentData.candidate_passportno} required={false} onHandleChange={(e) => this.setCandidatePassportno(e)}/>
                            </div>
                        </div>
                        <div className="editInputItem">
                            <label htmlFor="candidatePassportLimit">パスポート期限</label>
                            <div className="editInputInputArea">
                                <TextInput id="candidatePassportLimit" name="candidatePassportLimit" type="date" 
                                    setValue={this.state.studentData.candidate_passportlimit} required={false} onHandleChange={(e) => this.setCandidatePassportLimit(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="editButtonArea">
                        {/*
                        <div className="editDeleteButton">
                            <Button label="削除" buttonEnable={this.state.isDeleteBtnEnabled} 
                                callback={() => this.props.deleteStudentData(this.state.loginId, this.state.studentData.candidateId)}/>
                        </div>
                        */}
                        <div className="editSaveButton">
                            <Button label="保存" buttonEnable={this.state.saveBtnEnable}
                                callback={() => this.props.saveStudentData(this.state.studentData)}/>
                        </div>
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        saveInfo: state.saveStudentData.saveInfo
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveStudentData: (studentData) => dispatch(saveStudentData(studentData)),
    //deleteStudentData: (loginId, candidateId) => dispatch(deleteStudentData(loginId, candidateId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDetail);