import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow, checkOverFlow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// REACTION REQUEST
export const POST_APPLICANT_JOB_REQUEST = "POST_APPLICANT_JOB_REQUEST";
export const POST_APPLICANT_JOB_SUCCESS = "POST_APPLICANT_JOB_SUCCESS";
export const POST_APPLICANT_JOB_FAILURE = "POST_APPLICANT_JOB_FAILURE";
export const POST_APPLICANT_JOB_RESET = "POST_APPLICANT_JOB_RESET";

export const postApplicantJobRequest = () => {
    return {
        type: POST_APPLICANT_JOB_REQUEST
    }
}

export const postApplicantJobSuccess = (data) => {
    return {
        type: POST_APPLICANT_JOB_SUCCESS,
        data
    }
}

export const postApplicantJobFailure = (err) => {
    return {
        type: POST_APPLICANT_JOB_FAILURE,
        err
    }
}

export const postApplicantJobReset = () => {
    return {
        type: POST_APPLICANT_JOB_RESET
    }
}

const POST_APPLICANT_JOB = BASE_URL + "/api/apply_job_posting";

export const postApplicantJob = (jobId, applicantId) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        job_id: jobId,
        applicant_id: applicantId,
    }
    return async(dispatch) => {
        dispatch(postApplicantJobRequest());
        if (!_check_param(query)){
            return dispatch(postApplicantJobSuccess(400));
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                    var pems = {};
                    var keys = response.data['keys'];
                    for(var i = 0; i < keys.length; i++) {
                        var key_id = keys[i].kid;
                        var modulus = keys[i].n;
                        var exponent = keys[i].e;
                        var key_type = keys[i].kty;
                        var jwk = { kty: key_type, n: modulus, e: exponent};
                        var jtp = jwkToPem(jwk);
                        pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(postApplicantJobFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        verify = false;
                        return dispatch(postApplicantJobFailure("audクレームがアプリクライアント ID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt);
                        verify = false;
                        return dispatch(postApplicantJobFailure("issクレームがユーザープール ID と一致しません"));
                    }

                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(postApplicantJobFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                verify = false;
                                return dispatch(postApplicantJobFailure(err));
                            } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                axios.post(POST_APPLICANT_JOB, query, {headers: headerParam}).then(
                                    res => dispatch(postApplicantJobSuccess(res.data))
                                ).catch(
                                    err => dispatch(postApplicantJobFailure(err))
                                    //res => dispatch(postApplicantJobSuccess(200))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(postApplicantJobFailure(err))
                }
            )
        }
    }
}

export const applicantJobReset = () => {
    return (dispatch) => dispatch(postApplicantJobReset());
}

const _check_param = (query) => {
    console.log(query)
    let check_ok = true;
    for (const key in query) {
        const target = query[key];
        
        if (!_data_map[key]) {
            return false;
        }

        if (_data_map[key] !== checkType(target)) {
            return false;
        }

        if (key === "job_id") {
            check_ok = checkNumberOverflow(target);
        } else if (key === "applicant_id") {
            for(let i in target) {
                check_ok = checkOverFlow(target[i]);
            }
        }

        if (!check_ok) {
            return false;
        }
    }
    return check_ok;
}

const _data_map = {
    "job_id": "number",
    "applicant_id": "array",
}
