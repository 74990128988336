import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET UNIV OVERVIEW
export const SAVE_UNIV_OVERVIEW_REQUEST = "SAVE_UNIV_OVERVIEW_REQUEST";
export const SAVE_UNIV_OVERVIEW_SUCCESS = "SAVE_UNIV_OVERVIEW_SUCCESS";
export const SAVE_UNIV_OVERVIEW_FAILURE = "SAVE_UNIV_OVERVIEW_FAILURE";
export const SAVE_UNIV_OVERVIEW_RESET = "SAVE_UNIV_OVERVIEW_RESET";

export const saveUniversityActionRequest = (internId) => {
    return {
        type: SAVE_UNIV_OVERVIEW_REQUEST,
        internId
    }
}

export const saveUniversityActionSuccess = (data, internId) => {
    return {
        type: SAVE_UNIV_OVERVIEW_SUCCESS,
        data,
        internId
    }
}

export const saveUniversityActionFailure = (err) => {
    return {
        type: SAVE_UNIV_OVERVIEW_FAILURE,
        err
    }
}

export const resetUniversityAction = (internId) => {
    return {
        type: SAVE_UNIV_OVERVIEW_RESET,
        internId
    }
}

const SAVE_INTERN_STATUS = BASE_URL + "/api/university_intern_save_status";

export const saveUniversityAction = (internId, internStatus) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        intern_id: internId,
        intern_status: internStatus
    }
    return async(dispatch) => {
        dispatch(saveUniversityActionRequest(internId));

        if (!_check_param(query)) {
            return dispatch(saveUniversityActionSuccess(400));
        }

        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(saveUniversityActionFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(saveUniversityActionFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(saveUniversityActionFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(saveUniversityActionFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(saveUniversityActionFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const query = {
                                intern_id: internId,
                                intern_status: internStatus
                            }
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(SAVE_INTERN_STATUS, query, {headers: headerParam}).then(
                                res => {
                                    dispatch(saveUniversityActionSuccess(res.data, internId))
                                }
                            ).catch(
                                err => dispatch(saveUniversityActionFailure(err))
                                //res => dispatch(saveUniversityActionSuccess(200, internId))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(saveUniversityActionFailure(err))
            }
        )
    }
}

export const universitySaveActinReset = (internId) => {
    return (dispatch) => dispatch(resetUniversityAction(internId));
}

const _check_param = (query) => {
    if (!query["intern_status"] && !query["intern_id"]) {
        return false;
    }

    if (checkType(query["intern_status"]) !== "number") {
        return false;
    }

    if (checkType(query["intern_id"]) !== "number") {
        return false;
    }

    if (!checkNumberOverflow(query["intern_status"])) {
        return false
    }

    if (!checkNumberOverflow(query["intern_id"])) {
        return false
    }

    return true;
}
