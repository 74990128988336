import React from "react";
import "../../../css/Common.css";
import "../../../css/JobDetail.css";
import {BASE_URL} from "../../../constants/CommonConstatns";
import TEST_IMAGE from "../../../images/kanto.png";

class CompanyInfoTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
        }
        this.toggleChange = this.toggleChange.bind(this);
    }

    toggleChange = () => {
        const tgl = this.state.toggle ? false: true;
        this.setState({
            toggle: tgl
        });        
    }

    render() {
        return (
            <div className="detailJobResultItem">
                <div className="detailJobWrapper">
                    <h3 className="detailJobItemSubHeading">会社情報</h3>
                    <div className={this.state.toggle ? "detailJobItemDetailArea open" : "searchResultItemDetailArea"}>
                        <div className="detailJobItemDetailItem">

                            <div className="jobDetailItemDetailContents">
                                <div className="jobDetailItemDetailInformationArea">
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">法人名</div>
                                        <div className="jobDetailItemDetailInformationData">{this.props.name}</div>
                                    </div>
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">ホームページ</div>
                                        <div className="jobDetailItemDetailInformationData"><a href={this.props.web} target="_blank">{this.props.web}</a></div>
                                    </div>
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">施設名</div>
                                        <div className="jobDetailItemDetailInformationData">{this.props.facility_name}</div>
                                    </div>
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">施設所在地</div>
                                        <div className="jobDetailItemDetailInformationData">{this.props.facility_address}</div>
                                    </div>
                                    
                                </div>
                                <div className="jobDetailItemDetailImageArea">
                                    <div><img src={BASE_URL + this.props.map} alt=""/></div>
                                    {/* <div><img src={TEST_IMAGE} alt=""/></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                    className={this.state.toggle ? "jobDetailItemToggle open" : "jobDetailItemToggle"}
                    onClick={() => this.toggleChange()}>
                </div>
            </div>
        )
        
    }
}

export default CompanyInfoTable;











