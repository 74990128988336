import React from "react";
import "../../../css/Common.css";
import "../../../css/JobDetail.css"

class JobPostingTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
        }
        this.toggleChange = this.toggleChange.bind(this);
    }

    toggleChange = () => {
        const tgl = this.state.toggle ? false: true;
        this.setState({
            toggle: tgl
        });        
    }

    getHolidayInformation = (holiday, total) => {
        const t = total ? "（年間休日：" + total + "）": "";
        return(<p>{holiday + t}</p>)
    }

    render() {        
        return (
            <div className="detailJobResultItem">
                <div className="detailJobWrapper">
                    <h3 className="detailJobItemSubHeading">募集要項情報</h3>
                    <div className={this.state.toggle ? "detailJobItemDetailArea open" : "searchResultItemDetailArea"}>
                        <div className="detailJobItemDetailItem">
                            <div className="jobDetailItemDetailContents">
                                <div className="jobDetailItemDetailInformationArea">
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">募集職種</div>
                                        <div className="jobDetailItemDetailInformationData">{this.props.occupation}</div>
                                    </div>
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">募集内容</div>
                                        <div className="jobDetailItemDetailInformationData">{this.props.content}</div>
                                    </div>
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">勤務時間</div>
                                        <div className="jobDetailItemDetailInformationData">
                                            {
                                                this.props.working_time.works.map((item, index) => {
                                                    if (item.start || item.end || item.break_time || item.actual_time) {
                                                        const count = index + 1;
                                                        const start = item.start ? item.start: "未設定";
                                                        const end = item.end ? item.end: "未設定";
                                                        const rest = item.break_time ? " 休憩" + item.break_time : " 休憩時間未設定"
                                                        const actual = item.actual_time ? "実働" + item.actual_time: "実働時間未設定"
                                                        const showItems = count + ". " + start + "〜" + end + rest + "、" + actual;
                                                        return(<p key={index} className="working_time_paragraphe">{showItems}</p>)
                                                    }
                                                })
                                            }

                                            {this.props.working_time.is_decide_shift ? <p className="working_time_paragraphe">シフトにより決定</p>: null}
                                            {this.props.working_time.is_fixed_day ? <p className="working_time_paragraphe">曜日固定（{this.props.working_time.working_day}）</p>: null}
                                            {this.props.working_time.has_other ? <p className="working_time_paragraphe">その他（{this.props.working_time.other}）</p>: null}
                                        </div>
                                    </div>
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">休日</div>
                                        <div className="jobDetailItemDetailInformationData">
                                            {this.getHolidayInformation(this.props.holiday, this.props.total_holiday)}
                                        </div>
                                    </div>
                                    <div className="jobDetailItemDetailInformationItem">
                                        <div className="jobDetailItemDetailInformationTitle">手取り金額</div>
                                        <div className="jobDetailItemDetailInformationData">{this.props.payment}円</div>
                                    </div>
                                </div>
                                <div className="searchResultItemDetailImageArea">
                                    <div className="searchResultItemDetailImagePhoto"><img src={this.props.facility_introduction.image} alt=""/></div>
                                    <p className="searchResultItemDetailImageComment">{this.props.facility_introduction.comment}</p>
                                    <div className="searchResultItemDetailImagePhoto"><img src={this.props.nearby_info.image} alt=""/></div>
                                    <p className="searchResultItemDetailImageComment">{this.props.nearby_info.comment}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                    className={this.state.toggle ? "jobDetailItemToggle open" : "jobDetailItemToggle"}
                    onClick={() => this.toggleChange()}>
                </div>
            </div>
        )
        
    }
}

export default JobPostingTable;











