import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css";
import Button from "../atoms/Button";
import TextInput from "../atoms/TextInput";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import Textarea from "../atoms/Textarea";
import {calculatePayment, calculateSalary} from "../../constants/CommonConstatns";
import {MinimumWageMap} from "../../constants/CommonConstatns";
import {ErrorMap} from "../../constants/ManageConstants";
import "../../css/EditJob.css"

class EditJob4 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            holiday: null,
            total_holidays: null,
            paid_holiday: null,
            other_holiday: null,
            rent: null,
            board: null,
            allowance: null,
            salary: null,
            other: null,
            type: [true, true, true, true, true, true, true],
            deduction: [],
            hotels_is_service: false,
            hotels_catalog_price: null,
            hotels_publish_price: null,
            meals_is_service: false,
            meals_catalog_price: null,
            meals_publish_price: null,
            proceed: null,
            wage: null,
            error_message: []
        }

        this.getHoliday = this.getHoliday.bind(this);
        this.getTotalHolidays = this.getTotalHolidays.bind(this);
        this.getPaidHoliday = this.getPaidHoliday.bind(this);
        this.getOtherHoliday = this.getOtherHoliday.bind(this);
        this.getRent = this.getRent.bind(this);
        this.getBoard = this.getBoard.bind(this);
        this.getAllowance = this.getAllowance.bind(this);
        this.getType = this.getType.bind(this);
        this.getOther = this.getOther.bind(this);
        this.getLivelihoodSupportSalary = this.getLivelihoodSupportSalary.bind(this);
        this.getLivelihoodSupportDeductionNominal = this.getLivelihoodSupportDeductionNominal.bind(this);
        this.getLivelihoodSupportDeductionAmount = this.getLivelihoodSupportDeductionAmount.bind(this);
        this.getManagementHotelsIsService = this.getManagementHotelsIsService.bind(this);
        this.getManagementHotelsCatalogPrice = this.getManagementHotelsCatalogPrice.bind(this);
        this.getManagementHotelsPublishPrice = this.getManagementHotelsPublishPrice.bind(this);
        this.getManagementMealsIsService = this.getManagementMealsIsService.bind(this);
        this.getManagementMealsCatalogPrice = this.getManagementMealsCatalogPrice.bind(this);
        this.getManagementMealsPublishPrice = this.getManagementMealsPublishPrice.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            holiday: this.props.jobData.holiday,
            total_holidays: this.props.jobData.total_holidays,
            paid_holiday: this.props.jobData.paid_holiday,
            other_holiday: this.props.jobData.other_holiday,
            rent: this.props.jobData.payment_detail.rent,
            board: this.props.jobData.payment_detail.board,
            allowance: this.props.jobData.allowance,
            type: this.props.jobData.social_insurance.type,
            other: this.props.jobData.social_insurance.other,
            salary: this.props.jobData.livelihood_support.salary,
            deduction: this.props.jobData.livelihood_support.deduction,
            error_message: this.props.errorMessage,
        });
        window.scrollTo(0, 0);
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    temporalySave = () => {
        this.props.saveTemporaly();
    }

    calclateProceeds = () => {
        const saraly = this.state.salary;
        const amountList = this.state.deduction.map((value) => {
            return value.amount
        })
        const proceeds = calculatePayment(saraly, amountList);

        // 時給計算
        const wage = parseInt(proceeds / 160);
        
        this.setState({
            proceed: proceeds,
            wage: wage
        });
    }

    getWorkingTimeDay = (workingTimeDay) => {
        this.setState({
            day: parseInt(workingTimeDay)
        });
        this.props.getWorkingTimeDay(parseInt(workingTimeDay))
    }

    getHoliday = (holiday) => {
        this.setState({
            holiday: holiday
        });
        this.props.getHoliday(holiday)
    }

    getTotalHolidays = (totalholidays) => {
        this.setState({
            total_holidays: totalholidays
        });
        this.props.getTotalHolidays(totalholidays)
    }

    getPaidHoliday = (holiday) => {
        this.setState({
            paid_holiday: holiday
        });
        this.props.getPaidHoliday(holiday)
    }

    getOtherHoliday = (holiday) => {
        this.setState({
            other_holiday: holiday
        });
        this.props.getOtherHoliday(holiday)
    }

    getRent = (rent) => {
        this.setState({
            rent: rent
        });
        this.props.getRent(rent)
    }

    getBoard = (board) => {
        this.setState({
            board: board
        });
        this.props.getBoard(board)
    }

    getAllowance = (allowance) => {
        this.setState({
            allowance: allowance
        });
        this.props.getAllowance(allowance)
    }

    getOther = (other) => {
        this.setState({
            other: other
        });
        this.props.getOther(other)
    }

    getType = index => event => {
        this.state.type[index] = event.target.checked;
        this.setState({
            type: this.state.type
        });
        this.props.getType(this.state.type)
    };

    getLivelihoodSupportSalary = (livelihoodSupportSalary) => {
        this.setState({
            salary: parseInt(livelihoodSupportSalary)
        });
        this.props.getLivelihoodSupportSalary(parseInt(livelihoodSupportSalary))
    }

    getLivelihoodSupportDeductionNominal = (index, livelihoodSupportDeductionNominal) => {
        this.state.deduction[index].nominal = livelihoodSupportDeductionNominal;
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    getLivelihoodSupportDeductionAmount = (index, livelihoodSupportDeductionAmount) => {
        this.state.deduction[index].amount = parseInt(livelihoodSupportDeductionAmount);
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    getManagementHotelsIsService(event) {
        this.setState({
            hotels_is_service: event.target.checked
        });
        this.props.getManagementHotelsIsService(event.target.checked)
    }

    getManagementHotelsCatalogPrice = (managementHotelsCatalogPrice) => {
        this.setState({
            hotels_catalog_price: parseInt(managementHotelsCatalogPrice)
        });
        this.props.getManagementHotelsCatalogPrice(parseInt(managementHotelsCatalogPrice))
    }

    getManagementHotelsPublishPrice = (managementHotelsPublishPrice) => {
        this.setState({
            hotels_publish_price: parseInt(managementHotelsPublishPrice)
        });
        this.props.getManagementHotelsPublishPrice(parseInt(managementHotelsPublishPrice))
    }

    getManagementMealsIsService(event) {
        this.setState({
            meals_is_service: event.target.checked
        });
        this.props.getManagementMealsIsService(event.target.checked)
    }

    getManagementMealsCatalogPrice = (managementMealsCatalogPrice) => {
        this.setState({
            meals_catalog_price: parseInt(managementMealsCatalogPrice)
        });
        this.props.getManagementMealsCatalogPrice(parseInt(managementMealsCatalogPrice))
    }

    getManagementMealsPublishPrice = (managementMealsPublishPrice) => {
        this.setState({
            meals_publish_price: parseInt(managementMealsPublishPrice)
        });
        this.props.getManagementMealsPublishPrice(parseInt(managementMealsPublishPrice))
    }

    addDeduction = () => {
        let plan = {"nominal": "", "amount": 0};
        this.state.deduction.push(plan);
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    deleteDeduction = (index) => {
        this.state.deduction.splice(index, 1);
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";
        let company = this.props.jobData.company_info.company_id === "" ? this.props.companyList[this.props.companylist_num] : this.props.jobData.company_info;
        const facilityFullAddress = company.facility_address;
        console.log(facilityFullAddress);
        var re = /^(.{2}[都道府県]|.{3}県)(.+)/;
        let facilityName = "";
        if(re.test(company.facility_address)){
            var splitadd = company.facility_address.replace(/^(.{2}[都道府県]|.{3}県)(.+)/, "$1 $2").split(' ');
            facilityName = splitadd[0];
        }else{
            facilityName = "東京都";
        }
        let displayFacility = facilityName

        const minWage = MinimumWageMap[facilityName.slice(0, 3)];

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_job_posting" setError={this.props.saveMessage} /> : null}
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("edit_job", true)}>求人案件管理</a></li>
                        <li className="breadcrumbItem">求人案件登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job", false)}>募集時期</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_2", false)}>企業・施設概要</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern4">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post" id="form">
                            <h2 className="editInternItemHeading">休日</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeDay">休日<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="holiday" name="holiday" type="text" required={true} maxLength="300" setValue={this.state.holiday} onHandleChange={(e) => this.getHoliday(e)}/>                                   
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeHour">休日数</label>
                                <div className="editInternInputInputArea">
                                    
                                    <TextInput id="holidayCount" name="holidayCount" type="text" required={false} maxLength="300" setValue={this.state.total_holidays} onHandleChange={(e) => this.getTotalHolidays(e)}/>                                   
                                    
                                </div>
                            </div>
                            <h2 className="editInternItemHeading">休暇</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeDay">有給休暇</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="paidHoliday" name="paidHoliday" type="text" required={false} maxLength="300" setValue={this.state.paid_holiday} onHandleChange={(e) => this.getPaidHoliday(e)}/>                                   
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeHour">その他休暇</label>
                                <div className="editInternInputInputArea">
                                    
                                    <TextInput id="otherHoliday" name="otherHoliday" type="text" required={false} maxLength="300" setValue={this.state.other_holiday} onHandleChange={(e) => this.getOtherHoliday(e)}/>                                   
                                    
                                </div>
                            </div>
                        </form>
                    </section>
                    <section className="editInternItem">
                        <form action="" method="post" id="form">
                            <h2 className="editInternItemHeading">賃金</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="livelihoodSupportSalary">支給額</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="livelihoodSupportSalary" name="livelihoodSupportSalary" type="number" value="100000" required={false} setValue={this.state.salary} onHandleChange={(e) => this.getLivelihoodSupportSalary(e)}/>
                                    円/月
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="livelihoodSupportDeductionNominal">控除額</label>
                                <div className="editInternInputInputArea editInternInputInputAreaColumn">
                                    {
                                        this.state.deduction.map((deduction, index) => {
                                            if(index === 0) {
                                                return (
                                                    <div className="editInternInputInputAreaRow" key={index}>
                                                        名目
                                                        <TextInput id="livelihoodSupportDeductionNominal" name="livelihoodSupportDeductionNominal" type="text" value="寮費" required={false} maxLength="50" setValue={this.state.deduction[index].nominal} onHandleChange={(e) => this.getLivelihoodSupportDeductionNominal(index,e)}/>
                                                        <TextInput id="livelihoodSupportDeductionAmount" name="livelihoodSupportDeductionAmount" type="number" value="5000" required={false} setValue={this.state.deduction[index].amount} onHandleChange={(e) => this.getLivelihoodSupportDeductionAmount(index,e)}/>
                                                        円/月
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="editInternInputInputAreaRow" key={index}>
                                                        名目
                                                        <TextInput id="livelihoodSupportDeductionNominal" name="livelihoodSupportDeductionNominal" type="text" value="寮費" required={false} maxLength="50" setValue={this.state.deduction[index].nominal} onHandleChange={(e) => this.getLivelihoodSupportDeductionNominal(index,e)}/>
                                                        <TextInput id="livelihoodSupportDeductionAmount" name="livelihoodSupportDeductionAmount" type="number" value="5000" required={false} setValue={this.state.deduction[index].amount} onHandleChange={(e) => this.getLivelihoodSupportDeductionAmount(index,e)}/>
                                                        円/月
                                                        <div className="deleteButton">
                                                            <Button label="削除" buttonEnable={true} callback={() => this.deleteDeduction(index)}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    <div className="editInternInputInputAreaRow">
                                        <div className="addButton">
                                            <Button label="追加" buttonEnable={true} callback={() => this.addDeduction()}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="editInternCalculateButton">
                                <Button label="手取り金額の計算（税金を考慮した概算）" buttonEnable={true} callback={() => this.calclateProceeds()}/>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="afterTaxPrice">手取り金額</label>
                                <div className="editInternInputInputArea">
                                    １か月あたり　約
                                <span id="afterTaxPrice">{this.state.proceed}</span>
                                    円
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="hourlyPrice">時給換算</label>
                                <div className="editInternInputInputArea">
                                    手取り時給　　約
                                    <span id="hourlyPrice">{this.state.wage}</span>
                                    円相当額
                                </div>
                            </div>
                            {
                                minWage && 
                                <div className="editInternInputItem">
                                    <p id="minWageLabel"><span className="boldText">※{displayFacility}</span>の最低賃金</p>
                                    <div id="minWageArea">
                                    <span id="minWage">{minWage}</span>
                                        円
                                    </div>
                                </div>
                            }
                            <h2 className="editInternItemHeading">住居/食費</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeDay">住居</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="rent" name="rent" type="text" required={true} maxLength="300" setValue={this.state.rent} onHandleChange={(e) => this.getRent(e)}/>                                   
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeHour">食費</label>
                                <div className="editInternInputInputArea">
                                    
                                    <TextInput id="board" name="board" type="text" required={false} maxLength="300" setValue={this.state.board} onHandleChange={(e) => this.getBoard(e)}/>                                   
                                    
                                </div>
                            </div>
                            <h2 className="editInternItemHeading">その他手当</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="freeText">その他手当</label>
                                <div className="editInternInputInputArea">
                                    <Textarea id="freeText" name="freeText" required={false} maxLength="499" setValue={this.state.allowance} onHandleChange={(e) => this.getAllowance(e)}/>
                                </div>
                            </div>
                        </form>
                    </section>
                    <section className="editInternItem">
                        <form action="" method="post" id="form">
                            <h2 className="editInternItemHeading">社会保険等</h2>
                            <div className="editInternInputItem">
                                <div className="editInternInputInputArea checkboxGroup">
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="type" checked={this.state.type[0]} onChange={this.getType(0)}/>
                                        <span className="checkboxText">健康保険</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="type" checked={this.state.type[1]} onChange={this.getType(1)}/>
                                        <span className="checkboxText">厚生年金</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="type" checked={this.state.type[2]} onChange={this.getType(2)}/>
                                        <span className="checkboxText">雇用保険</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="type" checked={this.state.type[3]} onChange={this.getType(3)}/>
                                        <span className="checkboxText">労災保険</span>
                                    </label>
                                     <label className="checkboxLabel">
                                        <input type="checkbox" name="type" checked={this.state.type[4]} onChange={this.getType(4)}/>
                                        <span className="checkboxText">退職金制度</span>
                                    </label>
                                    <div className="editInternInputInputArea checkboxGroup">
                                        <label className="checkboxLabel">
                                                <input type="checkbox" name="type" checked={this.state.type[5]} onChange={this.getType(5)}/>
                                                <span className="checkboxText">その他</span>
                                        </label>
                                        <div id="inlineText">
                                            <TextInput id="checkBoxTextBox" name="other" type="text" required={false} maxLength="300" setValue={this.state.other} onHandleChange={(e) => this.getOther(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_job_5", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postJobDetailData.isFetching,
        isError: state.postJobDetailData.isError,
        saveMessage: state.postJobDetailData.errorMessage
    }
};

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(EditJob4);

