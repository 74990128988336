import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import TextInput from "../atoms/TextInput";
import Textarea from "../atoms/Textarea";
import {ErrorMap} from "../../constants/ManageConstants";
import "../../css/EditJob.css"

class EditJob3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sex: null,
            country: [],
            occupation: "",
            content: "",
            works: [],
            free_text: "",
            is_fixed_day: true,
            is_decide_shift: true,
            has_other: true,
            working_day: "",
            other: "",
            error_message: []
        }
        this.getSex = this.getSex.bind(this);
        this.getCountry = this.getCountry.bind(this);
        this.getOccupation = this.getOccupation.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getWorksStart = this.getWorksStart.bind(this);
        this.getWorksEnd = this.getWorksEnd.bind(this);
        this.getWorksBreakTime = this.getWorksBreakTime.bind(this);
        this.getWorksActualTime = this.getWorksActualTime.bind(this);
        this.getIsFixedDay = this.getIsFixedDay.bind(this);
        this.getIsDecideShift = this.getIsDecideShift.bind(this);
        this.getHasOther = this.getHasOther.bind(this);
        this.getWorkingOther = this.getWorkingOther.bind(this);
        this.getWorkingDay = this.getWorkingDay.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            sex: this.props.jobData.sex,
            country: this.props.jobData.country,
            occupation: this.props.jobData.occupation,
            content: this.props.jobData.content,
            works: this.props.jobData.working_time.works,
            is_fixed_day: this.props.jobData.working_time.is_fixed_day,
            is_decide_shift: this.props.jobData.working_time.is_decide_shift,
            has_other: this.props.jobData.working_time.has_other,
            working_day: this.props.jobData.working_time.working_day,
            other: this.props.jobData.working_time.other,
            error_message: this.props.errorMessage,

        });
        window.scrollTo(0, 0);
    }

    temporalySave = () => {
        this.props.saveTemporaly();
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    getSex(sex) {
        this.setState({
            sex: parseInt(sex.target.value)
        });
        this.props.getSex(parseInt(sex.target.value))
    }

    getCountry = index => event => {
        this.state.country[index] = event.target.checked;
        this.setState({
            country: this.state.country
        });
        this.props.getCountry(this.state.country)
    };

    getOccupation = (occupation) => {
        this.setState({
            occupation: occupation
        });
        this.props.getOccupation(occupation)
    }

    getContent = (content) => {
        this.setState({
            content: content
        });
        this.props.getContent(content)
    }

    getWorksStart = (index, start) => {
        this.state.works[index].start = start;
        this.setState({
            works: this.state.works
        });
        this.props.getWorks(this.state.works)
    }

    getWorksEnd = (index, end) => {
        this.state.works[index].end = end;
        this.setState({
            works: this.state.works
        });
        this.props.getWorks(this.state.works)
    }

    getWorksBreakTime = (index, bt) => {
        this.state.works[index].break_time = bt;
        this.setState({
            works: this.state.works
        });
        this.props.getWorks(this.state.works)
    }

    getWorksActualTime = (index, at) => {
        this.state.works[index].actual_time = at;
        this.setState({
            works: this.state.works
        });
        this.props.getWorks(this.state.works)
    }

    getWorkingDay = (day) => {
        this.setState({
            working_day: day
        });
        this.props.getWorkingDay(day)
    }

    getIsFixedDay = (event) => {
        this.setState({
            is_fixed_day: event.target.checked
        });
        this.props.getIsFixedDay(event.target.checked)
    };

    getIsDecideShift = (event) => {
        this.setState({
            is_decide_shift: event.target.checked
        });
        this.props.getIsDecideShift(event.target.checked)
    };

    getWorkingOther = (other) => {
        this.setState({
            other: other
        });
        this.props.getWorkingOther(other)
    }

    getHasOther = (event) => {
        this.setState({
            has_other: event.target.checked
        });
        this.props.getHasOther(event.target.checked)
    };

    saveReset = () => {
        this.props.saveReset();
    }

    addWorks = () => {
        let plan = {"start": "", "end": ""};
        this.state.works.push(plan);
        this.setState({
            works: this.state.works
        });
        this.props.getWorks(this.state.works)
    }

    deleteWorks = (index) => {
        this.state.works.splice(index, 1);
        this.setState({
            works: this.state.works
        });
        this.props.getWorks(this.state.works)
    }

    render() {
        var sexStates = [
            { code: 0, name: "男性" },
            { code: 1, name: "女性" },
            { code: 2, name: "限定無し" } ];
        var sexOptions = sexStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_job_posting" setError={this.props.saveMessage} /> : null}
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_job", true)}>求人案件管理</a></li>
                        <li className="breadcrumbItem">求人案件登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job", false)}>募集時期</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_2", false)}>企業・施設概要</a></li>
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern3">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <div className="editInternInputItem">
                                <label htmlFor="sex">性別<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <div className="selectWrapper editInternInputSelect">
                                        <select id="sex" name="sex" required value={this.state.sex} onChange={this.getSex}>
                                            {sexOptions}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="country">国籍</label>
                                <div className="editInternInputInputArea checkboxGroup">
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[1]} onChange={this.getCountry(1)}/>
                                        <span className="checkboxText">ベトナム</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[2]} onChange={this.getCountry(2)}/>
                                        <span className="checkboxText">台湾</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[3]} onChange={this.getCountry(3)}/>
                                        <span className="checkboxText">インドネシア</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[4]} onChange={this.getCountry(4)}/>
                                        <span className="checkboxText">ミャンマー</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[5]} onChange={this.getCountry(5)}/>
                                        <span className="checkboxText">シンガポール</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[6]} onChange={this.getCountry(6)}/>
                                        <span className="checkboxText">マレーシア</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[7]} onChange={this.getCountry(7)}/>
                                        <span className="checkboxText">中国</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[8]} onChange={this.getCountry(8)}/>
                                        <span className="checkboxText">韓国</span>
                                    </label>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="occupation">募集職種<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="occupation" name="occupation" type="text" maxLength="499" required={true} setValue={this.state.occupation} onHandleChange={(e) => this.getOccupation(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="content">業務内容<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <Textarea id="content" name="content" required={true} maxLength="499" setValue={this.state.content} onHandleChange={(e) => this.getContent(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="livelihoodSupportDeductionNominal">勤務時間<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea editInternInputInputAreaColumn">
                                    <div className="editInternInputInputAreaRow">
                                         <p className="headerIndex">#</p>
                                         <div className="headerText">始業</div>
                                         <div className="headerText">終業</div>
                                         <div className="headerText">休憩</div>
                                         <div className="headerText">実働</div>
                                         <div className="deleteButton">　　</div>
                                    </div>
                                    {
                                        this.state.works.map((work, index) => {
                                            if(index < 3) {
                                                return (
                                                    <div className="editInternInputInputAreaRow" key={index}>
                                                        { index + 1 }
                                                        <TextInput id="worksStart" name="worksStart" type="text" value="" required={false} maxLength="300" setValue={this.state.works[index].start} onHandleChange={(e) => this.getWorksStart(index,e)}/>
                                                        <TextInput id="worksEnd" name="worksEnd" type="text" value="" required={false} maxLength="300" setValue={this.state.works[index].end} onHandleChange={(e) => this.getWorksEnd(index,e)}/>
                                                        <TextInput id="worksBreakTime" name="worksBreakTime" type="text" value="" maxLength="300" required={false} setValue={this.state.works[index].break_time} onHandleChange={(e) => this.getWorksBreakTime(index,e)}/>
                                                        <TextInput id="worksActualTime" name="worksActualTime" type="text" value="" maxLength="300" required={false} setValue={this.state.works[index].actual_time} onHandleChange={(e) => this.getWorksActualTime(index,e)}/>
                                                       <div className="deleteButton">
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="editInternInputInputAreaRow" key={index}>
                                                        { index + 1 }
                                                        <TextInput id="worksStart" name="worksStart" type="text" value="" required={false} maxLength="300" setValue={this.state.works[index].start} onHandleChange={(e) => this.getWorksStart(index,e)}/>
                                                        <TextInput id="worksEnd" name="worksEnd" type="text" value="" required={false} maxLength="300" setValue={this.state.works[index].end} onHandleChange={(e) => this.getWorksEnd(index,e)}/>
                                                        <TextInput id="worksBreakTime" name="worksBreakTime" type="text" value="" required={false} maxLength="300" setValue={this.state.works[index].break_time} onHandleChange={(e) => this.getWorksBreakTime(index,e)}/>
                                                        <TextInput id="worksActualTime" name="worksActualTime" type="text" value="" required={false} maxLength="300" setValue={this.state.works[index].actual_time} onHandleChange={(e) => this.getWorksActualTime(index,e)}/>
                                                           <div className="deleteButton">
                                                                <Button label="削除" buttonEnable={true} callback={() => this.deleteWorks(index)}/>
                                                           </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    <div className="editInternInputInputAreaRow">
                                        <div className="addButton">
                                            <Button label="入力行追加" buttonEnable={true} callback={() => this.addWorks()}/>
                                        </div>
                                    </div>
                                    <div className="editInternInputInputAreaRow">
                                        <label>
                                            <input type="checkbox" name="type" checked={this.state.is_decide_shift} onChange={(e) => this.getIsDecideShift(e)}/>
                                            <span className="checkboxText" htmlFor="isFixedDay">シフトにより決定</span>
                                        </label>
                                    </div>
                                     <div className="editInternInputInputAreaRow">
                                        <label>
                                            <input type="checkbox" name="type" checked={this.state.is_fixed_day} onChange={(e) => this.getIsFixedDay(e)}/>
                                            <span className="checkboxText">曜日固定</span>
                                        </label>
                                        <div className="workShiftInput">
                                            <TextInput name="isFixedDay" type="text" required={false} maxLength="300" setValue={this.state.working_day} onHandleChange={(e) => this.getWorkingDay(e)}/>
                                        </div>
                                    </div>
                                    <div className="editInternInputInputAreaRow">
                                        <label>
                                            <input type="checkbox" name="type" checked={this.state.has_other} onChange={(e) => this.getHasOther(e)}/>
                                            <span className="checkboxText" htmlFor="hasOther">その他</span>
                                        </label>
                                        <div className="workShiftInput">
                                            <TextInput name="other" type="text" required={false} maxLength="300" setValue={this.state.other} onHandleChange={(e) => this.getWorkingOther(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave("edit_job_3", false)}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_job_4", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postJobDetailData.isFetching,
        isError: state.postJobDetailData.isError,
        saveMessage: state.postJobDetailData.errorMessage
    }
};

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(EditJob3);

