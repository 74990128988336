import React from "react";
import "../../css/StatusPostCheck.css"
import Button from "./Button";

export default function StatusPostCheck(props) {

    return(
        <div className="statusCheckWrapper">
            <div className="statuswrapper">
                <p className="checkText1">{props.setLabel1}</p>
                <p className="checkText2">{props.setLabel2}</p>
                <p className="checkText3">{props.setLabel3}</p>
                <div id="flexButton">
                    <Button label="はい" buttonEnable={true} callback={props.clickYes}/>
                    <Button label="いいえ" buttonEnable={true} callback={props.clickNo}/>
                </div>
            </div>
        </div>
    )
}
