import axios from "axios";
import {BASE_URL, checkType, checkMonthStyle, checkNumberOverflow, checkDateStyle, checkOverFlow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');


// REQUEST INSURANCE
export const REQUEST_INSURANCE_REQUEST = "REQUEST_INSURANCE_REQUEST";
export const REQUEST_INSURANCE_SUCCESS = "REQUEST_INSURANCE_SUCCESS";
export const REQUEST_INSURANCE_FAILURE = "REQUEST_INSURANCE_FAILURE";
export const REQUEST_INSURANCE_CLEAR = "REQUEST_INSURANCE_CLEAR";

export const requestInsuranceRequest = () => {
    return {
        type: REQUEST_INSURANCE_REQUEST
    }
}

export const requestInsuranceSuccess = (data) => {
    return {
        type: REQUEST_INSURANCE_SUCCESS,
        data
    }
}

export const requestInsuranceFailure = (err) => {
    return {
        type: REQUEST_INSURANCE_FAILURE,
        err
    }
}

export const requestInsuranceClear = () => {
    return {
        type: REQUEST_INSURANCE_CLEAR
    }
}

const REQUEST_INSURANCE_ACTION = BASE_URL + "/api/skt_insurance_request";

export const requestInsuranceAction = (displayPeriod, requestData) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        display_period: displayPeriod,
        request: requestData
    }
    return async(dispatch) => {
        dispatch(requestInsuranceRequest());
        console.log(query)
        
        if (!_check_param(query)) {
            return dispatch(requestInsuranceSuccess(400))
        }
        
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(requestInsuranceFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    console.log(decodedJwt.payload.aud);
                    verify = false;
                    return dispatch(requestInsuranceFailure("audクレームがアプリクライアントID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt.payload.iss);
                    verify = false;
                    return dispatch(requestInsuranceFailure("issクレームがユーザープールID と一致しません"));
                }
                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(requestInsuranceFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            return dispatch(requestInsuranceFailure(err));
                        } else {
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(REQUEST_INSURANCE_ACTION, query, {headers: headerParam}).then(
                                res => {
                                    dispatch(requestInsuranceSuccess(res.data))
                                }
                            ).catch(
                                err => dispatch(requestInsuranceFailure(err))
                                //res => dispatch(requestInsuranceSuccess(200))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(requestInsuranceFailure(err))
            }

        )
    }
}

export const requestInsuranceActionClear = () => {
    return (dispatch) => dispatch(requestInsuranceClear());
}

const _check_param = (query) => {
    let is_checked = true;
    for (const key in query) {
        const target = query[key];

        if (!_param[key]) {
            return false;
        }

        if (_param[key] !== checkType(target)) {
            return false;
        }

        if (key === "display_period") {
            is_checked = checkMonthStyle(query[key]);
        } else if (key === "request") {
            is_checked = _check_request_param(query[key]);
        }

        if (!is_checked) {

            return false;
        }
    }

    return is_checked;
}

const _check_request_param = (reqArr) => {
    let check_ok = true;
    for (const data of reqArr) {
        for (const key in data) {
            const target = data[key];
            if (!_param[key]) {
                return false;
            }
            
            if (_param[key] !== checkType(target)) {
                console.log("aa")
                return false;
            }
            
            if (key === "intern_id") {
                check_ok = checkOverFlow(data[key]);
            } else if (key === "type") {
                check_ok = checkNumberOverflow(data[key]);
            } else if (key === "insurance_plan") {
                check_ok =  checkNumberOverflow(data[key]);
            } else if (key === "insurance_start") {
                check_ok =  checkDateStyle(data[key]);
            } else if (key === "insurance_end") {
                check_ok =  checkDateStyle(data[key]);
            }

            if (!check_ok) {
                console.log("aaa")
                return false;
            }
        }
    }
    return check_ok
}

const _param = {
    "display_period": "string",
    "request": "array",
    "intern_id": "string",
    "type": "number",
    "insurance_plan": "number",
    "insurance_start": "string",
    "insurance_end": "string"
}
