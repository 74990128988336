import React from 'react';
import SendHomeTemplate from "../components/SendHomeTemplate"

function Home() {
  return (
    <section className="App">
        <SendHomeTemplate />
    </section>
  );
}

export default Home;
