import {
    SET_INSURANCE_DATA,
    UPDATE_INSURANCE_DATA,
    CLEAR_INSURANCE_DATA
} from "../../actions/skt/SetInsuranceDetail"

const resuponseState = {
    insuranceData: []
}

export const getUpdatedInsuranceData = (state = resuponseState, action) => {
    const newData = state.insuranceData.slice();
    switch(action.type) {
        case SET_INSURANCE_DATA:
            newData.push(action.data);
            return Object.assign({}, state, {
                insuranceData: newData
            })
        
        case UPDATE_INSURANCE_DATA:
            state.insuranceData.forEach((item, index) =>{
                if (item.intern_id === action.data.intern_id) {
                    newData[index] = JSON.parse(JSON.stringify(action.data));
                }
                console.log(newData)
            });
            return Object.assign({}, state, {
                insuranceData: newData
            });

        case CLEAR_INSURANCE_DATA:
            return Object.assign({}, state, {
                insuranceData: []
            });
        default:
            return state;
    }
}
