import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {TEST_ENTRY_LIST_1, TEST_ENTRY_LIST_2, TEST_ENTRY_LIST_3} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET JOB ENTRY LIST
export const GET_JOB_ENTRY_LIST_REQUEST = "GET_JOB_ENTRY_LIST_REQUEST";
export const GET_JOB_ENTRY_LIST_SUCCESS = "GET_JOB_ENTRY_LIST_SUCCESS";
export const GET_JOB_ENTRY_LIST_FAILURE = "GET_JOB_ENTRY_LIST_FAILURE";
export const GET_JOB_ENTRY_LIST_RESET = "GET_JOB_ENTRY_LIST_RESET";

export const getJobEntryListRequest = () => {
    return {
        type: GET_JOB_ENTRY_LIST_REQUEST
    }
}

export const getJobEntryListSuccess = (data) => {
    return {
        type: GET_JOB_ENTRY_LIST_SUCCESS,
        data
    }
}

export const getJobEntryListFailure = (err) => {
    return {
        type: GET_JOB_ENTRY_LIST_FAILURE,
        err
    }
}

export const getJobEntryListReset = () => {
    return {
        type: GET_JOB_ENTRY_LIST_RESET
    }
}

const GET_JOB_ENTRY_LIST= BASE_URL + "/api/get_entry_information";

export const getJobEntryInfoList = (jobId) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        job_id: jobId
    }
    return async(dispatch) => {
        dispatch(getJobEntryListRequest());
        if (!_check_param(query)){
            return dispatch(getJobEntryListSuccess(400));
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                    var pems = {};
                    var keys = response.data['keys'];
                    for(var i = 0; i < keys.length; i++) {
                        var key_id = keys[i].kid;
                        var modulus = keys[i].n;
                        var exponent = keys[i].e;
                        var key_type = keys[i].kty;
                        var jwk = { kty: key_type, n: modulus, e: exponent};
                        var jtp = jwkToPem(jwk);
                        pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(getJobEntryListFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        verify = false;
                        return dispatch(getJobEntryListFailure("audクレームがアプリクライアント ID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt);
                        verify = false;
                        return dispatch(getJobEntryListFailure("issクレームがユーザープール ID と一致しません"));
                    }

                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(getJobEntryListFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                verify = false;
                                return dispatch(getJobEntryListFailure(err));
                            } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                axios.post(GET_JOB_ENTRY_LIST, query, {headers: headerParam}).then(
                                    res => dispatch(getJobEntryListSuccess(res.data))
                                ).catch(
                                    err => dispatch(getJobEntryListFailure(err))
                                    //res => dispatch(getJobEntryListSuccess(TEST_ENTRY_LIST_1.slice()))
                                    //res => dispatch(getJobEntryListSuccess(500))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(getJobEntryListFailure(err))
                }
            )
        }
    }
}

export const jobEntryListReset = () => {
    return (dispatch) => dispatch(getJobEntryListReset());
}

const _check_param = (query) => {
    let check_ok = true;
    console.log(query)
    if (query.hasOwnProperty("job_id")) {
        const target = query.job_id;
        if (checkType(target) !== "number") {
            return false;
        }
        check_ok = checkNumberOverflow(target)
    } else {
        return false;
    }

    return check_ok;
}
