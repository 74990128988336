import React from 'react';
import {BrowserRouter, Route, Link, Router, Switch, Redirect} from 'react-router-dom';
import jwt from "jsonwebtoken";
import Login from './Login';
import HotelHome from "./HotelHome";
import UnivHome from "./UnivHome";
import SktHome from "./SktHome";
import ManageHome from "./ManageHome";
import SendHome from "./SendHome";
import {getIdToken} from "../constants/CommonConstatns";

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
const config = require('../components/config');

const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId,
  ClientId: config.ClientId,
  Storage: sessionStorage
});

function App() {
  var CurrentUser = userPool.getCurrentUser();
  const idToken = getIdToken();
  if(idToken != null){
    var decoded = jwt.decode(idToken, { complete: true });
    var date = new Date();
    var nowDate = date.getTime();
    var unixtime = Math.floor(nowDate / 1000);
    if(decoded.payload.exp < unixtime){
         if (CurrentUser != null) {
             CurrentUser.signOut();
             CurrentUser = null;
         }
         sessionStorage.clear();
    }
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login}></Route>
      </Switch>
      <Route path="/hotel/home" component={HotelHome} />
      <Route path="/univ/home" component={UnivHome} />
      <Route path="/skt/home" component={SktHome} />
      <Route path="/manage/home" component={ManageHome} />
      <Route path="/send/home" component={SendHome} />
      { CurrentUser ? null : <Redirect to={'/login'} /> }
    </BrowserRouter>
  );
}

export default App;
