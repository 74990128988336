import React from "react";
import {withRouter} from "react-router";
import Button from "./atoms/Button";
import Header from "../components/common_parts/Header";
import Footer from "../components/common_parts/Footer";
import Top from "./univ/Top"
import Search from "./univ/Search";
import Edit from "./univ/Edit";
import Profile from "./univ/Profile";

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
const CognitoRefreshToken = require('amazon-cognito-identity-js-node').CognitoRefreshToken;
const config = require('./config');

const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId,
  ClientId: config.ClientId,
  Storage: sessionStorage
});


class UnivHomeTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            display: "univ_top",
            userCd: "",
            expired: 0,
            userType: "",
            loginId: ""
        }
        this.switchDisplay = this.switchDisplay.bind(this);
        this.refreshToken = this.refreshToken.bind(this);
    }

    refreshToken = () => {
        var CurrentUser = userPool.getCurrentUser();
        if(this.state.expired > 0){
            var date = new Date();
            var nowDate = date.getTime();
            var unixtime = Math.floor(nowDate / 1000);
            if(this.state.expired < unixtime){
                 if (CurrentUser != null) {
                     CurrentUser.signOut();
                     CurrentUser.getSession(function(err, session) {
                         console.log(session);
                     });
                 }
                sessionStorage.clear();
                this.props.history.push('/login')
                return;
            }
        }
        let _this = this;
        if (CurrentUser != null) {
            CurrentUser.getSession(function(err, session) {

                CurrentUser.getUserAttributes(function(err, attrresult) {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    for (var i = 0; i < attrresult.length; i++) {
                        if (attrresult[i].getName()==="email"){
                            let email = attrresult[i].getValue();
                            _this.setState({
                                loginId: email
                            })
                        }
                        else if (attrresult[i].getName()==="custom:user_type"){
                            let user_type = attrresult[i].getValue();
                            console.log(user_type)
                            _this.setState({
                                userType: user_type
                            })
                        }
                        else if (attrresult[i].getName()==="custom:user_cd"){
                            let user_cd = attrresult[i].getValue();
                            console.log(user_cd)
                            _this.setState({
                                userCd: user_cd
                            })
                        }
                    }
                });
                var refreshTokenstr = session.getRefreshToken().getToken();
                var refreshToken = new CognitoRefreshToken({RefreshToken: refreshTokenstr});

                CurrentUser.refreshSession(refreshToken, (err, result)=>{
                    if(result) {
                        var idToken = result.getIdToken().getJwtToken();
                    
                        _this.setState({
                            expired: result.idToken.payload.exp
                        })

                        // API連携のためにIDトークンを常時更新
                        //const idTokenKey = 'CognitoIdentityServiceProvider.' + config.ClientId + '.' + this.state.loginId + '.idToken';
                        //sessionStorage.setItem(idTokenKey, idToken);
                    }
                });
            });
        }
    }

    componentDidMount = () => {
        this.browserBackBtnAction();
        this.refreshToken();
    }

    switchDisplay = (dispId, isReset) => {
        this.refreshToken();
        this.setState({
            display: dispId
        });
    }

    logoutAction = () => {
        const CurrentUser = userPool.getCurrentUser();
        if (CurrentUser != null) {
            CurrentUser.signOut();
            sessionStorage.clear();
            CurrentUser.getSession(function(err, session) {
                 console.log(session);
            });
            this.props.history.replace("/login");
        }
    }

    browserBackBtnAction = () => {
        window.addEventListener("popstate", (e) => {
            const CurrentUser = userPool.getCurrentUser();
            if (CurrentUser != null) {
                CurrentUser.signOut();
                sessionStorage.clear();
                this.props.history.push("/login");
            }
            return;
        });
    }

    render() {
        let displayParts;
        if (this.state.display === "univ_top") {
            displayParts = <Top 
                changeDisp={this.switchDisplay}
                logout_action={this.logoutAction}
                loginId={this.state.loginId}
                />;
        } else if (this.state.display === "univ_search") {
            displayParts = <Search changeDisp={this.switchDisplay}/>;
        } else if (this.state.display === "univ_edit") {
            displayParts = <Edit
                changeDisp={this.switchDisplay}
            />;
        } else if (this.state.display === "profile") {
            displayParts = <Profile
                changeDisp={this.switchDisplay}
                userType={this.state.userType}
                userCd={this.state.userCd}
            />;
        }
        
        if (this.state.display === "univ_top") {
            return(
                displayParts
            )
        } else {
            return (
                <div>
                    <Header />
                    <article className="contentsArea">
                        <div id="logoutBtnWrapper">
                            <Button label="ログアウト" buttonEnable={true} callback={() => this.logoutAction()}/>
                        </div>
                        {displayParts}
                    </article>
                    <Footer />
                </div>
            )
        }
    }
}

export default withRouter(UnivHomeTemplate);
