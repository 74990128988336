import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {POSTING_OVERVIEW_DATA_O, POSTING_OVERVIEW_DATA_K, POSTING_OVERVIEW_DATA_H, POSTING_OVERVIEW_DATA_S} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

//GET POSTING OVERVIEWS
export const POSTING_OVERVIEWS_REQUEST = "POSTING_OVERVIEWS_REQUEST";
export const POSTING_OVERVIEWS_SUCCESS = "POSTING_OVERVIEWS_SUCCESS";
export const POSTING_OVERVIEWS_FAILURE = "POSTING_OVERVIEWS_FAILURE";
export const POSTING_OVERVIEWS_RESET = "POSTING_OVERVIEWS_RESET";

export const postingOverviewsRequest = () => {
    return {
        type: POSTING_OVERVIEWS_REQUEST
    }
}

export const postingOverviewsSuccess = (data) => {
    return {
        type: POSTING_OVERVIEWS_SUCCESS,
        data
    }
}

export const postingOverviewsFailure = (err) => {
    return {
        type: POSTING_OVERVIEWS_FAILURE,
        err
    }
}

export const postingOverviewsReset = () => {
    return {
        type: POSTING_OVERVIEWS_RESET
    }
}

const GET_POSTING_OVERVIEW = BASE_URL + "/api/job_posting_overview"

export const getPostingOverviews = () => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(postingOverviewsRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(postingOverviewsFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(postingOverviewsFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(postingOverviewsFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(postingOverviewsFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(postingOverviewsFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_POSTING_OVERVIEW, null, {headers: headerParam}).then(
                                res => dispatch(postingOverviewsSuccess(res.data))
                            ).catch(
                                err => dispatch(postingOverviewsFailure(err))
                                //res => dispatch(postingOverviewsSuccess(POSTING_OVERVIEW_DATA_S))
                                //res => dispatch(postingOverviewsSuccess([]))
                                //res => dispatch(postingOverviewsSuccess(400))
                                //res => dispatch(postingOverviewsSuccess(500))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(postingOverviewsFailure(err))
            }
        )
    }
}

export const getPostingOverviewsReset = () => {
    return (dispatch) => dispatch(postingOverviewsReset());
}
