import {
    GET_INSURANCE_TARGET_REQUEST,
    GET_INSURANCE_TARGET_SUCCESS,
    GET_INSURANCE_TARGET_FAILURE,
    CLEAR_INSURANCE_TARGET
} from "../../actions/skt/GetInsuranceTargetList";


const listState = {
    isFetching: false,
    insuranceListData: [],
    internData: [],
    responseData: "",
    isError: false
}

export const getInsuranceTargetList = (state = listState, action) => {
    switch(action.type) {
        case GET_INSURANCE_TARGET_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                insuranceListData: [],
                internData: [],
                responseData: "REQUEST",
                isError: false
            });
        case GET_INSURANCE_TARGET_SUCCESS:
            let insuranceListData = {};
            let internData = [];
            let responseData = "";
            let isError = false;
            if (action.data === 400) {
                responseData = "400 パラメータが不正です";
                isError = true;
            } else if (action.data === 500) {
                responseData = "500 予期しないエラーが発生しました";
                isError = true;
            } else if (0 === Object.keys(action.data)) {
                insuranceListData = action.data;
                internData = []
            } else {
                insuranceListData = action.data;
                internData = insuranceListData.intern_data;
            }

            return Object.assign({}, state, {
                isFetching: false,
                insuranceListData: insuranceListData,
                internData: internData,
                responseData: responseData,
                isError: isError
            });
        case GET_INSURANCE_TARGET_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                insuranceListData: [],
                internData: [],
                responseData: action.err,
                isError: true
            });
        case CLEAR_INSURANCE_TARGET:
            return Object.assign({}, state, {
                isFetching: false,
                insuranceListData: [],
                internData: [],
                responseData: "",
                isError: false
            });
            
        default:
            return state;
    }
}