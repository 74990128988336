import React from "react";
import Logo from "../../images/logo.png";
import "../../css/Common.css";

export default function Header() {
    return(
        <header className="headerArea">
            <hr className="headerHr" />
            <div className="layoutInner">
                <div className="headerLogo">
                    <img src={Logo} alt="HITACHI Inspire the Next"/>
                </div>
            </div>
        </header>
    );
}