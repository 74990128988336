import React from 'react';
import HotelHomeTemplate from "../components/HotelHomeTemplate"

function Home() {
  return (
    <section className="App">
        <HotelHomeTemplate />
    </section>
  );
}

export default Home;