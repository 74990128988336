import axios from "axios";
import {BASE_URL} from "../../constants/CommonConstatns";
import {MANAGED_COMPANY_DATA} from "../../constants/TestData";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET COMPANY INFO
export const M_COMPANY_INFO_REQUEST = "M_COMPANY_INFO_REQUEST";
export const M_COMPANY_INFO_SUCCESS = "M_COMPANY_INFO_SUCCESS";
export const M_COMPANY_INFO_FAILURE = "M_COMPANY_INFO_FAILURE";
export const M_COMPANY_INFO_RESET = "M_COMPANY_INFO_RESET";

const M_GET_COMPANY_INFO = BASE_URL + "/api/get_related_companylist";

export const getCompanyRequest = () => {
    return {
        type: M_COMPANY_INFO_REQUEST
    }
}

export const getCompanySuccess = (data) => {
    return {
        type: M_COMPANY_INFO_SUCCESS,
        data
    }
}

export const getCompanyFailure = (err) => {
    return {
        type: M_COMPANY_INFO_FAILURE,
        err
    }
}

export const getCompanyReset = () => {
    return {
        type: M_COMPANY_INFO_RESET,
    }
}

export const getCompayInformation = () => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(getCompanyRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(getCompanyFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(getCompanyFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(getCompanyFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(getCompanyFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(getCompanyFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            };
                            axios.post(M_GET_COMPANY_INFO, null, {headers: headerParam}).then(
                                res => dispatch(getCompanySuccess(res.data))
                            ).catch(
                                err => dispatch(getCompanyFailure(err))
                                //res => dispatch(getCompanySuccess(MANAGED_COMPANY_DATA))
                                //res => dispatch(getCompanySuccess([]))
                                //res => dispatch(getCompanySuccess(500))
                                //res => dispatch(getCompanySuccess(400))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(getCompanyFailure(err))
            }
        )
    }
}

export const getCompanyInformationReset = () => {
    return (dispatch) => dispatch(getCompanyReset())
}
