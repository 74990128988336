import {
    M_COMPANY_INFO_REQUEST,
    M_COMPANY_INFO_SUCCESS,
    M_COMPANY_INFO_FAILURE,
    M_COMPANY_INFO_RESET
} from "../../actions/manage/GetCompanyListAction";

const infoState = {
    isFetching: false,
    companyData: [],
    isError: false,
    errorMessage: ""
}

export const getManageCompanyInformation = (state = infoState, action) => {
    switch(action.type) {
        case M_COMPANY_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                companyData: [],
                isError: false,
                errorMessage: ""
            });
        case M_COMPANY_INFO_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            } else {
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                companyData: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case M_COMPANY_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                companyData: [],
                isError: true,
                errorMessage: action.err
            });
        case M_COMPANY_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                companyData: [],
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}
