import React from "react";
import "../../../css/Common.css";
import "../../../css/JobDetail.css"
import Button from "../../atoms/Button";
import Textarea from "../../atoms/Textarea";
import {StatusMap, SexMap} from "../../../constants/ApplicantPostingConstants"

class ApplicantTable_O_H extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            applicantMessageDict: {},
        }
    }

    componentDidMount = () => {
        if (0 < this.props.entryApplicantList) {
                console.log(this.props.entryApplicantList);
                let messageDict = {}
                for (let i = 0; i < this.props.entryApplicantList.length; i++) {
                    let data = this.props.entryApplicantList[i]
                    messageDict[data.applicant_id] = "";
                }
                this.setState({
                    applicantMessageDict: messageDict,
                });

        }

    }


    clickCancel = (id) => {

        this.props.postCancel(id);
    }

    applyJob = () => {
       let idList = []
       for(let i in this.props.applicantIdList){
           idList.push(this.props.applicantIdList[i].applicant_id)
       }
       console.log(idList);
       this.props.postJob(idList)
    }


    setApplicantMessage = (message, id) => {
        this.state.applicantMessageDict[id] = message
        this.setState({
            applicantMessageDict: this.state.applicantMessageDict
        });
    }

    clickCheck = (id, status) => {

        this.props.getisCheck(id, status, this.state.applicantMessageDict[id]);
    }

    editApplicant = () => {
        this.props.handleToDisplay("show_applicant");
    }


    render() {

        var editApplyButton = (
            this.props.user_type === "O" ?
                <div id="detailSubHeaderButton">
                    <div>
                        <Button label="応募者選択" buttonEnable={true} callback={() => this.editApplicant()}/>
                    </div>
                    <div>
                        <Button label="応募する" buttonEnable={this.props.applicantIdList.length > 0} callback={() => this.applyJob()}/>
                    </div>
                </div>
            : null
        );

        return (
            <div className="detailJobResultItem">
                <h3 className="detailJobItemSubHeading">応募者一覧</h3>
                {editApplyButton}
                <table border="1" bordercolor="#ffffff">
                    <thead>
                        <tr>
                            <th>氏名（ローマ字）</th>
                            <th>国籍</th>
                            <th>性別</th>
                            <th>日本語</th>
                            <th>ステータス</th>
                            <th>連絡メッセージ</th>
                            <th>メッセージ処理</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.entryApplicantList.map((value, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{value.name}</td>
                                        <td>{value.country}</td>
                                        <td>{SexMap[value.sex]}</td>
                                        <td>{value.qualification_japanese}</td>
                                        <td>{value.status === null ? StatusMap[5] : StatusMap[value.status]}</td>
                                        <td>
                                            {
                                                value.message.length > 0?
                                                    value.message.map((v, i) => {
                                                        return(<p>{v}</p>)
                                                    })
                                                : null
                                            }
                                        </td>
                                        <td>
                                            {(() => {
                                                    if(this.props.user_type === "O" && this.props.applicantIdList.includes(value) && value.status === null) {
                                                        return(
                                                            <div className="detailButton" id="messageButton">
                                                                <div id="flexDetailDisplay">
                                                                    <div>
                                                                        <Button label="削除" buttonEnable={true} callback={() => this.clickCancel(value.applicant_id)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }else if(this.props.user_type === "O" && value.status === 2) {
                                                        return(
                                                            <div className="detailButton" id="messageButton">
                                                                <div id="detailInputText">
                                                                    <Textarea id="content" name="content" required={true} maxLength="499" setvalue={this.state.applicantMessageDict[value.applicant_id]} onHandleChange={(e) => this.setApplicantMessage(e, value.applicant_id)}/>
                                                                </div>
                                                                <div id="flexDetailDisplay">
                                                                    <div>
                                                                        <Button label="確認" buttonEnable={true} callback={() => this.clickCheck(value.applicant_id, 4)}/>
                                                                    </div>
                                                                    <div>
                                                                        <Button label="辞退" buttonEnable={true} callback={() => this.clickCheck(value.applicant_id, 3)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                     } else if(this.props.user_type === "H" && value.status === 0) {
                                                        return(
                                                            <div className="detailButton" id="messageButton">
                                                                <div id="detailInputText">
                                                                    <Textarea id="content" name="content" required={true} maxLength="499" setvalue={this.state.applicantMessageDict[value.applicant_id]} onHandleChange={(e) => this.setApplicantMessage(e, value.applicant_id)}/>
                                                                </div>
                                                                <div id="flexDetailDisplay">
                                                                     <div>
                                                                         <Button label="採用" buttonEnable={true} callback={() => this.clickCheck(value.applicant_id, 2)}/>
                                                                     </div>
                                                                     <div>
                                                                         <Button label="不採用" buttonEnable={true} callback={() => this.clickCheck(value.applicant_id, 1)}/>
                                                                     </div>
                                                                </div>
                                                            </div>
                                                        )
                                                     } else {
                                                         return(null)
                                                     }
                                            })()}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
        
    }
}

export default ApplicantTable_O_H;











