import {
    POSTING_APPLICANT_REQUEST,
    POSTING_APPLICANT_SUCCESS,
    POSTING_APPLICANT_FAILURE,
    POSTING_APPLICANT_RESET,
    POSTING_DETAIL_REQUEST,
    POSTING_DETAIL_SUCCESS,
    POSTING_DETAIL_FAILURE,
    POSTING_DETAIL_RESET

} from "../../actions/posting/GetPostingApplicantAction"

const overViewState = {
    isFetching: false,
    overViewData: [],
    isGetInfo: false,
    isError: false,
    errorMessage: ""
}

const detailState = {
    isFetching: false,
    detailData: [],
    isError: false,
    errorMessage: ""
}

export const getPostingApplicant = (state = overViewState, action) => {
    switch(action.type) {
        case POSTING_APPLICANT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isGetInfo: false,
                isError: false,
                errorMessage: ""
            });
        case POSTING_APPLICANT_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            }else{
                responseData = action.data;
                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                overViewData: responseData,
                isGetInfo: true,
                isError: isError,
                errorMessage: errorMessage
            });
        case POSTING_APPLICANT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isGetInfo: true,
                isError: true,
                errorMessage: action.err
            });
        case POSTING_APPLICANT_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isGetInfo: true,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}
