import React from "react";
import "../../css/Loading.css"

export default function Load(props) {

    return(
        <div className="loadWrapper">
            <div className="wrapper">
                <div className="balls-guruguru">
                    <span className="ball ball-1"></span>
                    <span className="ball ball-2"></span>
                    <span className="ball ball-3"></span>
                    <span className="ball ball-4"></span>
                    <span className="ball ball-5"></span>
                    <span className="ball ball-6"></span>
                    <span className="ball ball-7"></span>
                    <span className="ball ball-8"></span>
                </div>
                <p className="nowLoading">{props.setLabel}</p>
            </div>
        </div>
    )
}
