import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css";
import Button from "../atoms/Button";
import TextInput from "../atoms/TextInput";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import "../../css/EditIntern.css"
import {ErrorMap} from "../../constants/CommonConstatns";

class EditIntern extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            intern_start: "",
            intern_end: "",
            intern_pub_start: "",
            intern_pub_end: "",
            error_message: [],
            accepted: 2
        }
        this.getInternTitle = this.getInternTitle.bind(this);
        this.getAccepted = this.getAccepted.bind(this);
        this.getInternStart = this.getInternStart.bind(this);
        this.getInternEnd = this.getInternEnd.bind(this);
        this.getInternPubStart = this.getInternPubStart.bind(this);
        this.getInternPubEnd = this.getInternPubEnd.bind(this);
    }

    componentDidMount = () => {
        console.log(this.props.internData)
        this.setState({
            title: this.props.internData.title,
            accepted: this.props.internData.accepted,
            error_message: this.props.errorMessage,
            intern_start: this.props.internData.period.intern_start == null ? "": this.props.internData.period.intern_start.replace(/\//g, "-"),
            intern_end: this.props.internData.period.intern_end == null ? "": this.props.internData.period.intern_end.replace(/\//g, "-"),
            intern_pub_start: this.props.internData.period.intern_pub_start == null ? "": this.props.internData.period.intern_pub_start.replace(/\//g, "-"),
            intern_pub_end: this.props.internData.period.intern_pub_end == null ? "": this.props.internData.period.intern_pub_end.replace(/\//g, "-")
        });
        window.scrollTo(0, 0);
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    temporalySave = () => {
        this.props.saveTemporaly()
    }

    getInternTitle = (internTitle) => {
        this.setState({
            title: internTitle
        });
        this.props.getInternTitle(internTitle)
    }

    getAccepted = (acpt) => {
        this.setState({
            accepted: parseInt(acpt)
        });
        this.props.getAccepted(parseInt(acpt));
    }

    getInternStart = (internStart) => {
        this.setState({
            intern_start: internStart
        });
        this.props.getInternStart(internStart)
    }

    getInternEnd = (internEnd) => {
        this.setState({
            intern_end: internEnd
            
        });
        this.props.getInternEnd(internEnd)
    }

    getInternPubStart = (publishStart) => {
        this.setState({
            intern_pub_start: publishStart
        });
        this.props.getPublishStart(publishStart)
    }

    getInternPubEnd = (publishEnd) => {
        this.setState({
            intern_pub_end: publishEnd
        });
        this.props.getPublishEnd(publishEnd)
    }

    addInternTitle = (internTitle) => {
        this.setState({
            title: internTitle
        });
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_intern" setError={this.props.saveMessage} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("outline", true)}>インターンの概要</a></li>
                        <li className="navigationItem active">インターン管理</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_intern", true)}>インターン管理</a></li>
                        <li className="breadcrumbItem">インターン新規登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern", false)}>募集時期</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_2", false)}>会社概要</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern1">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <div className="editInternInputItem">
                                <label htmlFor="title">インターン名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="title" name="title" type="text" required={true} maxLength="300" setValue={this.state.title} onHandleChange={(e) => this.getInternTitle(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="accepted">人数<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="accepted" name="accepted" type="number" value="2" required={true} setValue={this.state.accepted} onHandleChange={(e) => this.getAccepted(e)}/>
                                    人
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodPublishStart">インターン開始<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodPublishStart" name="periodPublishStart" type="date" setValue={this.state.intern_start} required={true} onHandleChange={(e) => this.getInternStart(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodPublishEnd">インターン終了<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodPublishEnd" name="periodPublishEnd" type="date" required={true} setValue={this.state.intern_end} onHandleChange={(e) => this.getInternEnd(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodRecruitStart">掲載開始<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodRecruitStart" name="periodRecruitStart" type="date" required={true} setValue={this.state.intern_pub_start} onHandleChange={(e) => this.getInternPubStart(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodRecruitEnd">掲載終了<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodRecruitEnd" name="periodRecruitEnd" type="date" required={true} setValue={this.state.intern_pub_end} onHandleChange={(e) => this.getInternPubEnd(e)}/>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_intern_2", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postDetailData.isFetching,
        isError: state.postDetailData.isError,
        saveMessage: state.postDetailData.errorMessage
    }
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(EditIntern);

