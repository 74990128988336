import React from "react";
import "../../css/Common.css";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error"
import SearchOverview from "./searchParts/SearchOverview";
import "../../css/Search.css";
import {connect} from "react-redux";
import {univGetUniversityOverview, resetUniversityOverview, resetUniversityDetail} from "../../actions/univ/GetUnivInternAction";

class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            overviewData: [],
            startYear: 0,
            startMonth: 0,
            term: 0,
            people: 0,
            refineStartYear: 0,
            refineStartMonth: 0,
            refineTerm: 0,
            refinePeople: 0
        }
    }

    componentDidMount = () => {
        this.props.univGetOverviewInfo();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.univOverviewData || (prevState.overviewData !== nextProps.univOverviewData)) {
            return {
                overviewData: nextProps.univOverviewData
            };
        }
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo)
    }

    getStartYear = (event) => {
        this.setState({
            startYear: parseInt(event.target.value)
        });
    }

    getStartMonth = (event) => {
        this.setState({
            startMonth: parseInt(event.target.value)
        });
    }

    getTerm = (event) => {
        this.setState({
            term: parseInt(event.target.value)
        });
    }

    getPeople = (event) => {
        this.setState({
            people: parseInt(event.target.value)
        });
    }

    handleRefine = () => {
        console.log("refine")
        this.setState({
            refineStartYear: this.state.startYear,
            refineStartMonth: this.state.startMonth,
            refineTerm: this.state.term,
            refinePeople: this.state.people
        })
    }

    searchErrorDisable = () => {
        this.props.resetOverview();
    }

    detailsErrorDisable = () => {
        this.props.resetDetails()
    }

    render() {
        var startYearStates = [
            { code: 0, name: "指定なし" },
            { code: 2020, name: "2020" },
            { code: 2021, name: "2021" },
            { code: 2022, name: "2022" },
            { code: 2023, name: "2023" } ];
        var startYearOptions = startYearStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );

        var startMonthStates = [
            { code: 0, name: "指定なし" },
            { code: 1, name: "1" },
            { code: 2, name: "2" },
            { code: 3, name: "3" },
            { code: 4, name: "4" },
            { code: 5, name: "5" },
            { code: 6, name: "6" },
            { code: 7, name: "7" },
            { code: 8, name: "8" },
            { code: 9, name: "9" },
            { code: 10, name: "10" },
            { code: 11, name: "11" },
            { code: 12, name: "12" } ];
        var startMonthOptions = startMonthStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );
        var termStates = [
            { code: 0, name: "指定なし" },
            { code: 3, name: "～3か月" },
            { code: 6, name: "～6か月" },
            { code: 12, name: "～12か月" } ];
        var termOptions = termStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );
        var peopleStates = [
            { code: 0, name: "指定なし" },
            { code: 3, name: "～3人" },
            { code: 5, name: "～5人" },
            { code: 10, name: "～10人" } ];
        var peopleOptions = peopleStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );

        return (
            <article>
                {this.props.showLoadIcon ? <Load setLabel="読み込み中..."/>: null}
                {this.props.isSearchError ? <Error close={this.searchErrorDisable} apiName="university_intern_overview" setError={this.props.searchErrorMessage} /> : null}
                {this.props.isDetailError ? <Error close={this.detailsErrorDisable} apiName="university_intern_detail" setError={this.props.detailErrorMessage} /> : null}
                <nav className="navigationArea">
                        <ul className="navigationList layoutInner">
                            <li className="navigationItem active">インターン検索</li>
                            <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("univ_edit")}>応募者登録</a></li>
                            <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("profile")}>プロフィール編集</a></li>
                        </ul>
                    </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("univ_top")}>メニュー</a></li>
                        <li className="breadcrumbItem">インターン検索</li>
                    </ol>
                </div>
                <div className="layoutInner">
                    <section className="searchRefineArea">
                        <h2 className="searchRefineHeading">インターン絞り込み項目</h2>
                        <div className="searchRefineContents">
                            <div className="searchRefineItem">
                                <label htmlFor="startYear">インターン開始時期</label>
                                <div className="searchRefineItemSelect">
                                    <div className="selectWrapper">
                                        <select id="startYear" name="startYear" required value={this.state.startYear} onChange={this.getStartYear}>
                                            {startYearOptions}
                                        </select>
                                    </div>年
                                    <div className="selectWrapper">
                                        <select id="startMonth" name="startMonth" required value={this.state.startMonth} onChange={this.getStartMonth}>
                                            {startMonthOptions}
                                        </select>
                                    </div>月〜
                                </div>
                            </div>
                            <div className="searchRefineItem">
                                <label htmlFor="term">インターン期間</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="term" name="term" required value={this.state.term} onChange={this.getTerm}>
                                        {termOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="searchRefineItem">
                                <label htmlFor="people">インターン人数</label>
                                <div className="selectWrapper searchRefineItemSelect">
                                    <select id="people" name="people" required value={this.state.people} onChange={this.getPeople}>
                                        {peopleOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="searchRefineButton">
                                <Button label="絞り込み検索" buttonEnable={true} callback={() => this.handleRefine()}/>
                            </div>
                        </div>
                    </section>
                    <section className="searchResult">
                        {
                            this.state.overviewData.map((item) => {
                                const refineStartDay = this.state.refineStartYear + "/" + this.state.refineStartMonth + "/" + 1;
                                const refineStart = Date.parse(refineStartDay);
                                const internStart = Date.parse(item.period.intern_start);
                                const internEnd = Date.parse(item.period.intern_end);
                                const diff = internEnd - internStart;
                                const daysDiff = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
                                const termMonth = Math.floor(daysDiff / 30)
                                if(
                                    ((this.state.refineStartYear === 0 && this.state.refineStartMonth === 0) || (refineStart <= internStart))
                                    && ((this.state.refineTerm === 0) || (termMonth <= this.state.refineTerm))
                                    && ((this.state.refinePeople === 0) || (item.accepted <= this.state.refinePeople))
                                ) {
                                    return(
                                        <SearchOverview
                                            key={item.intern_id}
                                            itemData={item}
                                        />
                                    )
                                }
                            })
                        }
                    </section>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        univOverviewData: state.getUnivInternOverviews.overViewData,
        showLoadIcon: !state.getUnivInternOverviews.isGetInfo,
        isSearchError: state.getUnivInternOverviews.isError,
        searchErrorMessage: state.getUnivInternOverviews.errorMessage,
        isDetailError: state.getUnivInternDetails.isError,
        detailErrorMessage: state.getUnivInternDetails.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    univGetOverviewInfo: () => dispatch(univGetUniversityOverview()),
    resetOverview: () => dispatch(resetUniversityOverview()),
    resetDetails: () => dispatch(resetUniversityDetail())
});


export default connect(mapStateToProps, mapDispatchToProps)(Search);
