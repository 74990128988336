import React from "react";
import Button from "../../atoms/Button";

class HistoryBody extends React.Component {    
    setCammaToNumber = (num) => {
        return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    }
    
    exportReportFile = (type) => {
        this.props.exportReportFile(type, this.props.itemData.target_ym);
    }

    render() {
        const money = this.setCammaToNumber(this.props.itemData.amount);
        return(
            <tbody>
                <tr>
                    <td rowSpan="2">{this.props.itemData.target_ym}</td>
                    <td rowSpan="2">{this.props.itemData.standard3}</td>
                    <td rowSpan="2">{this.props.itemData.standard6}</td>
                    <td rowSpan="2">{this.props.itemData.standard9}</td>
                    <td rowSpan="2">{this.props.itemData.standard12}</td>
                    <td rowSpan="2">{this.props.itemData.short1}</td>
                    <td rowSpan="2">{this.props.itemData.short2}</td>
                    <td rowSpan="2">{this.props.itemData.short3}</td>
                    <td rowSpan="2">{this.props.itemData.subscribership}名</td>
                    <td rowSpan="2">{money}円</td>
                    <td>
                        <div id="dlReportBtnWrapper">
                            <Button label="報告書（.xlsx）" buttonEnable={true} callback={() => this.exportReportFile(1)}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div id="dlFormatBtnWapper">
                            <Button label="データフォーマット（.xlsx）" buttonEnable={true} callback={() => this.exportReportFile(2)}/>
                        </div>
                    </td>
                </tr>
            </tbody>
        )
    }
}

export default HistoryBody;