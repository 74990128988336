import axios from "axios";
import {BASE_URL, checkType, checkOverFlow, checkNumberOverflow, checkDateStyle, checkImageSize} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

//SAVE INTERN DETAIL
export const SAVE_DETAIL_REQUEST = "SAVE_DETAIL_REQUEST";
export const SAVE_DETAIL_SUCCESS = "SAVE_DETAIL_SUCCESS";
export const SAVE_DETAIL_FAILURE = "SAVE_DETAIL_FAILURE";
export const SAVE_DETAIL_RESET = "SAVE_DETAIL_RESET";

export const internDetailSaveRequest = () => {
    return {
        type: SAVE_DETAIL_REQUEST
    }
}

export const internDetailSaveSuccess = (data) => {
    return {
        type: SAVE_DETAIL_SUCCESS,
        data
    }
}

export const internDetailSaveFailure = (err) => {
    return {
        type: SAVE_DETAIL_FAILURE,
        err
    }
}

export const internDetailSaveReset = () => {
    return {
        type: SAVE_DETAIL_RESET
    }
}

const SAVE_DETAIL_POST = BASE_URL + "/api/save_intern";

export const saveInternDetail = (savedata) => {
    return async(dispatch) => {
        dispatch(internDetailSaveRequest());
        // 日付の記載を変更する
        savedata.period.intern_start = savedata.period.intern_start ? savedata.period.intern_start.replace(/-/g, "/"): null;
        savedata.period.intern_end = savedata.period.intern_end ? savedata.period.intern_end.replace(/-/g, "/"): null;
        savedata.period.intern_pub_start = savedata.period.intern_pub_start ? savedata.period.intern_pub_start.replace(/-/g, "/"): null;
        savedata.period.intern_pub_end = savedata.period.intern_pub_end ? savedata.period.intern_pub_end.replace(/-/g, "/"): null;
        console.log(savedata);
        const idToken = getIdToken();
        let verify = true;
        const headerParam = {
            Authorization: idToken
        }

        // 親パラメータチェック
        if (!_query_parent_check(savedata)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        // periodチェック
        if (!_period_check(savedata.period)) {
            return dispatch(internDetailSaveSuccess(400));
        }
       
        // 企業情報チェック
        if (!_company_info_check(savedata.company_info)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        // 設定国チェック
        if (!_country_check(savedata.country)) {
            return dispatch(internDetailSaveSuccess(400));
        }

        // 勤怠時間チェック
        if (!_working_time_check(savedata.working_time)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        // 待遇チェック
        if (!_livelihood_support_check(savedata.livelihood_support)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        // 管理者へのサービス
        if (!_management_check(savedata.management)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        // 勤務地チェック
        if (!_last_page_check(savedata.facility_introduction)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        // 近隣地情報チェック
        if (!_last_page_check(savedata.nearby_info)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        // 同意関連チェック
        if (!_insurance_check(savedata.insurance)) {
            return dispatch(internDetailSaveSuccess(400));
        }
        
        axios.get(cognitoEndpoint).then(
            response => {
                var pems = {};
                var keys = response.data['keys'];
                for(var i = 0; i < keys.length; i++) {
                    var key_id = keys[i].kid;
                    var modulus = keys[i].n;
                    var exponent = keys[i].e;
                    var key_type = keys[i].kty;
                    var jwk = { kty: key_type, n: modulus, e: exponent};
                    var jtp = jwkToPem(jwk);
                    pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(internDetailSaveFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(internDetailSaveFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(internDetailSaveFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(internDetailSaveFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(internDetailSaveFailure(err));
                        } else {
                            console.log("Valid Token.");
                            axios.post(SAVE_DETAIL_POST, savedata, {headers: headerParam}).then(
                                // API側と通信する
                                response => {
                                    return dispatch(internDetailSaveSuccess(response.data));
                                }
                            ).catch(
                                err => dispatch(internDetailSaveFailure(err))
                                //res => dispatch(internDetailSaveSuccess(200))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(internDetailSaveFailure(err));
                //return dispatch(internDetailSaveSuccess(200));
            }
        )
    }
}

const _query_parent_check = (query) => {
    let parent_check = true;
    for (const key in query) {
        if (!_intern_param[key]) {
            console.log(key)
            return false;
        }
        
        const target = query[key];

        if (_intern_param[key] !== checkType(target)) {
            console.log(key + target)
            if (target != null) {
                return false;
            }
        }

        if (key === "intern_id") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "entered_flg") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "accepted") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "sex") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "occupation") {
            parent_check = checkOverFlow(target);
        } else if (key === "content") {
            parent_check = checkOverFlow(target);
        } else if (key === "free_text") {
            parent_check = checkOverFlow(target);
        }

        if (!parent_check) {
            return false;
        }
    }

    return parent_check;
}

const _period_check = (query) => {
    let period_check = true;
    for(const key in query) {
        if (!_period_param[key]) {
            return false;
        }

        const target = query[key];

        if (_period_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "intern_start") {
            period_check = checkDateStyle(target);
        } else if (key === "intern_end") {
            period_check = checkDateStyle(target);
        } else if (key === "intern_pub_start") {
            period_check = checkDateStyle(target);
        } else if (key === "intern_pub_end") {
            period_check = checkDateStyle(target);
        }

        if (!period_check) {
            return false;
        }
    }
    return period_check;
}

const _company_info_check = (query) => {
    let company_check = true;
    for(const key in query) {
        if (!_company_param[key]) {
            return false;
        }

        const target = query[key];

        if (_company_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "employee") {
            const eQuery = query[key];
            for (const eKey in eQuery) {

                const eTarget = eQuery[eKey];

                if (!_company_param[eKey]) {
                    return false;
                }

                if (_company_param[eKey] !== checkType(eTarget)) {
                    if (eTarget != null) {
                        return false;
                    }
                }

                if (eKey === "regular") {
                    company_check = checkNumberOverflow(eTarget);
                } else if (key === "part_time") {
                    company_check = checkNumberOverflow(eTarget);
                }

                if (!company_check) {
                    return false;
                }
            }
        }

        if (key === "name") {
            company_check = checkOverFlow(target);
        } else if (key === "address") {
            company_check = checkOverFlow(target);
        } else if (key === "president") {
            company_check = checkOverFlow(target);
        } else if (key === "tel") {
            company_check = checkOverFlow(target);
        } else if (key === "fax") {
            company_check = checkOverFlow(target);
        } else if (key === "web") {
            company_check = checkOverFlow(target);
        }  else if (key === "facility_name") {
            company_check = checkOverFlow(target);
        } else if (key === "facility_address") {
            company_check = checkOverFlow(target);
        } else if (key === "pic") {
            company_check = checkOverFlow(target);
        } else if (key === "pic_tel") {
            company_check = checkOverFlow(target);
        } else if (key === "mail") {
            company_check = checkOverFlow(target);
        }

        if (!company_check) {
            return false;
        }
    }

    return company_check;

}

const _working_time_check = (query) => {
    let work_check = true;
    for(const key in query) {
        if (!_working_time_param[key]) {
            return false;
        }

        const target = query[key];

        if (_working_time_param[key] !== checkType(target)) {
            return false;
        }

        if (key === "day") {
            work_check = checkNumberOverflow(target);
        } else if (key === "hour") {
            work_check = checkNumberOverflow(target);
        } else if (key === "minute") {
            work_check = checkNumberOverflow(target);
        } 

        if (!work_check) {
            return false;
        }
    }
    return work_check;
}

const _livelihood_support_check = (query) => {
    let livelihood_check = true;
    for(const key in query) {
        if (!_livelihood_support_param[key]) {
            console.log(key)
            return false;
        }

        const target = query[key];

        if (_livelihood_support_param[key] !== checkType(target)) {
            console.log(key + target + checkType(target))
            return false;
        }

        if (key === "salary") {
            livelihood_check = checkNumberOverflow(target);
        }

        if (key === "deduction") {
            for (const data of query[key]) {
                if (_livelihood_support_param["nominal"] !== checkType(data["nominal"])) {
                    return false;
                }

                if (_livelihood_support_param["amount"] !== checkType(data["amount"])) {
                    return false;
                }

                if (!checkOverFlow(data["nominal"])) {
                    return false;
                }

                if (!checkNumberOverflow(data["amount"])) {
                    return false;
                }
            }
        }

        if (!livelihood_check) {
            return false;
        }
    }
    return livelihood_check;

}

const _management_check = (query) => {
    let management_check = true;
    for (const key in query) {
        if (!_hotel_meal_param[key]) {
            return false;
        }

        const target = query[key];

        if (_hotel_meal_param[key] !== checkType(target)) {
            return false;
        }

        for (const childKey in target) {
            if (!_hotel_meal_param[childKey]) {
                return false;
            }

            const childTarget = target[childKey];

            if (_hotel_meal_param[childKey] !== checkType(childTarget)) {
                if (childTarget != null) {
                    return false;
                }
            }
            
            if (childKey === "catalog_price") {
                management_check = checkNumberOverflow(childTarget);
                
            } else if (childKey === "publish_price") {
                management_check = checkNumberOverflow(childTarget);
            }

            if (!management_check) {
                return false;
            }
        }
        return management_check
    }
    return management_check;
}

const _last_page_check = (query) => {
    let last_check = true;
    for(const key in query) {
        if (!_last_page_param[key]) {
            return false;
        }

        const target = query[key];

        if (_last_page_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "image") {
            last_check = checkImageSize(target);
        } else if (key === "comment") {
            last_check = checkOverFlow(target);
        }

        if (!last_check) {
            return false;
        }
    }
    return last_check;

}

const _insurance_check = (query) => {
    for(const key in query) {
        if (!_insurance_param[key]) {
            return false;
        }

        const target = query[key];

        if (_insurance_param[key] !== checkType(target)) {
            return false;
        }
    }
    return true;
}

const _country_check = (query) => {
    if (checkType(query) !== "array") {
        return false;
    }

    for (const param of query) {
        if (checkType(param) !== "boolean") {
            return false;
        }
    }
    return true;
}

const _intern_param = {
    "status": "number",
    "title": "string",
    "plan": "array",
    "intern_id": "number",
    "entered_flg": "number",
    "period": "object",
    "accepted": "number",
    "company_info": "object",
    "sex": "number",
    "country": "array",
    "occupation": "string",
    "content": "string",
    "free_text": "string",
    "working_time": "object",
    "livelihood_support": "object",
    "management": "object",
    "facility_introduction": "object",
    "nearby_info": "object",
    "insurance": "object"
}

const _period_param = {
    "intern_start": "string",
    "intern_end": "string",
    "intern_pub_start": "string",
    "intern_pub_end": "string",
}

const _company_param = {
    "name": "string",
    "address": "string",
    "president": "string",
    "tel": "string",
    "fax": "string",
    "web": "string",
    "employee": "object",
    "regular": "number",
    "part_time": "number",
    "facility_name": "string",
    "facility_address": "string",
    "pic": "string",
    "pic_tel": "string",
    "mail": "string"
}

const _working_time_param = {
    "day": "number",
    "hour": "number",
    "minute": "number",
}

const _livelihood_support_param = {
    "salary": "number",
    "deduction": "array",
    "nominal": "string",
    "amount": "number",
}

const _hotel_meal_param = {
    "hotels": "object",
    "meals": "object",
    "is_service": "boolean",
    "catalog_price": "number",
    "publish_price": "number",
}

const _last_page_param = {
    "image": "string",
    "comment": "string"
}

const _insurance_param = {
    "accept_accident": "boolean",
    "accept_human_resources": "boolean",
    "request_training": "boolean",
    "request_promotion": "boolean"
}


export const saveInternReset = () => {
    return (dispatch) => dispatch(internDetailSaveReset());
}


//SAVE INTERN PLAN
export const SAVE_PLAN_REQUEST = "SAVE_PLAN_REQUEST";
export const SAVE_PLAN_SUCCESS = "SAVE_PLAN_SUCCESS";
export const SAVE_PLAN_FAILURE = "SAVE_PLAN_FAILURE";
export const SAVE_PLAN_RESET = "SAVE_PLAN_RESET";

export const internPlanSaveRequest = () => {
    return {
        type: SAVE_PLAN_REQUEST
    }
}

export const internPlanSaveSuccess = (data) => {
    return {
        type: SAVE_PLAN_SUCCESS,
        data
    }
}

export const internPlanSaveFailure = (err) => {
    return {
        type: SAVE_PLAN_FAILURE,
        err
    }
}

export const internPlanSaveReset = () => {
    return {
        type: SAVE_PLAN_RESET
    }
}

const SAVE_PLAN_POST = BASE_URL + "/api/save_internplan";

export const saveInternPlan = (savedata) => {
    console.log(savedata);
    const idToken = getIdToken();
    let verify = true;
    const headerParam = {
        Authorization: idToken
    }
    return async(dispatch) => {
        dispatch(internPlanSaveRequest());
        if (!_check_plan_query(savedata)) {
            return dispatch(internPlanSaveSuccess(400));
        }
        
        axios.get(cognitoEndpoint).then(
            response => {
                var pems = {};
                var keys = response.data['keys'];
                for(var i = 0; i < keys.length; i++) {
                    var key_id = keys[i].kid;
                    var modulus = keys[i].n;
                    var exponent = keys[i].e;
                    var key_type = keys[i].kty;
                    var jwk = { kty: key_type, n: modulus, e: exponent};
                    var jtp = jwkToPem(jwk);
                    pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(internPlanSaveFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(internPlanSaveFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(internPlanSaveFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(internPlanSaveFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            dispatch(internPlanSaveFailure(err));
                        } else {
                            console.log("Valid Token.");
                            axios.post(SAVE_PLAN_POST, savedata, {headers: headerParam}).then(
                                // API側と通信する
                                response => {
                                    return dispatch(internPlanSaveSuccess(response.data));
                                }
                            ).catch(
                                err => dispatch(internPlanSaveFailure(err))
                                //res => dispatch(internPlanSaveSuccess(200))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(internPlanSaveFailure(err));
            }
        )
    }
}


export const resetInternPlan = () => {
    return (dispatch) => dispatch(internPlanSaveReset());
}

const _check_plan_query = (query) => {
    console.log(query)
    let check_plan = true;
    for (const key in query) {
        if (!plan_param[key]) {
            return false;
        }

        const target = query[key];

        if (plan_param[key] !== checkType(target)) {
            return false;
        }

        if (key === "plan") {
            const data = target.slice();
            for (const childData of data) {
                for (const childKey in childData) {
                    if (!plan_param[childKey]) {
                        console.log("a")
                        return false;
                    }
                    
                    const childTarget = childData[childKey];
        
                    if (plan_param[childKey] !== checkType(childTarget)) {
                        console.log("a: " + plan_param[childKey])
                        console.log("b: " + checkType(childTarget))
                        console.log("aa")
                        return false;
                    }
        
                    if (childKey === "intern_season") {
                        check_plan = checkOverFlow(childTarget);
                    } else if (childKey === "intern_content") {
                        check_plan = checkOverFlow(childTarget);
                    }
                    
                    if (!check_plan) {
                        return false;
                    }
                }
            }
        }
    }
    return check_plan;
}

const plan_param = {
    "intern_id": "number",
    "plan": "array",
    "intern_season": "string",
    "intern_content": "string"
}
