import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Button from "./atoms/Button";
import Header from "../components/common_parts/Header";
import Footer from "../components/common_parts/Footer";
import Top from "./manage/Top"
import ShowJob from "./posting/ShowJob";
import EditJob from "./manage/EditJob";
import EditJob2 from "./manage/EditJob2";
import EditJob3 from "./manage/EditJob3";
import EditJob4 from "./manage/EditJob4";
import EditJob5 from "./manage/EditJob5";
import EditJob6 from "./manage/EditJob6";
import EditJob7 from "./manage/EditJob7";
import JobDetail from "./posting/DetailJob";
import {initial_manage_send_data, INITIAL_COMPANY_DATA} from "../constants/CommonConstatns";
import {jobValidationCheck} from "../constants/ManageConstants";
import {saveJobPostingDetail, saveJobDetailReset} from "../actions/manage/SaveJobPostingAction";
import {getCompayInformation, getCompanyInformationReset} from "../actions/manage/GetCompanyListAction";
import {JOB_POSTING_TEST} from "../constants/TestData";
import jwt from "jsonwebtoken";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
const CognitoRefreshToken = require('amazon-cognito-identity-js-node').CognitoRefreshToken;
const config = require('./config');

const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId,
  ClientId: config.ClientId,
  Storage: sessionStorage
});


class ManageHomeTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            display: "top",
            jobId: null,
            organizationId: null,
            sendData: JSON.parse(JSON.stringify(initial_manage_send_data)),
            errorMessage: [],
            response: "",
            expired: 0,
            companylist_num: 0,
            position: null,
            userType: "",
            isNewJob: false,
            pre_place: "",
            pre_facility: "",
            loginId: ""
        }
        this.setInitialState = this.setInitialState.bind(this);
        this.switchDisplay = this.switchDisplay.bind(this);
        this.switchDisplayWithJobId = this.switchDisplayWithJobId.bind(this);
        this.switchDisplayWithJobData = this.switchDisplayWithJobData.bind(this);
        this.getJobTitle = this.getJobTitle.bind(this);
        this.getEmploymentStart = this.getEmploymentStart.bind(this);
        this.getEmploymentEnd = this.getEmploymentEnd.bind(this);
        this.getPostingStart = this.getPostingStart.bind(this);
        this.getPostingEnd = this.getPostingEnd.bind(this);
        this.getAccepted = this.getAccepted.bind(this);
        this.getEmploymentType = this.getEmploymentType.bind(this);
        this.getRenewContract = this.getRenewContract.bind(this);
    }

    refreshToken = () => {
        var CurrentUser = userPool.getCurrentUser();
        if(this.state.expired > 0){
            var date = new Date();
            var nowDate = date.getTime();
            var unixtime = Math.floor(nowDate / 1000);
            if(this.state.expired < unixtime){
                 if (CurrentUser != null) {
                     CurrentUser.signOut();
                     CurrentUser.getSession(function(err, session) {
                         console.log(session);
                     });
                 }
                sessionStorage.clear();
                this.props.history.push('/login')
                return;
            }
        }
        let _this = this;
        if (CurrentUser != null) {
            CurrentUser.getSession(function(err, session) {
                if(_this.state.userType === ""){
                    CurrentUser.getUserAttributes(function(err, attrresult) {
                        if (err) {
                            console.log(err);
                            return;
                        }
                        for (var i = 0; i < attrresult.length; i++) {
                            if (attrresult[i].getName()==="custom:user_type"){
                                let user_type = attrresult[i].getValue();
                                console.log(user_type)
                                _this.setState({
                                    userType: user_type
                                })
                            } else if (attrresult[i].getName()==="email"){
                                let email = attrresult[i].getValue();
                                _this.setState({
                                    loginId: email
                                })
                            }
                        }
                    });
                }
                var refreshTokenstr = session.getRefreshToken().getToken();
                var refreshToken = new CognitoRefreshToken({RefreshToken: refreshTokenstr});

                CurrentUser.refreshSession(refreshToken, (err, result)=>{
                    var idToken = result.getIdToken().getJwtToken();
                    var decoded = jwt.decode(idToken, { complete: true });
                    console.log(decoded);
                    _this.setState({
                        expired: decoded.payload.exp
                    })
                });
            });
        }

    }

    componentDidMount = () => {
        this.browserBackBtnAction();
        this.refreshToken();
    }

    setInitialState = () => {
        let initData = JSON.parse(JSON.stringify(initial_manage_send_data));
        this.setState({
            sendData: initData,
            errorMessage: [],
            position: null
        });
    }

    setCompanyInfo = () => {
        console.log(this.props.companyList);
        if (this.props.companyList && Object.keys(this.props.companyList).length > 0) {
            console.log("clear");
            this.setState({
                companyList: this.props.companyList
            });
        }
    }

    switchDisplay = (dispId, isReset) => {
        this.refreshToken();
        if (!isReset) {
            this.setCompanyInfo();
        }
        this.setState({
            display: dispId
        });
    }

    switchDisplayWithJobId = (dispId, jobId, orgId) => {
        this.refreshToken();
        this.setState(
            {
                jobId: jobId,
                organizationId: orgId
            }, () => {
                this.setState({
                    display: dispId,
                })
            }
        )
    }

    switchDisplayWithJobData = (dispId, jobData) => {
        this.refreshToken();
        let currentData = {...this.state.sendData};
        
        this.setState({
            display: dispId,
            jobId: jobData.jobId,
            sendData: jobData
        }, () => {
            console.log(this.state.sendData)
        });
    }

    getJobTitle = (title) => {
        let newState = {...this.state.sendData}
        newState.title = title;
        this.setState({
            sendData: newState
        });
    }

    setCompanyListNum = (num) => {
        this.setState({
            companylist_num: num
        });
    }

    setPrePlace = (place) => {
        this.setState({
            pre_place: place
        });
    }

    setPreFacility = (place) => {
        this.setState({
            pre_facility: place
        });
    }

    setIsNewJob = (isNew) => {
        this.setState({
            isNewJob: isNew
        }, () => {
            console.log(this.state.isNewjob)
        });
    }

    getEmploymentStart = (employmentStart) => {
        let newState = {...this.state.sendData}
        newState.period.employment_start = employmentStart;
        console.log(newState)
        this.setState({
            sendData: newState
        });
    }

    getEmploymentEnd = (employmentEnd) => {
        let newState = {...this.state.sendData}
        newState.period.employment_end = employmentEnd;
        this.setState({
            sendData: newState
        });
    }

    getPostingStart = (postingStart) => {
        let newState = {...this.state.sendData}
        newState.period.posting_start = postingStart;
        this.setState({
            sendData: newState
        });
    }

    getPostingEnd = (postingEnd) => {
        let newState = {...this.state.sendData}
        newState.period.posting_end = postingEnd;
        this.setState({
            sendData: newState
        });
    }

    getAccepted = (accepted) => {
        let newState = {...this.state.sendData}
        newState.accepted = accepted;
        this.setState({
            sendData: newState
        });
    }

    getEmploymentType = (employmentType) => {
        let newState = {...this.state.sendData}
        newState.employment_type = employmentType;
        this.setState({
            sendData: newState
        });
    }

    getRenewContract = (renewContract) => {
        let newState = {...this.state.sendData}
        newState.renew_contract = renewContract;
        this.setState({
            sendData: newState
        });
    }
    getCompanyInfoName = (companyInfoName) => {
        let newState = {...this.state.sendData}
        newState.company_info.name = companyInfoName;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoId = (companyInfoId) => {
        let newState = {...this.state.sendData}
        newState.company_info.company_id = companyInfoId;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoAddress = (companyInfoAddress) => {
        let newState = {...this.state.sendData}
        newState.company_info.address = companyInfoAddress;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoPresident = (companyInfoPresident) => {
        let newState = {...this.state.sendData}
        newState.company_info.president = companyInfoPresident;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoTel = (companyInfoTel) => {
        let newState = {...this.state.sendData}
        newState.company_info.tel = companyInfoTel;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoFax = (companyInfoFax) => {
        let newState = {...this.state.sendData}
        newState.company_info.fax = companyInfoFax;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoWeb = (companyInfoWeb) => {
        let newState = {...this.state.sendData}
        newState.company_info.web = companyInfoWeb;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoEmployeeRegular = (companyInfoEmployeeRegular) => {
        let newState = {...this.state.sendData}
        newState.company_info.employee.regular = companyInfoEmployeeRegular;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoEmployeePartTime = (companyInfoEmployeePartTime) => {
        let newState = {...this.state.sendData}
        newState.company_info.employee.part_time = companyInfoEmployeePartTime;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoFacilityName = (companyInfoFacilityName) => {
        let newState = {...this.state.sendData}
        newState.company_info.facility_name = companyInfoFacilityName;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoFacilityAddress = (companyInfoFacilityAddress) => {
        let newState = {...this.state.sendData}
        newState.company_info.facility_address = companyInfoFacilityAddress;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoPic = (companyInfoPic) => {
        let newState = {...this.state.sendData}
        newState.company_info.pic = companyInfoPic;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoPosition = (companyInfoPosition) => {
        let newState = {...this.state.sendData}
        newState.company_info.position = companyInfoPosition;
        this.setState({
            sendData: newState,
            position: companyInfoPosition
        });
    }

    getCompanyInfoPicTel = (companyInfoPicTel) => {
        let newState = {...this.state.sendData}
        newState.company_info.pic_tel = companyInfoPicTel;
        this.setState({
            sendData: newState
        });
    }

    getCompanyInfoMail = (companyInfoMail) => {
        let newState = {...this.state.sendData}
        newState.company_info.mail = companyInfoMail;
        this.setState({
            sendData: newState
        });
    }

    getSex = (sex) => {
        let newState = {...this.state.sendData}
        newState.sex = sex;
        this.setState({
            sendData: newState
        });
    }

    getCountry = (country) => {
        let newState = {...this.state.sendData}
        newState.country = country;
        this.setState({
            sendData: newState
        });
    }

    getOccupation = (occupation) => {
        let newState = {...this.state.sendData}
        newState.occupation = occupation;
        this.setState({
            sendData: newState
        });
    }

    getContent = (content) => {
        let newState = {...this.state.sendData}
        newState.content = content;
        this.setState({
            sendData: newState
        });
    }

    getWorkingDay = (day) => {
        let newState = {...this.state.sendData}
        newState.working_time.working_day = day;
        this.setState({
            sendData: newState
        });
    }

    getWorkingOther = (other) => {
        let newState = {...this.state.sendData}
        newState.working_time.other = other;
        this.setState({
            sendData: newState
        });
    }

    getHoliday = (holiday) => {
        let newState = {...this.state.sendData}
        newState.holiday = holiday;
        this.setState({
            sendData: newState
        });
    }

    getTotalHoliday = (totalholiday) => {
        let newState = {...this.state.sendData}
        newState.total_holidays = totalholiday;
        this.setState({
            sendData: newState
        });
    }

    getPaidHoliday = (holiday) => {
        let newState = {...this.state.sendData}
        newState.paid_holiday = holiday;
        this.setState({
            sendData: newState
        });
    }

    getOtherHoliDays = (holiday) => {
        console.log(holiday)
        let newState = {...this.state.sendData}
        newState.other_holiday = holiday;
        this.setState({
            sendData: newState
        });
    }

    getOther = (other) => {
        let newState = {...this.state.sendData}
        newState.social_insurance.other = other;
        this.setState({
            sendData: newState
        });
    }

    getRent = (rent) => {
        let newState = {...this.state.sendData}
        newState.payment_detail.rent = rent;
        this.setState({
            sendData: newState
        });
    }

    getAllowance = (allowance) => {
        let newState = {...this.state.sendData}
        newState.allowance = allowance;
        this.setState({
            sendData: newState
        });
    }

    getBoard = (board) => {
        let newState = {...this.state.sendData}
        newState.payment_detail.board = board;
        this.setState({
            sendData: newState
        });
    }

    getType = (itype) => {
        let newState = {...this.state.sendData}
        newState.social_insurance.type = itype;
        this.setState({
            sendData: newState
        });
    }

    getIsFixedDay = (fixed) => {
        let newState = {...this.state.sendData}
        newState.working_time.is_fixed_day = fixed;
        this.setState({
            sendData: newState
        });
    }

    getIsDecideShift = (shift) => {
        let newState = {...this.state.sendData}
        newState.working_time.is_decide_shift = shift;
        this.setState({
            sendData: newState
        });
    }

    getHasOther = (fixed) => {
        let newState = {...this.state.sendData}
        newState.working_time.has_other = fixed;
        this.setState({
            sendData: newState
        });
    }

    getWorkingTimeMinute = (workingTimeMinute) => {
        let newState = {...this.state.sendData}
        newState.working_time.minute = workingTimeMinute;
        this.setState({
            sendData: newState
        });
    }

    getLivelihoodSupportSalary = (livelihoodSupportSalary) => {
        let newState = {...this.state.sendData}
        newState.livelihood_support.salary = livelihoodSupportSalary;
        this.setState({
            sendData: newState
        });
    }

    getLivelihoodSupportDeduction = (livelihoodSupportDeduction) => {
        let newState = {...this.state.sendData}
        newState.livelihood_support.deduction = livelihoodSupportDeduction;
        this.setState({
            sendData: newState
        });
    }

    getWorks = (works) => {
        let newState = {...this.state.sendData}
        newState.working_time.works = works;
        this.setState({
            sendData: newState
        });
    }

    getManagementHotelsIsService = (managementHotelsIsService) => {
        let newState = {...this.state.sendData}
        newState.management.hotels.is_service = managementHotelsIsService;
        this.setState({
            sendData: newState
        });
    }

    getManagementHotelsCatalogPrice = (managementHotelsCatalogPrice) => {
        let newState = {...this.state.sendData}
        newState.management.hotels.catalog_price = managementHotelsCatalogPrice;
        this.setState({
            sendData: newState
        });
    }

    getManagementHotelsPublishPrice = (managementHotelsPublishPrice) => {
        let newState = {...this.state.sendData}
        newState.management.hotels.publish_price = managementHotelsPublishPrice;
        this.setState({
            sendData: newState
        });
    }

    getManagementMealsIsService = (managementMealsIsService) => {
        let newState = {...this.state.sendData}
        newState.management.meals.is_service = managementMealsIsService;
        this.setState({
            sendData: newState
        });
    }

    getManagementMealsCatalogPrice = (managementMealsCatalogPrice) => {
        let newState = {...this.state.sendData}
        newState.management.meals.catalog_price = managementMealsCatalogPrice;
        this.setState({
            sendData: newState
        });
    }

    getManagementMealsPublishPrice = (managementMealsPublishPrice) => {
        let newState = {...this.state.sendData}
        newState.management.meals.publish_price = managementMealsPublishPrice;
        this.setState({
            sendData: newState
        });
    }

    getFacilityIntroductionComment = (facilityIntroductionComment) => {
        let newState = {...this.state.sendData}
        newState.facility_introduction.comment = facilityIntroductionComment;
        this.setState({
            sendData: newState
        });
    }

    getFacilityIntroductionImage = (image) => {
        let newState = {...this.state.sendData}
        newState.facility_introduction.image = image;
        this.setState({
            sendData: newState
        });
    }

    getNearbyInfoComment = (nearbyInfoComment) => {
        let newState = {...this.state.sendData}
        newState.nearby_info.comment = nearbyInfoComment;
        this.setState({
            sendData: newState
        });
    }

    getNearbyInfoImage = (image) => {
        let newState = {...this.state.sendData}
        newState.nearby_info.image = image;
        this.setState({
            sendData: newState
        });
    }

    getInsuranceStatus = (stats) => {
        let newState = {...this.state.sendData};
        newState.insurance = JSON.parse(stats);
        console.log(newState)
        this.setState({
            sendData: newState
        });
    }

    logoutAction = () => {
        const CurrentUser = userPool.getCurrentUser();
        if (CurrentUser != null) {
            CurrentUser.signOut();
            sessionStorage.clear();
            CurrentUser.getSession(function(err, session) {
                 console.log(session);
            });
            this.props.history.replace("/login");
        }
    }

    browserBackBtnAction = () => {
        window.addEventListener("popstate", (e) => {
            const CurrentUser = userPool.getCurrentUser();
            if (CurrentUser != null) {
                CurrentUser.signOut();
                sessionStorage.clear();
                this.props.history.push("/login");
            }
            return;
        });
    }

    // 一時保存処理
    saveTemporaly = () => {
        let currentState = this.state.sendData;
        //let currentState = JOB_POSTING_TEST;
        const tempTitle = "新規求人票名";
        currentState.entered_flg = 0;
        if (currentState.title === "") {
            const changeTitleState = {...this.state.sendData};
            changeTitleState.title = tempTitle;
            this.setState({
                sendData: changeTitleState
            });
            currentState.title = tempTitle;
            //this._callrefTitle(tempTitle);
        }
        console.log(currentState);
        this.props.saveJobPostingDetail(currentState)
    }

    // インターン詳細保存処理
    saveJobDetail = () => {
        const currentState = this.state.sendData;
        //let currentState = JOB_POSTING_TEST;
        let result = jobValidationCheck(currentState);
        console.log(result)
        if (result.length === 0) {
            currentState.entered_flg = 1;
            this.setState({
                errorMessage: []
            }, () => {
                this._callrefError();
            })
            this.props.saveJobPostingDetail(currentState);
        } else {
            this.setState({
                errorMessage: result
            }, () => {
                this._callrefError();
            })
        }
    }

    // TOP画面：企業情報取得エラーアイコン非表示
    companyInfoErrorDisable = () => {
        this.props.resetCompanyInfo();
    }

    // 保存状態リセット
    saveStateErrorDisable = () => {
        this.props.resetSaveState()
    }

    _callrefError() {
        this.refs.child.addErrorMessage(this.state.errorMessage);
    }

    _callrefTitle() {

        this.refs.child.addInternTitle(this.state.sendData.title);
    }

    render() {
        let displayParts;
        if (this.state.display === "top") {
            displayParts = <Top 
                changeDisp={this.switchDisplay}
                logout_action={this.logoutAction}
                loginId={this.state.loginId}
                />;
        } else if (this.state.display === "show_job") {
            displayParts = <ShowJob 
                getCompanyData={() => this.props.getCompanyData()} 
                changeDisp={this.switchDisplay} 
                changeDispWithId={this.switchDisplayWithJobId} 
                isCompanyError={this.props.isCompanyDataError} 
                errorDisable={this.companyInfoErrorDisable}
                companyInfoMessage={this.props.companyDataError}
                userType={this.state.userType}
                setIsNewJob={this.setIsNewJob}
                setPreFacility={this.setPreFacility}
                setPrePlace={this.setPrePlace}
            />;
        } else if (this.state.display === "edit_job") {
            displayParts = <EditJob
                resetState={this.setInitialState}
                jobData={this.state.sendData}
                getJobTitle={this.getJobTitle}
                getEmploymentStart={this.getEmploymentStart}
                getEmploymentEnd={this.getEmploymentEnd}
                getPostingStart={this.getPostingStart}
                getPostingEnd={this.getPostingEnd}
                getAccepted={this.getAccepted}
                getEmploymentType={this.getEmploymentType}
                getRenewContract={this.getRenewContract}
                errorMessage={this.state.errorMessage}
                changeDisp={this.switchDisplay}
                saveTemporaly={this.saveTemporaly}
                saveReset={this.saveStateErrorDisable}
                ref="child"
            />;
        } else if (this.state.display === "edit_job_2") {
            displayParts = <EditJob2
                resetState={this.setInitialState}
                isNewJob={this.state.isNewJob}
                jobData={this.state.sendData}
                companyList={this.props.companyList}
                companylist_num={this.state.companylist_num}
                position={this.state.position}
                getCompanyInfoId={this.getCompanyInfoId}
                getCompanyInfoName={this.getCompanyInfoName}
                getCompanyInfoAddress={this.getCompanyInfoAddress}
                getCompanyInfoPresident={this.getCompanyInfoPresident}
                getCompanyInfoTel={this.getCompanyInfoTel}
                getCompanyInfoFax={this.getCompanyInfoFax}
                getCompanyInfoWeb={this.getCompanyInfoWeb}
                getCompanyInfoEmployeeRegular={this.getCompanyInfoEmployeeRegular}
                getCompanyInfoEmployeePartTime={this.getCompanyInfoEmployeePartTime}
                getCompanyInfoFacilityName={this.getCompanyInfoFacilityName}
                getCompanyInfoFacilityAddress={this.getCompanyInfoFacilityAddress}
                getCompanyInfoPic={this.getCompanyInfoPic}
                getCompanyInfoPosition={this.getCompanyInfoPosition}
                getCompanyInfoPicTel={this.getCompanyInfoPicTel}
                getCompanyInfoMail={this.getCompanyInfoMail}
                changeDisp={this.switchDisplay}
                setPrePlace={this.setPrePlace}
                prePlace={this.state.pre_place}
                preFacility={this.state.pre_facility}
                setPreFacility={this.setPreFacility}
                setCompanyListNum={this.setCompanyListNum}
                saveTemporaly={this.saveTemporaly}
                saveReset={this.saveStateErrorDisable}
                errorMessage={this.state.errorMessage}
            />;
        } else if (this.state.display === "edit_job_3") {
            displayParts = <EditJob3
                resetState={this.setInitialState}
                jobData={this.state.sendData}
                getSex={this.getSex}
                getCountry={this.getCountry}
                getOccupation={this.getOccupation}
                getContent={this.getContent}
                getWorks={this.getWorks}
                getWorkingDay={this.getWorkingDay}
                getWorkingOther={this.getWorkingOther}
                getIsFixedDay={this.getIsFixedDay}
                getIsDecideShift={this.getIsDecideShift}
                getHasOther={this.getHasOther}
                changeDisp={this.switchDisplay}
                saveTemporaly={this.saveTemporaly}
                saveReset={this.saveStateErrorDisable}
                errorMessage={this.state.errorMessage}
            />;
        } else if (this.state.display === "edit_job_4") {
            displayParts = <EditJob4
                resetState={this.setInitialState}
                jobData={this.state.sendData}
                companyList={this.props.companyList}
                companylist_num={this.state.companylist_num}
                getHoliday={this.getHoliday}
                getTotalHolidays={this.getTotalHoliday}
                getPaidHoliday={this.getPaidHoliday}
                getOtherHoliday={this.getOtherHoliDays}
                getOther={this.getOther}
                getRent={this.getRent}
                getBoard={this.getBoard}
                getAllowance={this.getAllowance}
                getType={this.getType}
                getLivelihoodSupportSalary={this.getLivelihoodSupportSalary}
                getLivelihoodSupportDeduction={this.getLivelihoodSupportDeduction}
                getManagementHotelsIsService={this.getManagementHotelsIsService}
                getManagementHotelsCatalogPrice={this.getManagementHotelsCatalogPrice}
                getManagementHotelsPublishPrice={this.getManagementHotelsPublishPrice}
                getManagementMealsIsService={this.getManagementMealsIsService}
                getManagementMealsCatalogPrice={this.getManagementMealsCatalogPrice}
                getManagementMealsPublishPrice={this.getManagementMealsPublishPrice}
                changeDisp={this.switchDisplay}
                saveTemporaly={this.saveTemporaly}
                saveReset={this.saveStateErrorDisable}
                errorMessage={this.state.errorMessage}
            />;
        } else if (this.state.display === "edit_job_5") {
            displayParts = <EditJob5
                resetState={this.setInitialState}
                jobData={this.state.sendData}
                getFacilityIntroductionComment={this.getFacilityIntroductionComment}
                getFacilityIntroductionImage={this.getFacilityIntroductionImage}
                changeDisp={this.switchDisplay}
                saveTemporaly={this.saveTemporaly}
                saveReset={this.saveStateErrorDisable}
                errorMessage={this.state.errorMessage}
            />;
        } else if (this.state.display === "edit_job_6") {
            displayParts = <EditJob6
                resetState={this.setInitialState}
                jobData={this.state.sendData}
                getNearbyInfoComment={this.getNearbyInfoComment}
                getNearbyInfoImage={this.getNearbyInfoImage}
                changeDisp={this.switchDisplay}
                saveTemporaly={this.saveTemporaly}
                saveReset={this.saveStateErrorDisable}
                errorMessage={this.state.errorMessage}
            />;
        } else if (this.state.display === "edit_job_7") {
            displayParts = <EditJob7
                resetState={this.setInitialState}
                jobData={this.state.sendData}
                errorMessage={this.state.errorMessage}
                changeDisp={this.switchDisplay}
                saveJobDetail={this.saveJobDetail}
                getInsuranceStatus = {this.getInsuranceStatus}
                saveTemporaly={this.saveTemporaly}
                saveReset={this.saveStateErrorDisable}
                ref="child"
            />;
        } else if (this.state.display === "job_detail") {
            displayParts = <JobDetail 
                jobId={this.state.jobId}
                organizationId={this.state.organizationId}
                user_type={this.state.userType}
                changeDisp={this.switchDisplay}
                changeDispWithData={this.switchDisplayWithJobData}
                applicantIdList={[]}
                setIsEdit={() => {}}
            />
        }
        
        if (this.state.display === "top") {
            return(
                displayParts
            )
        } else {
            return (
                <div>
                    <Header />
                    <article className="contentsArea">
                        <div id="logoutBtnWrapper">
                            <Button label="ログアウト" buttonEnable={true} callback={() => this.logoutAction()}/>
                        </div>
                        {displayParts}
                    </article>
                    <Footer />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    if (!state.postJobDetailData.isSaveAction) {
        console.log(state.getManageCompanyInformation.companyData);
        if (Object.keys(state.getManageCompanyInformation.companyData).length !== 0) {
            console.log(state.getManageCompanyInformation.companyData);
            return {
                companyList: state.getManageCompanyInformation.companyData,
                initialData: JSON.parse(JSON.stringify(initial_manage_send_data)),
                isCompanyDataError: state.getCompanyInformation.isError,
                companyDataError: state.getCompanyInformation.errorMessage
            }
        } else {
            return {
                initialData: JSON.parse(JSON.stringify(initial_manage_send_data)),
                companyList: [],
                isCompanyDataError: state.getManageCompanyInformation.isError,
                companyDataError: state.getCompanyInformation.errorMessage
            }
        }
    } else {
        if (Object.keys(state.getManageCompanyInformation.companyData).length !== 0) {
            return {
                companyList: state.getManageCompanyInformation.companyData
            }
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveJobPostingDetail(saveData) {
            dispatch(saveJobPostingDetail(saveData))
        },
        getCompanyData() {
            dispatch(getCompayInformation())
        },
        resetCompanyInfo() {
            dispatch(getCompanyInformationReset())
        },
        resetSaveState() {
            dispatch(saveJobDetailReset())
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageHomeTemplate));

