import {
    GET_STUDENT_LIST_REQUEST,
    GET_STUDENT_LIST_SUCCESS,
    GET_STUDENT_LIST_FAILURE,
    GET_STUDENT_LIST_RESET
} from "../../actions/univ/GetStudentAction";

import {initial_student_data} from "../../constants/CommonConstatns";

const listState = {
    isFetching: false,
    studentListData: [],
    isGetInfo: false,
    isError: false,
    errorMessage: ""
}

export const getStudentList = (state = listState, action) => {
    switch(action.type) {
        case GET_STUDENT_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                studentListData: [],
                isGetInfo: false,
                isError: false,
                errorMessage: ""
            });
        case GET_STUDENT_LIST_SUCCESS:
            let responseData = null;
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [JSON.parse(JSON.stringify(initial_student_data))];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [JSON.parse(JSON.stringify(initial_student_data))];
                isError = true;
            }else{
                if (action.data.length < 1) {
                    responseData = [JSON.parse(JSON.stringify(initial_student_data))]
                } else {
                    responseData = action.data;
                }
                errorMessage = "";
            }
            
            return Object.assign({}, state, {
                isFetching: false,
                studentListData: responseData,
                isGetInfo: true,
                isError: isError,
                errorMessage: errorMessage
            });
        case GET_STUDENT_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                studentListData: [JSON.parse(JSON.stringify(initial_student_data))],
                isGetInfo: true,
                isError: true,
                errorMessage: action.err
            });
        case GET_STUDENT_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                studentListData: [JSON.parse(JSON.stringify(initial_student_data))],
                isGetInfo: true,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}