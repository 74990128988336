import {
    SAVE_UNIV_OVERVIEW_REQUEST,
    SAVE_UNIV_OVERVIEW_SUCCESS,
    SAVE_UNIV_OVERVIEW_FAILURE,
    SAVE_UNIV_OVERVIEW_RESET
} from "../../actions/univ/SaveUnivInternAction"

const saveActionState = {
    isFetching: false,
    saveState: "",
    saveInfo: "",
    isError: false,
    errorMessage: "",
    internId: ""
}

 // SAVE UNIV ACTION REDUCER
 export const saveUnivAction = (state = saveActionState, action) => {
    switch(action.type) {
        case SAVE_UNIV_OVERVIEW_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveState: "",
                saveInfo: "REQUEST",
                isError: false,
                errorMessage: "",
                internId: action.internId
            });
        case SAVE_UNIV_OVERVIEW_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            let saveInfo = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
                saveInfo = "ERROR"
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
                saveInfo = "ERROR"
            }else{
                responseData = action.data;
                errorMessage = "";
                saveInfo = "SUCCESS"
            }
            return Object.assign({}, state, {
                isFetching: false,
                saveState: responseData,
                saveInfo: saveInfo,
                isError: isError,
                errorMessage: errorMessage,
                internId: action.internId
            });
        case SAVE_UNIV_OVERVIEW_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: action.err,
                saveInfo: "ERROR",
                isError: true,
                errorMessage: action.err,
                internId: ""
            });
        case SAVE_UNIV_OVERVIEW_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                saveInfo: "",
                isError: false,
                errorMessage: "",
                internId: ""
            });
        default:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                saveInfo: "",
                isError: false,
                errorMessage: "",
                internId: ""
            });
    }
}