import {
    SAVE_CANDIDATE_STATUS_REQUEST,
    SAVE_CANDIDATE_STATUS_SUCCESS,
    SAVE_CANDIDATE_STATUS_FAILURE,
    SAVE_CANDIDATE_STATUS_RESET
} from "../../actions/hotel/SaveCandidateAction"

const saveActionState = {
    isFetching: false,
    saveState: "",
    isError: false,
    errorMessage: ""
}

 // SAVE CANDIDATE STATUS REDUCER
 export const saveCandidateAction = (state = saveActionState, action) => {
    switch(action.type) {
        case SAVE_CANDIDATE_STATUS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveState: "REQUEST",
                isError: false,
                errorMessage: ""
            });
        case SAVE_CANDIDATE_STATUS_SUCCESS:
            console.log(action)
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = "";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = "";
                isError = true;
            }else{
                responseData = "SUCCESS: " + action.data
                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                saveState: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case SAVE_CANDIDATE_STATUS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: true,
                errorMessage: action.err
            });
        case SAVE_CANDIDATE_STATUS_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}