import {
    POST_APPLICANT_STATUS_REQUEST,
    POST_APPLICANT_STATUS_SUCCESS,
    POST_APPLICANT_STATUS_FAILURE,
    POST_APPLICANT_STATUS_RESET
} from "../../actions/posting/PostApplicantStatusAction"

const resuponseState = {
    isFetching: false,
    responseData: 0,
    isError: false,
    errorMessage: ""
}

export const postingStatusResult = (state = resuponseState, action) => {
    switch(action.type) {
        case POST_APPLICANT_STATUS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                responseData: "",
                isError: false,
                errorMessage: ""
            });
        case POST_APPLICANT_STATUS_SUCCESS:
            let responseData = action.data;
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                isError = true;
            }

            return Object.assign({}, state, {
                isFetching: false,
                responseData: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case POST_APPLICANT_STATUS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                responseData: "",
                isError: true,
                errorMessage: action.err
            });
        case POST_APPLICANT_STATUS_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                responseData: 0,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}
