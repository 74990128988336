import {
    SAVE_STUDENT_DATA_REQUEST,
    SAVE_STUDENT_DATA_SUCCESS,
    SAVE_STUDENT_DATA_FAILURE,
    SAVE_STUDENT_DATA_RESET,
    DELETE_STUDENT_DATA_REQUEST,
    DELETE_STUDENT_DATA_SUCCESS,
    DELETE_STUDENT_DATA_FAILURE
} from "../../actions/univ/ControllStudentAction"

const saveState = {
    isFetching: false,
    saveInfo: "",
    isError: false,
    errorMessage: ""
}

const deleteState = {
    isFetching: false,
    deleteInfo: "",
}

export const saveStudentData = (state = saveState, action) => {
    switch(action.type) {
        case SAVE_STUDENT_DATA_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveInfo: "REQUEST",
                isError: false,
                errorMessage: ""
            });
        case SAVE_STUDENT_DATA_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = "ERROR";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = "ERROR";
                isError = true;
            }else{
                responseData = "SUCCESS: " + action.data;
                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                saveInfo: responseData,
                isError: isError,
                errorMessage: errorMessage
            });
        case SAVE_STUDENT_DATA_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                saveInfo: "ERROR",
                isError: true,
                errorMessage: action.err
            });
        case SAVE_STUDENT_DATA_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                saveInfo: "",
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}

export const deleteStudentData = (state = deleteState, action) => {
    switch(action.type) {
        case DELETE_STUDENT_DATA_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                deleteInfo: "DELETE"
            });
        case DELETE_STUDENT_DATA_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                deleteInfo: "SUCCESS: " + action.data
            });
        case DELETE_STUDENT_DATA_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                deleteInfo: action.err
            });
        default:
            return state;
    }
}
