import React from "react";
import "../../css/NewTopPage.css";
import moment from "moment";
import serviceLogo from "../../images/service_logo.png";
import icon_menu_open from "../../images/icon_menu_open.svg";
import icon_menu_close from "../../images/icon_menu_close.svg";
import icon_home from "../../images/icon_home.svg";
import icon_organization from "../../images/icon_organization.svg";
import icon_evaluation from "../../images/icon_evaluation.svg";
import icon_matching from "../../images/icon_matching.svg";
import icon_formalities from "../../images/icon_formalities.svg";
import icon_insurance from "../../images/icon_insurance.svg";
import icon_workshop from "../../images/icon_workshop.svg";
import icon_attendance from "../../images/icon_attendance.svg";
import icon_wages from "../../images/icon_wages.svg";
import icon_education from "../../images/icon_education.svg";
import icon_help from "../../images/icon_help.svg";
import icon_logout from "../../images/icon_logout.svg";
import icon_info from "../../images/icon_info.svg";
import icon_todo from "../../images/icon_todo.svg";

class Top extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            is_menu_open: true,
            menu_class: "ly_cont_side is_active_sp",
            current_time: moment().format("YYYY/MM/DD HH:mm:ss"),
            toggle_class: [
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item"
            ],
            contents_class: "ly_cont_main"
        }
    }

    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        return this.props.changeDisp(changeTo);   
    }

    logout_action = () => {
        this.props.logout_action()
    }

    menuAction = () => {
        if (this.state.is_menu_open) {
            this.setState({
                is_menu_open: false,
                menu_class: "ly_cont_side is_active_pc",
                contents_class: "ly_cont_main is_wide"
            });
        } else {
            this.setState({
                is_menu_open: true,
                menu_class: "ly_cont_side is_active_sp",
                contents_class: "ly_cont_main"
            });
        }
    }
    
    showLicensePage = () => {
        window.open(window.location.origin + "/license/index.html", "_blank");
    }

    toggleClassAction = (index) => {
        const changeState = this.state.toggle_class.slice();

        changeState[index] = changeState[index].indexOf("is_active") == -1 ? "bl_gnav_item js_gnavBtn is_active": "bl_gnav_item";

        this.setState({
            toggle_class: changeState
        });
    }

    render() {
        return (
            <div id="html">
                <div id="body">
                    <header class="ly_header">
                    <p class="el_headerLogo">
                        <img src={serviceLogo} alt="service Logo" class="el_headerLogo_img" />
                    </p>
                    <ul class="el_headerUserInfo">
                        <li>{this.state.current_time}</li>
                        <li>{this.props.loginId}</li>
                    </ul>
                    </header>
                    <div class="bl_sideOpenBtn_wrapper">
                    <button class="bl_sideOpenBtn js_sideOpenBtn" onClick={this.menuAction}>
                        <img src={icon_menu_open} alt="メニューを閉じる" class="bl_sideOpenBtn_img" />
                    </button>
                    </div>
                    <div class="ly_cont">
                    <nav class={this.state.menu_class} id="glonav">
                        <div class="bl_sideCloseBtn_wrapper">
                        <button class="bl_sideCloseBtn js_sideCloseBtn" onClick={this.menuAction}>
                            <img src={icon_menu_close} alt="メニューを閉じる" class="bl_sideCloseBtn_img" />
                        </button>
                        </div>
                        <ul class="bl_gnav">
                        <li class="bl_gnav_item">
                            <a class="bl_gnav_link">
                            <span class="bl_gnav_icon"><img src={icon_home} alt="" class="bl_gnav_img"/></span>
                            <span>ホーム画面へ</span>
                            </a>
                        </li>
                        <li class={this.state.toggle_class[0]}>
                            <span class="bl_gnav_btn js_gnavBtn"　onClick={() => this.toggleClassAction(0)}>
                            <span class="bl_gnav_icon"><img src={icon_organization} alt="" class="bl_gnav_img"/></span>
                            <span>ユーザ組織管理</span>
                            </span>
                            <ul class="bl_gnavChildNav">
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">組織管理</a>
                            </li>
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">ユーザ管理</a>
                            </li>
                            </ul>
                        </li>
                        <li class={this.state.toggle_class[1]}>
                            <span class="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(1)}>
                            <span class="bl_gnav_icon"><img src={icon_matching} alt="" class="bl_gnav_img"/></span>
                            <span>募集支援<small class="bl_gnav_small">（マッチング）</small></span>
                            </span>
                            <ul class="bl_gnavChildNav">
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link" onClick={() => this.handleToDisplay("show_job")}>案件管理</a>
                            </li>
                            </ul>
                        </li>
                        <li class={this.state.toggle_class[2]}>
                            <span class="bl_gnav_btn js_gnavBtn"　onClick={() => this.toggleClassAction(2)}>
                            <span class="bl_gnav_icon"><img src={icon_evaluation} alt="" class="bl_gnav_img"/></span>
                            <span>評価設定</span>
                            </span>
                            <ul class="bl_gnavChildNav">
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">評価アンケート</a>
                            </li>
                            </ul>
                        </li>
                        <li class={this.state.toggle_class[3]}>
                            <span class="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(3)}>
                            <span class="bl_gnav_icon"><img src={icon_formalities} alt="" class="bl_gnav_img"/></span>
                            <span>入国手続き支援</span>
                            </span>
                            <ul class="bl_gnavChildNav">
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">入国手続き支援</a>
                            </li>
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">入国手続き進捗</a>
                            </li>
                            </ul>
                        </li>
                        <li class={this.state.toggle_class[4]}>
                            <span class="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(4)}>
                            <span class="bl_gnav_icon"><img src={icon_workshop} alt="" class="bl_gnav_img"/></span>
                            <span>実習支援</span>
                            </span>
                            <ul class="bl_gnavChildNav">
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">日報管理</a>
                            </li>
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">実習管理</a>
                            </li>
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">実習完了管理</a>
                            </li>
                            </ul>
                        </li>
                        <li class={this.state.toggle_class[5]}>
                            <span class="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(5)}>
                            <span class="bl_gnav_icon"><img src={icon_attendance} alt="" class="bl_gnav_img"/></span>
                            <span>勤怠管理</span>
                            </span>
                            <ul class="bl_gnavChildNav">
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link">勤怠承認</a>
                            </li>
                            </ul>
                        </li>
                        <li class={this.state.toggle_class[6]}>
                            <span class="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(6)}>
                            <span class="bl_gnav_icon"><img src={icon_education} alt="" class="bl_gnav_img"/></span>
                            <span>教育</span>
                            </span>
                            <ul class="bl_gnavChildNav">
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link" onClick={() => this.handleToDisplay("outline")}>履修履歴管理</a>
                            </li>
                            <li class="bl_gnavChildNav_item">
                                <a class="bl_gnavChildNav_link" onClick={() => this.handleToDisplay("search")}>資格管理</a>
                            </li>
                            </ul>
                        </li>
                        </ul>
                        <ul class="bl_memberNav">
                        <li class="bl_memberNav_item">
                            <a class="bl_memberNav_link">
                            <span class="bl_memberNav_icon"><img src={icon_help} alt="" class="bl_memberNav_img"/></span>
                            <span>ヘルプ</span>
                            </a>
                        </li>
                        <li class="bl_memberNav_item">
                            <a class="bl_memberNav_link" onClick={this.logout_action}>
                            <span class="bl_memberNav_icon"><img src={icon_logout} alt="" class="bl_memberNav_img"/></span>
                            <span>ログアウト</span>
                            </a>
                        </li>
                        </ul>
                    </nav>
                        <main class={this.state.contents_class} id="toppage">
                            <div class="ly_mainCont">
                            <div class="un_topMainCont">
                                <section class="bl_info">
                                <div class="bl_info_header">
                                    <h1 class="bl_info_ttl">
                                    <span><img src={icon_info} alt="" /></span>
                                    <span>お知らせ</span>
                                    </h1>
                                    <a class="el_smBtn el_smBtn__inline">すべて見る</a>
                                </div>
                                <div class="bl_info_main">
                                    <ul class="bl_vertPosts">
                                    <li class="bl_vertPosts_item">
                                        <date class="bl_vertPosts_date">2020/06/23</date>
                                        <p class="bl_vertPosts_ttl">
                                        <a class="bl_vertPosts_link">システムメンテナンスがあります</a>
                                        </p>
                                    </li>
                                    
                                    </ul>
                                </div>
                                </section>
                                <section class="bl_info">
                                <div class="bl_info_header">
                                    <h1 class="bl_info_ttl">
                                    <span><img src={icon_todo} alt="" /></span>
                                    <span>TODO リスト</span>
                                    </h1>
                                    <a class="el_smBtn el_smBtn__inline" onClick={() => this.handleToDisplay("show_job")}>すべて見る</a>
                                </div>
                                <div class="bl_info_main">
                                    <ul class="bl_vertPosts">
                                    <li class="bl_vertPosts_item">
                                        <date class="bl_vertPosts_date">2020/06/23</date>
                                        <p class="bl_vertPosts_ttl">
                                        <a class="bl_vertPosts_link" onClick={() => this.handleToDisplay("show_job")}>エントリーを確認してください</a>
                                        </p>
                                    </li>
                                    </ul>
                                </div>
                                </section>
                                <section id="bl_topOther">
                                    <div id="bl_topOtherInner">
                                        <ul>
                                            <li><a onClick={this.showLicensePage}>オープンソースライセンス</a></li>
                                        </ul>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
        )
    }
}

export default Top;

