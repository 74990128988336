import axios from "axios";
import {BASE_URL} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {UNIV_OVERVIEW_TEST, UNIV_DETAIL_TEST} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET UNIV OVERVIEW
export const GET_UNIV_ACTION_OVERVIEW_REQUEST = "GET_UNIV_ACTION_OVERVIEW_REQUEST";
export const GET_UNIV_ACTION_OVERVIEW_SUCCESS = "GET_UNIV_ACTION_OVERVIEW_SUCCESS";
export const GET_UNIV_ACTION_OVERVIEW_FAILURE = "GET_UNIV_ACTION_OVERVIEW_FAILURE";

export const universityOverviewRequest = () => {
    return {
        type: GET_UNIV_ACTION_OVERVIEW_REQUEST
    }
}

export const universityOverviewSuccess = (data) => {
    return {
        type: GET_UNIV_ACTION_OVERVIEW_SUCCESS,
        data
    }
}

export const universityOverviewFailure = (err) => {
    return {
        type: GET_UNIV_ACTION_OVERVIEW_FAILURE,
        err
    }
}

const GET_UNIV_OVERVIEW = BASE_URL + "/api/skt_university_overview";

export const getUniversityOverview = () => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(universityOverviewRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(universityOverviewFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(universityOverviewFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(universityOverviewFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(universityOverviewFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(universityOverviewFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const query = {
                            };
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_UNIV_OVERVIEW, query, {headers: headerParam}).then(
                                res => dispatch(universityOverviewSuccess(res.data))
                            ).catch(
                                err => dispatch(universityOverviewFailure(err))
                                //res => dispatch(universityOverviewSuccess(UNIV_OVERVIEW_TEST))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(universityOverviewFailure(err))
            }
        )
    }
}

// GET UNIV DETAIL
export const GET_UNIV_ACTION_DETAIL_REQUEST = "GET_UNIV_ACTION_DETAIL_REQUEST";
export const GET_UNIV_ACTION_DETAIL_SUCCESS = "GET_UNIV_ACTION_DETAIL_SUCCESS";
export const GET_UNIV_ACTION_DETAIL_FAILURE = "GET_UNIV_ACTION_DETAIL_FAILURE";
export const U_UNIV_DETAIL_RESET = "U_UNIV_DETAIL_RESET";

export const universityDetailRequest = () => {
    return {
        type: GET_UNIV_ACTION_DETAIL_REQUEST
    }
}

export const universityDetailSuccess = (data, user_cd) => {
    return {
        type: GET_UNIV_ACTION_DETAIL_SUCCESS,
        data,
        user_cd
    }
}

export const universityDetailFailure = (err, user_cd) => {
    return {
        type: GET_UNIV_ACTION_DETAIL_FAILURE,
        err,
        user_cd
    }
}

export const universityDetailReset = () => {
    return {
        type: U_UNIV_DETAIL_RESET
    }
}

const GET_UNIV_DETAIL = BASE_URL + "/api/university_get_detail";

export const getUniversityProfile = (userCd) => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(universityDetailRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(universityDetailFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(universityDetailFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(universityDetailFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(universityOverviewFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(universityDetailFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const query = {
                            };
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_UNIV_DETAIL, query, {headers: headerParam}).then(
                                res => dispatch(universityDetailSuccess(res.data, userCd))
                            ).catch(
                                err => dispatch(universityDetailFailure(err, userCd))
                                //res => dispatch(universityDetailSuccess(UNIV_DETAIL_TEST, userCd))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(universityDetailFailure(err, userCd))
            }
        )
    }
}

export const resetUniversityDetail = () => {
    return (dispatch) => dispatch(universityDetailReset());
}
