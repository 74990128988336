import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import TextInput from "../atoms/TextInput";
import Textarea from "../atoms/Textarea";
import {ErrorMap} from "../../constants/CommonConstatns";
import "../../css/EditIntern.css"

class EditIntern3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sex: null,
            country: [],
            occupation: "",
            content: "",
            free_text: "",
            error_message: []
        }
        this.getSex = this.getSex.bind(this);
        this.getCountry = this.getCountry.bind(this);
        this.getOccupation = this.getOccupation.bind(this);
        this.getContent = this.getContent.bind(this);
        this.getFreeText = this.getFreeText.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            sex: this.props.internData.sex,
            country: this.props.internData.country,
            occupation: this.props.internData.occupation,
            content: this.props.internData.content,
            free_text: this.props.internData.free_text,
            error_message: this.props.errorMessage,

        });
        window.scrollTo(0, 0);
    }

    temporalySave = () => {
        this.props.saveTemporaly();
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    getSex(sex) {
        this.setState({
            sex: parseInt(sex.target.value)
        });
        this.props.getSex(parseInt(sex.target.value))
    }

    getCountry = index => event => {
        this.state.country[index] = event.target.checked;
        this.setState({
            country: this.state.country
        });
        this.props.getCountry(this.state.country)
    };

    getOccupation = (occupation) => {
        this.setState({
            occupation: occupation
        });
        this.props.getOccupation(occupation)
    }

    getContent = (content) => {
        this.setState({
            content: content
        });
        this.props.getContent(content)
    }

    getFreeText = (freeText) => {
        this.setState({
            free_text: freeText
        });
        this.props.getFreeText(freeText)
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        var sexStates = [
            { code: 0, name: "男性" },
            { code: 1, name: "女性" },
            { code: 2, name: "限定無し" } ];
        var sexOptions = sexStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_intern" setError={this.props.saveMessage} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("outline", true)}>インターンの概要</a></li>
                        <li className="navigationItem active">インターン管理</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_intern", true)}>インターン管理</a></li>
                        <li className="breadcrumbItem">インターン新規登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern", false)}>募集時期</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_2", false)}>会社概要</a></li>
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern3">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <div className="editInternInputItem">
                                <label htmlFor="sex">性別<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <div className="selectWrapper editInternInputSelect">
                                        <select id="sex" name="sex" required value={this.state.sex} onChange={this.getSex}>
                                            {sexOptions}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="country">国籍<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea checkboxGroup">
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[1]} onChange={this.getCountry(1)}/>
                                        <span className="checkboxText">ベトナム</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[2]} onChange={this.getCountry(2)}/>
                                        <span className="checkboxText">台湾</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[3]} onChange={this.getCountry(3)}/>
                                        <span className="checkboxText">インドネシア</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[4]} onChange={this.getCountry(4)}/>
                                        <span className="checkboxText">ミャンマー</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[5]} onChange={this.getCountry(5)}/>
                                        <span className="checkboxText">シンガポール</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[6]} onChange={this.getCountry(6)}/>
                                        <span className="checkboxText">マレーシア</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[7]} onChange={this.getCountry(7)}/>
                                        <span className="checkboxText">中国</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="country" checked={this.state.country[8]} onChange={this.getCountry(8)}/>
                                        <span className="checkboxText">韓国</span>
                                    </label>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="occupation">学習職種<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="occupation" name="occupation" type="text" maxLength="499" required={true} setValue={this.state.occupation} onHandleChange={(e) => this.getOccupation(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="content">学習内容<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <Textarea id="content" name="content" required={true} maxLength="499" setValue={this.state.content} onHandleChange={(e) => this.getContent(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="freeText">自由記入</label>
                                <div className="editInternInputInputArea">
                                    <Textarea id="freeText" name="freeText" required={false} maxLength="499" setValue={this.state.free_text} onHandleChange={(e) => this.getFreeText(e)}/>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave("edit_intern_3", false)}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_intern_4", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postDetailData.isFetching,
        isError: state.postDetailData.isError,
        saveMessage: state.postDetailData.errorMessage
    }
};

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(EditIntern3);

