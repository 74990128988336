import React from "react";
import Exclamation from "../../images/error.png"
import "../../css/Error.css"
const moment = require("moment");


export default function Error(props) {
    const time = "エラー発生時間：" + moment().format("YYYY/MM/DD HH:mm:ss")
    const closeWindow = () => {
        props.close();
    }
    return(
        <div className="errorWrapper" onClick={closeWindow}>
            <div className="e_wrapper">
                <img src={Exclamation} />
                <p className="alertLabel">エラーが発生しました</p>
                <div className="apiErrorArea">
                    <p>{time}</p>
                    <p>{"API名：" + props.apiName}</p>
                    <p>{"エラー：" + props.setError}</p>
                    <p className="support">※この画面を閉じる場合は、画面をクリックしてください。</p>
                </div>
            </div>
        </div>
    )
}
