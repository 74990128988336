import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from "redux";
import logger from "redux-logger";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import './css/normalize.css'
import './css/Common.css'
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import rootReducer from "./reducers/rootReducer";
import {setBaseUrl} from "./constants/CommonConstatns";

// BASE_URLの取得
//setBaseUrl(window.location.origin);

const store = createStore(
    rootReducer,
    applyMiddleware(thunk, logger)
)

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
