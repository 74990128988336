import React from "react";
import "../../css/MainPage.css"
import "../../css/PostingShowApplicant.css"
import "../../css/Common.css"
import {StatusMap, SexMap, SexFilterMap, AssessmentStarMap} from "../../constants/ApplicantPostingConstants"
import TextInput from "../atoms/TextInput";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import {connect} from "react-redux";
import {getPostingApplicant, getPostingApplicantReset} from "../../actions/posting/GetPostingApplicantAction";


class ShowApplicant extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            overViewData: [],
            statusStates: 6,
            displayStatus: 6,
            qualification: [false, false, false],
            filter_list: [false, false, false, false, false, false],
            status_list: [false, false, false, false, false, false],
            select_list: [],
            init_flug: true,
            status_init_flug: true,
            employment_start: "",
            employment_end: "",
            facility_name: "",
            name: "",
            sex: 0,
            displayfacility_name: "",
            displayname: "",
            displays: 0,
            displayqualification: [false, false, false]
        }
    }

    componentDidMount = () => {
        this.props.getApplicant();
        this.props.setApplicantList([]);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.overViewData !== nextProps.overViewData) {
            const selectArray = [];
            for (const d of nextProps.overViewData) {
                selectArray.push(false);
            }
            return {
                overViewData: nextProps.overViewData,
                select_list: selectArray.slice()
            };
        }
    }

    handleToDisplay = (changeTo) => {
        this.props.setIsEdit(false);
        return this.props.changeDisp(changeTo);
    }

    sendApplicantList = () => {
        const sendList = [];
        this.state.select_list.forEach((isCheck, index) => {
            if (isCheck) {
                sendList.push(this.state.overViewData[index]);
            }
        })

        this.props.setApplicantList(sendList);
        this.handleToDisplay("job_detail")
    }

    internErrorDisable = () => {
        this.props.overViewReset();
    }

    getSex = (event) => {
        let s = parseInt(event.target.value)
        this.setState({
            sex: s
        });
    }

    setFilter = () => {
        this.setState({
            displays: this.state.sex,
            displayname: this.state.name,
            displayqualification: this.state.qualification.concat(),
            status_list: this.state.filter_list.concat(),
            init_flug: this.state.qualification.indexOf(true) === -1,
            status_init_flug: this.state.filter_list.indexOf(true) === -1,
        });
        this.render();
    }

    getQualification = index => event => {
        const new_state = this.state.qualification.slice();
        new_state[index] = event.target.checked;
        this.setState({
            qualification: new_state
        });
    };

    getStatusFilter = index => event => {
        const new_state = this.state.filter_list.slice();
        new_state[index] = event.target.checked;
        this.setState({
            filter_list: new_state,
        });
    };

    getSelectedApplicant = index => event => {
        const new_state = this.state.select_list.slice();
        new_state[index] = event.target.checked;
        console.log(new_state)
        this.setState({
            select_list: new_state
        }, () => {
            console.log(this.state.select_list)
        });
    }

    getName = (event) => {
        this.setState({
            name: event
        });
    }

    render() {

        var sexOptions = Object.entries(SexFilterMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {value}
                </option>
            )
        );

        return (
            <article id="showInternArticle">
                {this.props.showLoadIcon ? <Load setLabel="読み込み中..."/>: null}
                {this.props.isPostingError ? <Error close={this.internErrorDisable} apiName="get_all_applicant" setError={this.props.internError} /> : null}
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                        <li className="breadcrumbItem">応募者管理</li>
                    </ol>
                </div>
                <section className="searchRefineArea">
                    <h2 className="searchRefineHeading">応募者絞り込み項目</h2>
                    <div className="searchRefineContents">
                        <div className="searchRefineItemIntern postingSearch">
                            <div className="flexDiv">
                                <div className="editInternInputItem">
                                    <label htmlFor="company">氏名（ローマ字）</label>
                                    <div className="searchRefineItemSelect">
                                        <TextInput id="company" name="company" type="text" required={false} maxLength="300" setValue={this.state.name} onHandleChange={(e) => this.getName(e)}/>
                                    </div>
                                </div>
                                <div className="editInternInputItem">
                                    <label htmlFor="term">性別</label>
                                    <div className="selectWrapper searchRefineItemSelect">
                                        <select id="term" name="term" value={this.state.sex} onChange={this.getSex}>
                                            {sexOptions}
                                       </select>
                                    </div>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="qualification">資格</label>
                                <div className="editInternInputInputArea checkboxGroup">
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.qualification[1]} onChange={this.getQualification(1)}/>
                                        <span className="checkboxText">技能実習</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.qualification[2]} onChange={this.getQualification(2)}/>
                                        <span className="checkboxText">特定技能</span>
                                    </label>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="checkboxLabel">ステータス</label>
                                <div className="editInternInputInputArea checkboxGroup">
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.filter_list[0]} onChange={this.getStatusFilter(0)}/>
                                        <span className="checkboxText">応募中</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.filter_list[1]} onChange={this.getStatusFilter(1)}/>
                                        <span className="checkboxText">不採用</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.filter_list[2]} onChange={this.getStatusFilter(2)}/>
                                        <span className="checkboxText">内定・確認待ち</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.filter_list[3]} onChange={this.getStatusFilter(3)}/>
                                        <span className="checkboxText">就労中</span>
                                    </label>
                                    <label className="checkboxLabel">
                                        <input type="checkbox" name="qualification" checked={this.state.filter_list[5]} onChange={this.getStatusFilter(5)}/>
                                        <span className="checkboxText">未応募</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div id="targetSearchBtnWrapper" className="showPostingSearch">
                            <Button label="検索" buttonEnable={true} callback={() => this.setFilter()}/>
                        </div>
                    </div>
                </section>
                <div className="showPostingContents" id="showApplicantContents">
                    {<Button label="新規応募者登録" buttonEnable={false} callback={() => null}/>}
                    <div id="sortbox">
                        <h1>
                            応募者一覧
                        </h1>
                    </div>
                    <table id="applicantTable" border="1" bordercolor="#ffffff">
                        <thead>
                            <tr>
                                <th width="60px">選択</th>
                                <th>氏名（ローマ字）</th>
                                <th>国籍</th>
                                <th>生年月日</th>
                                <th>性別</th>
                                <th>日本語</th>
                                <th>資格</th>
                                <th>評価</th>
                                <th>企業名</th>
                                <th>ステータス</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.overViewData.map((item, index) => {
                                    let enabledCheckbox = "";

                                    if (!this.props.isEdit || item.status != null) {
                                        enabledCheckbox = "disabled"
                                    }
                                    return (                                    
                                        (item.name.includes(this.state.displayname) || this.state.displayname === "")&&
                                        (item.sex === this.state.displays - 1 || this.state.displays === 0)&&
                                        (this.state.displayqualification[item.qualification_type] === true || (this.state.init_flug === true)) &&
                                        (this.state.status_list[item.status === null ? 5 : item.status] === true || (this.state.status_init_flug === true))
                                        ?
                                        <tr key={item.applicant_id}>
                                            <td>
                                                <label className="checkboxLabel">
                                                    <input type="checkbox" checked={this.state.select_list[index]} disabled={enabledCheckbox} onChange={this.getSelectedApplicant(index)}/>
                                                    <span className="tableCheckboxText"></span>
                                                </label>
                                            </td>
                                            <td>{item.name}</td>
                                            <td>{item.country}</td>
                                            <td>{item.birthday}</td>
                                            <td>{SexMap[item.sex]}</td>
                                            <td>{item.qualification_japanese}</td>
                                            <td>{item.qualification_name}</td>
                                            <td>{AssessmentStarMap[item.asssessment]}</td>
                                            <td>{item.apply_company}</td>
                                            <td>{item.status === null ? StatusMap[5] : StatusMap[item.status]}</td>
                                        </tr>
                                        : null
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div id="selectApplicantBtnWrapper">
                        <Button label="選択" buttonEnable={this.props.isEdit} callback={() => this.sendApplicantList()}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        overViewData: state.getPostingApplicant.overViewData,
        showLoadIcon: !state.getPostingApplicant.isGetInfo,
        isPostingError: state.getPostingApplicant.isError,
        internError: state.getPostingApplicant.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getApplicant: () => dispatch(getPostingApplicant()),
    overViewReset: () => dispatch(getPostingApplicantReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowApplicant);

