import React from "react";
import "../../../css/Common.css";
import Button from "../../atoms/Button";
import Load from "../../atoms/Load";
import Error from "../../atoms/Error";
import "../../../css/SearchUniversity.css"
import {connect} from "react-redux";
import {HasJpTeacher} from "../../../constants/CommonConstatns";
import {univInternAction, univInternReset} from "../../../actions/hotel/UnivInternAction";
import {getUniversityDetail} from "../../../actions/hotel/GetUnivInforAction";

class UnivDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            actionLog: null,
            detailData: {},
            btnLabel: "",
            isEnable: true,
            status: 9,
            showLoadIcon: false,
            universityId: -1
        }

        this.setInternAction = this.setInternAction.bind(this);
        this.setUiLabels = this.setUiLabels.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            universityId: this.props.universityId
        })
        this.props.getDetailInfo(this.props.universityId);
        this.setUiLabels(this.props.status);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newStatus = 9;
        let btnLabel = "";
        let btnEnable = false;

        if (prevState.universityId === nextProps.universityId_detail) {
            if (Object.keys(prevState.detailData).length < 1) {
                return {
                    detailData: nextProps.detailData,
                    actionLog: nextProps.detailData.action_log
                }
            }
        }

        if (prevState.universityId === nextProps.universityId_log && prevState.actionLog != null) {
            console.log(nextProps.newActionLog)
            if (prevState.actionLog.length !== nextProps.newActionLog.length) {
                if (prevState.status === 9) {
                    btnLabel = "インターンを取り下げる"
                    btnEnable = true;
                    newStatus = 1
                } else if (prevState.status === 1) {
                    btnLabel = "インターンを打診する"
                    btnEnable = true;
                    newStatus = 9
                } 
                else {
                    btnLabel = "インターンを打診する"
                    btnEnable = true;
                    newStatus = 2
                }
                nextProps.setNewStatus(newStatus);
                return {
                    actionLog: nextProps.newActionLog,
                    btnLabel: btnLabel,
                    status: newStatus,
                    btnEnable: btnEnable,
                    showLoadIcon: false
                }
            } else if (prevState.actionLog[0].content === null && nextProps.newActionLog[0].content !== null) {
                nextProps.setNewStatus(1);
                return {
                    actionLog: nextProps.newActionLog,
                    btnLabel: "インターンを取り下げる",
                    status: 1,
                    btnEnable: true,
                    showLoadIcon: false
                }

            } else {
                // エラー時
                return {
                    btnEnable: true,
                    showLoadIcon: false
                }
            }
        }
    }

    setUiLabels = (status) => {
        let label = "";
        let btnEnable = false;
        if (status === 9) {
            label = "インターンを打診する"
            btnEnable = true;
        } else if (status === 1) {
            label = "インターンを取り下げる"
            btnEnable = true;
        } else if (status === 2) {
            label = "インターンを取り下げる"
            btnEnable = true;
        } else {
            label = "インターン打診成功"
            btnEnable = false;
        }

        this.setState({
            status: status,
            isEnable: btnEnable,
            btnLabel: label
        })

    }

    setInternAction = () => {
        const actionFlg = this.state.status === 1 ? 2: 1;
        this.props.setUnivInternAction(this.state.universityId, actionFlg, this.state.actionLog);
        this.setState({
            showLoadIcon: true
        })
    }

    detailReset = () => {
        this.props.detailReset();
    }

    render() {
        if(0 < Object.keys(this.state.detailData).length) {    
            const univDetail = this.state.detailData.university_detail;
            const jpTeacher = HasJpTeacher[univDetail.jpteacher];
            return (
                <div>
                    {this.state.showLoadIcon ? <Load setLabel="処理中..."/>: null}
                    {this.props.isError ? <Error close={this.detailReset} apiName="university_internAction" setError={this.props.errorMessage} /> : null}
                    <div className="searchUniversityResultItemAction">
                        <div className="searchUniversityResultItemLog">
                            <h3 className="searchUniversityResultItemSubHeading">旅館・ホテル側からのアクション</h3>
                            <div className="searchUniversityResultItemLogContents">
                                <ul className="searchUniversityResultItemLogList">
                                    {
                                        this.state.actionLog.map((value, index) => {
                                            return(
                                                <li className="searchUniversityResultItemLogItem" key={index}>
                                                    <time className="searchUniversityResultItemLogDay">{value.date}</time>
                                                    <div className="searchUniversityResultItemLogStatus">{value.content}</div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="searchUniversityActionButton">
                                    <Button label={this.state.btnLabel} buttonEnable={this.state.isEnable} callback={() => this.setInternAction()} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="searchUniversityResultItemInformationArea">
                        <h3 className="searchUniversityResultItemSubHeading">大学情報詳細</h3>
                        <div className="searchUniversityResultItemInformationTable">
                            <table>
                                <tbody>
                                <tr>
                                    <th>学生の人数</th>
                                    <td>{univDetail.people}</td>
                                </tr>
                                <tr>
                                    <th>大学の日本語レベル</th>
                                    <td>{univDetail.japanese}</td>
                                </tr>
                                <tr>
                                    <th>大学の英語レベル</th>
                                    <td>{univDetail.english}</td>
                                </tr>
                                <tr>
                                    <th>設置区分</th>
                                    <td>{univDetail.section}</td>
                                </tr>
                                <tr>
                                    <th>修学期間</th>
                                    <td>{univDetail.period}年間</td>
                                </tr>
                                <tr>
                                    <th>日本語教育専門の先生有無</th>
                                    <td>{jpTeacher}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(null)
        }
    }
}

const mapStateToProps = (state) => {
    console.log(state.univInternAction)
    return {
        detailData: state.getUniversityDetail.univDetail,
        universityId_detail: state.getUniversityDetail.university_id,
        newActionLog: state.univInternAction.univDetail.action_log,
        universityId_log: state.univInternAction.university_id,
        showLoadIcon: !state.univInternAction.getInfo,
        isError: state.univInternAction.isError,
        errorMessage: state.univInternAction.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getDetailInfo: (universityId) => dispatch(getUniversityDetail(universityId)),
    setUnivInternAction: (universityId, actionFlg, actionLog) => dispatch(univInternAction(universityId, actionFlg, actionLog)),
    detailReset: () => dispatch(univInternReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(UnivDetail);