import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {ALL_APPLICANT} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

//GET POSTING APPLICANT
export const POSTING_APPLICANT_REQUEST = "POSTING_APPLICANT_REQUEST";
export const POSTING_APPLICANT_SUCCESS = "POSTING_APPLICANT_SUCCESS";
export const POSTING_APPLICANT_FAILURE = "POSTING_APPLICANT_FAILURE";
export const POSTING_APPLICANT_RESET = "POSTING_APPLICANT_RESET";

export const postingApplicantRequest = () => {
    return {
        type: POSTING_APPLICANT_REQUEST
    }
}

export const postingApplicantSuccess = (data) => {
    return {
        type: POSTING_APPLICANT_SUCCESS,
        data
    }
}

export const postingApplicantFailure = (err) => {
    return {
        type: POSTING_APPLICANT_FAILURE,
        err
    }
}

export const postingApplicantReset = () => {
    return {
        type: POSTING_APPLICANT_RESET
    }
}

const GET_POSTING_OVERVIEW = BASE_URL + "/api/get_all_applicant"

export const getPostingApplicant = () => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(postingApplicantRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(postingApplicantFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(postingApplicantFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(postingApplicantFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(postingApplicantFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(postingApplicantFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_POSTING_OVERVIEW, null, {headers: headerParam}).then(
                                res => dispatch(postingApplicantSuccess(res.data))
                            ).catch(
                                err => dispatch(postingApplicantFailure(err))
                                //res => dispatch(postingApplicantSuccess(ALL_APPLICANT))
                                //res => dispatch(postingApplicantSuccess([]))
                                //res => dispatch(postingApplicantSuccess(400))
                                //res => dispatch(postingApplicantSuccess(500))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(postingApplicantFailure(err))
            }
        )
    }
}

export const getPostingApplicantReset = () => {
    return (dispatch) => dispatch(postingApplicantReset());
}
