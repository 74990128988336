import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow, checkOverFlow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// REACTION REQUEST
export const REACTION_FOR_REQUEST_REQUEST = "REACTION_FOR_REQUEST_REQUEST";
export const REACTION_FOR_REQUEST_SUCCESS = "REACTION_FOR_REQUEST_SUCCESS";
export const REACTION_FOR_REQUEST_FAILURE = "REACTION_FOR_REQUEST_FAILURE";
export const REACTION_FOR_REQUEST_RESET = "REACTION_FOR_REQUEST_RESET";

export const reactionJobPostingRequest = () => {
    return {
        type: REACTION_FOR_REQUEST_REQUEST
    }
}

export const reactionJobPostingSuccess = (data) => {
    return {
        type: REACTION_FOR_REQUEST_SUCCESS,
        data
    }
}

export const reactionJobPostingFailure = (err) => {
    return {
        type: REACTION_FOR_REQUEST_FAILURE,
        err
    }
}

export const reactionJobPostingReset = () => {
    return {
        type: REACTION_FOR_REQUEST_RESET
    }
}

const REACTION_JOB_POSTING = BASE_URL + "/api/response_entry";

export const reactionJobPosting = (jobId, sendOrgId, answer, message) => {
    const idToken = getIdToken();
    let verify = true;
    const sendMessage = message ? message: null;
    const query = {
        job_id: jobId,
        send_org_id: sendOrgId,
        answer: answer,
        message: sendMessage
    }
    return async(dispatch) => {
        dispatch(reactionJobPostingRequest());
        if (!_check_param(query)){
            return dispatch(reactionJobPostingSuccess(400));
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                    var pems = {};
                    var keys = response.data['keys'];
                    for(var i = 0; i < keys.length; i++) {
                        var key_id = keys[i].kid;
                        var modulus = keys[i].n;
                        var exponent = keys[i].e;
                        var key_type = keys[i].kty;
                        var jwk = { kty: key_type, n: modulus, e: exponent};
                        var jtp = jwkToPem(jwk);
                        pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(reactionJobPostingFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        verify = false;
                        return dispatch(reactionJobPostingFailure("audクレームがアプリクライアント ID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt);
                        verify = false;
                        return dispatch(reactionJobPostingFailure("issクレームがユーザープール ID と一致しません"));
                    }

                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(reactionJobPostingFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                verify = false;
                                return dispatch(reactionJobPostingFailure(err));
                            } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                axios.post(REACTION_JOB_POSTING, query, {headers: headerParam}).then(
                                    res => dispatch(reactionJobPostingSuccess(res.data))
                                ).catch(
                                    err => dispatch(reactionJobPostingFailure(err))
                                    //res => dispatch(reactionJobPostingSuccess(200))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(reactionJobPostingFailure(err))
                }
            )
        }
    }
}

export const jobPostingReactinReset = () => {
    return (dispatch) => dispatch(reactionJobPostingReset());
}

const _check_param = (query) => {
    console.log(query)
    let check_ok = true;
    for (const key in query) {
        const target = query[key];
        
        if (!_data_map[key]) {
            return false;
        }

        if (_data_map[key] !== checkType(target)) {
            if (key === "message" && target != null) {
                return false;
            }
        }

        if (key === "job_id") {
            check_ok = checkNumberOverflow(target);
        } else if (key === "send__org_id") {
            check_ok = checkOverFlow(target);
        } else if (key === "answer") {
            check_ok = checkOverFlow(target)
        } else if (key === "message") {
            check_ok = checkOverFlow(target)
        }

        if (!check_ok) {
            return false;
        }
    }
    return check_ok;
}

const _data_map = {
    "job_id": "number",
    "send_org_id": "string",
    "answer": "number",
    "message": "string"
}
