import React from "react";

export default function Button(props) {

    const clickEvent = (e) => {
        props.callback({"value": "onClick"})
    }

    return(
        <input type="button" value={props.label} onClick={clickEvent} disabled={!props.buttonEnable}/>
    )
}