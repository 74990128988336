import React from 'react';
import SktHomeTemplate from "../components/SktHomeTemplate"

function Home() {
  return (
    <section className="App">
        <SktHomeTemplate />
    </section>
  );
}

export default Home;