import {
    SAVE_UNIVERSITY_DATA_REQUEST,
    SAVE_UNIVERSITY_DATA_SUCCESS,
    SAVE_UNIVERSITY_DATA_FAILURE,
    U_UNIV_SAVE_RESET
} from "../../actions/univ/ControllUniversityAction"

const saveState = {
    isFetching: false,
    saveInfo: "",
    isSave: false,
    errorMessage: ""
}

export const saveProfileData = (state = saveState, action) => {
    switch(action.type) {
        case SAVE_UNIVERSITY_DATA_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveInfo: "REQUEST",
                isSave: true,
                errorMessage: ""
            });
        case SAVE_UNIVERSITY_DATA_SUCCESS:
            let responseData = ""
            let saveInfo = ""
            if (action.data === 400) {
                responseData = "400 パラメータが不正です"
                saveInfo = "ERROR"
            } else if (action.data === 500) {
                responseData = "500 予期しないエラーが発生しました"
                saveInfo = "ERROR"
            }else{
                responseData = ""
                saveInfo = "SUCCESS"
            }
            return Object.assign({}, state, {
                isFetching: false,
                saveInfo: saveInfo,
                errorMessage: responseData,
                isSave: false,
            });
        case SAVE_UNIVERSITY_DATA_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.err,
                isSave: false,
                saveInfo: "ERROR"
            });
        case U_UNIV_SAVE_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                saveInfo: "",
                isSave: false,
                errorMessage: ""
            });
        default:
            return Object.assign({}, state, {
                isFetching: false,
                saveInfo: "",
                isSave: false,
                errorMessage: ""
            }); 
    }
}

