import React from "react";
import "../../../css/Common.css";
import Button from "../../atoms/Button";
import Load from "../../atoms/Load";
import Error from "../../atoms/Error";
import "../../../css/Search.css"
import {connect} from "react-redux";
import {univGetUniversityDetail} from "../../../actions/univ/GetUnivInternAction";
import {saveUniversityAction, universitySaveActinReset} from "../../../actions/univ/SaveUnivInternAction";
import {PrefecturesMap, BASE_URL} from "../../../constants/CommonConstatns";

class SearchDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            detailData: {},
            status: 0,
            btnLabel: "応募する",
            isEnable: true,
            internId: -1,
            saveInfo: false
        }
    }

    componentDidMount = () => {
        this.setState({
            internId: this.props.intern_id
        })
        this.props.getDetailInfo(this.props.intern_id);
        this.props.setNewStatus(this.state.status);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.saveInfo === "") {
            if (prevState.internId === nextProps.internId) {
                nextProps.setNewStatus(nextProps.detailData.intern_status)
                return {
                    detailData: nextProps.detailData,
                    status: nextProps.detailData.intern_status,
                    btnLabel: nextProps.detailData.intern_status === 0 ? "応募する": "取り下げ"
                }
            }
        }

        if (prevState.saveInfo !== nextProps.saveInfo) {
            if (prevState.internId === nextProps.saveInternId) {
                if (nextProps.saveInfo.toString().indexOf("SUCCESS") === 0) {
                    const sts = prevState.status === 0 ? 1: 0;
                    nextProps.setNewStatus(sts);
                    return {
                        saveInfo: false,
                        status: sts,
                        btnLabel: sts === 0 ? "応募する": "取り下げ"
                    }
                } else {
                    return {
                        saveInfo: nextProps.saveInfo.toString() === "REQUEST" ? true: false
                    }
                }
            }
        }
    }

    setUnivInternAction = () => {
        const sts = this.state.status == 0 ? 1: 0;
        this.props.setUnivInternAction(this.props.intern_id, sts);
    }

    saveErrorDisable = () => {
        this.props.universityActionReset()
    }

    render() {
        if(0 < Object.keys(this.state.detailData).length) {
            const pref = this.state.detailData.facility_address ? this.state.detailData.facility_address.slice(0, 3): "";
            const map = "/img/" + PrefecturesMap[pref] + ".png";
            return (
                <div>
                    {this.state.saveInfo ? <Load setLabel="実行中..."/>: null}
                    {this.props.isError ? <Error close={this.saveErrorDisable} apiName="university_intern_save_status" setError={this.props.errorMessage} /> : null}
                    <div className="searchResultItemDetailItem">
                        <h3 className="searchResultItemSubHeading">会社情報</h3>
                        <div className="searchResultItemDetailContents">
                            <div className="searchResultItemDetailInformationArea">
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">法人名</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.name}</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">ホームページ</div>
                                    <div className="searchResultItemDetailInformationData"><a href={this.state.detailData.web} target="_blank">{this.state.detailData.web}</a></div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">施設名</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.facility_name}</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">施設所在地</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.facility_address}</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">寮の有無</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.dorm === 1 ? "有": "無"}</div>
                                </div>
                            </div>
                            <div className="searchResultItemDetailImageArea">
                                <div className="searchResultItemDetailImagePhoto"><img src={BASE_URL + map} alt=""/></div>
                                {/* <div className="searchResultItemDetailImagePhoto"><img src={`${process.env.PUBLIC_URL}` + map} alt=""/></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="searchResultItemDetailItem">
                        <h3 className="searchResultItemSubHeading">インターン情報</h3>
                        <div className="searchResultItemDetailContents">
                            <div className="searchResultItemDetailInformationArea">
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">学習職種</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.occupation}</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">学習内容</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.content}</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">自由記入</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.free_text}</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">就業日数</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.working_time.day}日/月</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">実労働時間</div>
                                    <div className="searchResultItemDetailInformationData">{this.state.detailData.working_time.hour}時間{this.state.detailData.working_time.minute}分/日</div>
                                </div>
                                <div className="searchResultItemDetailInformationItem">
                                    <div className="searchResultItemDetailInformationTitle">手取り支援額</div>
                                    <div className="searchResultItemDetailInformationData">{this.props.salary}円/月</div>
                                </div>
                            </div>
                            <div className="searchResultItemDetailImageArea">
                                <div className="searchResultItemDetailImagePhoto"><img src={this.state.detailData.facility_introduction.image} alt=""/></div>
                                <p className="searchResultItemDetailImageComment">{this.state.detailData.facility_introduction.comment}</p>
                                <div className="searchResultItemDetailImagePhoto"><img src={this.state.detailData.nearby_info.image} alt=""/></div>
                                <p className="searchResultItemDetailImageComment">{this.state.detailData.nearby_info.comment}</p>
                            </div>
                        </div>
                    </div>
                    <div className="searchResultItemDetailItem">
                        <h3 className="searchResultItemSubHeading">SKTコメント</h3>
                        <div className="searchResultItemDetailContents">
                            <p className="searchResultItemDetailComment">{this.state.detailData.skt_comment}
                            </p>
                        </div>
                    </div>

                    <div className="searchUniversityActionButton">
                        <Button label={this.state.btnLabel} buttonEnable={this.state.isEnable} callback={() => this.setUnivInternAction()} />
                    </div>
                </div>
            )
        } else {
            return(null)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        detailData: state.getUnivInternDetails.detailData,
        internId: state.getUnivInternDetails.intern_id,
        saveInfo: state.saveUnivAction.saveInfo,
        saveInternId: state.saveUnivAction.internId,
        isError: state.saveUnivAction.isError,
        errorMessage: state.saveUnivAction.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getDetailInfo: (internId) => dispatch(univGetUniversityDetail(internId)),
    setUnivInternAction: (internId, internStatus) => dispatch(saveUniversityAction(internId, internStatus)),
    universityActionReset: () => dispatch(universitySaveActinReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDetail);