import React from "react";
import "../../css/Common.css";
import Button from "../atoms/Button";
import Error from "../atoms/Error";
import TextInput from "../atoms/TextInput";
import CandidateList from "./candidate/CandidateList";
import "../../css/DetailIntern.css"
import {connect} from "react-redux"
import {getInternDetail, internDetailReset} from "../../actions/hotel/GetInternAction";
import {saveInternPlan, resetInternPlan} from "../../actions/hotel/SaveInternAction";
import {getInternCandidateList, candidateReset} from "../../actions/hotel/GetCandidateAction";
import {resetSaveCandidate} from "../../actions/hotel/SaveCandidateAction";
import Load from "../atoms/Load";
import {SexMap, CountryMap, StatusBGMap, StatusMap, calculatePayment, calculateSalary} from "../../constants/CommonConstatns";

class DetailIntern extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            internId: undefined,
            detailData: undefined,
            internPlan: [],
            candidateSaveState: false
        }
        this.addInternPlan = this.addInternPlan.bind(this);
        this.deleteInternPlan = this.deleteInternPlan.bind(this);
        this.addInternSeason = this.addInternSeason.bind(this);
        this.addInternContent = this.addInternContent.bind(this);
        this.handleToDisplay = this.handleToDisplay.bind(this);
        this.handleToDisplayWithData = this.handleToDisplayWithData.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            internId: this.props.internId
        })
        this.props.getIntern(this.props.internId);
        this.props.getInternCandidateList(this.props.internId)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.detailData !== nextProps.detailData) {
            const insertData = nextProps.detailData;
            let periodData = "";
            
            if (insertData.length > 0) {
                periodData = insertData.period;
                periodData.intern_end = periodData.intern_end === null ? "" : periodData.intern_end;
                periodData.intern_start = periodData.intern_start === null ? "" : periodData.intern_start;
                periodData.intern_pub_start = periodData.intern_pub_start === null ? "": periodData.intern_pub_start;
                periodData.intern_pub_end = periodData.intern_pub_end === null ? "": periodData.intern_pub_end;
                insertData.period = periodData;
            }
            
            return {
                detailData: insertData,
                internPlan: nextProps.detailData.plan
            }
        }
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo)
    }

    handleToDisplayWithData = () => {
        return this.props.changeDispWithData("edit_intern", this.state.detailData);
    }

    saveInternPlan = () => {
        const saveData = {
            intern_id: this.state.internId,
            plan: this.state.internPlan
        }
        this.props.savePlan(saveData);
    }

    addInternPlan = () => {
        let plan = {"intern_season": "", "intern_content": ""};
        this.state.internPlan.push(plan);
        this.setState({
            internPlan: this.state.internPlan
        });
    }

    deleteInternPlan = (index) => {
        this.state.internPlan.splice(index, 1);
        this.setState({
            internPlan: this.state.internPlan
        });
    }

    addInternSeason = (index, seasonText) => {
        this.state.internPlan[index].intern_season = seasonText;
        this.setState({
            internPlan: this.state.internPlan
        })
    }

    addInternContent = (index, internContent) => {
        this.state.internPlan[index].intern_content = internContent;
        this.setState({
            internPlan: this.state.internPlan
        })
    }

    setCandidateSaveState = (sts) => {
        this.setState({
            candidateSaveState: sts
        })
    }

    internErrorDisable = () => {
        this.props.internReset();
    }

    candidateErrorDisable = () => {
        this.props.candidateDataReset();
    }

    saveErrorDisable = () => {
        this.props.resetInternPlan();
    }

    candidateSaveErrorDisable = () => {
        this.props.resetSaveCandidate()
    }

    render() {
        if (this.state.detailData.length !== 0) {
            const working = this.state.detailData.working_time;
            const deducation = this.state.detailData.livelihood_support.deduction;
            const workingTime = working.hour + "時間" + (working.minute !== "" ? working.minute + "分": "");
            const amountsList = this.state.detailData.livelihood_support.deduction.map((value) => {
                return value.amount
            })
            const payment = calculatePayment(this.state.detailData.livelihood_support.salary, amountsList);
            const minutes = working.minute == null? 0: working.minute / 60;
            const workingValue = working.hour + minutes;
            const salaryByTime = calculateSalary(payment, working.day, workingValue)
            const stateClass = "detailInternStatus " + StatusBGMap[this.state.detailData.status];
            return (
                <article>
                    {this.props.saveState ? <Load setLabel="保存中..."/>: null}
                    {this.state.candidateSaveState ? <Load setLabel="実行中..."/>: null}
                    {this.props.isInternError ? <Error close={this.internErrorDisable} apiName="intern_detail" setError={this.props.internErrorMessage} /> : null}
                    {this.props.isCandidateError ? <Error close={this.candidateErrorDisable} apiName="intern_candidate_list" setError={this.props.candidateMessage} /> : null}
                    {this.props.isSaveError ? <Error close={this.saveErrorDisable} apiName="save_internplan" setError={this.props.saveErrorMessage} /> : null}
                    {this.props.isCandidateSaveError ? <Error close={this.candidateSaveErrorDisable} apiName="intern_save_candidate_status" setError={this.props.candidateSaveMessage} /> : null}
                    <nav className="navigationArea">
                        <ul className="navigationList layoutInner">
                            <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("outline")}>インターンの概要</a></li>
                            <li className="navigationItem active">インターン管理</li>
                        </ul>
                    </nav>
                    <div className="breadcrumbArea layoutInner">
                        <ol className="breadcrumbList">
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_intern")}>インターン管理</a></li>
                            <li className="breadcrumbItem">インターン詳細</li>
                        </ol>
                    </div>
                    <div className="detailInternContents layoutInner">
                        <section className="detailInternMain">
                            <div className="detailInternMainHeader">
                                <h2 className="detailInternMainHeading">インターン情報</h2>
                                <div className={stateClass}>{
                                    this.state.detailData.entered_flg === 0 ? StatusMap[5]: StatusMap[this.state.detailData.status]
                                }</div>
                            </div>
                            <div className="detailInternMainContents">
                                <div className="detailInternMainLeft">
                                    <div className="detailInternMainTable">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>インターン名</th>
                                                    <td>{this.state.detailData.title}</td>
                                                </tr>
                                                <tr>
                                                    <th>インターン期間</th>
                                                    <td>{this.state.detailData.period.intern_start + " 〜 " + this.state.detailData.period.intern_end}</td>
                                                </tr>
                                                <tr>
                                                    <th>インターン人数</th>
                                                    <td>{this.state.detailData.accepted + "名"}</td>
                                                </tr>
                                                <tr>
                                                    <th>掲載期間</th>
                                                    <td>{this.state.detailData.period.intern_pub_start + " 〜 " + this.state.detailData.period.intern_pub_end}</td>
                                                </tr>
                                                <tr>
                                                    <th>募集性別</th>
                                                    <td>{SexMap[this.state.detailData.sex]}</td>
                                                </tr>
                                                <tr>
                                                    <th>募集国籍</th>
                                                    <td>{
                                                        this.state.detailData.country.map((value, index) => {
                                                            if(value) {
                                                                return(<p key={index}>{CountryMap[index]}</p>)
                                                            }
                                                        })
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>学習職種</th>
                                                    <td>{this.state.detailData.occupation}</td>
                                                </tr>
                                                <tr>
                                                    <th>学習内容</th>
                                                    <td>{this.state.detailData.content}</td>
                                                </tr>
                                                <tr>
                                                    <th>自由記入</th>
                                                    <td>{this.state.detailData.free_text}</td>
                                                </tr>
                                                <tr>
                                                    <th>就業体験</th>
                                                    <td>
                                                        <p>就業日数:{this.state.detailData.working_time.day}日/月</p>
                                                        <p>実労働時間:{workingTime}/日</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>生活支援</th>
                                                        <td>
                                                            <p>支援額:{this.state.detailData.livelihood_support.salary}円/月</p>
                                                            <p>差引額:</p>
                                                            <ul>
                                                                {
                                                                    deducation.map((item, index) => {
                                                                        let nominal = item.nominal == null ? "未設定" : item.nominal;
                                                                        let amount = item.amount == null ? "未設定" : item.amount + "円";
                                                                        
                                                                        return <li key={index}>{nominal + "：" + amount}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                            <p>手取り支援額:約{parseInt(payment)}円</p>
                                                            <p>時間あたり:約{salaryByTime}円相当額</p>
                                                        </td>
                                                </tr>
                                                <tr>
                                                    <th>生活支援</th>
                                                    <td>
                                                        <p>宿泊費をサービスする:{this.state.detailData.management.hotels.is_service ? "する": "しない"}</p>
                                                        <p>宿泊費定価:{this.state.detailData.management.hotels.catalog_price != null ? this.state.detailData.management.hotels.catalog_price + "円": ""}</p>
                                                        <p>提示する宿泊費:{this.state.detailData.management.hotels.publish_price != null ? this.state.detailData.management.hotels.publish_price + "円": ""}</p>
                                                        <p>食費をサービスする:{this.state.detailData.management.meals.is_service ? "する": "しない"}</p>
                                                        <p>食費定価:{this.state.detailData.management.meals.catalog_price != null ? this.state.detailData.management.meals.catalog_price + "円": ""}</p>
                                                        <p>提示する食費:{this.state.detailData.management.meals.publish_price != null ? this.state.detailData.management.meals.publish_price + "円": ""}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="detailInternMainRight">
                                    <div className="detailInternImageArea">
                                        <div className="detailInternImagePhoto"><img src={this.state.detailData.facility_introduction.image} alt=""/></div>
                                        <p className="detailInternImageComment">{this.state.detailData.facility_introduction.comment}</p>
                                    </div>
                                    <div className="detailInternImageArea">
                                        <div className="detailInternImagePhoto"><img src={this.state.detailData.nearby_info.image} alt=""/></div>
                                        <p className="detailInternImageComment">{this.state.detailData.nearby_info.comment}</p>
                                    </div>
                                    <div className="detailInternEditButton">
                                        <Button label="インターン情報修正" buttonEnable={true} callback={() => this.handleToDisplayWithData()}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*
                        <section className="detailInternItem">
                            <h2 className="detailInternItemHeading">お問合せ</h2>
                            <div className="detailInternItemContents">
                                <div className="detailInternItemTable">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>2020/03/01（ターゲット外）</th>
                                                <td>台湾（ターゲット外）</td>
                                                <td>zzzz University（ターゲット外）</td>
                                            </tr>
                                            <tr>
                                                <th>2020/02/10（ターゲット外）</th>
                                                <td>韓国（ターゲット外）</td>
                                                <td>zzzz University（ターゲット外）</td>
                                            </tr>
                                            <tr>
                                                <th>2020/02/02（ターゲット外）</th>
                                                <td>ベトナム（ターゲット外）</td>
                                                <td>zzzz University（ターゲット外）</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                        */}
                        <section className="detailInternItem">
                            <h2 className="detailInternItemHeading">提携大学</h2>
                            <div className="detailInternItemContents">
                                <div className="detailInternItemTable">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                        <section className="detailInternItem">
                            <h2 className="detailInternItemHeading">応募者</h2>
                            <div className="detailInternItemContents">
                                <div className="detailInternItemTable">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th width="40%">大学名</th>
                                                <th width="40%">氏名</th>
                                                <th width="10%">ステータス</th>
                                                <th width="10%">実行</th>
                                            </tr>
                                            {
                                                this.props.candidateList.map((item, index) => {
                                                    return(
                                                        <CandidateList 
                                                            key={item.candidate_id}
                                                            internId={this.state.internId}
                                                            candidateData={item}
                                                            setCandidateSaveState={this.setCandidateSaveState}
                                                        />  
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </section>
                        <section className="detailInternItem">
                            <h2 className="detailInternItemHeading">インターン計画</h2>
                            <div className="detailInternItemContents">
                                <div className="detailInternPlan">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>時期</th>
                                                <th>活動内容</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                            {
                                                this.state.internPlan.map((planList, index) => {
                                                    if (index === 0) {
                                                        return(
                                                            <tr key={index}>
                                                                <td>
                                                                    <TextInput type="text" required={false} maxLength="300" setValue={planList.intern_season} onHandleChange={(e) => this.addInternSeason(index, e)}/>
                                                                </td>
                                                                <td>
                                                                    <TextInput type="text" required={false} maxLength="300" setValue={planList.intern_content} onHandleChange={(e) => this.addInternContent(index, e)}/>
                                                                </td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return(
                                                            <tr key={index}>
                                                                <td>
                                                                    <TextInput type="text" required={false} maxLength="300" setValue={planList.intern_season} onHandleChange={(e) => this.addInternSeason(index, e)}/>
                                                                </td>
                                                                <td>
                                                                    <TextInput type="text" required={false} maxLength="300" setValue={planList.intern_content} onHandleChange={(e) => this.addInternContent(index, e)}/>
                                                                </td>
                                                                <td>
                                                                    <div className="deleteButton">
                                                                        <Button label="削除" buttonEnable={true} callback={() => this.deleteInternPlan(index)}/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )

                                                    }
                                                    
                                                })
                                            }
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>
                                                    <div className="addButton">
                                                        <Button label="追加" buttonEnable={true} callback={() => this.addInternPlan()}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="detailInternSaveButton">
                                        <Button label="保存" buttonEnable={true} callback={() => this.saveInternPlan()}/>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*
                        <section className="detailInternItem">
                            <h2 className="detailInternItemHeading">総評コメント</h2>
                            <div className="detailInternItemContents">
                                <p className="detailInternItemComment">（ターゲット外）コメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメント</p>
                            </div>
                        </section>
                        <section className="detailInternItem">
                            <h2 className="detailInternItemHeading">大学・学生からのフィードバック</h2>
                            <div className="detailInternItemContents">
                                <p className="detailInternItemComment">（ターゲット外）コメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメントコメント</p>
                            </div>
                        </section>
                        */}
                    </div>
                </article>
            )   
        } else {
            return(
                <article>
                    {this.props.isFetch ? <Load setLabel="読み込み中..."/> : null}
                    {this.props.isInternError ? <Error close={this.internErrorDisable} apiName="intern_detail" setError={this.props.internErrorMessage} /> : null}
                    {this.props.isCandidateError ? <Error close={this.candidateErrorDisable} apiName="intern_candidate_list" setError={this.props.candidateMessage} /> : null}
                    <nav className="navigationArea">
                        <ul className="navigationList layoutInner">
                            <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("outline")}>インターンの概要</a></li>
                            <li className="navigationItem active">インターン管理</li>
                        </ul>
                    </nav>
                    <div className="breadcrumbArea layoutInner">
                        <ol className="breadcrumbList">
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                            <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_intern")}>インターン管理</a></li>
                            <li className="breadcrumbItem">インターン詳細</li>
                        </ol>
                    </div>
                    <div className="detailInternContents layoutInner">
                    </div>
                </article>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.getInternDetails.isFetch,
        detailData: state.getInternDetails.detailData,
        isInternError: state.getInternDetails.isError,
        internErrorMessage: state.getInternDetails.errorMessage,
        saveState: state.postPlanData.isFetching,
        isSaveError: state.postPlanData.isError,
        saveErrorMessage: state.postPlanData.errorMessage,
        candidateList: state.getCandidateList.candidateList,
        isCandidateError: state.getCandidateList.isError,
        candidateMessage: state.getCandidateList.errorMessage,
        isCandidateSaveError: state.saveCandidateAction.isError,
        candidateSaveMessage: state.saveCandidateAction.errorMessage
    }
};

function mapDispatchToProps(dispatch) {
    return {
        getIntern(internId) {
            dispatch(getInternDetail(internId))
        },
        internReset() {
            dispatch(internDetailReset())
        },
        savePlan(saveData) {
            dispatch(saveInternPlan(saveData))
        },
        resetInternPlan() {
            dispatch(resetInternPlan())
        },
        getInternCandidateList(internId) {
            dispatch(getInternCandidateList(internId))
        },
        candidateDataReset() {
            dispatch(candidateReset())
        },
        resetSaveCandidate() {
            dispatch(resetSaveCandidate())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailIntern);

