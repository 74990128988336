import React from "react";
import {connect} from "react-redux";
import {getInsuranceData, getInsuranceMonth, INITIAL_REQ_INSURANCE} from "../../../constants/CommonConstatns";
import {setInsuranceDetailData, updateInsuranceDetailData} from "../../../actions/skt/SetInsuranceDetail";
const moment = require("moment");

class InsuranceBody extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedInsurance: "",
            type: "",
            exec: "",
            internInfo: {},
            stay: "",
            internPeriod: "",
            iPeriod: [],
            sendData: {}
        }
    }

    continuanceLabel = () => {
        return (
            <p>継続<br/>加入</p>
        )
    }
 
    componentDidMount = () => {
        const _type = this.props.internData.type === 1 ? "新規": this.continuanceLabel();
        const _insurancePeriod = this.props.internData.insured_period;
        const _internInfo = this.props.internData.intern_info
        const _stay = this.props.internData.stay_start + "〜" + this.props.internData.stay_end;
        const _internPeriod = _internInfo.intern_start + "〜" + _internInfo.intern_end;
        const _periodLen = _insurancePeriod.length;
        const _iPeriod = [];
        const _sendData = JSON.parse(JSON.stringify(INITIAL_REQ_INSURANCE));
        let _selectedInsurance = this.props.internData.insurance_plan;
        let _insuranceStart = "";
        let _insuranceEnd = "";

        _insurancePeriod.forEach((value) => {
            const start = value.start;
            const end = value.end;
            _iPeriod.push(start + "〜" + end);
        });

        if (_periodLen === 0) {
            _insuranceStart = this.props.internData.stay_start;
        } else {
            const endDate = _insurancePeriod[_periodLen - 1].end;
            const dt = moment(new Date(endDate));
            dt.add(1, "days");
            _insuranceStart = dt.format("YYYY/MM/DD");
        }

        if (_selectedInsurance === 0) {
            _selectedInsurance = this.computeBetterInsurance(_insuranceStart, this.props.internData.stay_end);
        }

        if (this.props.exec_flg === 0) {
            _insuranceEnd = this.getInsuranceEndDate(_selectedInsurance, _insuranceStart);
            _iPeriod.push(_insuranceStart + "〜" + _insuranceEnd);
        }

        _sendData.intern_id = this.props.internData.intern_id;
        _sendData.type = this.props.internData.type;
        _sendData.insurance_start = _insuranceStart;
        _sendData.insurance_end = _insuranceEnd;
        _sendData.insurance_plan = parseInt(_selectedInsurance);

        this.setState({
            type: _type,
            internInfo: _internInfo,
            stay: _stay,
            internPeriod: _internPeriod,
            iPeriod: _iPeriod,
            selectedInsurance: _selectedInsurance,
            sendData: _sendData
        }, () => {
            this.props.setInsuranceDetailData(_sendData);
        })
    }

    getSelectedInsurance = (e) => {
        const newData = JSON.parse(JSON.stringify(this.state.sendData));
        newData.insurance_plan = parseInt(e.target.value);
        newData.insurance_end = this.getInsuranceEndDate(e.target.value, newData.insurance_start);
        
        //保険期間更新
        const newPeriod = this.state.iPeriod.slice();
        newPeriod.pop();
        newPeriod.push(newData.insurance_start + "〜" + newData.insurance_end);

        this.setState({
            selectedInsurance: e.target.value,
            iPeriod: newPeriod
        }, () => {
            this.props.updateInsuranceDetailData(newData)
        })
    }

    // 保険終了日計算ロジック（保険申込開始月 + 期間月 - 1日）
    getInsuranceEndDate = (select, startDate) => {
        const difMonth = getInsuranceMonth()[select];
        const dt = moment(new Date(startDate));
        dt.add({days: -1, months: difMonth});
        return dt.format("YYYY/MM/DD");
    }

    // スタートとエンドから、カバーできる保険を算出する
    computeBetterInsurance = (start, end) => {
        const startDate = moment(new Date(start));
        const endDate = moment(new Date(end));
        const diff = endDate.diff(startDate, "months") + 1;
        console.log(diff);

        switch (diff) {
            case 0:
            case 1:
                return "2001"
            case 2:
                return "2002"
            case 3:
                return "1003"
            case 4:
            case 5:
            case 6:
                return "1006"
            case 7:
            case 8:
            case 9:
                return "1009"
            default:
                return "1012"
            
        }
    }

    render() {
        const insuranceTargetOptions = getInsuranceData().map(
            (n) => (
                <option key={n.code} value={n.code} >
                    {n.name}
                </option>
            )
        )
        
        return(
            <tbody>
                <tr>
                    <td rowSpan="2" className="narrow_cell">{this.state.type}</td>
                    <td rowSpan="2" className="narrow_cell">{this.props.exec_flg === 1 ? "実行済": ""}</td>
                    <td rowSpan="2">
                        <div className="selectTableWrapper">
                            <select disabled={this.props.exec_flg === 1 ? true: false}  value={this.state.selectedInsurance} onChange={this.getSelectedInsurance}>
                                {insuranceTargetOptions}
                            </select>
                        </div>
                    </td>
                    <td rowSpan="2">
                        {
                            this.state.iPeriod.map((period, index) => {
                                if(index === this.state.iPeriod.length - 1 && this.props.exec_flg === 0) {
                                    return(
                                        <p key={index} className="textColorRed">{period}</p>
                                    )
                                } else {
                                    return(
                                        <p key={index}>{period}</p>
                                    )
                                }
                            })
                        }
                    </td>
                    <td rowSpan="2">{this.state.stay}</td>
                    <td>{this.props.internData.intern_name}</td>
                    <td>{this.state.internInfo.company_name}</td>
                </tr>
                <tr>
                    <td>{this.state.internPeriod}</td>
                    <td>{this.state.internInfo.facility_name}</td>
                </tr>
            </tbody>
        )
    }
}

const mapStateToProps = () => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => ({
    setInsuranceDetailData: (newData) => dispatch(setInsuranceDetailData(newData)),
    updateInsuranceDetailData: (updateData) => dispatch(updateInsuranceDetailData(updateData))
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceBody);