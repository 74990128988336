import React from "react";
import Logo from "../../images/hi_logo.png"
import "../../css/Common.css"
import {Version} from "../../constants/CommonConstatns";

export default function Header() {
    
    const showLicensePage = () => {
        window.open(window.location.origin + "/license/index.html", "_blank");
    }

    return(
        <footer className="footerArea">
            <div className="layoutInner">
                <div className="footerLogo">
                    <img src={Logo} alt="Human IT"/>
                </div>
                <a id="linkLicense" onClick={showLicensePage}>オープンソースライセンス</a>
                <p id="version">{Version}</p>
            </div>
        </footer>
    );
}