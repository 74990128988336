import React from "react";
import "../../css/MainPage.css"
import "../../css/ShowIntern.css"
import "../../css/Common.css"
import {StatusMap, StatusDisplayMap, SortMap} from "../../constants/CommonConstatns"
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import {connect} from "react-redux";
import {getInternOverviews, getInternOverviewsReset} from "../../actions/hotel/GetInternAction";


class ShowIntern extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            overViewData: [],
            statusStates: 6,
            displayStatus: 6,
            sortStates: 0,
        }
        this.clickInternTitle = this.clickInternTitle.bind(this);
    }

    componentDidMount = () => {
        this.props.getOverviews();
        this.props.getCompanyData()
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo);
    }

    clickInternTitle = (internId) => {
        return this.props.changeDispWithId("detail_intern", internId);
    }

    companyErrorDisable = () => {
        this.props.errorDisable();
    }

    internErrorDisable = () => {
        this.props.overViewReset();
    }

    getStatusStates = (event) => {
        this.setState({
            statusStates: parseInt(event.target.value)
        });
    }

    setFilter = () => {
        let select = this.state.statusStates;
        if(select === 1) select = 5;
        if(select === 2) select = 1;
        if(select === 0) select = 6;
        console.log(select);
        this.setState({
            displayStatus: select
        });
        this.render();
    }

    getSortStates = (event) => {
        this.setState({
            sortStates: parseInt(event.target.value)
        });
        this.render();
    }
    render() {

        var statusOptions = Object.entries(StatusDisplayMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {value}
                </option>
            )
        );
        var sortOptions = Object.entries(SortMap).map(
            ([key, value])=>(
                <option key={key} value={key}>
                    {value}
                </option>
            )
        );
        return (
            <article id="showInternArticle">
                {this.props.showLoadIcon ? <Load setLabel="読み込み中..."/>: null}
                {this.props.isCompanyError ? <Error close={this.companyErrorDisable} apiName="company_info" setError={this.props.companyInfoMessage} /> : null}
                {this.props.isInternError ? <Error close={this.internErrorDisable} apiName="intern_overview" setError={this.props.internError} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("outline")}>インターンの概要</a></li>
                        <li className="navigationItem active">インターン管理</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                        <li className="breadcrumbItem">インターン管理</li>
                    </ol>
                </div>
                <section className="searchRefineArea">
                    <h2 className="searchRefineHeading">インターン絞り込み項目</h2>
                    <div className="searchRefineContents">
                        <div className="searchRefineItemIntern">
                            <label htmlFor="term">ステータス</label>
                            <div className="selectWrapper searchRefineItemSelect">
                                <select id="term" name="term" required value={this.state.statusStates} onChange={this.getStatusStates}>
                                    {statusOptions}
                                </select>
                            </div>
                            <div id="targetSearchBtnWrapper">
                                <Button label="絞り込み検索" buttonEnable={true} callback={() => this.setFilter()}/>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="showInternContents">
                    <Button label="インターン案件新規登録" buttonEnable={true} callback={() => this.handleToDisplay("edit_intern")}/>
                    <div id="sortbox">
                        <h1>
                            現在の登録済インターン案件一覧
                        </h1>
                        <div className="sortRefineItemIntern">
                            <label htmlFor="term">並べ替え</label>
                            <div className="selectWrapper sortRefineItemSelect">
                                <select id="term" name="term" required value={this.state.sortStates} onChange={this.getSortStates}>
                                    {sortOptions}
                                </select>
                            </div>
                        </div>
                    </div>
                    <table border="1" bordercolor="#ffffff">
                        <thead>
                            <tr>
                                <th>ステータス</th>
                                <th>案件名</th>
                                <th>インターン期間</th>
                                <th>募集人数</th>
                                <th>応募人数</th>
                                <th>問い合わせ件数</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                if(this.props.overViewData.length > 0) {
                                    return(
                                    this.props.overViewData.sort((a, b) => (this.state.sortStates === 0 ? b.intern_id - a.intern_id : a.intern_id - b.intern_id)
                                    ).map((item) => (
                                            (this.state.displayStatus === item.status && item.entered_flg !== 0) || this.state.displayStatus === 6 || 
                                                 (this.state.displayStatus === 5 && item.entered_flg === 0) ?
                                            <tr key={item.intern_id}>
                                                <td>{item.entered_flg === 0 ? StatusMap[5]: StatusMap[item.status]}</td>
                                                <td><a onClick={() => this.clickInternTitle(item.intern_id)}>{item.title}</a></td>
                                                <td>{item.period.intern_start + "〜" + item.period.intern_end}</td>
                                                <td>{item.accepted + "人"}</td>
                                                <td>{item.applicant + "人"}</td>
                                                <td>{item.inquiry + "人"}</td>
                                            </tr>
                                            : null
                                        ))
                                    )   
                                }
                            })()}
                        </tbody>
                    </table>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        overViewData: state.getInternOverviews.overViewData,
        showLoadIcon: !state.getInternOverviews.isGetInfo,
        isInternError: state.getInternOverviews.isError,
        internError: state.getInternOverviews.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getOverviews: () => dispatch(getInternOverviews()),
    overViewReset: () => dispatch(getInternOverviewsReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowIntern);

