import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css";
import Button from "../atoms/Button";
import TextInput from "../atoms/TextInput";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import {calculatePayment, calculateSalary} from "../../constants/CommonConstatns";
import {ErrorMap, MinimumWageMap} from "../../constants/CommonConstatns";
import "../../css/EditIntern.css"

class EditIntern4 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            day: null,
            hour: null,
            minute: null,
            salary: null,
            deduction: [],
            hotels_is_service: false,
            hotels_catalog_price: null,
            hotels_publish_price: null,
            meals_is_service: false,
            meals_catalog_price: null,
            meals_publish_price: null,
            proceed: null,
            wage: null,
            error_message: []
        }

        this.getWorkingTimeDay = this.getWorkingTimeDay.bind(this);
        this.getWorkingTimeHour = this.getWorkingTimeHour.bind(this);
        this.getWorkingTimeMinute = this.getWorkingTimeMinute.bind(this);
        this.getLivelihoodSupportSalary = this.getLivelihoodSupportSalary.bind(this);
        this.getLivelihoodSupportDeductionNominal = this.getLivelihoodSupportDeductionNominal.bind(this);
        this.getLivelihoodSupportDeductionAmount = this.getLivelihoodSupportDeductionAmount.bind(this);
        this.getManagementHotelsIsService = this.getManagementHotelsIsService.bind(this);
        this.getManagementHotelsCatalogPrice = this.getManagementHotelsCatalogPrice.bind(this);
        this.getManagementHotelsPublishPrice = this.getManagementHotelsPublishPrice.bind(this);
        this.getManagementMealsIsService = this.getManagementMealsIsService.bind(this);
        this.getManagementMealsCatalogPrice = this.getManagementMealsCatalogPrice.bind(this);
        this.getManagementMealsPublishPrice = this.getManagementMealsPublishPrice.bind(this);
    }

    componentDidMount = () => {
        this.setState({
            day: this.props.internData.working_time.day,
            hour: this.props.internData.working_time.hour,
            minute: this.props.internData.working_time.minute,
            salary: this.props.internData.livelihood_support.salary,
            deduction: this.props.internData.livelihood_support.deduction,
            hotels_is_service: this.props.internData.management.hotels.is_service,
            hotels_catalog_price: this.props.internData.management.hotels.catalog_price,
            hotels_publish_price: this.props.internData.management.hotels.publish_price,
            meals_is_service: this.props.internData.management.meals.is_service,
            meals_catalog_price: this.props.internData.management.meals.catalog_price,
            meals_publish_price: this.props.internData.management.meals.publish_price,
            error_message: this.props.errorMessage,
        });
        window.scrollTo(0, 0);
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    temporalySave = () => {
        this.props.saveTemporaly();
    }

    calclateProceeds = () => {
        const saraly = this.state.salary;
        const amountList = this.state.deduction.map((value) => {
            return value.amount
        })
        const proceeds = calculatePayment(saraly, amountList);
        
        //1日の働く時間
        const minutes = this.state.minute == null? 0: this.state.minute / 60;
        const workingTime = this.state.hour + minutes;
        
        const wage = calculateSalary(proceeds, this.state.day, workingTime);
        this.setState({
            proceed: proceeds,
            wage: wage
        });
    }

    getWorkingTimeDay = (workingTimeDay) => {
        this.setState({
            day: parseInt(workingTimeDay)
        });
        this.props.getWorkingTimeDay(parseInt(workingTimeDay))
    }

    getWorkingTimeHour = (workingTimeHour) => {
        this.setState({
            hour: parseInt(workingTimeHour)
        });
        this.props.getWorkingTimeHour(parseInt(workingTimeHour))
    }

    getWorkingTimeMinute = (workingTimeMinute) => {
        this.setState({
            minute: parseInt(workingTimeMinute)
        });
        this.props.getWorkingTimeMinute(parseInt(workingTimeMinute))
    }

    getLivelihoodSupportSalary = (livelihoodSupportSalary) => {
        this.setState({
            salary: parseInt(livelihoodSupportSalary)
        });
        this.props.getLivelihoodSupportSalary(parseInt(livelihoodSupportSalary))
    }

    getLivelihoodSupportDeductionNominal = (index, livelihoodSupportDeductionNominal) => {
        this.state.deduction[index].nominal = livelihoodSupportDeductionNominal;
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    getLivelihoodSupportDeductionAmount = (index, livelihoodSupportDeductionAmount) => {
        this.state.deduction[index].amount = parseInt(livelihoodSupportDeductionAmount);
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    getManagementHotelsIsService(event) {
        this.setState({
            hotels_is_service: event.target.checked
        });
        this.props.getManagementHotelsIsService(event.target.checked)
    }

    getManagementHotelsCatalogPrice = (managementHotelsCatalogPrice) => {
        this.setState({
            hotels_catalog_price: parseInt(managementHotelsCatalogPrice)
        });
        this.props.getManagementHotelsCatalogPrice(parseInt(managementHotelsCatalogPrice))
    }

    getManagementHotelsPublishPrice = (managementHotelsPublishPrice) => {
        this.setState({
            hotels_publish_price: parseInt(managementHotelsPublishPrice)
        });
        this.props.getManagementHotelsPublishPrice(parseInt(managementHotelsPublishPrice))
    }

    getManagementMealsIsService(event) {
        this.setState({
            meals_is_service: event.target.checked
        });
        this.props.getManagementMealsIsService(event.target.checked)
    }

    getManagementMealsCatalogPrice = (managementMealsCatalogPrice) => {
        this.setState({
            meals_catalog_price: parseInt(managementMealsCatalogPrice)
        });
        this.props.getManagementMealsCatalogPrice(parseInt(managementMealsCatalogPrice))
    }

    getManagementMealsPublishPrice = (managementMealsPublishPrice) => {
        this.setState({
            meals_publish_price: parseInt(managementMealsPublishPrice)
        });
        this.props.getManagementMealsPublishPrice(parseInt(managementMealsPublishPrice))
    }

    addDeduction = () => {
        let plan = {"nominal": "", "amount": 0};
        this.state.deduction.push(plan);
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    deleteDeduction = (index) => {
        this.state.deduction.splice(index, 1);
        this.setState({
            deduction: this.state.deduction
        });
        this.props.getLivelihoodSupportDeduction(this.state.deduction)
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";
        const facilityFullAddress = this.props.internData.company_info.facility_address;
        const facilityName = facilityFullAddress.slice(0, 3);
        let displayFacility = ""

        if (facilityName === "神奈川" || facilityName === "鹿児島") {
            displayFacility = facilityName + "県";
        } else {
            displayFacility = facilityName;
        }

        const minWage = MinimumWageMap[facilityName];

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_intern" setError={this.props.saveMessage} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("outline", true)}>インターンの概要</a></li>
                        <li className="navigationItem active">インターン管理</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_intern", true)}>インターン管理</a></li>
                        <li className="breadcrumbItem">インターン新規登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern", false)}>募集時期</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_2", false)}>会社概要</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_intern_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern4">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <h2 className="editInternItemHeading">就業体験</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeDay">就業日数<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    １月あたり
                                    <TextInput id="workingTimeDay" name="workingTimeDay" type="number" value="18" required={true} setValue={this.state.day} onHandleChange={(e) => this.getWorkingTimeDay(e)}/>
                                    日
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="workingTimeHour">実労働時間<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    １日あたり
                                    <TextInput id="workingTimeHour" name="workingTimeHour" type="number" value="6" required={true} setValue={this.state.hour} onHandleChange={(e) => this.getWorkingTimeHour(e)}/>
                                    時間
                                    <TextInput id="workingTimeMinute" name="workingTimeMinute" type="number" value="0" required={true} setValue={this.state.minute} onHandleChange={(e) => this.getWorkingTimeMinute(e)}/>
                                    分
                                </div>
                            </div>
                            <p className="editInternItemExplanation">インターン生の労働時間が⾧いと、労基署に実質労働者としてみなされ課税される場合があります。そのため、インターン生の労働従事時間は正社員の実労働時間（残業時間含む）の平均値の４分の３に収まる範囲にすることが推奨されます。</p>
                        </form>
                    </section>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <h2 className="editInternItemHeading">生活支援</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="livelihoodSupportSalary">支援額</label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="livelihoodSupportSalary" name="livelihoodSupportSalary" type="number" value="100000" required={false} setValue={this.state.salary} onHandleChange={(e) => this.getLivelihoodSupportSalary(e)}/>
                                    円/月
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="livelihoodSupportDeductionNominal">差引額</label>
                                <div className="editInternInputInputArea editInternInputInputAreaColumn">
                                    {
                                        this.state.deduction.map((deduction, index) => {
                                            if(index === 0) {
                                                return (
                                                    <div className="editInternInputInputAreaRow" key={index}>
                                                        名目
                                                        <TextInput id="livelihoodSupportDeductionNominal" name="livelihoodSupportDeductionNominal" type="text" value="寮費" required={false} maxLength="50" setValue={this.state.deduction[index].nominal} onHandleChange={(e) => this.getLivelihoodSupportDeductionNominal(index,e)}/>
                                                        <TextInput id="livelihoodSupportDeductionAmount" name="livelihoodSupportDeductionAmount" type="number" value="5000" required={false} setValue={this.state.deduction[index].amount} onHandleChange={(e) => this.getLivelihoodSupportDeductionAmount(index,e)}/>
                                                        円/月
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="editInternInputInputAreaRow" key={index}>
                                                        名目
                                                        <TextInput id="livelihoodSupportDeductionNominal" name="livelihoodSupportDeductionNominal" type="text" value="寮費" required={false} maxLength="50" setValue={this.state.deduction[index].nominal} onHandleChange={(e) => this.getLivelihoodSupportDeductionNominal(index,e)}/>
                                                        <TextInput id="livelihoodSupportDeductionAmount" name="livelihoodSupportDeductionAmount" type="number" value="5000" required={false} setValue={this.state.deduction[index].amount} onHandleChange={(e) => this.getLivelihoodSupportDeductionAmount(index,e)}/>
                                                        円/月
                                                        <div className="deleteButton">
                                                            <Button label="削除" buttonEnable={true} callback={() => this.deleteDeduction(index)}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    <div className="editInternInputInputAreaRow">
                                        <div className="addButton">
                                            <Button label="追加" buttonEnable={true} callback={() => this.addDeduction()}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="editInternItemExplanation">インターン生の賃金や手当には法的な規制はありません。地域の最低賃金が目安とされています。<br/>一方で、大学も学生も手取りの多さで職場を選ぶ傾向があります。</p>
                            <div className="editInternCalculateButton">
                                <Button label="手取り支援額の計算（税金を考慮した概算）" buttonEnable={true} callback={() => this.calclateProceeds()}/>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="afterTaxPrice">手取り支援額</label>
                                <div className="editInternInputInputArea">
                                    １か月あたり　約
                                <span id="afterTaxPrice">{this.state.proceed}</span>
                                    円
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="hourlyPrice">時給換算</label>
                                <div className="editInternInputInputArea">
                                    時間あたり　　約
                                    <span id="hourlyPrice">{this.state.wage}</span>
                                    円相当額
                                </div>
                            </div>
                            {
                                minWage && 
                                <div className="editInternInputItem">
                                    <p id="minWageLabel"><span className="boldText">※{displayFacility}</span>の最低賃金</p>
                                    <div id="minWageArea">
                                    <span id="minWage">{minWage}</span>
                                        円
                                    </div>
                                </div>
                            }
                            <p className="editInternItemExplanation">支援額から差引額を差し引いた学生の手取り支援額が、年間103万または月88000円以上で所得税と住民税が課税されて額が減少することがあります。</p>
                        </form>
                    </section>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <h2 className="editInternItemHeading">監督教授受入条件</h2>
                            <div className="editInternInputItem">
                                <label htmlFor="managementHotelsIsService">担当教員</label>
                                <div className="editInternInputInputArea">
                                    <div className="editInternInputInputAreaColumn">
                                        <div className="editInternInputInputGroup">
                                            <label className="checkboxLabel">
                                                <input type="checkbox" name="managementHotelsIsService" checked={this.state.hotels_is_service} onChange={this.getManagementHotelsIsService}/>
                                                <div className="checkboxText">宿泊費をサービスする</div>
                                            </label>
                                            <div className="editInternInputInputAreaColumn">
                                                <div className="editInternInputInputAreaRow">
                                                    宿泊費定価:
                                                    <TextInput id="managementHotelsCatalogPrice" name="managementHotelsCatalogPrice" type="number" required={false} setValue={this.state.hotels_catalog_price} onHandleChange={(e) => this.getManagementHotelsCatalogPrice(e)}/>
                                                </div>
                                                <div className="editInternInputInputAreaRow">
                                                    掲示する宿泊費:
                                                    <TextInput id="managementHotelsPublishPrice" name="managementHotelsPublishPrice" type="number" required={false} setValue={this.state.hotels_publish_price} onHandleChange={(e) => this.getManagementHotelsPublishPrice(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="editInternInputInputGroup">
                                            <label className="checkboxLabel">
                                                <input type="checkbox" name="managementMealsIsService" checked={this.state.meals_is_service} onChange={this.getManagementMealsIsService}/>
                                                <div className="checkboxText">食費をサービスする</div>
                                            </label>
                                            <div className="editInternInputInputAreaColumn">
                                                <div className="editInternInputInputAreaRow">
                                                    食費定価:
                                                    <TextInput id="managementMealsCatalogPrice" name="managementMealsCatalogPrice" type="number" required={false} setValue={this.state.meals_catalog_price} onHandleChange={(e) => this.getManagementMealsCatalogPrice(e)}/>
                                                </div>
                                                <div className="editInternInputInputAreaRow">
                                                    掲示する食費:
                                                    <TextInput id="managementMealsPublishPrice" name="managementMealsPublishPrice" type="number" required={false} setValue={this.state.meals_publish_price} onHandleChange={(e) => this.getManagementMealsPublishPrice(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="editInternItemExplanation">実習期間中に大学の担当教員がインターンの実態を視察に来ます。<br/>その際の宿泊費や食費をサービスにすると喜ばれます。通常期間中に１回程度。</p>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_intern_5", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postDetailData.isFetching,
        isError: state.postDetailData.isError,
        saveMessage: state.postDetailData.errorMessage
    }
};

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(EditIntern4);

