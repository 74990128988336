import React from 'react';
import ManageHomeTemplate from "../components/ManageHomeTemplate"

function Home() {
  return (
    <section className="App">
        <ManageHomeTemplate />
    </section>
  );
}

export default Home;
