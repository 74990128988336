import {
    POSTING_OVERVIEWS_REQUEST,
    POSTING_OVERVIEWS_SUCCESS,
    POSTING_OVERVIEWS_FAILURE,
    POSTING_OVERVIEWS_RESET
} from "../../actions/posting/GetPostingAction"

const overViewState = {
    isFetching: false,
    overViewData: [],
    isGetInfo: false,
    isError: false,
    errorMessage: ""
}

const detailState = {
    isFetching: false,
    detailData: [],
    isError: false,
    errorMessage: ""
}

export const getPostingOverviews = (state = overViewState, action) => {
    switch(action.type) {
        case POSTING_OVERVIEWS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isGetInfo: false,
                isError: false,
                errorMessage: ""
            });
        case POSTING_OVERVIEWS_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""

            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = [];
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = [];
                isError = true;
            }else{
                responseData = action.data.map((item) => {
                    item.dummy_eval = Math.floor(Math.random() * (5 + 1 - 3)) + 3;
                    return item;
                });

                errorMessage = "";
            }

            return Object.assign({}, state, {
                isFetching: false,
                overViewData: responseData,
                isGetInfo: true,
                isError: isError,
                errorMessage: errorMessage
            });
        case POSTING_OVERVIEWS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isGetInfo: true,
                isError: true,
                errorMessage: action.err
            });
        case POSTING_OVERVIEWS_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isGetInfo: true,
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
}
