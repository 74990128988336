import axios from "axios";
import {BASE_URL, checkType, checkOverFlow, checkNumberOverflow, checkDateStyle, checkImageSize} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

//SAVE INTERN DETAIL
export const SAVE_JOB_DETAIL_REQUEST = "SAVE_JOB_DETAIL_REQUEST";
export const SAVE_JOB_DETAIL_SUCCESS = "SAVE_JOB_DETAIL_SUCCESS";
export const SAVE_JOB_DETAIL_FAILURE = "SAVE_JOB_DETAIL_FAILURE";
export const SAVE_JOB_DETAIL_RESET = "SAVE_JOB_DETAIL_RESET";

export const jobDetailSaveRequest = () => {
    return {
        type: SAVE_JOB_DETAIL_REQUEST
    }
}

export const jobDetailSaveSuccess = (data) => {
    return {
        type: SAVE_JOB_DETAIL_SUCCESS,
        data
    }
}

export const jobDetailSaveFailure = (err) => {
    return {
        type: SAVE_JOB_DETAIL_FAILURE,
        err
    }
}

export const jobDetailSaveReset = () => {
    return {
        type: SAVE_JOB_DETAIL_RESET
    }
}

const SAVE_JOB_POST = BASE_URL + "/api/save_job_posting";

export const saveJobPostingDetail = (savedata) => {
    return async(dispatch) => {
        dispatch(jobDetailSaveRequest());
        // 日付の記載を変更する
        savedata.period.employment_start = savedata.period.employment_start ? savedata.period.employment_start.replace(/-/g, "/"): null;
        savedata.period.employment_end = savedata.period.employment_end ? savedata.period.employment_end.replace(/-/g, "/"): null;
        savedata.period.posting_start = savedata.period.posting_start ? savedata.period.posting_start.replace(/-/g, "/"): null;
        savedata.period.posting_end = savedata.period.posting_end ? savedata.period.posting_end.replace(/-/g, "/"): null;

        // 空文字はnullに変更
        savedata.company_info.fax = savedata.company_info.fax === "" ? null: savedata.company_info.fax;

        const worktimeData = savedata.working_time.works;
        for (const data of worktimeData) {
            for (let d in data) {
                if (data[d] === "") {
                    data[d] = null;
                }
            }
        }

        savedata.working_time.working_day =
            savedata.working_time.working_day === "" ? null: savedata.working_time.working_day;

        savedata.working_time.other =
            savedata.working_time.other === "" ? null: savedata.working_time.other;

        savedata.total_holidays =
            savedata.total_holidays === "" ? null: savedata.total_holidays;
        
        savedata.paid_holiday = savedata.paid_holiday === "" ? null: savedata.paid_holiday;
        savedata.other_holiday = savedata.other_holiday === "" ? null: savedata.other_holiday;

        const deduction = savedata.livelihood_support.deduction;
        for (let data of deduction) {
            for (let d in data) {
                console.log(data[d])
                if (data[d] === "") {
                    data[d] = null;
                }
            }
        }

        savedata.payment_detail.rent =
            savedata.payment_detail.rent === "" ? null: savedata.payment_detail.rent;
        
        savedata.payment_detail.board =
            savedata.payment_detail.board === "" ? null: savedata.payment_detail.board;
        
        savedata.allowance = savedata.allowance === "" ? null: savedata.allowance;

        savedata.social_insurance.other = savedata.social_insurance.other === "" ? null: savedata.social_insurance.other;

        savedata.facility_introduction.comment =
            savedata.facility_introduction.comment === "" ? null: savedata.facility_introduction.comment;
        
        savedata.nearby_info.comment =
            savedata.nearby_info.comment === "" ? null: savedata.nearby_info.comment;

        console.log(savedata);
        const idToken = getIdToken();
        let verify = true;
        const headerParam = {
            Authorization: idToken
        }

        // 親パラメータチェック
        if (!_query_parent_check(savedata)) {
            console.log("a")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        // periodチェック
        if (!_period_check(savedata.period)) {
            console.log("b")
            return dispatch(jobDetailSaveSuccess(400));
        }
       
        // 企業情報チェック
        if (!_company_info_check(savedata.company_info)) {
            console.log("c")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        // 設定国チェック
        if (!_country_check(savedata.country)) {
            console.log("d")
            return dispatch(jobDetailSaveSuccess(400));
        }

        // 勤怠時間チェック
        if (!_working_time_check(savedata.working_time)) {
            console.log("e")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        // 待遇チェック
        if (!_livelihood_support_check(savedata.livelihood_support)) {
            console.log("f")
            return dispatch(jobDetailSaveSuccess(400));
        }

        // 給与関連チェック
        if (!_payment_detail_check(savedata.payment_detail)) {
            console.log("g")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        // 社会保険チェック
        if (!_social_insurance_check(savedata.social_insurance)) {
            console.log("h")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        // 勤務地チェック
        if (!_last_page_check(savedata.facility_introduction)) {
            console.log("i")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        // 近隣地情報チェック
        if (!_last_page_check(savedata.nearby_info)) {
            console.log("j")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        // 同意関連チェック
        if (!_insurance_check(savedata.insurance)) {
            console.log("k")
            return dispatch(jobDetailSaveSuccess(400));
        }
        
        axios.get(cognitoEndpoint).then(
            response => {
                var pems = {};
                var keys = response.data['keys'];
                for(var i = 0; i < keys.length; i++) {
                    var key_id = keys[i].kid;
                    var modulus = keys[i].n;
                    var exponent = keys[i].e;
                    var key_type = keys[i].kty;
                    var jwk = { kty: key_type, n: modulus, e: exponent};
                    var jtp = jwkToPem(jwk);
                    pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(jobDetailSaveFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(jobDetailSaveFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(jobDetailSaveFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(jobDetailSaveFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(jobDetailSaveFailure(err));
                        } else {
                            console.log("Valid Token.");
                            console.log(savedata)
                            axios.post(SAVE_JOB_POST, savedata, {headers: headerParam}).then(
                                // API側と通信する
                                response => {
                                    return dispatch(jobDetailSaveSuccess(response.data));
                                }
                            ).catch(
                                err => dispatch(jobDetailSaveFailure(err))
                                //res => dispatch(jobDetailSaveSuccess(200))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(jobDetailSaveFailure(err));
                //return dispatch(jobDetailSaveSuccess(200));
            }
        )
    }
    
}

const _query_parent_check = (query) => {
    let parent_check = true;
    for (const key in query) {
        if (!_intern_param[key]) {
            console.log(key)
            return false;
        }
        
        const target = query[key];

        if (_intern_param[key] !== checkType(target)) {
            console.log(key + target)
            if (target != null) {
                return false;
            }
        }

        if (key === "job_id") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "title") {
            parent_check = checkOverFlow(target);
        } else if (key === "entered_flg") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "accepted") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "employment_type") {
            parent_check = checkOverFlow(target);
        } else if (key === "renew_contract") {
            parent_check = checkOverFlow(target);
        } else if (key === "sex") {
            parent_check = checkNumberOverflow(target);
        } else if (key === "occupation") {
            parent_check = checkOverFlow(target);
        } else if (key === "content") {
            parent_check = checkOverFlow(target);
        } else if (key === "holiday") {
            parent_check = checkOverFlow(target);
        } else if (key === "total_holidays") {
            parent_check = checkOverFlow(target);
        } else if (key === "paid_holiday") {
            parent_check = checkOverFlow(target);
        } else if (key === "other_holiday") {
            parent_check = checkOverFlow(target);
        } else if (key === "allowance") {
            parent_check = checkOverFlow(target);
        }

        if (!parent_check) {
            return false;
        }
    }

    return parent_check;
}

const _period_check = (query) => {
    let period_check = true;
    for(const key in query) {
        if (!_period_param[key]) {
            return false;
        }

        const target = query[key];

        if (_period_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "employment_start") {
            period_check = checkDateStyle(target);
        } else if (key === "employment_end") {
            period_check = checkDateStyle(target);
        } else if (key === "posting_start") {
            period_check = checkDateStyle(target);
        } else if (key === "posting_end") {
            period_check = checkDateStyle(target);
        }

        if (!period_check) {
            return false;
        }
    }
    return period_check;
}

const _company_info_check = (query) => {
    let company_check = true;
    for(const key in query) {
        if (!_company_param[key]) {
            return false;
        }

        const target = query[key];

        if (_company_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "employee") {
            const eQuery = query[key];
            for (const eKey in eQuery) {

                const eTarget = eQuery[eKey];

                if (!_company_param[eKey]) {
                    return false;
                }

                if (_company_param[eKey] !== checkType(eTarget)) {
                    if (eTarget != null) {
                        return false;
                    }
                }

                if (eKey === "regular") {
                    company_check = checkNumberOverflow(eTarget);
                } else if (key === "part_time") {
                    company_check = checkNumberOverflow(eTarget);
                }

                if (!company_check) {
                    return false;
                }
            }
        }

        if (key === "name") {
            company_check = checkOverFlow(target);
        } else if (key === "address") {
            company_check = checkOverFlow(target);
        } else if (key === "president") {
            company_check = checkOverFlow(target);
        } else if (key === "tel") {
            company_check = checkOverFlow(target);
        } else if (key === "fax") {
            company_check = checkOverFlow(target);
        } else if (key === "web") {
            company_check = checkOverFlow(target);
        }  else if (key === "facility_name") {
            company_check = checkOverFlow(target);
        } else if (key === "facility_address") {
            company_check = checkOverFlow(target);
        } else if (key === "pic") {
            company_check = checkOverFlow(target);
        } else if (key === "position") {
            company_check = checkOverFlow(target);
        } else if (key === "pic_tel") {
            company_check = checkOverFlow(target);
        } else if (key === "mail") {
            company_check = checkOverFlow(target);
        }

        if (!company_check) {
            return false;
        }
    }

    return company_check;

}

const _working_time_check = (query) => {
    let work_check = true;
    for(const key in query) {
        if (!_working_time_param[key]) {
            return false;
        }

        const target = query[key];

        if (_working_time_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "working_day") {
            work_check = checkOverFlow(target);
        } else if (key === "other") {
            work_check = checkOverFlow(target);
        }

        if (key === "works") {
            for (const data of query[key]) {
                if (_working_time_param["start"] !== checkType(data["start"])) {
                    if (data["start"] != null) {
                        return false;
                    }
                }

                if (_working_time_param["end"] !== checkType(data["end"])) {
                    if (data["end"] != null) {
                        return false;
                    }
                }

                if (_working_time_param["break_time"] !== checkType(data["break_time"])) {
                    if (data["break_time"] != null) {
                        return false;
                    }
                }

                if (_working_time_param["actual_time"] !== checkType(data["actual_time"])) {
                    if (data["actual_time"] != null) {
                        return false;
                    }
                }

                if (!checkOverFlow(data["start"])) {
                    console.log("aa")
                    return false;
                }

                if (!checkOverFlow(data["end"])) {
                    console.log("ab")
                    return false;
                }

                if (!checkOverFlow(data["break_time"])) {
                    console.log("ac")
                    return false;
                }

                if (!checkOverFlow(data["actual_time"])) {
                    console.log("ad")
                    return false;
                }
            }
        }

        if (!work_check) {
            return false;
        }
    }
    return work_check;
}

const _livelihood_support_check = (query) => {
    let livelihood_check = true;
    for(const key in query) {
        if (!_livelihood_support_param[key]) {
            console.log(key)
            return false;
        }

        const target = query[key];

        if (_livelihood_support_param[key] !== checkType(target)) {
            console.log(key + target + checkType(target))
            return false;
        }

        if (key === "salary") {
            livelihood_check = checkNumberOverflow(target);
        }

        if (key === "deduction") {
            for (const data of query[key]) {
                if (_livelihood_support_param["nominal"] !== checkType(data["nominal"])) {
                    if (data["nominal" != null]) {
                        return false;
                    }
                }

                if (_livelihood_support_param["amount"] !== checkType(data["amount"])) {
                    return false;
                }

                if (!checkOverFlow(data["nominal"])) {
                    return false;
                }

                if (!checkNumberOverflow(data["amount"])) {
                    return false;
                }
            }
        }

        if (!livelihood_check) {
            return false;
        }
    }
    return livelihood_check;

}

const _payment_detail_check = (query) => {
    let payment_check = true;
    for(const key in query) {
        if (!_payment_detail_param[key]) {
            return false;
        }

        const target = query[key];

        if (_payment_detail_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "rent") {
            payment_check = checkOverFlow(target);
        } else if (key === "board") {
            payment_check = checkOverFlow(target);
        }

        if (!payment_check) {
            return false;
        }
    }
    return payment_check;
}

const _social_insurance_check = (query) => {
    let social_insurance_check = true;
    for (const key in query) {
        if (!_social_insurance_param[key]) {
            return false;
        }

        const target = query[key];

        if (_social_insurance_param[key] !== checkType(target)) {
            if (key === "other" && target != null) {
                return false;
            }
        }

        if (key === "other") {
            social_insurance_check = checkOverFlow(target);
        } else {
            for (const data of query[key]) {
                if (checkType(data) !== "boolean") {
                    social_insurance_check = false;
                }
            }
        }

        if (!social_insurance_check) {
            return false;
        }
    }
    return social_insurance_check;
}

const _last_page_check = (query) => {
    let last_check = true;
    for(const key in query) {
        if (!_last_page_param[key]) {
            return false;
        }

        const target = query[key];

        if (_last_page_param[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "image") {
            last_check = checkImageSize(target);
        } else if (key === "comment") {
            last_check = checkOverFlow(target);
        }

        if (!last_check) {
            return false;
        }
    }
    return last_check;

}

const _insurance_check = (query) => {
    console.log(query)
    for(const key in query) {
        if (!_insurance_param[key]) {
            console.log(key)
            return false;
        }

        const target = query[key];

        if (_insurance_param[key] !== checkType(target)) {
            console.log("bb")
            return false;
            
        }
    }
    return true;
}

const _country_check = (query) => {
    if (checkType(query) !== "array") {
        return false;
    }

    for (const param of query) {
        if (checkType(param) !== "boolean") {
            return false;
        }
    }
    return true;
}

const _intern_param = {
    "job_id": "number",
    "title": "string",
    "entered_flg": "number",
    "period": "object",
    "accepted": "number",
    "employment_type": "string",
    "renew_contract": "string",
    "company_info": "object",
    "sex": "number",
    "country": "array",
    "occupation": "string",
    "content": "string",
    "working_time": "object",
    "holiday": "string",
    "total_holidays": "string",
    "paid_holiday": "string",
    "other_holiday": "string",
    "livelihood_support": "object",
    "payment_detail": "object",
    "allowance": "string",
    "social_insurance": "object",
    "management": "object",
    "facility_introduction": "object",
    "nearby_info": "object",
    "insurance": "object"
}

const _period_param = {
    "employment_start": "string",
    "employment_end": "string",
    "posting_start": "string",
    "posting_end": "string",
}

const _company_param = {
    "company_id": "string",
    "name": "string",
    "address": "string",
    "president": "string",
    "tel": "string",
    "fax": "string",
    "web": "string",
    "employee": "object",
    "regular": "number",
    "part_time": "number",
    "facility_name": "string",
    "facility_address": "string",
    "pic": "string",
    "pic_tel": "string",
    "mail": "string",
    "position": "string"
}

const _working_time_param = {
    "works": "array",
    "start": "string",
    "end": "string",
    "break_time": "string",
    "actual_time": "string",
    "is_decide_shift": "boolean",
    "is_fixed_day": "boolean",
    "working_day": "string",
    "has_other": "boolean",
    "other": "string",
}

const _livelihood_support_param = {
    "salary": "number",
    "deduction": "array",
    "nominal": "string",
    "amount": "number",
}

const _payment_detail_param = {
    "rent": "string",
    "board": "string"
}

const _social_insurance_param = {
    "type": "array",
    "other": "string"
}

const _last_page_param = {
    "image": "string",
    "comment": "string"
}

const _insurance_param = {
    "accept_accident": "boolean",
    "accept_human_resources": "boolean",
    "request_training": "boolean",
    "request_promotion": "boolean"
}


export const saveJobDetailReset = () => {
    return (dispatch) => dispatch(jobDetailSaveReset());
}
