import axios from "axios";
import {BASE_URL, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
//import {INTERN_TITLE_DATA, INTERN_DETAIL_DATA} from "../../constants/TestData";
import {getIdToken, checkType, checkOverFlow, checkImageSize, checkDateStyle, checkNumberOverflow} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// SAVE STUDENT DATA
export const SAVE_STUDENT_DATA_REQUEST = "SAVE_STUDENT_DATA_REQUEST";
export const SAVE_STUDENT_DATA_SUCCESS = "SAVE_STUDENT_DATA_SUCCESS";
export const SAVE_STUDENT_DATA_FAILURE = "SAVE_STUDENT_DATA_FAILURE";
export const SAVE_STUDENT_DATA_RESET = "SAVE_STUDENT_DATA_RESET";

export const saveStudentDataRequest = () => {
    return {
        type: SAVE_STUDENT_DATA_REQUEST
    }
}

export const saveStudentDataSuccess = (data) => {
    return {
        type: SAVE_STUDENT_DATA_SUCCESS,
        data
    }
}

export const saveStudentDataFailure = (err) => {
    return {
        type: SAVE_STUDENT_DATA_FAILURE,
        err
    }
}

export const saveStudentDataReset = () => {
    return {
        type: SAVE_STUDENT_DATA_RESET
    }
}

const SAVE_STUDENT_LIST = BASE_URL + "/api/university_save_candidate";

export const saveStudentData = (studentData) => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(saveStudentDataRequest());
        delete studentData.candidate_status;

        const sendQuery = JSON.parse(JSON.stringify(studentData));

        sendQuery.candidate_birthday 
            = sendQuery.candidate_birthday ? sendQuery.candidate_birthday.replace(/-/g, "/"): null;

        sendQuery.candidate_passportlimit
            = sendQuery.candidate_passportlimit ? sendQuery.candidate_passportlimit.replace(/-/g, "/"): null;

        console.log(sendQuery)
        if (!_check_save_param(sendQuery)) {
            return dispatch(saveStudentDataSuccess(400))
        }

        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(saveStudentDataFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(saveStudentDataFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(saveStudentDataFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(saveStudentDataFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(saveStudentDataFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(SAVE_STUDENT_LIST, sendQuery, {headers: headerParam}).then(
                                res => {
                                    dispatch(saveStudentDataSuccess(res.data))
                                }
                            ).catch(
                                err => dispatch(saveStudentDataFailure(err))
                                //res => dispatch(universityOverviewSuccess(INTERN_TITLE_DATA))
                                //res => dispatch(saveStudentDataSuccess(200))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(saveStudentDataFailure(err))
            }
        )
    }
}

export const resetSaveStudentData = () => {
    return (dispatch) => dispatch(saveStudentDataReset());
}

// DELETE STUDENT DATA
export const DELETE_STUDENT_DATA_REQUEST = "DELETE_STUDENT_DATA_REQUEST";
export const DELETE_STUDENT_DATA_SUCCESS = "DELETE_STUDENT_DATA_SUCCESS";
export const DELETE_STUDENT_DATA_FAILURE = "DELETE_STUDENT_DATA_FAILURE";

export const deleteStudentDataRequest = () => {
    return {
        type: DELETE_STUDENT_DATA_REQUEST
    }
}

export const deleteStudentDataSuccess = (data) => {
    return {
        type: DELETE_STUDENT_DATA_SUCCESS,
        data
    }
}

export const deleteStudentDataFailure = (err) => {
    return {
        type: DELETE_STUDENT_DATA_FAILURE,
        err
    }
}

const _check_save_param = (query) => {
    let checkResult = false;
    for (const key in query) {
        if (!_save_candidate_map[key]) {
            return false;
        }

        const target = query[key];

        if (_save_candidate_map[key] !== checkType(target)) {
            if (target != null) {
                return false;
            }
        }

        if (key === "candidate_id") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_photo") {
            checkResult = checkImageSize(target)
        } else if (key === "candidate_student_no") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_name") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_nickname") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_country") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_birthday") {
            checkResult = checkDateStyle(target);
        } else if (key === "candidate_sex") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_japanese_level") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_department") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_acquired_credits") {
            checkResult = checkNumberOverflow(target);
        } else if (key === "candidate_schedule_credits") {
            checkResult = checkNumberOverflow(target);
        } else if (key === "candidate_passportno") {
            checkResult = checkOverFlow(target);
        } else if (key === "candidate_passportlimit") {
            checkResult = checkDateStyle(target);
        }

        if (!checkResult) {
            return false;
        }
    }
    return checkResult;
}

const _save_candidate_map = {
    "candidate_id": "string",
    "candidate_photo": "string",
    "candidate_student_no": "string",
    "candidate_name": "string",
    "candidate_nickname": "string",
    "candidate_country": "string",
    "candidate_birthday": "string",
    "candidate_sex": "string",
    "candidate_japanese_level": "string",
    "candidate_department": "string",
    "candidate_acquired_credits": "number",
    "candidate_schedule_credits": "number",
    "candidate_passportno": "string",
    "candidate_passportlimit": "string"
}

const DELETE_STUDENT_LIST = BASE_URL + "/api/university_del_candidate";

export const deleteStudentData = (candidateId) => {
    const idToken = getIdToken();
    let verify = true;
    return async(dispatch) => {
        dispatch(deleteStudentDataRequest());
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(deleteStudentDataFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(deleteStudentDataFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(deleteStudentDataFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(deleteStudentDataFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(deleteStudentDataFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const query = {
                                "candidate_id": candidateId
                            };
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(DELETE_STUDENT_LIST, query, {headers: headerParam}).then(
                                res => {
                                    dispatch(deleteStudentDataSuccess(res.data))
                                }
                            ).catch(
                                err => dispatch(deleteStudentDataFailure(err))
                                //res => dispatch(universityOverviewSuccess(INTERN_TITLE_DATA))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(deleteStudentDataFailure(err))
            }
        )
    }
}
