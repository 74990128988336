import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
import {CANDIDATE_LIST_DATA} from "../../constants/TestData";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET CANDIDATE LIST
export const GET_CANDIDATE_LIST_REQUEST = "GET_CANDIDATE_LIST_REQUEST";
export const GET_CANDIDATE_LIST_SUCCESS = "GET_CANDIDATE_LIST_SUCCESS";
export const GET_CANDIDATE_LIST_FAILURE = "GET_CANDIDATE_LIST_FAILURE";
export const GET_CANDIDATE_LIST_RESET = "GET_CANDIDATE_LIST_RESET";

export const getCandidateRequest = () => {
    return {
        type: GET_CANDIDATE_LIST_REQUEST
    }
}

export const getCandidateSuccess = (data) => {
    return {
        type: GET_CANDIDATE_LIST_SUCCESS,
        data
    }
}

export const getCandidateFailure = (err) => {
    return {
        type: GET_CANDIDATE_LIST_FAILURE,
        err
    }
}

export const getCandidateReset = () => {
    return {
        type: GET_CANDIDATE_LIST_RESET
    }
}

const GET_CANDIDATE_LIST= BASE_URL + "/api/intern_candidate_list";

export const getInternCandidateList = (internId) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        intern_id: internId
    }
    return async(dispatch) => {
        dispatch(getCandidateRequest());
        if (!_check_param(query)){
            return dispatch(getCandidateSuccess(400));
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                    var pems = {};
                    var keys = response.data['keys'];
                    for(var i = 0; i < keys.length; i++) {
                        var key_id = keys[i].kid;
                        var modulus = keys[i].n;
                        var exponent = keys[i].e;
                        var key_type = keys[i].kty;
                        var jwk = { kty: key_type, n: modulus, e: exponent};
                        var jtp = jwkToPem(jwk);
                        pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(getCandidateFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        verify = false;
                        return dispatch(getCandidateFailure("audクレームがアプリクライアント ID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt);
                        verify = false;
                        return dispatch(getCandidateFailure("issクレームがユーザープール ID と一致しません"));
                    }

                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(getCandidateFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                verify = false;
                                return dispatch(getCandidateFailure(err));
                            } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                axios.post(GET_CANDIDATE_LIST, query, {headers: headerParam}).then(
                                    res => dispatch(getCandidateSuccess(res.data))
                                ).catch(
                                    err => dispatch(getCandidateFailure(err))
                                    //res => dispatch(getCandidateSuccess(CANDIDATE_LIST_DATA))
                                    //res => dispatch(getCandidateSuccess(500))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(getCandidateFailure(err))
                }
            )
        }
    }
}

export const candidateReset = () => {
    return (dispatch) => dispatch(getCandidateReset());
}

const _check_param = (query) => {
    let check_ok = true;
    if (query.hasOwnProperty("intern_id")) {
        const target = query.intern_id;
        if (checkType(target) !== "number") {
            return false;
        }
        check_ok = checkNumberOverflow(target)
    } else {
        return false;
    }

    return check_ok;
}
