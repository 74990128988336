import { 
    SAVE_JOB_DETAIL_REQUEST,
    SAVE_JOB_DETAIL_SUCCESS,
    SAVE_JOB_DETAIL_FAILURE,
    SAVE_JOB_DETAIL_RESET
 } from "../../actions/manage/SaveJobPostingAction";

 const saveJobPostingState = {
     isFetching: false,
     saveState: "",
     isError: false,
     errorMessage: "",
     isSaveAction: false
 }

 // SAVE JOB POSTING DETAIL
 export const postJobDetailData = (state = saveJobPostingState, action) => {
     switch(action.type) {
         case SAVE_JOB_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                saveState: "",
                isError: false,
                errorMessage: "",
                isSaveAction: true
             });
        case SAVE_JOB_DETAIL_SUCCESS:
            let responseData = ""
            let isError = false;
            let errorMessage = ""
            if (action.data === 400) {
                errorMessage = "400 パラメータが不正です";
                responseData = "";
                isError = true;
            } else if (action.data === 500) {
                errorMessage = "500 予期しないエラーが発生しました";
                responseData = "";
                isError = true;
            } else {
                responseData = action.data;
                errorMessage = "";
            }
            return Object.assign({}, state, {
                isFetching: false,
                saveState: responseData,
                isError: isError,
                errorMessage: errorMessage,
                isSaveAction: true
            });
        case SAVE_JOB_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: true,
                errorMessage: action.err,
                isSaveAction: true
            });
        case SAVE_JOB_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: false,
                errorMessage: "",
                isSaveAction: true
             });
        default:
            return Object.assign({}, state, {
                isFetching: false,
                saveState: "",
                isError: false,
                errorMessage: "",
                isSaveAction: false
             });
     }
 }