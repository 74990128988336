import React from "react";
import Button from "../atoms/Button";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import EditDetail from "./editParts/editDetail"
import {connect} from "react-redux";
import {getStudentList, resetStudentList} from "../../actions/univ/GetStudentAction";
import {resetSaveStudentData} from "../../actions/univ/ControllStudentAction";
import {initial_student_data} from "../../constants/CommonConstatns";
import "../../css/Common.css";
import "../../css/Edit.css";


class Edit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            studentsList: [],
            nowLoading: false
        };
    }

    componentDidMount = () => {
        this.props.getStudentList()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.studentsList !== nextProps.studentsList) {
            return {
                studentsList: nextProps.studentsList,
                nowLoading: nextProps.nowLoading
            }
        }
    }

    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo)
    }

    addData = () => {
        const newData = JSON.parse(JSON.stringify(initial_student_data));
        this.state.studentsList.push(newData);
        this.setState({
            studentsList: this.state.studentsList
        });
    }

    listErrorDisable = () => {
        this.props.resetStudentList();
    }

    dataErrorDisable = () => {
        this.props.resetStudentData();
    }

    render() {
        return (
            <article>
                {this.state.nowLoading ? <Load setLabel="読み込み中..."/>: null}
                {this.props.isListError ? <Error close={this.listErrorDisable} apiName="university_list_candidate" setError={this.props.listErrorMessage} /> : null}
                {this.props.isDataError ? <Error close={this.dataErrorDisable} apiName="university_save_candidate" setError={this.props.dataErrorMessage} /> : null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("univ_search")}>インターン検索</a></li>
                        <li className="navigationItem active">インターン応募者登録</li>
                        <li className="navigationItem"><a className="navigationLink" onClick={() => this.handleToDisplay("profile")}>プロフィール編集</a></li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("univ_top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem">インターン応募者登録</li>
                    </ol>
                </div>
                <div className="editContents">
                    <p className="editExplanation">以下の項目を入力し、「保存」ボタンを押してください。</p>
                    <p className="editExpBold">「<span className="editRequired">学籍番号</span>」は必須入力であるため、空欄の際、「保存」ボタンは押せません。</p>
                    {
                        this.state.studentsList.map((studentData, index) => {
                            return(
                                <EditDetail 
                                    index_num={index}
                                    studentData={studentData}
                                    candidateStatus={studentData.candidate_status}
                                    />
                            )
                        })
                    }
                    {/*
                    <div className="editButtonArea">
                        <div className="editAddButton">
                            <Button label="追加" buttonEnable={true} callback={() => this.addData()}/>
                        </div>
                    </div>
                    */}
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        studentsList: state.getStudentList.studentListData,
        nowLoading: !state.getStudentList.isGetInfo,
        isListError: state.getStudentList.isError,
        listErrorMessage: state.getStudentList.errorMessage,
        isDataError: state.saveStudentData.isError,
        dataErrorMessage: state.saveStudentData.errorMessage
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStudentList: () => dispatch(getStudentList()),
    resetStudentList: () => dispatch(resetStudentList()),
    resetStudentData: () => dispatch(resetSaveStudentData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);

