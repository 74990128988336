import axios from "axios";
import {BASE_URL} from "../../constants/CommonConstatns";
import {HISTORY_TEST} from "../../constants/TestData";
import {getIdToken, cognitoIss, cognitoEndpoint, checkType, checkMonthStyle} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET INSURANCE HISTORY
export const GET_INSURANCE_HISTORY_REQUEST = "GET_INSURANCE_HISTORY_REQUEST";
export const GET_INSURANCE_HISTORY_SUCCESS = "GET_INSURANCE_HISTORY_SUCCESS";
export const GET_INSURANCE_HISTORY_FAILURE = "GET_INSURANCE_HISTORY_FAILURE";
export const GET_INSURANCE_HISTORY_CLEAR = "GET_INSURANCE_HISTORY_CLEAR";

export const insuranceHisotryRequest = () => {
    return {
        type: GET_INSURANCE_HISTORY_REQUEST
    }
}

export const insuranceHisotrySuccess = (data) => {
    return {
        type: GET_INSURANCE_HISTORY_SUCCESS,
        data
    }
}

export const insuranceHisotryFailure = (err) => {
    return {
        type: GET_INSURANCE_HISTORY_FAILURE,
        err
    }
}

export const insuranceHistoryClear = () => {
    return {
        type: GET_INSURANCE_HISTORY_CLEAR
    }
}

const GET_INSRANCE_HISTORY = BASE_URL + "/api/skt_insurance_history";

export const getInsuranceHistory = (targetStart, targetEnd) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        target_start: targetStart,
        target_end: targetEnd
    }
    return async(dispatch) => {
        dispatch(insuranceHisotryRequest());

        if (!_param_check(query)) {
            return dispatch(insuranceHisotrySuccess(400))
        }
        
        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(insuranceHisotryFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(insuranceHisotryFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(insuranceHisotryFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(insuranceHisotryFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(insuranceHisotryFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const headerParam = {
                                Authorization: idToken
                            }
                            axios.post(GET_INSRANCE_HISTORY, query, {headers: headerParam}).then(
                                res => {
                                    dispatch(insuranceHisotrySuccess(res.data))
                                }
                            ).catch(
                                err => dispatch(insuranceHisotryFailure(err))
                                //res => dispatch(insuranceHisotrySuccess(HISTORY_TEST))
                                //dispatch(insuranceHisotrySuccess(500))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(insuranceHisotryFailure(err))
            }
        )
    }
}

export const clearInsuranceHistory = () => {
    return (dispatch) => dispatch(insuranceHistoryClear());
}

const _param_check = (query) => {
    for (const key in query) {
        const target = query[key];
        
        if (key !== "target_start" && key !== "target_end") {
            return false;
        }

        if (checkType(target) !== "string") {
            return false;
        }

        if (target !== "" && !checkMonthStyle(target)) {
            return false;
        }
    }
    return true;
}
 

