import React from "react";
import "../../../css/Common.css";
import "../../../css/JobDetail.css"
import Textarea from "../../atoms/Textarea";
import Button from "../../atoms/Button";
import {EntryStatusMap, EntryStatusMessageMap} from "../../../constants/JobPostingConstants"

class EntryListTable_S extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sendMessage: []
        }
    }

    componentDidMount = () => {
        const setList = new Array(this.props.entryList.length).fill("");
        this.setState({
            sendMessage: setList
        })
    }

    getMessage = (message, index) => {
        const insertList = this.state.sendMessage.slice();
        insertList[index] = message;
        this.setState({
            sendMessage: insertList
        }, () => {
            this.props.setSendingMessage(index, this.state.sendMessage);
        });
    }

    reactionForRequest = (index, action) => {
        this.props.sendReaction(index, action)
    }

    displayStatusArea = (status, index) => {
        if (status == 1) {
            return(
                <div id="reactionWrapper">
                    <Textarea id="content" name="content" required={true} maxLength="499" setValue={this.state.sendMessage[index]} onHandleChange={(e) => this.getMessage(e, index)}/>
                    <div id="reactionAcceptBtnWrapper">
                        <Button label="受付" buttonEnable={true} callback={() => this.reactionForRequest(index, 1)}/>
                    </div>
                    <div id="reactionDenyBtnWrapper">
                        <Button label="辞退" buttonEnable={true} callback={() => this.reactionForRequest(index, 0)}/>
                    </div>
                </div>
            )

        } else {
            return(
                EntryStatusMap[status]
            )
        }
    }

    render() {
        return (
            <div className="detailJobResultItem">
                <h3 className="detailJobItemSubHeading">エントリー情報</h3>
                <table border="1" bordercolor="#ffffff">
                    <thead>
                        <tr>
                            <th>送り出し機関</th>
                            <th>エントリー日</th>
                            <th>メッセージ</th>
                            <th>応募依頼</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.entryList.map((value, index) => {
                                const date = value.entry_date.split(" ")[0];

                                return(
                                    <tr key={index}>
                                        <td>{value.send_org}</td>
                                        <td>{date}</td>
                                        <td>
                                            {/* <p>{EntryStatusMessageMap[value.status]}</p>
                                            <p>コメント：{value.message}</p> */}
                                            {
                                                value.message.split('\n').map((str, index) => {
                                                    return(
                                                        <p key={index}>{str}<br/></p>
                                                    )
                                            })
                                            }
                                        </td>
                                        <td>{this.displayStatusArea(value.status, index)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
        
    }
}

export default EntryListTable_S;











