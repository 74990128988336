import React from "react";
import "../../../css/Common.css";
import "../../../css/JobDetail.css"
import {EntryStatusMessageMap} from "../../../constants/JobPostingConstants"

class EntryListTable_O extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="detailJobResultItem">
                <h3 className="detailJobItemSubHeading">エントリー情報</h3>
                <div>
                    <ul className="entryLogResultItemLogList">
                        {
                            this.props.entryList.map((value, index) => {
                                const date = value.entry_date.replace(":", "時") + "分";
                                const comment = value.message;
                                return(
                                    <li className="entryLogResultItemLogItem" key={index}>
                                        <time className="entryLogResultItemLogDay">{date}</time>
                                        <div className="entryLogResultItemLogStatus">{comment}</div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            
        )
        
    }
}

export default EntryListTable_O;











