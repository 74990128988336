import React from "react";
import {connect} from "react-redux";
import "../../css/Common.css";
import Button from "../atoms/Button";
import TextInput from "../atoms/TextInput";
import Load from "../atoms/Load";
import Error from "../atoms/Error";
import "../../css/EditJob.css"
import {RenewContractMap, EmploymentTypeMap} from "../../constants/CommonConstatns";
import {ErrorMap} from "../../constants/ManageConstants";

class EditJob extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            employment_start: "",
            employment_end: "",
            posting_start: "",
            posting_end: "",
            renew_contract: "原則として更新する",
            employment_type: "契約社員",
            error_message: [],
            accepted: 2
        }
        this.getJobTitle = this.getJobTitle.bind(this);
        this.getAccepted = this.getAccepted.bind(this);
        this.getEmploymentType = this.getEmploymentType.bind(this);
        this.getRenewContract = this.getRenewContract.bind(this);
        this.getEmploymentStart = this.getEmploymentStart.bind(this);
        this.getEmploymentEnd = this.getEmploymentEnd.bind(this);
        this.getPostingStart = this.getPostingStart.bind(this);
        this.getPostingEnd = this.getPostingEnd.bind(this);
    }

    componentDidMount = () => {
        console.log(this.props.jobData)
        this.setState({
            title: this.props.jobData.title,
            accepted: this.props.jobData.accepted,
            employment_type: this.props.jobData.employment_type,
            renew_contract: this.props.jobData.renew_contract,
            error_message: this.props.errorMessage,
            employment_start: this.props.jobData.period.employment_start == null ? "": this.props.jobData.period.employment_start.replace(/\//g, "-"),
            employment_end: this.props.jobData.period.employment_end == null ? "": this.props.jobData.period.employment_end.replace(/\//g, "-"),
            posting_start: this.props.jobData.period.posting_start == null ? "": this.props.jobData.period.posting_start.replace(/\//g, "-"),
            posting_end: this.props.jobData.period.posting_end == null ? "": this.props.jobData.period.posting_end.replace(/\//g, "-")
        });
        window.scrollTo(0, 0);
    }

    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetState();
        }
        return this.props.changeDisp(changeTo, isReset)
    }

    temporalySave = () => {
        this.props.saveTemporaly()
    }

    getJobTitle = (jobTitle) => {
        this.setState({
            title: jobTitle
        });
        this.props.getJobTitle(jobTitle)
    }

    getAccepted = (acpt) => {
        this.setState({
            accepted: parseInt(acpt)
        });
        this.props.getAccepted(parseInt(acpt));
    }

    getEmploymentStart = (employmentStart) => {
        this.setState({
            employment_start: employmentStart
        });
        this.props.getEmploymentStart(employmentStart)
    }

    getEmploymentEnd = (employmentEnd) => {
        this.setState({
            employment_end: employmentEnd
            
        });
        this.props.getEmploymentEnd(employmentEnd)
    }

    getPostingStart = (posStart) => {
        this.setState({
            posting_start: posStart
        });
        this.props.getPostingStart(posStart)
    }

    getPostingEnd = (posEnd) => {
        this.setState({
            posting_end: posEnd
        });
        this.props.getPostingEnd(posEnd)
    }

    getEmploymentType = (event) => {
        this.setState({
            employment_type: event.target.value
        });
        this.props.getEmploymentType(event.target.value)
    }

    getRenewContract = (event) => {
        this.setState({
            renew_contract: event.target.value
        });
        this.props.getRenewContract(event.target.value)
    }

    addInternTitle = (internTitle) => {
        this.setState({
            title: internTitle
        });
    }

    saveReset = () => {
        this.props.saveReset();
    }

    render() {
        let isDisplay = (this.state.error_message.length === 0) ? "errorArea notDisplay": "errorArea";

        var renewContractOptions = Object.entries(RenewContractMap).map(
            ([key, value])=>(
                <option key={key} value={value}>
                    {value}
                </option>
            )
        );

        var employmentTypeOptions = Object.entries(EmploymentTypeMap).map(
            ([key, value])=>(
                <option key={key} value={value}>
                    {value}
                </option>
            )
        );

        return (
            <article>
                {this.props.isFetch ? <Load setLabel="保存中..."/>: null}
                {this.props.isError ? <Error close={this.saveReset} apiName="save_job_posting" setError={this.props.saveMessage} /> : null}
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top", true)}>メニュー</a></li>
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("show_job", true)}>求人案件管理</a></li>
                        <li className="breadcrumbItem">求人案件登録</li>
                    </ol>
                </div>
                <div className="editInternStepArea layoutInner">
                    <ul className="editInternStepList">
                        <li className="editInternStepItem active"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job", false)}>募集時期</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_2", false)}>企業・施設概要</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_3", false)}>条件・業務</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_4", false)}>待遇</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_5", false)}>施設紹介</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_6", false)}>周辺情報</a></li>
                        <li className="editInternStepItem"><a className="editInternStepLink" onClick={() => this.handleToDisplay("edit_job_7", false)}>サポートサービス</a></li>
                        <li className="editInternStepItem">登録完了</li>
                    </ul>
                </div>
                <div className="editInternContents editIntern1">
                    <p className="editInternExplanation">以下の項目を入力し、「次へ>」ボタンを押してください。「<span className="editInternRequired">*</span>」のついた項目は必須入力です。</p>
                    <ul className={isDisplay}>
                        {
                            this.state.error_message.map((value, index) => {
                                return (
                                    <li key={index} className="errorText">{ErrorMap[value]}</li>
                                )
                            })
                        }
                    </ul>
                    <section className="editInternItem">
                        <form action="" method="post">
                            <div className="editInternInputItem">
                                <label htmlFor="title">求人案件名<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="title" name="title" type="text" required={true} maxLength="300" setValue={this.state.title} onHandleChange={(e) => this.getJobTitle(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="accepted">人数<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="accepted" name="accepted" type="number" value="2" required={true} setValue={this.state.accepted} onHandleChange={(e) => this.getAccepted(e)}/>
                                    人
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodPublishEnd">雇用形態<span className="editInternRequired">*</span></label>
                                <div className="selectWrapper editInternInputSelect">
                                    <select id="term" name="term" required value={this.state.employment_type} onChange={this.getEmploymentType}>
                                    {employmentTypeOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodPublishStart">雇用期間<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodPublishStart" name="periodPublishStart" type="date" setValue={this.state.employment_start} required={true} onHandleChange={(e) => this.getEmploymentStart(e)}/>
                                    〜
                                    <TextInput id="periodPublishEnd" name="periodPublishEnd" type="date" required={true} setValue={this.state.employment_end} onHandleChange={(e) => this.getEmploymentEnd(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodPublishEnd">更新有無<span className="editInternRequired">*</span></label>
                                <div className="selectWrapper editInternInputSelect" id="renewContract">
                                    <select id="term" name="term" required value={this.state.renew_contract} onChange={this.getRenewContract}>
                                    {renewContractOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodRecruitStart">掲載開始<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodRecruitStart" name="periodRecruitStart" type="date" required={true} setValue={this.state.posting_start} onHandleChange={(e) => this.getPostingStart(e)}/>
                                </div>
                            </div>
                            <div className="editInternInputItem">
                                <label htmlFor="periodRecruitEnd">掲載終了<span className="editInternRequired">*</span></label>
                                <div className="editInternInputInputArea">
                                    <TextInput id="periodRecruitEnd" name="periodRecruitEnd" type="date" required={true} setValue={this.state.posting_end} onHandleChange={(e) => this.getPostingEnd(e)}/>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className="editInternButtonArea layoutInner">
                    <div className="editInternSaveButton">
                        <Button label="途中保存" buttonEnable={true} callback={() => this.temporalySave()}/>
                    </div>
                    <div className="editInternNextButton">
                        <Button label="次へ" buttonEnable={true} callback={() => this.handleToDisplay("edit_job_2", false)}/>
                    </div>
                </div>
            </article>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetch: state.postJobDetailData.isFetching,
        isError: state.postJobDetailData.isError,
        saveMessage: state.postJobDetailData.errorMessage
    }
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(EditJob);

