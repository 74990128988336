import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Header from "../components/common_parts/Header";
import Footer from "../components/common_parts/Footer";
import Button from "./atoms/Button";
import Top from "./send/Top"
import ShowJob from "./posting/ShowJob";
import ShowApplicant from "./posting/ShowApplicant";
import JobDetail from "./posting/DetailJob";
import jwt from "jsonwebtoken";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";
const CognitoRefreshToken = require('amazon-cognito-identity-js-node').CognitoRefreshToken;
const config = require('./config');

const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId,
  ClientId: config.ClientId,
  Storage: sessionStorage
});


class ManageHomeTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            display: "top",
            jobId: null,
            organizationId: null,
            expired: 0,
            userType: "",
            applicantIdList:[],
            isEdit: false,
            loginId: ""
        }

        this.switchDisplay = this.switchDisplay.bind(this);
        this.switchDisplayWithJobId = this.switchDisplayWithJobId.bind(this);
    }

    refreshToken = () => {
        var CurrentUser = userPool.getCurrentUser();
        if(this.state.expired > 0){
            var date = new Date();
            var nowDate = date.getTime();
            var unixtime = Math.floor(nowDate / 1000);
            if(this.state.expired < unixtime){
                 if (CurrentUser != null) {
                     CurrentUser.signOut();
                     CurrentUser.getSession(function(err, session) {
                         console.log(session);
                     });
                 }
                sessionStorage.clear();
                this.props.history.push('/login')
                return;
            }
        }
        let _this = this;
        if (CurrentUser != null) {
            CurrentUser.getSession(function(err, session) {
                if(_this.state.userType === ""){
                    CurrentUser.getUserAttributes(function(err, attrresult) {
                        if (err) {
                            console.log(err);
                            return;
                        }
                        for (var i = 0; i < attrresult.length; i++) {
                            if (attrresult[i].getName()==="custom:user_type"){
                                let user_type = attrresult[i].getValue();
                                console.log(user_type)
                                _this.setState({
                                    userType: user_type
                                })
                            } else if (attrresult[i].getName()==="email"){
                                let email = attrresult[i].getValue();
                                _this.setState({
                                    loginId: email
                                })
                            }
                        }
                    });
                }
                var refreshTokenstr = session.getRefreshToken().getToken();
                var refreshToken = new CognitoRefreshToken({RefreshToken: refreshTokenstr});

                CurrentUser.refreshSession(refreshToken, (err, result)=>{
                    var idToken = result.getIdToken().getJwtToken();
                    var decoded = jwt.decode(idToken, { complete: true });
                    console.log(decoded);
                    _this.setState({
                        expired: decoded.payload.exp
                    })
                });
            });
        }

    }

    componentDidMount = () => {
        this.browserBackBtnAction();
        this.refreshToken();
    }

    switchDisplay = (dispId) => {
        this.refreshToken();
        this.setState({
            display: dispId
        });
    }

    switchDisplayWithJobId = (dispId, jobId, orgId) => {
        this.refreshToken();
        this.setState(
            {
                jobId: jobId,
                organizationId: orgId
            }, () => {
                this.setState({
                    display: dispId,
                })
            }
        )
    }

    setIsEdit = (isedit) => {
        this.setState({
            isEdit: isedit
        });
    }

    setApplicantIdList = (list) => {
        this.setState({
            applicantIdList: list.slice()
        })
        console.log(this.state.applicantIdList);
    }

    logoutAction = () => {
        const CurrentUser = userPool.getCurrentUser();
        if (CurrentUser != null) {
            CurrentUser.signOut();
            sessionStorage.clear();
            CurrentUser.getSession(function(err, session) {
                 console.log(session);
            });
            this.props.history.replace("/login");
        }
    }

    browserBackBtnAction = () => {
        window.addEventListener("popstate", (e) => {
            const CurrentUser = userPool.getCurrentUser();
            if (CurrentUser != null) {
                CurrentUser.signOut();
                sessionStorage.clear();
                this.props.history.push("/login");
            }
            return;
        });
    }

    render() {
        let displayParts;
        if (this.state.display === "top") {
            displayParts = <Top 
                changeDisp={this.switchDisplay}
                logout_action={this.logoutAction}
                loginId={this.state.loginId}
                />;
        } else if (this.state.display === "show_job") {
            displayParts = <ShowJob 
                getCompanyData={() => this.props.getCompanyData()} 
                changeDisp={this.switchDisplay} 
                changeDispWithId={this.switchDisplayWithJobId} 
                setIsNewJob={() => {}}
                isCompanyError={this.props.isCompanyDataError} 
                errorDisable={this.companyInfoErrorDisable}
                companyInfoMessage={this.props.companyDataError}
                userType={this.state.userType}
                setApplicantList={this.setApplicantIdList}
                setIsEdit={this.setIsEdit}
                setPreFacility={() => {}}
                setPrePlace={() => {}}
            />;
        } else if (this.state.display === "job_detail") {
            displayParts = <JobDetail 
                jobId={this.state.jobId}
                organizationId={this.state.organizationId}
                user_type={this.state.userType}
                changeDisp={this.switchDisplay}
                changeDispWithData={null}
                applicantIdList={this.state.applicantIdList}
                setIsEdit={this.setIsEdit}
                isEdit={this.state.isEdit}
                setApplicantList={this.setApplicantIdList}
            />
        } else if (this.state.display === "show_applicant") {
            displayParts = <ShowApplicant 
                getCompanyData={() => this.props.getCompanyData()} 
                changeDisp={this.switchDisplay} 
                changeDispWithId={this.switchDisplayWithJobId}
                setIsEdit={this.setIsEdit}
                isEdit={this.state.isEdit}
                setApplicantList={this.setApplicantIdList}
            />;
        }
        
        if (this.state.display === "top") {
            return(
                displayParts
            )
        } else {
            return (
                <div>
                    <Header />
                    <article className="contentsArea">
                        <div id="logoutBtnWrapper">
                            <Button label="ログアウト" buttonEnable={true} callback={() => this.logoutAction()}/>
                        </div>
                        {displayParts}
                    </article>
                    <Footer />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
}

function mapDispatchToProps(dispatch) {
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageHomeTemplate));

