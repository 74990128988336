import React from "react";
import "../../../css/Common.css";
import "../../../css/JobDetail.css"
import Button from "../../atoms/Button";
import {StatusMap, SexMap} from "../../../constants/ApplicantPostingConstants"

class ApplicantTable extends React.Component {
    constructor(props) {
        super(props)
        
    }

    render() {
        return (
            <div className="detailJobResultItem">
                <h3 className="detailJobItemSubHeading">応募者一覧</h3>
                <table border="1" bordercolor="#ffffff">
                    <thead>
                        <tr>
                            <th>送り出し機関</th>
                            <th>氏名（ローマ字）</th>
                            <th>国籍</th>
                            <th>性別</th>
                            <th>日本語</th>
                            <th>ステータス</th>
                            <th>連絡メッセージ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.entryApplicantList.map((value, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{value.send_org_name}</td>
                                        <td>{value.name}</td>
                                        <td>{value.country}</td>
                                        <td>{SexMap[value.sex]}</td>
                                        <td>{value.qualification_japanese}</td>
                                        <td>{StatusMap[value.status]}</td>
                                        <td>
                                            {
                                                value.message.length > 0?
                                                    value.message.map((v, i) => {
                                                        return(<p>{v}</p>)
                                                    })
                                                :null
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
        
    }
}

export default ApplicantTable;











