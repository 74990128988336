import React from "react";
import "../../../css/Common.css";
import "../../../css/JobDetail.css"
import {EntryStatusMap, EntryStatusMessageMap} from "../../../constants/JobPostingConstants"

class EntryListTable_H_K extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="detailJobResultItem">
                <h3 className="detailJobItemSubHeading">エントリー情報</h3>
                <table border="1" bordercolor="#ffffff">
                    <thead>
                        <tr>
                            <th>送り出し機関</th>
                            <th>エントリー日</th>
                            <th>メッセージ</th>
                            <th>ステータス</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.entryList.map((value, index) => {
                                const date = value.entry_date.split(" ")[0];
                                const status = EntryStatusMap[value.status];
                                return(
                                    <tr key={index}>
                                        <td>{value.send_org}</td>
                                        <td>{date}</td>
                                        <td>
                                            <p>{value.message}</p>
                                        </td>
                                        <td>{status}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
        
    }
}

export default EntryListTable_H_K;











