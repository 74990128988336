import React, {useState} from "react";

export default function PasswordInput(props) {
    const [pw, setpw] = useState(props.setValue)

    const handleChange = (e) => {
        setpw(e.target.value);
        props.onHandleChange(e.target.value)
    }

    return(
        <input type="password" value={pw} onChange={handleChange} placeholder="　パスワード"/>
    )
}
