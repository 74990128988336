import React from 'react';
import Header from "../components/common_parts/Header"
import Footer from "../components/common_parts/Footer"
import LoginTemplate from "../components/LoginTemplate"

function Login() {
  return (
    <section className="App">
        <Header />
            <LoginTemplate />
        <Footer />
    </section>
  );
}

export default Login;
