import axios from "axios";
import {BASE_URL} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint, checkType, checkMonthStyle, checkNumberOverflow} from "../../constants/CommonConstatns";
import {saveAs} from "file-saver";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// GET INSURANCE HISTORY
export const EXPORT_REPORT_REQUEST = "EXPORT_REPORT_REQUEST";
export const EXPORT_REPORT_SUCCESS = "EXPORT_REPORT_SUCCESS";
export const EXPORT_REPORT_FAILURE = "EXPORT_REPORT_FAILURE";
export const EXPORT_REPORT_CLEAR = "EXPORT_REPORT_CLEAR";

export const exportReportRequest = () => {
    return {
        type: EXPORT_REPORT_REQUEST
    }
}

export const exportReportSuccess = (data) => {
    return {
        type: EXPORT_REPORT_SUCCESS,
        data
    }
}

export const exportReportFailure = (err) => {
    return {
        type: EXPORT_REPORT_FAILURE,
        err
    }
}

export const exportReportClear = () => {
    return {
        type: EXPORT_REPORT_CLEAR
    }
}

const GET_INSRANCE_HISTORY = BASE_URL + "/api/skt_insurance_doc";

export const exportReport = (format, target) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        format: format,
        target_ym: target
    }
    return async(dispatch) => {
        dispatch(exportReportRequest());

        if (!_param_check(query)) {
            return dispatch(exportReportSuccess(400));
        }

        axios.get(cognitoEndpoint).then(
            response => {
               var pems = {};
               var keys = response.data['keys'];
               for(var i = 0; i < keys.length; i++) {
                   var key_id = keys[i].kid;
                   var modulus = keys[i].n;
                   var exponent = keys[i].e;
                   var key_type = keys[i].kty;
                   var jwk = { kty: key_type, n: modulus, e: exponent};
                   var jtp = jwkToPem(jwk);
                   pems[key_id] = jtp;
                }
                var decodedJwt = jwt.decode(idToken, {complete: true});
                if (!decodedJwt) {
                    console.log("Not a valid JWT token");
                    verify = false;
                    return dispatch(exportReportFailure("トークンの形式が不正です"));
                }
                if (decodedJwt.payload.aud !== config.ClientId) {
                    verify = false;
                    return dispatch(exportReportFailure("audクレームがアプリクライアント ID と一致しません"));
                }
                if (decodedJwt.payload.iss !== cognitoIss) {
                    console.log(decodedJwt);
                    verify = false;
                    return dispatch(exportReportFailure("issクレームがユーザープール ID と一致しません"));
                }

                var kid = decodedJwt.header.kid;
                var pem = pems[kid];
                if (!pem) {
                    console.log('kid not found');
                    verify = false;
                    return dispatch(exportReportFailure("kidが不正です"));
                }
                if(verify){
                    jwt.verify(idToken, pem, function(err, payload) {
                        if(err) {
                            console.log("Invalid Token.");
                            verify = false;
                            return dispatch(exportReportFailure(err));
                        } else {
                            console.log("Valid Token.");
                            const config = {
                                headers: {
                                    Authorization: idToken,
                                    ContentType: 'application/vnd.ms-excel;charset=UTF-8',
                                    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                },
                                responseType: "blob",
                                dataType: "binary"
                            }
                            axios.post(GET_INSRANCE_HISTORY, query, config).then(
                                res => {
                                    if (res.data === 400 || res.data === 500) {
                                        dispatch(exportReportSuccess(res.data))
                                    } else {
                                        const blob = new Blob([res.data], {
                                            type: res.data.type
                                        });
                                        const nameType = format === 1 ? "対象者報告書": "対象者データフォーマット"
                                        const dateInfo = target.replace("/", "");
                                        const fileName = dateInfo + "_" + nameType + ".xlsx";
                                        
                                        if (window.navigator.msSaveBlob) {
                                            window.navigator.msSaveBlob(blob, fileName)
                                        } else {
                                            saveAs(blob, fileName);
                                        }
                                    }
                                }
                            ).catch(
                                err => dispatch(exportReportFailure(err))
                                //res => dispatch(exportReportSuccess(200))
                            )
                        }
                    });
                }
            }
        ).catch(
            function(err) {
                return dispatch(exportReportFailure(err))
            }
        )
    }
}

export const clearReportState = () => {
    return (dispatch) => dispatch(exportReportClear())
}

const _param_check = (query) => {
    let check_ok = true;
    for (const key in query) {
        const target = query[key];
        
        if (!_data_map[key]) {
            return false;
        }

        if (_data_map[key] !== checkType(target)) {
            return false;
        }

        if (key === "format") {
            check_ok = checkNumberOverflow(target);
        } else if (key === "target_ym") {
            check_ok = checkMonthStyle(target);
        }

        if (!check_ok) {
            return false;
        }
    }
    return check_ok;
}

const _data_map = {
    "format": "number",
    "target_ym": "string"
}
