import React from "react";
import "../../../css/Common.css";
import "../../../css/History.css"
import Load from "../../atoms/Load";
import Error from "../../atoms/Error";
import Button from "../../atoms/Button";
import HistoryBody from "./HistoryBody";
import {connect} from "react-redux";
import {getInsuranceHistory, clearInsuranceHistory} from "../../../actions/skt/GetInsuranceHistoryAction";
import {toDoubleDigits} from "../../../constants/CommonConstatns";
import { exportReport, clearReportState } from "../../../actions/skt/ExportReportAction";

class HistoryView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callApi: 0,
            apiName: "",
            historyData: {},
            history: [],
            searchStartYear: 0,
            searchStartMonth: 0,
            searchEndYear: 0,
            searchEndMonth: 0,
            showError: false,
            errorMessage: "",
            isValidate: true
        }
    }

    componentDidMount = () => {
        this.setState({
            callApi: 0,
            historyData: [],
            searchYear: 0,
            searchMonth: 0,
            isError: false,
            showError: false,
            errorMessage: "",
            isValidate: true
        });
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        
        if ((nextProps.isSearchError) || (nextProps.isExportError)) {
            return {
                errorMessage: nextProps.isSearchError ? nextProps.historyData: nextProps.exportResult,
                showError: true
            }
        }
        
        if (prevState.historyData !== nextProps.historyData) {
            if (!nextProps.historyData || !nextProps.historyData.hasOwnProperty("history")) {
                return {
                    historyData: {},
                    history: []
                }
            }

            return {
                historyData: nextProps.historyData,
                history: nextProps.historyData.history
            }
        }
    }

    handleToDisplay = (changeTo) => {
        this.props.clearReport();
        this.props.clearInsuranceHistory();
        return this.props.changeDisp(changeTo)
    }

    closeAlertDisplay = () => {
        this.state.apiName === 
            "api/skt_insurance_history" ? 
                this.props.clearInsuranceHistory(): this.props.clearReport();

        this.setState({
            showError: false,
            errorMessage: ""
        })
    }

    searchHistoryFromSpan = () => {
        let targetStart = toDoubleDigits(this.state.searchStartYear) + "/" + toDoubleDigits(this.state.searchStartMonth);
        let targetEnd = toDoubleDigits(this.state.searchEndYear) + "/" + toDoubleDigits(this.state.searchEndMonth);
        if (targetStart === "00/00") {
            targetStart = "";
        }

        if (targetEnd === "00/00") {
            targetEnd = ""
        }

        if (this._validationCheck(targetStart, targetEnd)) {
            this.setState({
                "apiName": "api/skt_insurance_history",
                isValidate: true
            });
            
            this.props.getInsuranceHistory(targetStart, targetEnd);
        } else {
            this.setState({
                "apiName": "api/skt_insurance_history",
                isValidate: false
            });
        }
    }

    _validationCheck = (targetStart, targetEnd) => {
        // 先頭2文字が00の時はNG
        if (targetStart.slice(0, 2) === "00" || targetEnd.slice(0, 2 === "00")) {
            return false;
        }

        // 末尾2文字が00の時はNG
        if (targetStart.slice(-2) === "00" || targetEnd.slice(-2) === "00") {
            return false;
        }

        return true;
    }

    exportReportFile = (type, target) => {
        this.setState({
            "apiName": "api/skt_insurance_doc"
        });
        this.props.exportReport(type, target);
    }

    getSearchStartYearStates = (event) => {
        this.setState({
            searchStartYear: event.target.value
        });
    }

    getSearchStartMonthStates = (event) => {
        this.setState({
            searchStartMonth: event.target.value
        });
    }

    getSearchEndYearStates = (event) => {
        this.setState({
            searchEndYear: event.target.value
        });
    }

    getSearchEndMonthStates = (event) => {
        this.setState({
            searchEndMonth: event.target.value
        });
    }

    createSelectItems = (start, itr) => {
        const data = [
            {"code": 0, "name": ""}
        ];
        for (let i = 0; i < itr; i++) {
            const item = {}
            item.code = start + i;
            item.name = (start + i) + "";
            data.push(item);
        }
        return data.slice();
    }

    render() {
        
        const searchYearStates = this.createSelectItems(2020, 4);
        const searchMonthStates = this.createSelectItems(1, 12);

        const searchYearOptions = searchYearStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );

        const searchMonthOptions = searchMonthStates.map(
            (n)=>(
                <option key={n.code} value={n.code}>
                    {n.name}
                </option>
            )
        );

        let isDisplay = (this.state.isValidate) ? "errorArea notDisplay": "errorArea";

        return (
            <section>
                {this.props.searchFetch ? <Load setLabel="読み込み中..."/>: null}
                {this.props.exportFetch ? <Load setLabel="書き出し中..."/>: null}
                {this.state.showError ? <Error 
                    apiName={this.state.apiName} 
                    setError={this.state.errorMessage} 
                    close={this.closeAlertDisplay}/>: null}
                <nav className="navigationArea">
                    <ul className="navigationList layoutInner">
                        <li className="navigationItem" ><a className="navigationLink" onClick={() => this.handleToDisplay("insurance")}>保険申込</a></li>
                        <li className="navigationItem active">履歴管理</li>
                    </ul>
                </nav>
                <div className="breadcrumbArea layoutInner">
                    <ol className="breadcrumbList">
                        <li className="breadcrumbItem"><a className="breadcrumbLink" onClick={() => this.handleToDisplay("top")}>メニュー</a></li>
                        <li className="breadcrumbItem">履歴管理</li>
                    </ol>
                </div>
                <div className="layoutInner">
                    <ul className={isDisplay}>
                        <li className="errorText">「年」「月」の両方を設定してください</li>
                        <li className="errorText">開始・終了共に未設定の場合は、全件を検索します</li>
                    </ul>
                    <section className="searchRefineArea">
                        <h2 className="searchRefineHeading">検索項目</h2>
                        <div className="searchRefineContents">
                            <div className="searchHistoryItem">
                                <label htmlFor="term">対象年月</label>
                                <div className="selectWrapper searchHistoryItemSelect">
                                    <select value={this.state.searchStartYear} onChange={this.getSearchStartYearStates}>
                                        {searchYearOptions}
                                    </select>
                                </div>

                                <label>年</label>
                                
                                <div className="selectWrapper searchHistoryItemSelect">
                                    <select value={this.state.searchStartMonth} onChange={this.getSearchStartMonthStates}>
                                        {searchMonthOptions}
                                    </select>
                                </div>

                                <label>月　〜　</label>
                                
                                <div className="selectWrapper searchHistoryItemSelect">
                                    <select value={this.state.searchEndYear} onChange={this.getSearchEndYearStates}>
                                        {searchYearOptions}
                                    </select>
                                </div>
                                
                                <label>年</label>
                                
                                <div className="selectWrapper searchHistoryItemSelect">
                                    <select value={this.state.searchEndMonth} onChange={this.getSearchEndMonthStates}>
                                        {searchMonthOptions}
                                    </select>
                                </div>
                                <label>月</label>
                            </div>
                            <div id="historySearchBtnWrapper">
                                <Button label="検索" buttonEnable={true} callback={() => this.searchHistoryFromSpan()}/>
                            </div>
                        </div>
                    </section>
                    <div id="showHistoryList">
                        <h1>
                            保険申込履歴一覧
                        </h1>
                        <table border="1" bordercolor="#ffffff">
                            <thead>
                                <tr>
                                    <th rowSpan="2">対象年月</th>
                                    <th colSpan="4">スタンダードプラン</th>
                                    <th colSpan="3">短期プラン</th>
                                    <th rowSpan="2">加入者数</th>
                                    <th rowSpan="2">共済掛金額</th>
                                    <th rowSpan="2">申込書ダウンロード</th>
                                </tr>
                                <tr>
                                    <th>3ヶ月</th>
                                    <th>6ヶ月</th>
                                    <th>9ヶ月</th>
                                    <th>12ヶ月</th>
                                    <th>1ヶ月</th>
                                    <th>2ヶ月</th>
                                    <th>3ヶ月</th>
                                </tr>
                            </thead>
                            {
                                this.state.history.map((item) => {
                                    return(
                                        <HistoryBody 
                                            key={item.target_ym}
                                            itemData={item}
                                            exportReportFile={this.exportReportFile}
                                        />
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
            </section>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        historyData: state.insuranceHistoryData.responseData,
        searchFetch: state.insuranceHistoryData.isFetching,
        isSearchError: state.insuranceHistoryData.isError,
        exportFecth: state.exportReportFile.isFetch,
        exportResult: state.exportReportFile.responseData,
        isExportError: state.exportReportFile.isError
    }
}

const mapDispatchToProps = (dispatch) => ({
    getInsuranceHistory: (targetStart, targetEnd) => dispatch(getInsuranceHistory(targetStart, targetEnd)),
    clearInsuranceHistory: () => dispatch(clearInsuranceHistory()),
    exportReport: (format, target) => dispatch(exportReport(format, target)),
    clearReport: () => dispatch(clearReportState())
});


export default connect(mapStateToProps, mapDispatchToProps)(HistoryView);