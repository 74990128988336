import axios from "axios";
import {BASE_URL, checkType, checkNumberOverflow} from "../../constants/CommonConstatns";
import {getIdToken, cognitoIss, cognitoEndpoint} from "../../constants/CommonConstatns";
const config = require('../../components/config');
const jwkToPem = require('jwk-to-pem');
const jwt = require('jsonwebtoken');

// ENTRY JOB POSTING
export const ENTRY_JOB_POSTING_REQUEST = "ENTRY_JOB_POSTING_REQUEST";
export const ENTRY_JOB_POSTING_SUCCESS = "ENTRY_JOB_POSTING_SUCCESS";
export const ENTRY_JOB_POSTING_FAILURE = "ENTRY_JOB_POSTING_FAILURE";
export const ENTRY_JOB_POSTING_RESET = "ENTRY_JOB_POSTING_RESET";

export const entryJobPostingRequest = () => {
    return {
        type: ENTRY_JOB_POSTING_REQUEST
    }
}

export const entryJobPostingSuccess = (data) => {
    return {
        type: ENTRY_JOB_POSTING_SUCCESS,
        data
    }
}

export const entryJobPostingFailure = (err) => {
    return {
        type: ENTRY_JOB_POSTING_FAILURE,
        err
    }
}

export const entryJobPostingReset = () => {
    return {
        type: ENTRY_JOB_POSTING_RESET
    }
}

const ENTRY_JOB_POSTING = BASE_URL + "/api/entry_job_posting";

export const entryJobPosting = (jobId, status) => {
    const idToken = getIdToken();
    let verify = true;
    const query = {
        job_id: jobId,
        status: status
    }
    return async(dispatch) => {
        dispatch(entryJobPostingRequest());
        if (!_check_param(query)){
            return dispatch(entryJobPostingSuccess(400));
        }else{
            axios.get(cognitoEndpoint).then(
                response => {
                    var pems = {};
                    var keys = response.data['keys'];
                    for(var i = 0; i < keys.length; i++) {
                        var key_id = keys[i].kid;
                        var modulus = keys[i].n;
                        var exponent = keys[i].e;
                        var key_type = keys[i].kty;
                        var jwk = { kty: key_type, n: modulus, e: exponent};
                        var jtp = jwkToPem(jwk);
                        pems[key_id] = jtp;
                    }
                    var decodedJwt = jwt.decode(idToken, {complete: true});
                    if (!decodedJwt) {
                        console.log("Not a valid JWT token");
                        verify = false;
                        return dispatch(entryJobPostingFailure("トークンの形式が不正です"));
                    }
                    if (decodedJwt.payload.aud !== config.ClientId) {
                        verify = false;
                        return dispatch(entryJobPostingFailure("audクレームがアプリクライアント ID と一致しません"));
                    }
                    if (decodedJwt.payload.iss !== cognitoIss) {
                        console.log(decodedJwt);
                        verify = false;
                        return dispatch(entryJobPostingFailure("issクレームがユーザープール ID と一致しません"));
                    }

                    var kid = decodedJwt.header.kid;
                    var pem = pems[kid];
                    if (!pem) {
                        console.log('kid not found');
                        verify = false;
                        return dispatch(entryJobPostingFailure("kidが不正です"));
                    }
                    if(verify){
                        jwt.verify(idToken, pem, function(err, payload) {
                            if(err) {
                                console.log("Invalid Token.");
                                verify = false;
                                return dispatch(entryJobPostingFailure(err));
                            } else {
                                console.log("Valid Token.");
                                const headerParam = {
                                    Authorization: idToken
                                }
                                axios.post(ENTRY_JOB_POSTING, query, {headers: headerParam}).then(
                                    res => dispatch(entryJobPostingSuccess(res.data))
                                ).catch(
                                    err => dispatch(entryJobPostingFailure(err))
                                    //res => dispatch(entryJobPostingSuccess(200))
                                )
                            }
                        });
                    }
                }
            ).catch(
                function(err) {
                    return dispatch(entryJobPostingFailure(err))
                }
            )
        }
    }
}

export const jobEntryActionReset = () => {
    return (dispatch) => dispatch(entryJobPostingReset());
}

const _check_param = (query) => {
    let check_ok = true;
    for (const key in query) {
        const target = query[key];
        
        if (!_data_map[key]) {
            return false;
        }

        if (_data_map[key] !== checkType(target)) { 
            return false;
        }

        if (key === "job_id") {
            check_ok = checkNumberOverflow(target);
        } else if (key === "status") {
            check_ok = checkNumberOverflow(target);
        }

        if (!check_ok) {
            return false;
        }
    }
    return check_ok;
}

const _data_map = {
    "job_id": "number",
    "status": "number"
}
